import {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { WithChildren } from 'interfaces/children';
import { InteractionsOpenState, InteractionsTabs } from 'constants/enums';

type ContextType = {
  openState: InteractionsOpenState;
  setOpenState: (state: InteractionsOpenState) => void;
  tab: InteractionsTabs;
  setTab: (tab: InteractionsTabs) => void;
  availableTabs: InteractionsTabs[];
  setAvailableTabs: (tabs: SetStateAction<InteractionsTabs[]>) => void;
  hasCollapse: boolean;
  setHasCollapse: (isOpen: boolean) => void;
};

const defaultValue: ContextType = {
  openState: InteractionsOpenState.closed,
  setOpenState: () => undefined,
  tab: InteractionsTabs.networking,
  setTab: () => undefined,
  availableTabs: [InteractionsTabs.networking, InteractionsTabs.chat],
  setAvailableTabs: () => undefined,
  hasCollapse: false,
  setHasCollapse: () => undefined,
};

export const InteractionsContext = createContext<ContextType>(defaultValue);

export const InteractionsProvider = ({ children }: WithChildren) => {
  const [openState, setOpenState] = useState(defaultValue.openState);
  const [tab, setTab] = useState(defaultValue.tab);
  const [availableTabs, setAvailableTabs] = useState<InteractionsTabs[]>(
    defaultValue.availableTabs,
  );
  const [hasCollapse, setHasCollapse] = useState(defaultValue.hasCollapse);

  useEffect(() => {
    const [firstTab] = availableTabs;

    setTab(
      (currentTab) =>
        availableTabs.find((availableTab) => availableTab === currentTab) ??
        firstTab,
    );
  }, [availableTabs]);

  return (
    <InteractionsContext.Provider
      value={{
        openState,
        setOpenState,
        tab,
        setTab,
        availableTabs,
        setAvailableTabs,
        hasCollapse,
        setHasCollapse,
      }}
    >
      {children}
    </InteractionsContext.Provider>
  );
};

export const useInteractionsContext = () => useContext(InteractionsContext);
