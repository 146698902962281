type MapStyleFactory = {
  elementType?: string;
  featureType?: string;
  color?: string;
  visibility?: 'on' | 'off';
};

const labelsTextField = 'labels.text.fill';
const labelsTextStroke = 'labels.text.stroke';
const poi = 'poi';
const poiPark = 'poi.park';
const geometry = 'geometry';
const road = 'road';
const roadArterial = 'road.arterial';
const roadHighway = 'road.highway';
const roadLocal = 'road.local';
const transit = 'transit';
const water = 'water';

const mapStyleFactory = ({ color, visibility }: MapStyleFactory) =>
  JSON.parse(JSON.stringify([{ color }, { visibility }])).filter(
    (styler: MapStyleFactory) => !!Object.keys(styler).length,
  );

export const useStyles = () => [
  {
    elementType: geometry,
    stylers: mapStyleFactory({ color: '#212121' }),
  },
  {
    elementType: 'labels.icon',
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    elementType: labelsTextField,
    stylers: mapStyleFactory({ color: '#757575' }),
  },
  {
    elementType: labelsTextStroke,
    stylers: mapStyleFactory({ color: '#212121' }),
  },
  {
    featureType: 'administrative',
    elementType: geometry,
    stylers: mapStyleFactory({ color: '#757575' }),
  },
  {
    featureType: 'administrative.country',
    elementType: labelsTextField,
    stylers: mapStyleFactory({ color: '#9e9e9e' }),
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    featureType: 'administrative.locality',
    elementType: labelsTextField,
    stylers: mapStyleFactory({ color: '#bdbdbd' }),
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    featureType: 'administrative.province',
    elementType: geometry,
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    featureType: 'administrative.province',
    elementType: 'labels',
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    featureType: poi,
    elementType: 'labels.text',
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    featureType: poi,
    elementType: labelsTextField,
    stylers: mapStyleFactory({ color: '#757575' }),
  },
  {
    featureType: 'poi.business',
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    featureType: poiPark,
    elementType: geometry,
    stylers: mapStyleFactory({ color: '#181818' }),
  },
  {
    featureType: poiPark,
    elementType: labelsTextField,
    stylers: mapStyleFactory({ color: '#616161' }),
  },
  {
    featureType: poiPark,
    elementType: labelsTextStroke,
    stylers: mapStyleFactory({ color: '#1b1b1b' }),
  },
  {
    featureType: road,
    elementType: 'geometry.fill',
    stylers: mapStyleFactory({ color: '#2c2c2c' }),
  },
  {
    featureType: road,
    elementType: 'labels',
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    featureType: road,
    elementType: 'labels.icon',
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    featureType: road,
    elementType: labelsTextField,
    stylers: mapStyleFactory({ color: '#8a8a8a' }),
  },
  {
    featureType: roadArterial,
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    featureType: roadArterial,
    elementType: geometry,
    stylers: mapStyleFactory({ color: '#373737' }),
  },
  {
    featureType: roadHighway,
    elementType: geometry,
    stylers: mapStyleFactory({ color: '#3c3c3c' }),
  },
  {
    featureType: roadHighway,
    elementType: 'labels',
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: geometry,
    stylers: mapStyleFactory({ color: '#4e4e4e' }),
  },
  {
    featureType: roadLocal,
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    featureType: roadLocal,
    elementType: labelsTextField,
    stylers: mapStyleFactory({ color: '#616161' }),
  },
  {
    featureType: transit,
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    featureType: transit,
    elementType: labelsTextField,
    stylers: mapStyleFactory({ color: '#757575' }),
  },
  {
    featureType: water,
    elementType: geometry,
    stylers: mapStyleFactory({ color: '#000000' }),
  },
  {
    featureType: water,
    elementType: 'labels.text',
    stylers: mapStyleFactory({ visibility: 'off' }),
  },
  {
    featureType: water,
    elementType: labelsTextField,
    stylers: mapStyleFactory({ color: '#3d3d3d' }),
  },
];
