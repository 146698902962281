/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
// import { useUpdateUser } from 'useCases/users';
import { useTranslate } from 'hooks';
import { useCountdown } from 'hooks/useCountdown';
import { setPadStart } from 'lib/contexts/accreditation';
import { getUserId } from 'lib/core/session';
import { ThemeCustomProvider } from 'contexts';
import { Logo } from 'components/structure';
import { SubTitle, Icon, Heading, Paragraph } from 'components/structure';
import * as CountdownTheme from 'styles/overrides/Countdown.theme';
import { THEME } from 'styles/themes';
import { SocialLink } from 'interfaces/event';
import { User, UserAPI } from 'interfaces/user';
import { USERS } from 'constants/endpoints';
import { ROUTES } from 'constants/urls';
import * as S from './Countdown.styles';

type Countdown = {
  total: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

type CountdownProps = {
  countdown?: Countdown;
  socialLinks?: SocialLink[] | null;
  eventDate: string;
  hashtags?: string[];
  hasFooterDescription?: boolean;
  hasFooterTitle?: boolean;
  footerTitle?: string;
  subTitle?: string;
  hasMemberGetMember?: boolean;
};

type Icons = {
  [key: string]: () => JSX.Element;
};

type Periods = 'days' | 'hours' | 'minutes' | 'seconds';

const icons: Icons = {
  linkedin: () => <Icon icon="IcLinkedinSmall" />,
  youtube: () => <Icon icon="IcYoutube" />,
  twitter: () => <Icon icon="IcTwitter" />,
  instagram: () => <Icon icon="IcInstragram" />,
  facebook: () => <Icon icon="IcFacebook" />,
};

const socialsLinks = [
  { icon: '', links: '' },
  { icon: '', links: '' },
  { icon: '', links: '' },
];

const getIcon = (name: string) => icons[name.toLowerCase()]();
const Countdown = ({
  eventDate,
  socialLinks,
  hashtags,
  hasFooterTitle = false,
  footerTitle,
  subTitle,
  hasMemberGetMember,
}: CountdownProps) => {
  const translate = useTranslate();
  const [dots, setDots] = useState<string>('.');
  const [countDown, setCountDown] = useState<boolean>(true);
  const navigate = useNavigate();
  const userId = getUserId();
  // const { updateUser } = useUpdateUser();
  const { days, hours, minutes, seconds } = useCountdown(eventDate);
  const user: User = {
    id: userId!,
  };
  const checkTimeRemaining = (time: Periods) => {
    if (time === 'days' && days) return time;

    if (time === 'hours' && !days && hours) return time;

    if (time === 'minutes' && !days && !hours && minutes) return time;

    if (time === 'seconds' && !days && !hours && !minutes && seconds)
      return time;
  };
  const checkCountdown = () => {
    days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0
      ? setCountDown(false)
      : null;
  };

  useEffect(() => {
    if (days || minutes || hours || seconds) {
      checkCountdown();
    }
  }, [days, minutes, hours, seconds]);

  useEffect(() => {
    (async () => {
      const { data } = await api.patch<UserAPI>(USERS.ROOT, {
        ...user,
        completeRegistration: true,
      });
    })();
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((dots: string) => {
        if (dots === '...') {
          return '.';
        } else {
          return dots + '.';
        }
      });
    }, 700);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <ThemeCustomProvider {...CountdownTheme}>
      <S.Container hasMemberGetMember={hasMemberGetMember}>
        <S.Content>
          <S.LogoContainer>
            <Logo />
          </S.LogoContainer>
          {/* <Icon
            style={{ position: 'absolute', top: '50px', bottom: '0px' }}
            icon="IcBayer"
            width={'40px'}
            height={'40px'}
          /> */}
          <S.Card>
            {countDown === true ? (
              <>
                <S.Title>{translate('accreditation.countdown.title')}</S.Title>
                <S.TitleTwo>
                  {translate('accreditation.countdown.subTitle')}
                </S.TitleTwo>
                {/* <S.TitleEnd>
                  {translate('accreditation.countdown.subTitleEnd')}
                </S.TitleEnd> */}
                <S.CountdownContainer>
                  <S.CurrentTimeContainer
                    isActive={!!checkTimeRemaining('days')}
                  >
                    <S.CountText>
                      {days >= 0 ? setPadStart(days) : '00'}
                    </S.CountText>
                    <S.PeriodText>
                      {translate('accreditation.countdown.days')}
                    </S.PeriodText>
                  </S.CurrentTimeContainer>

                  <S.CurrentTimeContainer
                    isActive={!!checkTimeRemaining('hours')}
                  >
                    <S.CountText>
                      {hours >= 0 ? setPadStart(hours) : '00'}
                    </S.CountText>
                    <S.PeriodText>
                      {translate('accreditation.countdown.hours')}
                    </S.PeriodText>
                  </S.CurrentTimeContainer>

                  <S.CurrentTimeContainer
                    isActive={!!checkTimeRemaining('minutes')}
                  >
                    <S.CountText>
                      {minutes >= 0 ? setPadStart(minutes) : '00'}
                    </S.CountText>
                    <S.PeriodText>
                      {translate('accreditation.countdown.minutes')}
                    </S.PeriodText>
                  </S.CurrentTimeContainer>

                  <S.CurrentTimeContainer
                    isActive={!!checkTimeRemaining('seconds')}
                  >
                    <S.CountText>
                      {seconds >= 0 ? setPadStart(seconds) : '00'}
                    </S.CountText>
                    <S.PeriodText>
                      {translate('accreditation.countdown.seconds')}
                    </S.PeriodText>
                  </S.CurrentTimeContainer>
                </S.CountdownContainer>
              </>
            ) : (
              <S.WaitingStart>
                <h1>Aguarde</h1>
                <h1>Começaremos em breve </h1>
                <h3> {dots}</h3>
              </S.WaitingStart>
            )}
            <S.SocialLinksContainer>
              {hasFooterTitle && <S.FooterTitle>{footerTitle}</S.FooterTitle>}
              {hashtags?.map((item) => (
                <strong key={item}>{item}</strong>
              ))}

              {socialLinks && (
                <S.SocialLinksList>
                  {socialLinks?.map(({ name, link }) => (
                    <a
                      key={name}
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getIcon(name)}
                    </a>
                  ))}
                </S.SocialLinksList>
              )}
            </S.SocialLinksContainer>
            <S.CountdownFooter>
              <S.ContentFooter>
                <S.FooterDescription>
                  {translate('accreditation.countdown.footerDescription')}
                </S.FooterDescription>
              </S.ContentFooter>
            </S.CountdownFooter>
          </S.Card>
        </S.Content>
        {hasMemberGetMember && (
          <S.Footer>
            <S.Aside>
              <Icon
                icon="IcChevronLeft"
                onClick={() =>
                  navigate(ROUTES.countdown.getLink('userCountdown'))
                }
              />

              <Heading>{translate('accreditation.countdown.rewards')}</Heading>
            </S.Aside>
            <THEME.Logo />
          </S.Footer>
        )}
      </S.Container>
    </ThemeCustomProvider>
  );
};

export default Countdown;
