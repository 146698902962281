import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import * as TypographyStyles from 'components/structure/Typography/Typography';
import { ErrorSearchProps } from './ErrorSearch';

const containerModifiers = {
  primary: (theme: DefaultTheme) => css`
    > svg {
      color: ${theme.text.secondary};
    }

    ${TypographyStyles.Heading}, ${TypographyStyles.Paragraph} {
      color: ${theme.text.secondary};
      text-align: center;
    }
  `,
  secondary: (theme: DefaultTheme) => css`
    > svg {
      color: ${theme.text.secondary};
    }

    ${TypographyStyles.Heading}, ${TypographyStyles.Paragraph} {
      color: ${theme.text.secondary};
    }
  `,
};

export const Container = styled.div<ErrorSearchProps>`
  ${({ theme, color }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 ${theme.spacing.sm};
    overflow: hidden;

    ${TypographyStyles.Heading} {
      color: ${theme.text.secondary};
      margin-top: ${theme.spacing.sm};
      font-size: ${theme.typography.sizes.lg};
      margin-bottom: 0;
      line-height: ${theme.typography.sizes.xl};
      text-align: center;
    }

    ${TypographyStyles.Paragraph} {
      color: ${theme.text.secondary};
      margin-top: ${theme.spacing.xs};
      line-height: ${theme.typography.sizes.lg};
      text-align: center;
    }

    ${color && containerModifiers[color](theme)}

    ${media.lessThan('medium')`

      svg {
        width: 28px;
        height: 28px;
      }

      ${TypographyStyles.Heading} {
        font-size: ${theme.typography.sizes.base};
        line-height: ${theme.typography.sizes.lg};
      }
    `}
  `}
`;

export const IcSearch = styled.div<{ src: string }>`
  ${({ src }) => css`
    background-image: url(${src});
    background-size: cover;
    width: 46px;
    height: 46px;
  `}
`;
