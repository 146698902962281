import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { dispatchGAEvent } from 'services/google';
import { useEvent } from 'useCases/events';
import { useNetworking, Connections } from 'useCases/networking';
import { useClickOutside, useInfiniteScroll, useTranslate } from 'hooks';
import { useDebounce } from 'hooks/useDebounce';
import { brazilianStates } from 'components/contexts/accreditation/BusinessCardForm/Default/BusinessCardForm.mock';
import { Avatar, UserCard } from 'components/contexts/users';
import { MultiSelect, Options, SearchField } from 'components/form';
import { ErrorSearch, FormGroup, Spinner } from 'components/structure';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { User } from 'interfaces/user';
import { InteractionsTabs, BRAZIL_COUNTRY_CODE } from 'constants/enums';
import { GA_EVENTS } from 'constants/enums';
import { Skeleton } from './Networking.skeleton';
import * as S from './Networking.styles';

type FormModel = User;

export type NetworkingProps = {
  handleOpenPrivateChat: (id: string) => void;
  setTab: (tab: InteractionsTabs) => void;
  isPending?: boolean;
  isChatDisabled?: boolean;
  hasInterests?: boolean;
};

export type Params = {
  name?: string;
  interests?: string[];
};

//TODO check infinite react updates
export const Networking = ({
  handleOpenPrivateChat,
  setTab,
  isPending = false,
  isChatDisabled = false,
  hasInterests = false,
}: NetworkingProps) => {
  const { register, control } = useForm<FormModel>({
    defaultValues: {
      name: '',
      interests: [],
    },
    shouldFocusError: false,
  });
  const translate = useTranslate();
  const { data } = useEvent();
  const [selected, setSelected] = useState('');
  const [uniqueConnections, setUniqueConnections] = useState<Connections[]>([]);
  const [params, setParams] = useState<Params>({
    interests: [],
    name: '',
  });
  const { useOnlineUsers } = useNetworking();
  const clickOutSideRef = useClickOutside(() => setSelected(''));
  const {
    data: { connections } = {},
    isLoading,
    isLoadingMore,
    hasMore,
    loadMoreUsers,
  } = useOnlineUsers(params);

  const lastUserConnectedRef = useInfiniteScroll(
    isLoadingMore,
    hasMore,
    loadMoreUsers,
  );

  const getUserConnectedRef = (position: number, arraySize: number) =>
    position === arraySize - 1 ? lastUserConnectedRef : null;

  const handleSelectFirstName = (name: string) => name.trim().split(' ')[0];
  const handleUserState = (country: string, state: string) => {
    const userState = brazilianStates.find(({ value }) => value === state)
      ?.label;

    return `${userState}, ${country}`;
  };

  const handleFilterByName = useDebounce(
    (value: string) =>
      setParams((prevState) => ({ ...prevState, name: value })),
    500,
  );

  const handleFilterByInterest = (value: Options[]) => {
    setParams((prevState) => ({
      ...prevState,
      interests: value.map((interest) => interest.id),
    }));
  };

  const handlePrivateChat = (id: string) => {
    handleOpenPrivateChat(id);
    setTab(InteractionsTabs.chat);
  };

  useEffect(() => {
    const uniqueItemsItems: string[] = [];
    const uniqueItems: Connections[] = [];
    connections?.forEach((connection) => {
      if (!uniqueItemsItems.includes(connection.id)) {
        uniqueItems.push(connection);
        uniqueItemsItems.push(connection.id);
      }
    });
    setUniqueConnections(uniqueItems);
  }, [connections]);

  if (isPending || (isLoading && !params.interests?.length && !params.name)) {
    return <Skeleton />;
  }

  return (
    <S.Wrapper hasUserSelected={!!selected}>
      <form>
        <FormGroup hasMargin={false}>
          <SearchField
            ref={register}
            type="uuid"
            name="name"
            autoComplete="off"
            placeholder={translate('interactions.searchByName')}
            onChange={({ target: { value } }) => handleFilterByName(value)}
          />
          {hasInterests === true ? (
            <MultiSelect
              control={control}
              name="interests"
              label={translate('interactions.selectInterests')}
              placeholder={translate('interactions.selectInterests')}
              options={data?.event.interests}
              onChange={handleFilterByInterest}
            />
          ) : (
            ''
          )}
        </FormGroup>
      </form>

      <S.AvailableUsers noGrid={!uniqueConnections.length}>
        {uniqueConnections?.length ? (
          <S.List>
            {uniqueConnections.map(
              ({ id, avatar, name, country, state }, index, connections) => (
                <S.UserWrap
                  ref={id === selected ? clickOutSideRef : null}
                  key={id}
                  isSelected={id === selected}
                >
                  <S.UserItem
                    ref={getUserConnectedRef(index, connections.length)}
                    onClick={() =>
                      setSelected((prevState) => (prevState === id ? '' : id))
                    }
                  >
                    <Avatar src={avatar} alt={name} />
                    <span>{handleSelectFirstName(name)}</span>
                  </S.UserItem>
                  <UserCard
                    handlePrivateChat={() => {
                      handlePrivateChat(id);
                      dispatchGAEvent({
                        action: EventAction.click,
                        category: EventCategory.Chat,
                        label: GA_EVENTS.chat.labels.talk,
                      });
                    }}
                    handleCloseCard={() => setSelected('')}
                    user={{
                      ...connections[index],
                      country:
                        country.value === BRAZIL_COUNTRY_CODE
                          ? handleUserState(country.label, state!)
                          : country.label,
                    }}
                    isChatDisabled={isChatDisabled}
                  />
                </S.UserWrap>
              ),
            )}
          </S.List>
        ) : (
          !isLoading &&
          (!!params.name || !!params.interests?.length) && <ErrorSearch />
        )}

        {(isLoading || isLoadingMore) && (
          <S.SpinnerWrap>
            <Spinner />
          </S.SpinnerWrap>
        )}
      </S.AvailableUsers>
    </S.Wrapper>
  );
};
