import { useTranslate } from 'hooks';
import { PlenaryCard } from 'components/contexts/home';
import { Icon } from 'components/structure';
import { Stream } from 'interfaces/stream';
import { ROUTES } from 'constants/urls';
import * as S from './OtherStreams.styles';

export type OtherStreamsProps = {
  streams?: Stream[];
};

export const OtherStreams = ({ streams = [] }: OtherStreamsProps) => {
  const translate = useTranslate();

  return (
    <S.Wrapper>
      <S.StreamsWrapper>
        {streams.map(
          ({
            id,
            title,
            state,
            scheduledStartTime,
            scheduledEndTime,
            thumbnail,
            description,
          }) => (
            <PlenaryCard
              small
              key={`plenary-card-${id}`}
              id={id}
              title={title}
              thumbnail={thumbnail}
              description={description}
              streamState={state}
              scheduledStartTime={scheduledStartTime}
              scheduledEndTime={scheduledEndTime}
            />
          ),
        )}
      </S.StreamsWrapper>
      <S.LinkButton to={ROUTES.schedule.getLink('base')}>
        <Icon icon="IcCalendar" />
        {translate('labels.seeSchedule')}
      </S.LinkButton>
    </S.Wrapper>
  );
};
