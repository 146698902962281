import { dispatchGAEvent } from 'services/google';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { GA_EVENTS } from 'constants/enums';
import * as S from './WallpaperCard.styles';

export type WallpaperCardProps = {
  title?: string;
  description?: string;
  image?: string;
  urlDownload?: string;
};

export const WallpaperCard = ({
  title,
  description,
  image,
  urlDownload,
}: WallpaperCardProps) => {
  const handleClick = () => {
    dispatchGAEvent({
      category: EventCategory.Coffee,
      action: EventAction.click,
      label: GA_EVENTS.coffee.labels.wallpaperCard,
    });
  };

  return (
    <a download href={urlDownload} onClick={handleClick}>
      <S.Container>
        <S.AsideContainer>
          <S.Heading>{title}</S.Heading>

          <S.Description>{description}</S.Description>
        </S.AsideContainer>

        <img src={image} alt="wallpaper" />
      </S.Container>
    </a>
  );
};
