import { NavLink } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { MenuLinkType } from 'components/contexts/users/UserMenu/FloatingLink/FloatingLink.styles';
import { Button, Paragraph } from 'components/structure';
import * as ChangeLanguageSelect from 'components/structure/ChangeLanguageSelect/ChangeLanguageSelect.styles';
import * as LogoStyles from 'components/structure/Logo/Logo.styles';
import * as PopoverStyles from 'components/structure/Popover/Popover.styles';
import { NavbarProps } from './Navbar';

type CommonProps = {
  isOpen?: boolean;
};

type MenuItemMoreProps = {
  show?: boolean;
};

type MenuNavProps = {
  disableFocus?: boolean;
};

type WrapperProps = Pick<NavbarProps, 'hasInteractions'>;

const wrapperModifiers = {
  hasInteractions: (theme: DefaultTheme) => css`
    padding-left: ${theme.spacing.md};
    padding-right: ${theme.spacing.md};
    max-width: calc(100% - ${'360px'});

    ${media.lessThan('large')`
      max-width: 100%;
    `}
  `,
};
export const IconButton = styled(Button)`
  ${({ theme }) => css`
    && {
      width: 60px;
      min-width: 20px;
      background-color: ${theme.primary};
      color: ${theme.secondary};
      min-height: 30px;
      height: 32px;
      margin-right: ${theme.spacing.md};
      border: 1px solid ${theme.primary};
      border-radius: 20px;
      svg {
        color: ${theme.secondary};
        width: 18px;
        height: 18px;
      }
    }
  `}
`;

export const Wrapper = styled.menu<WrapperProps>`
  ${({ theme, hasInteractions }) => css`
    background-color: ${theme.headerBackground};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${theme.spacing['2xl']};
    height: ${'80px'};
    transition: max-width 0.2s linear;
    position: fixed;
    left: 0;
    top: 0;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    width: 100vw;
    z-index: 99;

    ${media.lessThan('large')`
      height: ${'56px'};
      padding: 0 ${theme.spacing.sm};
    `}

    ${hasInteractions && wrapperModifiers.hasInteractions(theme)};
  `}
`;

export const WrapperTranslation = styled.div`
  & ${PopoverStyles.Content} {
    bottom: -125px;
    left: -50px;
  }
`;

export const NavbarActions = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    ${ChangeLanguageSelect.LanguageContainer} {
      margin-right: ${theme.spacing.md};
    }
    ${ChangeLanguageSelect.LanguageDisplay} {
      margin-top: 0;

      strong {
        font-size: ${theme.typography.sizes.base};
      }
      svg {
        height: 9px;
      }
    }
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.primary};
    height: 30px;
    display: flex;
  `}
`;

export const LogoWrapper = styled.div`
  svg {
    height: 56px;
  }
  ${media.lessThan('large')`


    ${LogoStyles.Wrapper} {
      /* height: 40px; */
    }
  `}
  ${media.lessThan('medium')`
  /* svg {
     height: 100px;
    } */

  `}
`;

export const MenuLink = styled(NavLink)<MenuLinkType>`
  ${({ theme, disabled }) => css`
    color: ${disabled ? theme.text.secondary : theme.text.secondary};
    cursor: pointer;
    font-size: ${theme.typography.sizes.base};
    line-height: ${theme.typography.lineHeight.base};
    display: inline-flex;
    align-items: center;
    transition: color 0.3s linear;

    &.active {
      color: ${theme.primary};
    }

    &.active-disable {
      color: ${theme.secondary};
    }

    svg {
      margin-right: ${theme.spacing.xs};
      height: 24px;
      width: 24px;
    }

    &:not(:last-child) {
      margin-right: ${theme.spacing.md};
    }

    &:hover {
      color: ${theme.primary};
    }

    ${media.lessThan('large')`
      color: ${theme.primary};
      font-size: ${theme.typography.sizes.lg};
      line-height: ${theme.typography.lineHeight.base};
      font-weight: 700;
      margin-bottom: ${theme.spacing.sm};
      color: ${theme.primary}

      &,
      &:not(:last-child) {
        margin-right: 0;
        color: ${theme.primary}
      }
    `}
  `}
`;

export const MenuButtonLogout = styled.button`
  ${({ theme }) => css`
    color: ${theme.secondary};
    font-size: ${theme.typography.sizes.lg};
    line-height: ${theme.typography.lineHeight.base};
    font-weight: 700;
    margin-bottom: ${theme.spacing.sm};
  `}
`;

export const MenuGroup = styled.div`
  display: flex;
  align-items: center;
`;

const menuNavModifiers = {
  disableFocus: (theme: DefaultTheme) => css`
    & ${MenuLink} {
      color: ${theme.text.secondary};
    }
  `,
};

export const MenuNav = styled.div<MenuNavProps>`
  ${({ theme, disableFocus }) => css`
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing.md};

    ${media.lessThan('large')`
      flex-direction: column;
      justify-content: flex-start;
      flex: 1;
      align-items: flex-end;
    `}

    ${disableFocus && menuNavModifiers.disableFocus(theme)}
  `}
`;

const menuItemMoreModifiers = {
  show: (theme: DefaultTheme) => css`
    ${MenuLink}, ${MenuButtonLogout} {
      opacity: 1;
    }

    a {
      opacity: 1;
    }

    &,
    span {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: ${theme.primary};
        right: -${theme.spacing.xs};
      }
    }

    span {
      &:after {
        width: 3px;
      }
    }
  `,
};

export const MenuItemMore = styled.div<MenuItemMoreProps>`
  ${({ theme, show }) => css`
    color: ${theme.primary};
    font-size: ${theme.typography.sizes.lg};
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: end;
    a {
      &.active {
        color: ${theme.primary};
      }
    }

    span {
      margin-bottom: ${theme.spacing.sm};
    }

    ${MenuLink}, ${MenuButtonLogout}, a {
      opacity: 0;
      visibility: ${!show ? 'hidden' : 'visible'};
      transition: opacity 0.2s ease-in-out;
      color: ${theme.primary};
      margin-bottom: ${theme.spacing.sm};
    }

    &:after {
      content: '';
      transition: all 0.2s ease-in-out;
    }

    ${show && menuItemMoreModifiers.show(theme)}
  `}
`;

const menuFullModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: all;
  `,
};

export const MenuFull = styled.nav<CommonProps>`
  ${({ theme, isOpen = false }) => css`
    display: flex;
    opacity: 0;
    flex-direction: column;
    background-color: white;
    position: fixed;
    width: 100%;
    top: ${'56px'};
    left: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1002;
    transition: all 0.5s ease;
    padding-top: ${theme.spacing.lg};

    height: calc(100vh - ${'56px'});

    ${isOpen && menuFullModifiers.open()};
  `}
`;

export const MenuIconLabel = styled.label`
  height: 100%;
`;

export const MenuIcon = styled.span`
  ${({ theme }) => css`
    margin-top: 15px;
    position: relative;
    display: block;
    background: ${theme.primary};
    width: 24px;
    border-radius: 2px;
    height: 2px;
    transition: 0.5s ease-in-out;

    &::after,
    &::before {
      background: ${theme.primary};
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      transition: 0.5s ease-in-out;
      border-radius: 2px;
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -6px;
    }
  `}
`;

export const MenuIconContainer = styled.input`
  position: relative;
  display: none;

  &:checked ~ label span {
    transform: rotate(45deg);
  }

  &:checked ~ label span::before {
    transform: rotate(90deg);
    top: 0;
  }

  &:checked ~ label span::after {
    transform: rotate(90deg);
    top: 0;
  }
`;
