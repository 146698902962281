import { useTranslate } from 'hooks';
import { getLocalHourFromDate, removeSecondsFromHour } from 'lib/contexts';
import { Icon } from 'components/structure';
import { SubTitle } from 'components/structure/Typography/Typography';
import { NotificationItem } from 'interfaces/notification';
import { NotificationTypes } from 'constants/enums';
import * as S from './NotificationList.styles';

export type NotificationListProps = {
  items: NotificationItem[];
  handleClear: () => void;
  handleClick: (notification: NotificationItem) => void;
};

export const NotificationList = ({
  items,
  handleClear,
  handleClick,
}: NotificationListProps) => {
  const translate = useTranslate();

  const getTranslation = (type: NotificationTypes, count: number) =>
    count > 1
      ? translate.withValues(`notification.${type}s`, { count })
      : translate(`notification.${type}`);

  return (
    <S.Wrapper>
      <S.Header>
        <SubTitle>{translate('notification.title')}</SubTitle>
        {Boolean(items.length) && (
          <S.ButtonRemove onClick={handleClear}>
            <Icon icon="IcTrash" width="16px" height="16px" />
          </S.ButtonRemove>
        )}
      </S.Header>
      {!items.length && (
        <S.NoData>{translate('notification.notAvailable')}</S.NoData>
      )}
      <S.Container>
        {items
          .sort((a, b) => b.time.getTime() - a.time.getTime())
          .map((notification, index) => (
            <S.Item
              key={`${notification.id}-${index}`}
              onClick={() => handleClick(notification)}
            >
              <S.ItemStatus viewed={notification.viewed} />
              <S.ItemContent divider={items.length - 1 !== index}>
                <S.ItemContentText>
                  <strong>{notification.name}</strong>{' '}
                  {notification.excerpt ??
                    getTranslation(
                      notification.type,
                      notification.count ?? 1,
                    )}{' '}
                </S.ItemContentText>
                <S.ItemAction>
                  <S.ItemTime>
                    {removeSecondsFromHour(
                      getLocalHourFromDate(notification.time.toISOString()),
                    )}
                  </S.ItemTime>
                </S.ItemAction>
              </S.ItemContent>
            </S.Item>
          ))}
      </S.Container>
    </S.Wrapper>
  );
};
