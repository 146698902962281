import accreditation from './accreditation.json';
import auth from './auth.json';
import coffee from './coffee.json';
import configurations from './configurations.json';
import errorGuard from './error-guard.json';
import errors from './errors.json';
import footer from './footer.json';
import helpCenter from './help-center.json';
import interactions from './interactions.json';
import labels from './labels.json';
import materials from './materials.json';
import navbar from './navbar.json';
import notification from './notification.json';
import rewards from './rewards.json';
import routes from './routes.json';
import schedule from './schedule.json';
import stream from './stream.json';
import users from './users.json';

export const accreditationPT = {
  accreditation: accreditation,
};

export const authPT = {
  auth: auth,
};
export const coffeePT = {
  coffee: coffee,
};
export const configurationsPT = {
  configurations: configurations,
};
export const errorGuardPT = {
  errorGuard: errorGuard,
};
export const footerPT = {
  footer: footer,
};
export const helpCenterPT = {
  helpCenter: helpCenter,
};
export const interactionsPT = {
  interactions: interactions,
};
export const materialsPT = {
  materials: materials,
};
export const navbarPT = {
  navbar: navbar,
};
export const notificationPT = {
  notification: notification,
};
export const rewardsPT = {
  rewards: rewards,
};
export const routesPT = {
  routes: routes,
};
export const schedulePT = {
  schedule: schedule,
};
export const streamPT = {
  stream: stream,
};
export const usersPT = {
  users: users,
};

export const errorsPT = {
  errors: errors,
};
export const labelsPT = {
  labels: labels,
};
