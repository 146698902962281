import { Helmet } from 'react-helmet-async';
import { useEventById } from 'useCases/events';
import { useEnv } from 'hooks';

export const Helment = () => {
  const { data } = useEventById(useEnv().eventId);

  return (
    <Helmet>
      <title>{data?.event.name}</title>
      <link
        rel="icon"
        type="image/png"
        href={data?.event.favicon}
        sizes="16x16"
      />
    </Helmet>
  );
};
