import styled, { css, keyframes, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { ButtonProps } from './Button';

type WrapperProps = Pick<
  ButtonProps,
  | 'color'
  | 'fullWidth'
  | 'fullWidthMobile'
  | 'isSmall'
  | 'isLoading'
  | 'isRound'
>;

const wrapperModifiers = {
  primary: (theme: DefaultTheme) => css`
    background-color: ${theme.primary};
    border: none;
    color: ${theme.secondary};

    &:active {
      background-color: ${theme.primary};
    }

    &:disabled {
      background-color: ${theme.primary};
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }

    &:hover,
    &:focus {
      &:not([disabled]) {
        opacity: 0.8;
        background-color: ${theme.primary};
      }
    }
  `,
  secondary: (theme: DefaultTheme) => css`
    background-color: ${theme.tertiary};
    border: 1px solid ${theme.text.primary};
    backdrop-filter: blur(50px);
    color: ${theme.primary};

    &:active {
      background-color: ${theme.secondary};
    }

    &:disabled {
      color: black;
      border-color: ${theme.secondary};
      color: ${theme.secondary};
    }

    &:hover,
    &:focus {
      &:not([disabled]) {
        color: ${theme.secondary};
      }
    }
  `,
  tertiary: (theme: DefaultTheme) =>
    css`
      background-color: transparent;
      border-color: transparent;
      color: ${theme.primary};

      &:hover,
      &:focus {
        &:not([disabled]) {
          color: ${theme.secondary};
        }
      }
    `,
  fullWidth: () => css`
    min-width: 100%;
    width: 100%;
  `,
  fullWidthMobile: () => css`
    ${media.lessThan('medium')`
      width: 100%;
    `}
  `,
  isSmall: (theme: DefaultTheme) => css`
    font-size: ${theme.typography.sizes.sm};
    font-weight: normal;
    height: 24px;
    min-height: 24px;
    padding: 0 ${theme.spacing.xs};
    min-width: 96px;
  `,
  isLoading: () => css`
    cursor: not-allowed;
    pointer-events: none;
  `,
  round: (theme: DefaultTheme) => css`
    border-radius: 50%;
    color: ${theme.text.primary};
    padding: 0;
    min-width: 40px;
    min-height: 40px;
    height: 40px;
    width: 40px;
  `,
};

export const Wrapper = styled.button<WrapperProps>`
  ${({
    theme,
    color,
    fullWidth,
    fullWidthMobile,
    isSmall,
    isLoading,
    isRound,
  }) => css`
    appearance: none;
    border-radius: ${theme.shapes.borderRadius.xs};
    cursor: pointer;
    font-size: ${theme.typography.sizes.base};
    font-family: ${theme.typography.family.primary};
    font-weight: bold;
    padding: 0 ${theme.spacing.sm};
    height: 48px;
    min-height: 48px;
    min-width: 160px;
    transition: all 0.3s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    ${!!color && wrapperModifiers[color](theme)};
    ${fullWidth && wrapperModifiers.fullWidth()};
    ${fullWidthMobile && wrapperModifiers.fullWidthMobile()};
    ${isSmall && wrapperModifiers.isSmall(theme)};
    ${isLoading && wrapperModifiers.isLoading()};
    ${isRound && wrapperModifiers.round(theme)};
    /* color: ${(props) => props.theme.button.text}; */
  `}
`;

const rippleEffect = keyframes`
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
`;

export const Loading = styled.div`
  ${({ theme }) => css`
    display: inline-block;
    position: relative;
    height: 80px;
    width: 80px;
    transform: scale(0.35);

    & div {
      border: 4px solid ${theme.text.secondary};
      position: absolute;
      opacity: 1;
      border-radius: 50%;
      animation: ${rippleEffect} 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;

      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  `}
`;
