/* eslint-disable @typescript-eslint/no-explicit-any */
import { handleBlurInput } from 'helpers/handleBlurInput';
import { useState, useRef, useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { useAPI, useTranslate, useToast, useQueryString } from 'hooks';
import { removeClaims, setUserId, setUserRole } from 'lib/core/session';
import { ForgotPassword, SignIn } from 'interfaces/auth';
import { User } from 'interfaces/user';
import { AUTH, USERS } from 'constants/endpoints';
import { ROUTES } from 'constants/urls';

export interface SignInResponse {
  user: User;
  error?: string;
}

export type UseForgotPassword = {
  onEmailSended: () => void;
};

export type UpdateForgotPassword = {
  token?: string;
  newPassword: string;
  rePassword: string;
};

const TOO_MANY_TRIES = 429;
const RECAPTCHA_TOKEN_IS_MISSING = 'recaptcha-token-is-missing';
const RECAPTCHA_TOKEN_IS_INVALID = 'recaptcha-token-is-invalid';
const USER_NOT_FOUND = 404;

export const useSignIn = (hasConfirmationEmail?: boolean) => {
  const api = useAPI();
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  useEffect(() => {
    if (isPending) {
      handleBlurInput(document);
    }
  }, [isPending]);
  const handleAuthenticate = async ({ uuid, password }: SignIn) => {
    setIsPending(true);
    const recaptchaToken = recaptchaRef.current?.getValue();

    try {
      const {
        data: { user },
      } = await api.post<SignInResponse>(AUTH.SIGN_IN, {
        uuid,
        password,
        ...(recaptchaToken && { token: recaptchaToken }),
      });
      setUserId(user.id!);
      setUserRole(user.role!);
      // handleSessionStatus();
      // localStorage.setItem('country', (user?.country as any).value);
      // localStorage.setItem('instagram', user?.instagram || '');
      mutate(USERS.BY_ID(user.id!), { user });

      const routeToNavigate = ROUTES.app.getLink('home');
      navigate(routeToNavigate);
    } catch (error: any) {
      setIsPending(false);

      if (error.response.status === TOO_MANY_TRIES) {
        setShowRecaptcha(true);
        recaptchaRef.current?.reset();
      }

      if (error.response.data.error === RECAPTCHA_TOKEN_IS_MISSING) {
        setShowRecaptcha(true);
        recaptchaRef.current?.reset();
      }

      if (error.response.data.error === RECAPTCHA_TOKEN_IS_INVALID) {
        recaptchaRef.current?.reset();
      }
    }
  };

  return { handleAuthenticate, isPending, showRecaptcha, recaptchaRef };
};

export const useSignOut = () => {
  const navigate = useNavigate();
  const api = useAPI();
  const handleLogout = async () => {
    removeClaims();
    navigate(ROUTES.accreditation.getLink('gettingStarted'));
    try {
      const response = await api.post(AUTH.LOGOUT);
      return response;
    } catch (err) {
      console.info(err);
    }
  };

  return { handleLogout };
};

export const useForgotPassword = ({ onEmailSended }: UseForgotPassword) => {
  const translate = useTranslate();
  const api = useAPI();
  const [isPending, setIsPending] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const { setToast } = useToast();

  const handleForgotPassword = async ({ email }: ForgotPassword) => {
    setIsPending(true);
    const recaptchaToken = recaptchaRef.current?.getValue();
    try {
      await api.post(AUTH.FORGOT_PASSWORD, {
        email,
        ...(recaptchaToken && { token: recaptchaToken }),
      });
      onEmailSended();
    } catch (error: any) {
      setIsPending(false);

      if (error.response.status === USER_NOT_FOUND) {
        setToast({
          hasChat: false,
          type: 'danger',
          description: translate('auth.forgotPassword.userNotFound'),
        });
      }

      if (error.response.status === TOO_MANY_TRIES) {
        setShowRecaptcha(true);
        recaptchaRef.current?.reset();
      }

      if (error.response.data.error === RECAPTCHA_TOKEN_IS_MISSING) {
        setShowRecaptcha(true);
        recaptchaRef.current?.reset();
      }

      if (error.response.data.error === RECAPTCHA_TOKEN_IS_INVALID) {
        recaptchaRef.current?.reset();
      }
    }
  };

  return { handleForgotPassword, isPending, showRecaptcha, recaptchaRef };
};

export const useUpdatePassword = () => {
  const api = useAPI();
  const translate = useTranslate();
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const { setToast } = useToast();

  const handleUpdatePassword = async ({
    token,
    newPassword,
    rePassword,
  }: UpdateForgotPassword) => {
    setIsPending(true);

    try {
      await api.post(AUTH.UPDATE_PASSWORD(token), { newPassword, rePassword });
      setToast({
        hasChat: false,
        type: 'success',
        description: translate('auth.changePassword.success'),
      });
      navigate(ROUTES.app.getLink('signIn'));
    } catch (error) {
      setIsPending(false);
    }
  };

  return { handleUpdatePassword, isPending };
};

export const useConfirmRegistration = () => {
  const api = useAPI();
  const { setToast } = useToast();
  const navigate = useNavigate();
  const translate = useTranslate();
  const { search } = useLocation();
  const items = search.split('&');
  const data = items.reduce((prev: { [key: string]: string }, curr: string) => {
    const [key, value] = curr.split('=');
    prev[key.replace('?', '')] = value;
    return prev;
  }, {});
  const handleConfirmRegistration = useCallback(
    async (token: string) => {
      try {
        await api.post(AUTH.CONFIRMATION, { token: data.token });
        setToast({
          hasChat: false,
          type: 'success',
          description: translate('auth.confirmation.success'),
        });
      } finally {
        navigate(`${ROUTES.login.getLink('signIn')}?uuid=${data.uuid}`);
      }
    },
    [api, navigate],
  );

  return { handleConfirmRegistration };
};
