import ClipLoader from 'react-spinners/ClipLoader';
// import theme from 'styles/theme';
import * as S from './Spinner.styles';

export type SpinnerProps = {
  size?: number;
  isLoading?: boolean;
};

export const Spinner = ({ size = 35, isLoading = true }: SpinnerProps) => (
  <S.Wrapper>
    <S.Loader>
      <ClipLoader size={size} color={'white'} loading={isLoading} />
    </S.Loader>
  </S.Wrapper>
);
