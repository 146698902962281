import { useEffect, useState } from 'react';
import { validatePassword } from 'lib/contexts/input';
import { getEventThemeName } from 'lib/core/event';
import { ProjectNames } from 'constants/enums';

export const MAX_PASSWORD_STRENGTH =
  getEventThemeName() === ProjectNames.VALE ? 1 : 5;

export const usePasswordStrength = (password: string) => {
  const [validations, setValidations] = useState([]);
  useEffect(() => {
    validatePassword(password)
      .then(() => setValidations([]))
      .catch(({ errors }) => setValidations(errors));
  }, [password]);

  const strength = MAX_PASSWORD_STRENGTH - validations.length;

  return {
    strength,
  };
};
