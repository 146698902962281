import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const TermsContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.lg};
  `}
  ${media.lessThan('large')`
       margin-bottom: 25px !important;
    `}
`;

export const Term = styled.span`
  ${({ theme }) => css`
    color: ${theme.text.secondary};
  `}
`;

export const ButtonTerms = styled.button`
  ${({ theme }) => css`
    text-decoration: underline;
    margin: 0 ${theme.spacing['2xs']};
  `}
`;
