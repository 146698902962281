import styled, { css } from 'styled-components';
import { PlenaryIconProps } from './PlenaryIcon';

type WrapperProps = Pick<PlenaryIconProps, 'size'>;

const wrapperModifiers = {
  small: () => css`
    height: 32px;
    width: 32px;

    svg {
      height: 16px;
      width: 16px;
    }
  `,
  medium: () => css`
    height: 40px;
    width: 40px;
  `,
  large: () => css`
    height: 80px;
    width: 80px;
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, size }) => css`
    grid-row: 1/-1;
    background-color: ${theme.secondary};
    border-radius: 50%;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${theme.text.primary};
      height: 24px;
      width: 24px;
    }

    ${size && wrapperModifiers[size]()};
  `}
`;
