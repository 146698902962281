import {
  authEN,
  coffeeEN,
  helpCenterEN,
  accreditationEN,
  footerEN,
  errorGuardEN,
  materialsEN,
  interactionsEN,
  navbarEN,
  configurationsEN,
  routesEN,
  notificationEN,
  rewardsEN,
  scheduleEN,
  streamEN,
  labelsEN,
  errorsEN,
  usersEN,
} from './en';
import {
  authES,
  coffeeES,
  helpCenterES,
  accreditationES,
  footerES,
  errorGuardES,
  materialsES,
  interactionsES,
  navbarES,
  configurationsES,
  routesES,
  notificationES,
  rewardsES,
  scheduleES,
  streamES,
  labelsES,
  errorsES,
  usersES,
} from './es';
import {
  authPT,
  coffeePT,
  helpCenterPT,
  accreditationPT,
  footerPT,
  errorGuardPT,
  materialsPT,
  interactionsPT,
  navbarPT,
  configurationsPT,
  routesPT,
  notificationPT,
  rewardsPT,
  schedulePT,
  streamPT,
  usersPT,
  labelsPT,
  errorsPT,
} from './pt';

export const contextsPT = {
  ...accreditationPT,
  ...authPT,
  ...usersPT,
  ...interactionsPT,
  ...streamPT,
  ...helpCenterPT,
  ...coffeePT,
  ...routesPT,
  ...configurationsPT,
  ...materialsPT,
  ...schedulePT,
  ...errorGuardPT,
  ...rewardsPT,
  ...navbarPT,
  ...notificationPT,
  ...footerPT,
  ...errorsPT,
  ...labelsPT,
};

export const contextsEN = {
  ...accreditationEN,
  ...authEN,
  ...usersEN,
  ...interactionsEN,
  ...streamEN,
  ...helpCenterEN,
  ...coffeeEN,
  ...routesEN,
  ...configurationsEN,
  ...materialsEN,
  ...scheduleEN,
  ...errorGuardEN,
  ...rewardsEN,
  ...navbarEN,
  ...notificationEN,
  ...footerEN,
  ...errorsEN,
  ...labelsEN,
};

export const contextsES = {
  ...accreditationES,
  ...authES,
  ...usersES,
  ...interactionsES,
  ...streamES,
  ...helpCenterES,
  ...coffeeES,
  ...routesES,
  ...configurationsES,
  ...materialsES,
  ...scheduleES,
  ...errorGuardES,
  ...rewardsES,
  ...navbarES,
  ...notificationES,
  ...footerES,
  ...errorsES,
  ...labelsES,
};
