import { ChatParams } from 'interfaces/chat';

export const getURLWithParams = (url: string, params?: unknown) =>
  `${url}?` + new URLSearchParams(params as Record<string, string>).toString();

type NetworkingUrlParams = {
  page?: number;
  size?: number;
} & Pick<ChatParams, 'name' | 'interests'>;

export const getNetworkingUrl = (url: string, params: NetworkingUrlParams) => {
  const interests = params.interests?.map(
    (interest) => `interests=${interest}`,
  );
  const formattedInterests = interests?.join('&');
  return `${url}?${formattedInterests}&name=${params.name}&page=${params.page}&size=${params.size}`;
};
