import locales from 'locales';
import { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { correctLanguage, flattenMessages } from 'lib/core/language';
import { WithChildren } from 'interfaces/children';
import {
  DEFAULT_LANGUAGE,
  LANGUAGE_KEY,
  POSSIBLE_LANGUAGES,
} from 'constants/enums';

export type Language = 'pt' | 'en';
export type LanguageStream = Language | 'libras' | 'original';

export const LanguageSupport = ['pt', 'en'];

type ContextType = {
  // TODO: Global language
  language: Language;
  setLanguage: (language: Language) => void;
  // TODO: Usado em seções especificos (coffee, stream)
  languageStream: LanguageStream;
  setLanguageStream: (language: LanguageStream) => void;
};

export const IntlContext = createContext<ContextType>({} as ContextType);

export const IntlProvider = ({ children }: WithChildren) => {
  const [cookies, setCookie] = useCookies();

  const device = () => {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      return 'iPhone';
    } else {
      return 'desktop';
    }
  };

  localStorage.setItem('checkDevice', device());

  const defaultLanguage = () => {
    const { language } = cookies;
    if (language && POSSIBLE_LANGUAGES.includes(language)) {
      return language;
      // return DEFAULT_LANGUAGE;
    }
    if (LanguageSupport.includes(correctLanguage(navigator.language))) {
      // return navigator.language.slice(0, 2);
      return DEFAULT_LANGUAGE;
    }
    return DEFAULT_LANGUAGE;
  };

  const [currentLanguage, setCurrentLanguage] = useState<Language>(
    defaultLanguage,
  );

  const [
    currentLanguageStream,
    setCurrentLanguageStream,
  ] = useState<LanguageStream>(defaultLanguage);

  const setLanguage = (language = DEFAULT_LANGUAGE) =>
    setCurrentLanguage(language);

  const setLanguageStream = (language = DEFAULT_LANGUAGE as LanguageStream) =>
    setCurrentLanguageStream(language);

  useEffect(() => {
    setCookie(LANGUAGE_KEY, currentLanguage ?? DEFAULT_LANGUAGE, {
      path: '/',
    });
  }, [currentLanguage, setCookie]);

  return (
    <ReactIntlProvider
      locale={currentLanguage}
      key={currentLanguage}
      messages={flattenMessages(locales[currentLanguage])}
      onError={() => undefined}
    >
      <IntlContext.Provider
        value={{
          language: currentLanguage,
          setLanguage,
          languageStream: currentLanguageStream,
          setLanguageStream,
        }}
      >
        {children}
      </IntlContext.Provider>
    </ReactIntlProvider>
  );
};

export const useIntlContext = () => useContext(IntlContext);
