import { useEffect } from 'react';
import { dispatchGAEvent } from 'services/google';
import { useEvent } from 'useCases/events';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { GA_EVENTS } from 'constants/enums';
import { useScript } from './useScript';

export const useZendesk = () => {
  const { data: event } = useEvent();
  const zendeskKey = event?.event.zendesk?.id;
  const zendeskScriptUrl = event?.event.zendesk?.url;
  const isActive = event?.event.zendesk?.active;
  const loaded = useScript(
    zendeskScriptUrl as string,
    zendeskKey as string,
    event?.event.zendesk?.active,
  );

  const handlerSupport = () => {
    if (window?.zE && isActive) {
      window.zE(() => {
        window.zE.activate();
        dispatchGAEvent({
          category: EventCategory.Navigation,
          action: EventAction.click,
          label: GA_EVENTS.support.labels.support,
        });
      });
    }
  };

  useEffect(() => {
    if (loaded && window?.zE && isActive) {
      window.zE('webWidget', 'hide');
      window.zE('webWidget', 'setLocale', navigator.language);
      window.zE(() => {
        window.zE.hide();
      });
    }
  }, [loaded]);

  return handlerSupport;
};
