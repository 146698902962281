import { useCallback, useMemo } from 'react';
import * as S from './AnimatedIcon.styles';

type AnimatedIconProps = {
  onAnimationComplete?: () => void;
  reactType: string;
  position?: string;
};

export const AnimatedIcon = ({
  onAnimationComplete,
  reactType,
  position,
}: AnimatedIconProps) => {
  const getRandomNumber = useCallback(
    (min: number, max: number) => Math.random() * (max - min) + min,
    [],
  );

  const animate = useMemo(
    () => ({
      opacity: [0, 0.9, 0],
      y: [getRandomNumber(0, 100), -200, -400],
      x: [
        getRandomNumber(-15, 15),
        getRandomNumber(-50, 15),
        getRandomNumber(-50, 15),
        getRandomNumber(-15, 15),
        getRandomNumber(-50, 15),
        getRandomNumber(-50, 15),
      ],
    }),
    [getRandomNumber],
  );

  const color = useMemo(() => {
    const colors = ['white'];
    return colors[Math.floor(getRandomNumber(0, 2.9))];
  }, [getRandomNumber]);

  return (
    <S.MotionDiv
      animate={animate}
      transition={{
        duration: 5,
        ease: 'linear',
      }}
      style={{ position: 'absolute', right: 230, bottom: 0 }}
      onAnimationComplete={onAnimationComplete}
      position={position}
    >
      <S.LikeIconStyled
        icon={
          reactType === 'like'
            ? 'IcLike'
            : reactType === 'happy'
            ? 'IcHappy'
            : reactType === 'loved'
            ? 'IcIloved'
            : 'IcSuprise'
        }
        color={color}
      />
    </S.MotionDiv>
  );
};
