import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useEventById } from 'useCases/events';
import { useUpdateUser, useUserById } from 'useCases/users';
import {
  useEnv,
  useTranslate,
  useToast,
  useMapUserToBusinessCard,
} from 'hooks';
import { retrieveUserSession } from 'lib/core/session';
import { InterestsForm } from 'components/contexts/accreditation';
import { User } from 'interfaces/user';
import { Skeleton } from './ConfigurationsInterests.skeleton';
import * as S from './ConfigurationsInterests.styles';

type FormData = {
  allowChat?: boolean;
  userImage?: File;
};

export const ConfigurationsInterests = () => {
  const { setToast } = useToast();
  const translate = useTranslate();
  const { eventId } = useEnv();
  const { id: userId } = retrieveUserSession();
  const { isPending, updateUser } = useUpdateUser();
  const userById = useUserById(userId!);
  const eventById = useEventById(eventId);

  const { register, setValue, handleSubmit, reset } = useForm<FormData>({
    defaultValues: userById?.data?.user,
  });

  useEffect(() => {
    reset(userById?.data?.user);
  }, [userById?.data?.user, reset]);

  const onSubmit = async (data: User) => {
    await updateUser(data);
    setToast({
      type: 'success',
      hasChat: false,
      description: translate('configurations.interestsSuccessMessage'),
    });
  };

  useMapUserToBusinessCard(userById.data?.user);

  if (eventById.isLoading || userById.isLoading) {
    return <Skeleton />;
  }

  return (
    <S.Container>
      <InterestsForm
        register={register}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setValue={setValue}
        interests={eventById.data?.event.interests}
        isPending={isPending}
        selectedInterests={userById?.data?.user.interests}
        submitLabel={translate('configurations.save')}
        showSupportButton={true}
        showPoweredBy
      />
    </S.Container>
  );
};
