import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Wrapper as IndicationStyles } from 'components/contexts/rewards/IndicationProgress/IndicationProgress.styles';
import { ProgressBox } from 'components/contexts/rewards/IndicationProgress/ProgressBox/ProgressBox.styles';
import { Container, Heading } from 'components/structure';

export const Wrapper = styled(Container)`
  ${({ theme }) => css`
    height: 100%;

    ${Heading} {
      margin-left: ${theme.spacing.md};
    }

    ${media.lessThan('large')`
      padding: 0;
    `}
  `}
`;

export const Content = styled.main`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 ${theme.spacing['2xl']};
    margin-top: ${theme.spacing['2xl']};

    ${IndicationStyles} {
      margin: 0;
      padding: 0;

      > div {
        margin-top: ${theme.spacing.md};
      }
    }

    ${ProgressBox} {
      &::after {
        height: 22px;
      }
    }

    ${media.lessThan('large')`
      flex-direction: column;
      margin-top: ${theme.spacing.xl};
    `}
  `}
`;
