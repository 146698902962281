import { Link } from 'react-router-dom';
import { useTranslate } from 'hooks';
//import { hoursIntervalWithoutSeconds } from 'lib/contexts/date';
import { useIntlContext } from 'contexts';
import { Icon } from 'components/structure';
import { LanguagesAcronyms } from 'constants/enums';
import * as S from './Topic.styles';

export type TimeSlotProps = {
  end: string;
  start: string;
};

export type DetailsProps = {
  timeSlot: TimeSlotProps;
};

export type TopicProps = {
  id: string;
  description: string;
  hasDetails: boolean;
  details: DetailsProps;
  beforeTimeSlot: TimeSlotProps;
};

export const Topic = ({
  id,
  description,
  details,
  hasDetails,
  beforeTimeSlot,
}: TopicProps) => {
  const { language } = useIntlContext();
  const translate = useTranslate();
  // const language = 'pt-br';
  const ScheduleTimer = () =>
    beforeTimeSlot.start !== details.timeSlot.start &&
    beforeTimeSlot.end !== details.timeSlot.end ? (
      <small>
        {
          new Date(details.timeSlot.start)
            .toLocaleTimeString(LanguagesAcronyms[language])
            .split(':')[0]
        }
        {':'}
        {
          new Date(details.timeSlot.start)
            .toLocaleTimeString(LanguagesAcronyms[language])
            .split(':')[1]
        }
        {details.timeSlot.start !== details.timeSlot.end &&
          `
           -
          ${
            new Date(details.timeSlot.end)
              .toLocaleTimeString(LanguagesAcronyms[language])
              .split(':')[0]
          }:${
            new Date(details.timeSlot.end)
              .toLocaleTimeString(LanguagesAcronyms[language])
              .split(':')[1]
          }
          `}
      </small>
    ) : (
      <></>
    );

  const Content = () => (
    <S.ScheduleEvent>
      <S.Description dangerouslySetInnerHTML={{ __html: description }} />
      {hasDetails && (
        <S.ContainerDetails>
          <S.Details>{translate('schedule.more')}</S.Details>{' '}
          <Icon icon="IcChevronRight" />
        </S.ContainerDetails>
      )}
    </S.ScheduleEvent>
  );

  return (
    <S.Container>
      <S.ScheduleTimeContainer>
        {details.timeSlot?.start && details.timeSlot?.end && <ScheduleTimer />}
      </S.ScheduleTimeContainer>

      <S.ScheduleEventsContainer hasDetails={hasDetails}>
        {hasDetails ? (
          <Link to={`${id}`}>
            <Content />
          </Link>
        ) : (
          <Content />
        )}
      </S.ScheduleEventsContainer>
    </S.Container>
  );
};
