import * as Yup from 'yup';
import { passwordStrength } from 'lib/contexts/input';
import messages from 'lib/core/errors';
import { BRAZIL_COUNTRY_CODE } from 'constants/enums';
import { enabledCities } from './BusinessCardForm.mock';

const { requiredParam, paramsDontMatch, invalidParam } = messages;
export const schema = {
  firstName: Yup.string().required(requiredParam('firstName')),
  lastName: Yup.string().required(requiredParam('lastName')),
  uuid: Yup.string().email().required(requiredParam('uuid')),
  email: Yup.string().email().required(requiredParam('uuid')),
  state: Yup.string().when('country', {
    is: BRAZIL_COUNTRY_CODE.toString(),
    then: Yup.string().required(requiredParam('state')),
  }),
  // password: Yup.string()
  //   .min(8, invalidParam('password'))
  //   .required(requiredParam('password')),
  // rePassword: Yup.string()
  //   .required(requiredParam('rePassword'))
  //   .oneOf([Yup.ref('password'), null], paramsDontMatch('password')),
  terms: Yup.bool().oneOf([true], requiredParam('terms')),
  ask1: Yup.string().required().min(2).uppercase(),
};

export const schemaForNotBayer = Yup.object().shape({
  profile: Yup.object({
    specialty: Yup.string().required(requiredParam('specialty')),
  }),
  healthProfessional: Yup.bool().oneOf(
    [true],
    requiredParam('healthProfessional'),
  ),
  ...schema,
});

export const schemaForBayer = Yup.object().shape(schema);

export const defaultValues = {
  firstName: '',
  lastName: '',
  uuid: '',
  country: '',
  profile: {
    specialty: '',
  },
  professionalId: '',
  workshop: {
    first: '',
    second: '',
    third: '',
  },
  preferableLanguage: '',
  state: '',
  password: '',
  rePassword: '',
  terms: false,
};
