const USER_NAME = 'firstName';
const USER_LAST_NAME = 'lastName';
const USER_ROLE = 'role';
const USER_AVATAR = 'avatar';
const USER_ID = 'id';

const USER_EMAIL = 'email';
const USER_COUNTRY = 'country';
const USER_PROFILE = 'profile';
const USER_UUID = 'uuid';

const BUSINESS_CARD_NAME = 'businessCardName';

export const getUserId = () => localStorage.getItem(USER_ID);

export const setUserRole = (role: string) => {
  localStorage.setItem(USER_ROLE, role);
};

export const setUserId = (userId: string) => {
  localStorage.setItem(USER_ID, userId);
};

export const retrieveUserSession = () => {
  try {
    return {
      firstName: localStorage.getItem(USER_NAME),
      lastName: localStorage.getItem(USER_LAST_NAME),
      role: localStorage.getItem(USER_ROLE),
      avatar: localStorage.getItem(USER_AVATAR),
      id: localStorage.getItem(USER_ID),
      email: localStorage.getItem(USER_EMAIL),
      country: localStorage.getItem(USER_COUNTRY),
      profile: JSON.parse(localStorage.getItem(USER_PROFILE)!),
      uuid: localStorage.getItem(USER_UUID),
      businessCardName: localStorage.getItem(BUSINESS_CARD_NAME),
    };
  } catch (e) {
    return {
      firstName: localStorage.getItem(USER_NAME),
      lastName: localStorage.getItem(USER_LAST_NAME),
      role: localStorage.getItem(USER_ROLE),
      avatar: localStorage.getItem(USER_AVATAR),
      id: localStorage.getItem(USER_ID),
      email: localStorage.getItem(USER_EMAIL),
      country: localStorage.getItem(USER_COUNTRY),
      uuid: localStorage.getItem(USER_UUID),
      businessCardName: localStorage.getItem(BUSINESS_CARD_NAME),
    };
  }
};

export const removeClaims = () => {
	localStorage.clear()
};

export const isAuthenticated = () => getUserId() !== null;
