import { useMemo } from 'react';
import { useTranslate } from 'hooks';
import { LanguageStream } from 'contexts';
import * as S from './ChangeLanguage.styles';

export type ChangeLanguageProps = {
  show?: boolean;
  bottom?: boolean;
  onSelectedOption: (lang: LanguageStream) => void;
  streamLanguages: LanguageStream[];
};

export const ChangeLanguage = ({
  onSelectedOption,
  streamLanguages,
}: ChangeLanguageProps) => {
  const translate = useTranslate();

  const availableStreamLanguages = useMemo(
    () =>
      streamLanguages.map((language) => ({
        value: language,
        label: translate(`labels.${language}`),
      })),
    [streamLanguages, translate],
  );

  return (
    <S.ChangeLanguage>
      {availableStreamLanguages.map(({ label, value }) => (
        <button
          key={value}
          type="button"
          onClick={() => onSelectedOption(value)}
        >
          {label}
        </button>
      ))}
    </S.ChangeLanguage>
  );
};
