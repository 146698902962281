import { Option } from 'components/contexts/Materials/SectionMaterial/SectionMaterial';
import { Icon } from 'components/structure';
import * as S from './MaterialItem.styles';

type MaterialItemProps = {
  isVideo?: boolean;
  material: Option;
  onClick: (e: MouseEvent) => void;
  as: React.ElementType;
  href?: React.ElementType | string;
  target?: unknown;
  cover: string;
};

export const MaterialItem = ({
  isVideo,
  material: { name },
  // name,
  onClick,
  as,
  href,
  cover,
  target,
}: MaterialItemProps) => (
  <S.Container href={href} target={target} as={as} onClick={onClick}>
    <S.WrapperItem>
      <S.ThumbImage src={cover} isVideo={isVideo}>
        <Icon icon="IcPlayVideo" />
      </S.ThumbImage>
      <S.MaterialName>{name}</S.MaterialName>
    </S.WrapperItem>
  </S.Container>
);
