import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';

export const Skeleton = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 328 435"
      style={{ margin: '16px' }}
    >
      <rect width="328" height="48" rx="4" />
      <circle cx="20" cy="92" r="20" />
      <circle cx="20" cy="220" r="20" />
      <circle cx="20" cy="348" r="20" />
      <circle cx="20" cy="156" r="20" />
      <circle cx="20" cy="284" r="20" />
      <circle cx="20" cy="412" r="20" />
      <rect x="48" y="78" width="113" height="18" />
      <rect x="48" y="269" width="113" height="18" />
      <rect x="48" y="140" width="113" height="18" />
      <rect x="48" y="331" width="113" height="18" />
      <rect x="48" y="204" width="172" height="18" />
      <rect x="48" y="395" width="172" height="18" />
      <rect x="48" y="103" width="63" height="15" />
      <rect x="48" y="166" width="276" height="15" />
      <rect x="48" y="229" width="241" height="15" />
      <rect x="48" y="294" width="277" height="15" />
      <rect x="48" y="358" width="265" height="15" />
      <rect x="48" y="420" width="115" height="15" />
    </ContentLoader>
  );
};
