import { ProjectNames } from 'constants/enums';

export const setPadStart = (time: number) => String(time).padStart(2, '0');

const now = new Date();
export const futureDate = new Date(
  now.setDate(now.getDate() + 1),
).toISOString();
export const pastDate = new Date(now.setDate(now.getDate() - 1)).toISOString();

export const setPasswordMinLength = (
  themeName: string,
  hasPasswordStrength?: boolean,
) => (ProjectNames.VALE === themeName ? 6 : hasPasswordStrength ? 8 : 1);
