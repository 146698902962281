import styled, { css, DefaultTheme } from 'styled-components/macro';
import { HelperText } from 'components/form';
import { CheckboxProps } from './Checkbox';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: ${theme.spacing.sm};
    }
    ${HelperText} {
      padding: 0;
    }
  `}
`;

type LabelTextProps = Pick<CheckboxProps, 'labelPosition'>;

export const Checkbox = styled.span<LabelTextProps>`
  ${({ theme, labelPosition }) => css`
    border-radius: ${theme.shapes.borderRadius.sm};
    border: 2px solid ${theme.text.secondary};
    display: inline-block;
    height: 14px;
    width: 24px;
    min-width: 24px;
    position: relative;
    transition: border-color 0.2s linear;
    margin-${labelPosition}: ${theme.spacing.xs};

    &::after {
      background: ${theme.text.secondary};
      border-radius: 50%;
      content: '';
      height: 8px;
      width: 8px;
      position: absolute;
      top: 50%;
      left: 2px;
      transform: translateY(-50%);
      transition: transform 0.2s linear;
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.text.secondary};
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: ${theme.typography.family.primary};
    font-size: ${theme.typography.sizes.sm};
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: left;
    max-width: 600px;

    a {
      font-family: ${theme.typography.family.primary};
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: ${theme.text.secondary};
      font-family: inherit;
      text-decoration: underline;
    }
  `}
`;

export const LabelText = styled.span<LabelTextProps>`
  ${({ theme, labelPosition }) => css`
    color: ${theme.text.secondary};
    order: ${labelPosition === 'left' ? '0' : '2'};
  `}
`;

type CheckboxWrapperProps = Pick<CheckboxProps, 'dark'>;

const checkboxWrapperModifiers = {
  dark: (theme: DefaultTheme) => css`
    ${Checkbox} {
      border-color: ${theme.secondary};

      &::after {
        background-color: ${theme.background};
      }
    }

    ${Label} {
      color: ${theme.text.secondary};
    }
  `,
};

export const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
  ${({ theme, dark }) => css`
    display: flex;
    justify-content: flex-start;

    ${dark && checkboxWrapperModifiers.dark(theme)};
  `}
`;

export const Input = styled.input`
  margin: 5px;
  cursor: pointer;
  accent-color: ${({ theme }) => theme.primary};
  &:checked {
    background: ${({ theme }) => theme.primary};
  }
`;
