import React from 'react'
import { SetStateAction, createContext, useState } from "react"

export const CurrentChatIdContext = createContext<{
	currentChat: string,
	setCurrentChat: React.Dispatch<SetStateAction<string>> | null
}>({
	setCurrentChat: null,
	currentChat: ''
})


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CurrentChatIdProvider({ children }: { children: any }) {
	const [currentChat, setCurrentChat] = useState('')
	return (
		<CurrentChatIdContext.Provider value={{ currentChat, setCurrentChat }}>
			{children}
		</CurrentChatIdContext.Provider>
	)
}
