import { useCallback } from 'react';
import { useAPI, useFetch, useTranslate } from 'hooks';
import { ASSETS } from 'constants/endpoints';

export interface Country {
  label: string;
  value: string;
}

export const useCountries = () => useFetch<Country[]>(ASSETS.COUNTRIES);

export const useCertificate = () => {
  const api = useAPI();
  const translate = useTranslate();
  const certificate = 'navbar.userMenu.certificate';

  return useCallback(
    async (eventName?: string) => {
      const { data } = await api.get(ASSETS.CERTIFICATE, {
        headers: {
          'Content-Type': 'application/pdf',
        },
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${translate(certificate)}-${eventName}.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    },
    [api, translate],
  );
};
