import { useContext, createContext } from 'react';
import { WithChildren } from 'interfaces/children';

type ContextType = {
  isOpen: boolean;
  onClick?: () => void;
  arrowDirection?: 'center' | 'left' | 'right';
  distance?: string;
  placement?: 'bottom';
  colorScheme?: 'dark' | 'light';
};

const defaultValue = {
  isOpen: false,
  onClick: () => {
    // do nothing
  },
};

export const PopoverContext = createContext<ContextType>(defaultValue);

type PopoverProviderProps = { value: ContextType } & WithChildren;

export const PopoverProvider = ({ children, value }: PopoverProviderProps) => (
  <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>
);

export const usePopoverContext = () => useContext(PopoverContext);
