import { useParams } from 'react-router';
import { useSurvey } from 'useCases/surveys';
import { Questions, Results } from 'components/contexts/interactions';
import { EmptySearch } from 'components/structure';
import { SurveyState } from 'constants/enums';
import * as S from './Survey.styles';

export type SurveyProps = {
  state?: SurveyState;
  onSurveyAvailable?: () => void;
  onSurveyResult?: () => void;
};

export const Survey = ({
  state = SurveyState.notAvailable,
  onSurveyAvailable,
  onSurveyResult,
}: SurveyProps) => {
  const { id } = useParams();
  useSurvey({
    streamId: id,
    onSurveyAvailable,
    onSurveyResult,
  });

  return (
    <S.Wrapper>
      {state === SurveyState.notAvailable && (
        <EmptySearch title="interactions.survey.notAvailable" icon="IcPoll" />
      )}

      {state === SurveyState.available && <Questions />}

      {state === SurveyState.resultAvailable && <Results />}
    </S.Wrapper>
  );
};
