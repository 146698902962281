type Masks = {
  [key: string]: (value: string) => string;
};

const masks: Masks = {
  char(value: string) {
    return value.replace(/\d/g, '');
  },
  number(value: string) {
    return value.replace(/\D/g, '');
  },
};

const getMask = (key: string) => masks[key] || ((value: string) => value);

const handleInputMask = (
  event: React.FormEvent<HTMLInputElement>,
  mask: string,
) => {
  const { currentTarget } = event;

  if (mask) {
    currentTarget.value = getMask(mask)(currentTarget.value);
  }
};

export const MASK_TYPES = {
  char: 'char',
};

export const removeMask = (maskedText: string) =>
  maskedText.replace(/(\.|-|(|)|\/)/g, '');

export default handleInputMask;
