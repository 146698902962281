import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as TypographyStyles from 'components/structure/Typography/Typography';

export const WrapperSpinner = styled.div`
  display: flex;
  height: 200px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      color: ${theme.primary};
      cursor: pointer;
      > svg {
        margin-right: ${theme.spacing.sm};
        color: ${theme.primary};
      }
    }
  `}
`;

export const PlenarySection = styled.div`
  ${({ theme }) => css`
    margin-top: 42px;

    ${media.lessThan('medium')`
      margin-top: ${theme.spacing.md};
    `}
  `}
`;

export const PlenaryScheduleContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    ${TypographyStyles.Heading} {
      margin-bottom: 0;
      font-size: ${theme.typography.sizes.lg};

      &:first-child {
        margin-right: ${theme.spacing.sm};
        line-height: ${theme.typography.lineHeight.md};
      }

      ${media.lessThan('medium')`
        &:nth-child(2) {
          min-width: 160px;
        }
      `}
    }
  `}
`;

export const ScheduleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  margin-bottom: 0px;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.sm};
    color: ${theme.text.secondary};
    line-height: ${theme.spacing.md};
    max-width: 645px;

    ${media.lessThan('large')`
      max-width: 100%;
    `}
  `}
`;

export const SpeakersSection = styled.main`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.xl};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.lessThan('medium')`
      flex-direction: column;
      padding-bottom: ${theme.spacing['2xl']};
    `}
  `}
`;

export const SaveOnAgenda = styled.text`
  font-size: 15px;
  align-items: center;
  font-weight: bold;
  display: flex;
  color: black;
`;

export const ButtonOnAgenda = styled.button`
  border-radius: 3px;
  display: flex;
  border: 2px solid black;
  align-items: center;
  width: 110%;
  height: 40px;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  &:hover {
    background-color: black;
    color: white;
  }
`;

export const OnAgendaSession = styled.div`
  position: absolute;
  top: 43px;
  right: 80px;
`;
