import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as Button from 'components/structure/Button/Button.styles';
// import { PreviewProps } from './Preview';

// type WrapperProps = Pick<PreviewProps, 'showModal'>;
type DisplayContainerProps = {
  isVideo?: boolean;
};

// const wrapperModifiers = {
//   showModal: () => css`
//     opacity: 1;
//     visibility: visible;
//     transition: opacity 0.2s linear, visibility 0s 0s;
//   `,
// };

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s linear, visibility 0s 0.2s;
    z-index: ${'999'};
    overflow: hidden;
    padding: 100px ${theme.spacing['2xl']};

    ${media.lessThan('large')`
      padding: 200px 10px;
    `}

    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s linear, visibility 0s 0s;
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.primary};
    cursor: pointer;
    position: relative;
    width: 100%;
    max-width: 1088px;
    text-align: right;
    padding-top: ${theme.spacing.lg};

    ${media.lessThan('medium')`
      padding: 0;
    `}

    svg {
      width: 24px;
      height: 24px;
    }
  `}
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  ${media.lessThan('large')`
    justify-content: center;
  `}
`;

const displayModifiers = {
  isVideo: () => css`
    ${media.lessThan('medium')`
      width: 90%;
      height: 184px;
    `}
  `,
};

export const DisplayContainer = styled.div<DisplayContainerProps>`
  ${({ theme, isVideo }) => css`
    width: 100%;
    max-width: 1088px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: ${theme.spacing.lg};
    padding-bottom: ${theme.spacing.xl};

    ${Button.Wrapper} {
      margin-top: ${theme.spacing.md};
      border: 1px solid ${theme.primary};
      color: ${theme.primary};
      min-height: 40px;
      height: 40px;

      ${media.lessThan('medium')`
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      `}
    }

    ${media.lessThan('large')`
      position: relative;
      width: 100%;
    `}

    ${isVideo && displayModifiers.isVideo()}
  `}
`;

export const Document = styled.object`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    ${media.lessThan('large')`
      margin-bottom: ${theme.spacing.xs};
    `}
  `}
`;
