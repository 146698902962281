import { useTranslate } from 'hooks';
import { SmallCard } from 'components/contexts/home/SmallCard/SmallCard';
import { StreamState, Stream } from 'interfaces/stream';
import * as S from './WatchAgain.styles';

export type WatchAgainProps = {
  streams: Stream[];
};

export const WatchAgain = ({ streams = [] }: WatchAgainProps) => {
  const translation = useTranslate();
  const watchAgainList = streams
    .filter(
      ({ state, stage }) => StreamState.WatchAgain === state && stage === 1,
    )
    .sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });

  if (!watchAgainList.length) return null;
  return (
    <S.Wrapper>
      <S.Title as="h3">{translation('stream.watchAgain')}</S.Title>
      <S.Content>
        {watchAgainList.map(({ id, title, thumbnail }) => (
          <SmallCard id={id} key={id} title={title} thumbnail={thumbnail} />
        ))}
      </S.Content>
    </S.Wrapper>
  );
};
