import { useCallback } from 'react';
import { Message } from 'interfaces/chat';
import { useSession } from './useSession';
import useTranslate from './useTranslate';

export const usePrefixMessage = () => {
  const translate = useTranslate();
  const session = useSession();

  return useCallback(
    (message?: Message, appendName = false) => {
      if (!message?.content) return translate('interactions.sendAmessage');

      const sendByUser = session.id === message.userId;

      if (sendByUser) {
        return translate('interactions.you') + message.content;
      }

      if (appendName && message.name) {
        const [firstName = ''] = message.name?.split(' ');

        return `${firstName}: ` + message.content;
      }

      return message.content;
    },
    [session.id, translate],
  );
};
