import * as S from './Interest.styles';

export type InterestProps = {
  id: string;
  value: string;
  active?: boolean;
  onClick: () => void;
};

export const Interest = ({ active = false, value, onClick }: InterestProps) => (
  <S.Wrapper type="button" active={active} onClick={onClick}>
    {value}
  </S.Wrapper>
);

export default Interest;
