import { useTranslate } from 'hooks';
import { Heading, Paragraph, Icon } from 'components/structure';
import * as S from './ErrorSearch.styles';

export type ErrorSearchProps = {
  color?: 'primary' | 'secondary';
};

export const ErrorSearch = ({ color = 'primary' }: ErrorSearchProps) => {
  const translate = useTranslate();

  return (
    <S.Container color={color}>
      <Icon icon="IcSearch" />
      <Heading>{translate('errors.errorSearch.title')}</Heading>
      <Paragraph>{translate('errors.errorSearch.subTitle')}</Paragraph>
    </S.Container>
  );
};
