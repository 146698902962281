import {
	ThemePartial,
	ThemeCountdownPartial,
	ThemePrivatePartial,
	ThemePublicPartial,
	ThemeCoffeePartial,
	ThemeBusinessCardPartial,
	Colors,
	ThemeOptions,
} from 'interfaces/theme';
import BackgroundError from 'assets/images/core/sxsw/background-error.png'
import { ReactComponent as Logo } from 'assets/images/core/sxsw/logoTm1.svg';
import BackgroundCountdown from 'assets/images/pages/sxsw/accreditation/bg-fallback-en.jpg';
import BackgroundAccreditationFallback from 'assets/images/pages/sxsw/accreditation/bg-fallback.png';

export const colors: Colors = {
	primary: {
		main: '#39bf6f', //cor do box send && botoes chat && envio de mensagen
		hover: '#66d9a3',
		active: '#66d9a3',
	},
	secondary: {
		main: '#39bf6f',
		hover: '#39bf6f',
		active: '#39bf6f',
	},
	tertiary: {
		main: '#66d9a3',
		active: '#47c885',
		hover: '#5ad297',
	},
	neutral: {
		black: 'black',
		filter: 'rgba(0, 0, 0, 1)',
		modal: '#ffffff',
		materialVideoOverlay: 'rgba(0, 0, 0, 0.4)',
		white: '#fff',
	},
	grayscale: {
		dark: {
			title: '#fff',
			paragraph: '#fff',
			info: 'rgba(255, 255, 255, 0.6)',
			line: 'rgba(255, 255, 255, 0.2)',
		},
		light: {
			title: '#000000',
			info: 'rgba(0, 0, 0, 0.5)',
			line: 'rgba(0, 0, 0, 0.2)',
			paragraph: 'rgba(0, 0, 0, 0.8)',
		},
	},
	background: {
		dark: {
			input: 'rgba(0, 0, 0, 0.5)',
			button: 'rgba(0, 0, 0, 0.01)',
		},
		light: {
			input: 'rgba(255, 255, 255, 0.5)',
			button: 'rgba(255, 255, 255, 0.01)',
		},
		outlet: '#24292D',
		float: {
			solid: '#000000',
		},
		public: {
			outlet: '#202020',
		},
	},
	contextual: {
		info: {
			main: '',
			hover: '',
			active: '',
		},
		success: {
			main: '#38cd3c',
			hover: '#2fc533',
			active: '#22b825',
		},
		attention: {
			main: '#fda700',
			hover: '',
			active: '',
		},
		danger: {
			main: '#ff0202',
			hover: '#cc0202',
			active: '#990303',
		},
		disabled: {
			main: '',
			hover: '',
			active: '',
		},
	},
	skeleton: {
		backgroundColor: '#c4c4c4',
		foregroundColor: 'rgba(255, 255, 255, 0.5)',
	},
	message: {
		light: 'rgba(255, 255, 255, 0.15)',
		dark: 'rgba(0, 0, 0, 0.2)',
	},
	popover: {
		dark: {
			background: '#fff',
		},
		light: {
			background: '#fff',
		},
	},
	modal: {
		dark: {
			background: '#fff',
			backdrop: 'rgba(0, 0, 0, 0.8)',
		},
		light: {
			background: '#fff',
			backdrop: 'rgb(255 255 255 / 38%)',
		},
	},
};

export const typography = {
	family: {},
};

export const Navbar: ThemePartial = {
	colors: {
		neutral: {
			...colors.neutral,
			...{
				black: '#fff',
				white: 'rgba(0, 0, 0, 0.7)',
			},
		},
	},
};

export const BusinessCard: ThemeBusinessCardPartial = {};

export const Footer: ThemePartial = {
	colors: {
		neutral: {
			...colors.neutral,
			...{
				black: '#fff',
				white: '#000',
			},
		},
	},
};

export const Interactions: ThemePartial = {
	colors: {
		grayscale: {
			dark: colors.grayscale.dark,
			light: {
				...colors.grayscale.light,
				paragraph: '#000',
			},
		},
	},
};

export const Private: ThemePrivatePartial = {
	colors: {
		grayscale: {
			dark: colors.grayscale.dark,
			light: colors.grayscale.light,
		},
	},
};

export const Public: ThemePublicPartial = {
	colors: {
		grayscale: {
			dark: colors.grayscale.dark,
			light: colors.grayscale.light,
		},
	},
	options: {
		backgroundPosition: 'center',
	},
};

export const Countdown: ThemeCountdownPartial = {
	colors: {
		neutral: {
			...colors.neutral,
		},
	},
	options: {
		hasFooterDescription: true,
		hasFooterTitle: true,
		hasSocialsLinks: true,
		hasHashtags: false,
	},
};

export const UserCountdown: ThemePartial = {};

export const Coffee: ThemeCoffeePartial = {
	colors: {
		neutral: {
			...colors.neutral,
			black: '#FFF',
			white: '#000',
		},
		background: {
			...colors.background,
			public: {
				outlet: '#fff',
			},
		},
	},
	options: {
		heading: false,
		template: 'default',
	},
};

export const EventOptions: ThemeOptions = {
	hasNotification: true,
	hasLinkedInField: false,
	hasPasswordStrength: false,
	hasTranslationChange: false,
	accreditation: {
		isPrivate: false,
		hasBtnSupport: false,
	},
};

// Quais items do menu estão ativos
export const MenuLabels = [
	'navbar.userMenu.profile',
	'navbar.plenary',
	'navbar.schedule',
	//'navbar.coffee',
	'navbar.sponsors',
	// 'navbar.workshops',
];

export const LogoWhite = Logo;

export {
	Logo,
	BackgroundAccreditationFallback,
	BackgroundCountdown,
	BackgroundError
	// BackgroundCountdownMovile,
};
