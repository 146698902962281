import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { ChatRoomProps } from './ChatRoom';

type WrapperProps = Pick<ChatRoomProps, 'show'>;

const wrapperModifiers = {
  show: () => css`
    transform: translate(0);
    pointer-events: all;
    border-color: transparent;
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, show }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.secondary};
    border-right: 1px solid ${theme.secondary};
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    pointer-events: none;
    transition: all 0.2s ease-in;
    will-change: transform;

    ${media.lessThan('medium')`
      padding-bottom: 80px;
    `}

    ${show && wrapperModifiers.show()};
  `}
`;
