import { useTranslate } from 'hooks';
import { ErrorGeneric } from 'components/structure';
import { THEME } from 'styles/themes';

export const NotFound = () => {
  const translate = useTranslate();

  return (
    <ErrorGeneric
      backgroundImage={THEME.BackgroundError}
      title={translate('errors.notFound.title')}
      description={translate('errors.notFound.description')}
      buttonLabel={translate('labels.goHome')}
      actionNavigate="/home"
    />
  );
};
