import * as S from './UploadProgress.styles';

export type UploadProgressProps = {
  value?: number | undefined;
  justLoad?: boolean;
};

export const UploadProgress = ({
  value = 0,
  justLoad = false,
}: UploadProgressProps) => {
  if (justLoad) {
    return (
      <S.LoaderWrapper>
        <S.Loader />
        <S.LoaderInside />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.LoaderWrapper>
      <S.Value>{value}%</S.Value>
      <S.Loader />
      <S.LoaderInside />
    </S.LoaderWrapper>
  );
};
