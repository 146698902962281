import React, { useEffect } from 'react';
import { useTranslate } from 'hooks';
import { useSettingsContext } from 'contexts';
import { ErrorGeneric } from 'components/structure';
import { THEME } from 'styles/themes';

type ErrorGuard = {
	isError: boolean;
	children: React.ReactNode;
};

export const ServerErrorGuard = ({ isError, children }: ErrorGuard) => {
	const translate = useTranslate();

	const { setIsSpaceFull } = useSettingsContext();

	useEffect(() => {
		if (isError) {
			setIsSpaceFull(true);
		}

		return () => setIsSpaceFull(false);
	}, [isError, setIsSpaceFull]);

	if (isError)
		return (
			<ErrorGeneric
				backgroundImage={THEME.BackgroundError}
				title={translate('errorGuard.title')}
				subtitle={translate('errorGuard.subtitle')}
				description={translate('errorGuard.description')}
				buttonLabel={translate('errorGuard.buttonLabel')}
				actionReload={() => location.reload()}
				isSpaceFull={false}
			/>
		);

	return <>{children}</>;
};
