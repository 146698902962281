/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { getEventThemeName } from 'lib/core/event';

const getEventNameWhiteTranslate = (id: string) => {
  const list = id.split('.');

  const index = list.indexOf(list[list.length]);
  list.splice(index, 0, getEventThemeName());

  return list.join('.');
};

const useTranslate = () => {
  const { formatMessage } = useIntl();

  // TODO validate if is a problem keep like this
  const translate = (id?: string, defaultMessage?: string): string => {
    if (!id) return '';
    const translationEventNameId = getEventNameWhiteTranslate(id);

    const formatMessageEvent = formatMessage({
      id: translationEventNameId,
    });

    if (formatMessageEvent !== translationEventNameId) {
      return formatMessageEvent;
    }

    return formatMessage({ id, defaultMessage });
  };

  translate.withValues = (id: string, values?: any) =>
    formatMessage({ id }, values);

  const translateRef = useRef(translate);

  return translateRef.current;
};

export default useTranslate;
