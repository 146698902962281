import { useRef, useEffect, useState, useCallback } from 'react';
import io from 'socket.io-client';
import { useUserById } from 'useCases/users';

type UseReactionsProps = {
  streamId: string;
};

type HandleSendReactionProps = {
  userId: string | undefined;
  eventId: string;
  reactType: string;
};

type HandleReceiveReactionProps = {
  id: string;
  createdAt: string;
  eventId: string;
  userId: string;
  updatedAt: string;
  reactType: string;
};

export const useReactions = ({ streamId }: UseReactionsProps) => {
  const socket = useRef<SocketIOClient.Socket>();

  const { data } = useUserById(localStorage.getItem('id') || '');
  const [reactions, setReactions] = useState<HandleReceiveReactionProps[]>([]);

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL!, {
      path: '/reactions',
      transports: ['websocket'],
      auth: {
        accessToken: data?.accessToken,
      },
    });

    socket.current.on('connected', () => {
      socket.current?.emit('join_stream', {
        streamId: streamId,
      });
    });
    return () => {
      socket.current?.emit('leave_stream', {
        streamId: streamId,
      });

      socket.current?.close();
    };
  }, [data?.accessToken, streamId]);

  const handleSendReaction = useCallback(
    ({ userId, eventId, reactType }: HandleSendReactionProps) => {
      if (userId) {
        socket.current?.emit('new_reaction', {
          userId: userId,
          eventId: eventId,
          streamId: streamId,
          reactType: reactType,
        });
      }
    },
    [streamId],
  );

  const handleReceiveReaction = useCallback(() => {
    socket.current?.on(
      'share_reaction',
      ({
        id,
        createdAt,
        eventId,
        userId,
        updatedAt,
        reactType,
      }: HandleReceiveReactionProps) => {
        setReactions((prevState) => [
          ...prevState,
          { id, createdAt, eventId, userId, updatedAt, reactType },
        ]);
      },
    );

    socket.current?.on(
      'share_to_all',
      ({
        id,
        createdAt,
        eventId,
        userId,
        updatedAt,
        reactType,
      }: HandleReceiveReactionProps) => {
        setReactions((prevState) => [
          ...prevState,
          { id, createdAt, eventId, userId, updatedAt, reactType },
        ]);
      },
    );
  }, []);

  return {
    handleSendReaction,
    handleReceiveReaction,
    reactions,
    setReactions,
  };
};
