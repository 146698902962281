import styled, { css, DefaultTheme } from 'styled-components/macro';
import { FooterPoweredByProps, Icon } from 'components/structure';

const footerModifiers = {
  fixed: (theme: DefaultTheme) => css`
    /* position: fixed; */
    bottom: calc(${'80px'} + ${theme.spacing.xl});
    right: 39px;
  `,
};

export const Container = styled.div<FooterPoweredByProps>`
  ${({ theme, fixed }) => css`
    background: transparent;
    display: flex;
    justify-content: flex-end;
    ${Icon} {
      color: ${theme.text.details};
      height: 24px;
      width: 120px;
    }
    ${fixed && footerModifiers.fixed(theme)}
  `}
`;
