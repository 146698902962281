import accreditation from './accreditation.json';
import auth from './auth.json';
import coffee from './coffee.json';
import configurations from './configurations.json';
import errorGuard from './error-guard.json';
import errors from './errors.json';
import footer from './footer.json';
import helpCenter from './help-center.json';
import interactions from './interactions.json';
import labels from './labels.json';
import materials from './materials.json';
import navbar from './navbar.json';
import notification from './notification.json';
import rewards from './rewards.json';
import routes from './routes.json';
import schedule from './schedule.json';
import stream from './stream.json';
import users from './users.json';

export const accreditationES = {
  accreditation: accreditation,
};

export const authES = {
  auth: auth,
};
export const coffeeES = {
  coffee: coffee,
};
export const configurationsES = {
  configurations: configurations,
};
export const errorGuardES = {
  errorGuard: errorGuard,
};
export const footerES = {
  footer: footer,
};
export const helpCenterES = {
  helpCenter: helpCenter,
};
export const interactionsES = {
  interactions: interactions,
};
export const materialsES = {
  materials: materials,
};
export const navbarES = {
  navbar: navbar,
};
export const notificationES = {
  notification: notification,
};
export const rewardsES = {
  rewards: rewards,
};
export const routesES = {
  routes: routes,
};
export const scheduleES = {
  schedule: schedule,
};
export const streamES = {
  stream: stream,
};

export const usersES = {
  users: users,
};
export const labelsES = {
  labels: labels,
};
export const errorsES = {
  errors: errors,
};
