import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 70 331 600"
    >
      <rect y="72" width="84" height="25" />

      <rect y="130" width="327" height="40" rx="4" />
      <rect y="186" width="327" height="40" rx="4" />
      <rect y="242" width="327" height="40" rx="4" />
      <rect y="298" width="327" height="40" rx="4" />
    </ContentLoader>
  );
};

const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 70 1000 580"
    >
      <rect x="176" y="75" width="83" height="25" />

      <rect x="176" y="125" width="682" height="40" rx="4" />
      <rect x="176" y="173" width="682" height="40" rx="4" />
      <rect x="176" y="221" width="682" height="40" rx="4" />

      <rect x="176" y="318" width="83" height="25" />

      <rect x="176" y="371" width="682" height="40" rx="4" />
      <rect x="176" y="419" width="682" height="40" rx="4" />
      <rect x="176" y="467" width="682" height="40" rx="4" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <Container>
        <SkeletonMobile />
      </Container>
    </MediaMatch>
  </>
);
