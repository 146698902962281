import { useEffect, useState } from 'react';
import { useEvent } from 'useCases/events';
import { useSettingsContext } from 'contexts';
import { Tour } from 'components/contexts/expo';
import { Spinner } from 'components/structure';
import * as S from './VirtualExpo.styles';

const VirtualExpo = () => {
  const {
    setHasInteraction,
    setIsSpaceFull,
    setHasFooter,
  } = useSettingsContext();
  const { data } = useEvent();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setHasInteraction(false);
    setIsSpaceFull(true);
    setHasFooter(false);

    return () => {
      setHasInteraction(true);
      setIsSpaceFull(false);
      setHasFooter(true);
    };
  }, [setHasFooter, setHasInteraction, setIsSpaceFull]);

  return (
    <S.Container>
      {!loaded && (
        <S.SpinnerWrapper>
          <Spinner size={50} />
        </S.SpinnerWrapper>
      )}
      <Tour src={data?.event.expoUrl || ''} onLoad={() => setLoaded(true)} />
    </S.Container>
  );
};

export default VirtualExpo;
