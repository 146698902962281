import styled, { css } from 'styled-components';
import * as TextFieldStyles from 'components/form/TextField/TextField.styles';
import { DefaultScroll } from 'components/structure';

export const Wrapper = styled(TextFieldStyles.InputWrapper)`
  ${({ theme }) => css`
    background-color: ${theme.inputChat.backgroundColor};
    color: ${theme.inputChat.textColor};
    border-radius: ${theme.shapes.borderRadius.lg};
    padding-left: ${theme.spacing.xl};
    border: none;
  `}
`;

export const Field = styled(TextFieldStyles.Field)`
  ${({ theme }) => css`
    background: 'white';
    border-radius: ${theme.shapes.borderRadius.lg};
    backdrop-filter: none;
    border: none;
    color: ${'black'};
    z-index: 998;
    &,
    &:placeholder-shown {
      padding: 12px 60px 12px 2px;
    }

    &::placeholder {
      font-family: inherit;
      color: ${theme.input.label};
    }
  `}
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
`;

export const ButtonEmoji = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    position: absolute;
    outline: none;
    left: 18px;
    top: 55%;
    transform: translateY(-50%);
    transition: transform 0.2s linear;
    z-index: ${theme.secondary};

    &:active {
      transform: translateY(-50%) scale(0.95);
    }
  `}
`;

type EmojiSelectWrapperProps = {
  show: boolean;
};

export const EmojiSelectWrapper = styled.div<EmojiSelectWrapperProps>`
  ${({ show }) => css`
    display: ${show ? 'block' : 'none'};
    position: absolute;
    bottom: 70px;
    left: 0;
    z-index: 50;

    .emoji-picker-react {
      box-shadow: none;
    }
  `}
`;

type MarkingSelectWrapperProps = {
  show: boolean;
};

export const MarkingWrapper = styled.div<MarkingSelectWrapperProps>`
  ${({ show }) => css`
    display: ${show ? 'block' : 'none'};
    position: absolute;
    background-color: white;
    box-shadow: 0 0px 0px 0 rgb(0 0 0 / 10%), 0 0px 4px 0 rgb(0 0 0 / 15%);
    overflow-y: overlay;
    left: 0px;
    right: 16px;
    bottom: 50px;
    height: auto;
    min-height: 40px;
    max-height: 230px;
    width: 100%;
    border-radius: 16px;
    z-index: 997;
    ${DefaultScroll};

    &:focus {
      outline: none;
    }

    &::-webkit-scrollbar {
      width: 2px;
    }
  `}
`;

export const MarkingContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.secondary};
    display: grid;
    grid-template-rows: 12px 1fr;
    grid-gap: 0 ${theme.spacing.xs};
    justify-items: flex-start;
    position: relative;
    height: 40px;
    flex: 1 0 40px;
    margin: 4px;
    cursor: pointer;
  `}
`;

export const MarkingNameUser = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0px 10% 0px 51px;
  color: black;
`;

export const Marking01 = styled.div`
  border-bottom: 1px solid rgb(39 36 36 / 17%);
  width: 95%;
  margin: 7px;
`;

export const Img = styled.div`
  grid-row: 0;
  margin-top: 6px;
  margin-left: 10px;
`;
