import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 3% calc(${theme.spacing.lg} * 2);
    min-height: 100vh;
    height: 100%;
    position: relative;

    &::before {
      background: linear-gradient(
        to right,
        #f05a6a 27%,
        #d7176f 27%,
        #d7176f 54%,
        #e74783 54%,
        #e74783 71%,
        #ec81a8 71%,
        #ec81a8 88%,
        #f6a2b4 88%,
        #f6a2b4 100%
      );
      content: '';
      height: 135px;
      width: 100%;
      left: 0;
      bottom: 0;
      position: absolute;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.sm};
    max-width: ${'1300px'};
    margin: 0 auto;
    position: relative;
    width: 100%;
    z-index: ${10};
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.secondary};
    font-size: 80px;
    font-family: ${theme.typography.family.primary};
    font-weight: bold;
    margin-bottom: ${theme.spacing.lg};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: #747678;
    font-size: ${theme.typography.sizes.xl};
    font-family: ${theme.typography.family.primary};
    font-weight: 300;
    margin-bottom: ${theme.spacing.xl};
    max-width: 770px;
    width: 100%;

    &:last-child {
      margin-bottom: ${theme.spacing['2xl']};
    }
  `}
`;

export const Actions = styled.div`
  display: flex;
  max-width: 650px;
  width: 100%;
  min-width: 0;
`;

export const Hero = styled.div`
  height: 300px;
  width: 300px;
  position: absolute;
  top: 25%;
  right: 0;

  img {
    height: 100%;
    width: 100%;
  }
`;
