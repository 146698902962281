import { useTranslate } from 'hooks';
import { useSurveyContext } from 'contexts';
import { Paragraph, SubTitle } from 'components/structure';
import * as S from './Results.styles';

export type ResultsProps = {
  showVotes?: boolean;
  alternatives?: {
    id: string;
    title: string;
    percent: number;
  }[];
};

export const Results = ({ showVotes = false }: ResultsProps) => {
  const translate = useTranslate();
  const { survey } = useSurveyContext();

  const getAlternativeName = (targetId: string) =>
    survey.alternatives.find(({ id }) => targetId === id)?.text;

  return (
    <S.Wrapper data-testid="empty-state">
      <S.TopContent>
        <SubTitle>{translate('interactions.survey.results')}</SubTitle>
        {showVotes && (
          <S.AmountVotes>
            <S.AmountText>{survey?.result?.total}</S.AmountText>
          </S.AmountVotes>
        )}
      </S.TopContent>

      <Paragraph>{survey?.question?.text}</Paragraph>

      <div>
        {survey?.result?.alternatives?.map(({ id, percent }) => (
          <S.Result key={id}>
            <S.ResultTitle>{getAlternativeName(id)}</S.ResultTitle>
            <S.ProgressBar width={percent} />
            <strong>{percent.toFixed(0)}%</strong>
          </S.Result>
        ))}
      </div>
    </S.Wrapper>
  );
};
