import { Avatar } from 'components/contexts/users';
import { Icon, Paragraph, SubTitle } from 'components/structure';
import * as S from './Ranking.styles';

export type RankedUser = {
  id: string;
  avatar: string;
  name: string;
  points: number;
};

export type RankingProps = {
  title: string;
  rankedUsers: RankedUser[];
};

export type PositionKeys = 'first' | 'second' | 'third';

type CurrentPosition = {
  [key: number]: PositionKeys;
};

const setPosition: CurrentPosition = {
  1: 'first',
  2: 'second',
  3: 'third',
};

export const Ranking = ({ title, rankedUsers }: RankingProps) => (
  <S.Wrapper>
    <SubTitle>{title}</SubTitle>

    <S.Content>
      {rankedUsers.map(({ id, avatar, name, points }, index) => {
        const position = index + 1;
        const positionKey = setPosition[position];

        return (
          <S.User aria-label="rankedUser" key={id}>
            <S.Position position={positionKey}>{position}</S.Position>
            <Avatar src={avatar} alt={name} />
            <Paragraph>{name}</Paragraph>
            <S.Points showIcon={position === 1}>
              {points}{' '}
              <Icon role="img" aria-hidden={position !== 1} icon="IcCrown" />
            </S.Points>
          </S.User>
        );
      })}
    </S.Content>
  </S.Wrapper>
);
