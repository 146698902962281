import { InterestProps } from 'components/contexts/users';
import { NewTheme } from 'styles/themes/default';
import { Certificate } from 'constants/enums';

export type SocialLink = {
  [key: string]: string;
};

export type Profiles = {
  value: string;
  id: string;
};

export enum EventStatus {
  ShowStreams = 'show-streams',
  ShowAwaitWatchAgain = 'show-await-watch-again',
  ShowWatchAgain = 'show-watch-again',
  ShowBeforeStreams = 'show-before-streams',
  Finished = 'finished',
  ShowCountdown = 'show-countdown',
}

export type Workshop = {
  value: string;
  id: string;
};

export type WorkshopsDays = {
  first: Workshop[];
  second: Workshop[];
  third: Workshop[];
};

export type Event = {
  id: string;
  preferableLanguage: string;
  background: string;
  logo: {
    link: string;
    alt: string;
  };
  interests: InterestProps[];
  profiles: Profiles[];
  languages: string[];
  name: string;
  dates: string[];
  favicon: string;
  socialNetworks: SocialLink[];
  confirmationEmail?: boolean;
  hashtags?: string[];
  expoUrl: string;
  googleAnalyticsId: string;
  watchAgainImageAfterEvent?: string;
  watchAgainImageDuringEvent?: string;
  beforeStreamsImage?: string;
  status: EventStatus;
  closed: boolean;
  workshops: WorkshopsDays;
  hasMemberGetMember?: boolean;
  certificate: Certificate;
  disableChat?: boolean;
  themes?: NewTheme;
  zendesk?: {
    open?: boolean;
    active: boolean;
    url: string;
    id: string;
  };
};
