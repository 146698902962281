import { useFetch } from 'hooks';
import { getEventId } from 'lib/core/event';
import { SERVICES_TERMS } from 'constants/endpoints';

export type Term = {
  name: string;
  url: string;
};

export type TermProps = {
  [key: string]: Term;
};

export const useTerms = () =>
  useFetch<{ terms: TermProps }>(SERVICES_TERMS.BY_EVENT_ID(getEventId()!));
