import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { getEventThemeName } from 'lib/core/event';

const useTheme = () => useContext(ThemeContext);

export const useThemeOptions = () => ({
  themeName: getEventThemeName(),
});

export default useTheme;
