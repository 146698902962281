import { getEventThemeName } from 'lib/core/event';
import * as SXSW from './sxsw';
import * as TM1 from './tm1';

export const themes = {
  SXSW,
  TM1,
};

type ThemeName = /* ProjectNames.SXSW */ keyof typeof themes


const getTheme = () => themes[getEventThemeName() as ThemeName];

export const THEME = getTheme();

export default themes;
