import styled, { css } from 'styled-components';
import * as TypographyStyles from 'components/structure/Typography/Typography';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-gap: ${theme.spacing.xs} ${theme.spacing.sm};
    justify-items: flex-start;
    margin-bottom: 40px;

    ${TypographyStyles.SubTitle} {
      margin-bottom: 0;
    }

    input {
      display: none;
    }
  `}
`;
