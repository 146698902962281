import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';
import { background } from '@storybook/theming';

const SkeletonMobile = () => {
  const { background,  } = useTheme();

  return (
    <div
      style={{
        width: '327px',
        height: '136px',
        marginBottom: '32px',
      }}
    >
      <ContentLoader
        {...skeletonProps}
        viewBox="0 0 327 20"
      >
        <rect x="60" width="203" height="12" />
      </ContentLoader>
      <div
        style={{
          backgroundColor:
            background,
          width: '327px',
          height: '136px',
          borderRadius: '24px',
        }}
      >
        <ContentLoader
          {...skeletonProps}
          viewBox="0 0 331 160"
        >
          <circle cx="40" cy="40" r="25" />

          <rect x="70" y="22" width="200" height="16" />

          <rect x="70" y="46" width="179" height="11" />

          <rect x="70" y="65" width="88" height="11" />

          <rect x="70" y="93" width="18" height="18" />
          <rect x="109" y="93" width="18" height="18" />
        </ContentLoader>
      </div>
    </div>
  );
};

const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <div
      style={{
        width: '288px',
        height: '160px',
      }}
    >
      <ContentLoader
        
        viewBox="0 0 331 20"
      >
        <rect x="60" width="203" height="12" />
      </ContentLoader>
      <div
        style={{
          width: '288px',
          height: '160px',
          borderRadius: '24px',
        }}
      >
        <ContentLoader
          {...skeletonProps}
          viewBox="0 0 331 160"
        >
          <circle cx="56" cy="56" r="40.5" />

          <rect x="108" y="26" width="200" height="24" />

          <rect x="108" y="65" width="179" height="11" />

          <rect x="108" y="89" width="88" height="11" />

          <rect x="108" y="133" width="18" height="18" />
          <rect x="148" y="133" width="18" height="18" />
        </ContentLoader>
      </div>
    </div>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <SkeletonMobile />
    </MediaMatch>
  </>
);
