import { ChangeEvent, InputHTMLAttributes } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { HelperText } from 'components/form';
import * as S from './Checkbox.styles';

export type CheckboxProps = {
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string | JSX.Element;
  name: string;
  error?: string;
  dark?: boolean;
  labelPosition?: 'right' | 'left';
  noCheckOnLabel?: boolean;
} & InputHTMLAttributes<HTMLInputElement> &
  Pick<UseFormMethods, 'register'>;

export const Checkbox = ({
  label = '',
  handleChange,
  register,
  error = '',
  labelPosition = 'right',
  dark = false,
  name,
  noCheckOnLabel,
  ...props
}: CheckboxProps) => (
  <S.Wrapper>
    <S.CheckboxWrapper dark={dark}>
      <S.Input
        ref={register}
        type="checkbox"
        onChange={(event) => handleChange && handleChange(event)}
        name={name}
        id={name}
        {...props}
      />
      <S.Label htmlFor={name}>
        {!!label && (
          <S.LabelText
            onClick={(e) => noCheckOnLabel && e.preventDefault()}
            labelPosition={labelPosition}
          >
            {label}
          </S.LabelText>
        )}
        {/* <S.Checkbox labelPosition={labelPosition} /> */}
      </S.Label>
    </S.CheckboxWrapper>
    {!!error && (
      <HelperText error={!!error}>
        <FormattedMessage id={error} defaultMessage={error} />
      </HelperText>
    )}
  </S.Wrapper>
);
