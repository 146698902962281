import { useEffect } from 'react';
import { Navigate } from 'react-router';
import {
  useCreateBusinessCard,
  useUpdateBusinessCard,
} from 'useCases/accreditation';
import { useCountries } from 'useCases/assets';
import { useEventById, useEvent } from 'useCases/events';
import { useEnv, useTranslate } from 'hooks';
import { useAccreditationContext, useBusinessCardContext } from 'contexts';
import { BusinessCardForm } from 'components/contexts/accreditation';
// import { PublicFaqAnchor } from 'components/extra/PublicFaqAnchor';
import EventOptions from 'styles/overrides/EventOptions.theme';
import { ROUTES } from 'constants/urls';
import { Skeleton } from './CreateBusinessCard.skeleton';

const mapToOptions = (
  optionsAsString: string[] | undefined,
  transform?: (id: string) => string,
) =>
  optionsAsString?.map((option: string) => ({
    label: transform ? transform(`labels.${option}`) : option,
    value: option,
  }));

export const CreateBusinessCard = () => {
  const translate = useTranslate();
  const { eventId } = useEnv();
  const { data: event } = useEvent();
  const { data: countries } = useCountries();
  const { isLoading, data } = useEventById(eventId);

  const { gettingStarted } = useAccreditationContext();
  const { createBusinessCard, isPending } = useCreateBusinessCard();
  const { updateBusinessCard, updateIsPeding } = useUpdateBusinessCard();
  const { setShow } = useBusinessCardContext();
  const hasInterests = !!data?.event.interests.length;
  const hasMemberGetMember = data?.event.hasMemberGetMember;
  // const { referrer } = useQueryString() as { referrer?: string };
  useEffect(() => {
    setShow(true);

    return () => setShow(false);
  }, [setShow]);

  if (!gettingStarted.uuid || !gettingStarted.email) {
    return <Navigate to={ROUTES.accreditation.getLink('gettingStarted')} />;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  const navigateToInterests = Boolean(
    hasInterests && EventOptions.hasInterests,
  );
  const hasConfirmationEmail = event?.event.confirmationEmail
  return (
    <>
      <BusinessCardForm
        countries={countries}
        profiles={data?.event.profiles.map(({ value, id }) => ({
          value: id,
          label: value,
        }))}
        firstDayWorkshops={data?.event?.workshops?.first.map(
          ({ value, id }) => ({
            value: id,
            label: value,
          }),
        )}
        secondDayWorkshops={data?.event?.workshops?.second.map(
          ({ value, id }) => ({
            value: id,
            label: value,
          }),
        )}
        thirdDayWorkshops={data?.event?.workshops?.third.map(
          ({ value, id }) => ({
            value: id,
            label: value,
          }),
        )}
        onSubmit={(values) =>
          !data?.event.closed
            ? createBusinessCard(values, navigateToInterests, hasConfirmationEmail)
            : updateBusinessCard(
              { ...gettingStarted, ...values },
              navigateToInterests,
            )
        }
        isPending={isPending || updateIsPeding}
        languages={mapToOptions(data?.event.languages, translate)}
        defaultValues={gettingStarted}
        hasLinkedInField={EventOptions.hasLinkedInField}
        hasSupportButton={event?.event.zendesk?.active}
        hasCountry={EventOptions.hasCountry}
        hasJobArea={EventOptions.hasJobArea}
        hasMemberGetMember={hasMemberGetMember}
        hasPasswordStrength={EventOptions.hasPasswordStrength}
      />
    </>
  );
};
