import { useState } from 'react';
import {
  useUsersOnlineByCountry,
  IntegrationsTypes,
  Coffee as CoffeeData,
  UserOnline,
  UserOnlineByCountry,
  IntegrationType,
} from 'useCases/coffee';
import { useTranslate } from 'hooks';
import { getEventThemeName } from 'lib/core/event';
import {
  CodeCard,
  MapsCard,
  InstagramCard,
  ExplorationCard,
  WallpaperCard,
} from 'components/contexts/coffee';
import { SubTitle } from 'components/structure';
import { GA_EVENTS, BRAZIL_COUNTRY_CODE, ProjectNames } from 'constants/enums';
import * as S from './Coffee.styles';

export type CoffeeVariationProps = {
  data?: CoffeeData;
  coffeeMap?: UserOnline;
  setCountryToGetUsers: (value: number) => void;
  coffeeMapPersons?: UserOnlineByCountry;
  instagramInformations: IntegrationType;
};

type WrapperProps = {
  data?: CoffeeData;
};

type MapCardWrapperProps = WrapperProps & {
  data?: CoffeeData;
  coffeeMap?: UserOnline;
  setCountryToGetUsers: (value: number) => void;
  coffeeMapPersons?: UserOnlineByCountry;
};

type InstagramCardWrapperProps = {
  instagramInformations: IntegrationType;
};

export const PhotoAppCardWrapper = ({ data }: WrapperProps) => (
  <S.PhotoApp>
    <CodeCard
      hasIcon
      title={data?.photoOpp.title}
      description={data?.photoOpp.description}
      image={data?.photoOpp.qrCode || ''}
      url={data?.photoOpp?.url || ''}
      gaLabel={GA_EVENTS.coffee.labels.filter}
    />
  </S.PhotoApp>
);

export const ExplorationCardWrapper = ({ data }: WrapperProps) => (
  <S.Explorador>
    <ExplorationCard
      title={data?.exploration.title}
      description={data?.exploration.description}
      image={data?.exploration.image || ''}
    />
  </S.Explorador>
);

export const WordsCloudWrapper = ({ data }: WrapperProps) =>
  data?.wordsCloud ? (
    <S.Cloud>
      <CodeCard
        title={data?.wordsCloud.title}
        description={data?.wordsCloud.description}
        image={data?.wordsCloud.qrCode || ''}
        url={data?.wordsCloud?.url || ''}
        gaLabel={GA_EVENTS.coffee.labels.wordsCloud}
      />
    </S.Cloud>
  ) : null;

export const WallpaperCardWrapper = ({ data }: WrapperProps) =>
  data?.wallpaper ? (
    <S.WallpaperCard>
      <WallpaperCard
        title={data?.wallpaper.title}
        description={data?.wallpaper.description}
        image={data?.wallpaper.image || ''}
        urlDownload={data?.wallpaper.url || ''}
      />
    </S.WallpaperCard>
  ) : null;

export const MapCardWrapper = ({
  coffeeMapPersons,
  coffeeMap,
  setCountryToGetUsers,
}: MapCardWrapperProps) => {
  const translate = useTranslate();
  return (
    <S.Map>
      {getEventThemeName() === ProjectNames.VALE && (
        <SubTitle>
          {translate(`coffee.${getEventThemeName()}.titleMap`)}
        </SubTitle>
      )}
      <MapsCard
        mapItems={coffeeMap || { total: 0, countries: [] }}
        connectionItems={coffeeMapPersons || { total: 0, connections: [] }}
        getConnectionsByCountry={(value) => setCountryToGetUsers(value)}
      />
    </S.Map>
  );
};

export const InstagramCardWrapper = ({
  instagramInformations,
}: InstagramCardWrapperProps) => (
  <S.InstramFeed>
    <InstagramCard instagramInformations={instagramInformations} />
  </S.InstramFeed>
);

export const CoffeeMain = ({
  data,
  coffeeMap,
  coffeeMapPersons,
  setCountryToGetUsers,
  instagramInformations,
}: CoffeeVariationProps) => (
  <>
    <InstagramCardWrapper instagramInformations={instagramInformations} />
    <MapCardWrapper
      coffeeMap={coffeeMap}
      coffeeMapPersons={coffeeMapPersons}
      setCountryToGetUsers={setCountryToGetUsers}
    />

    <ExplorationCardWrapper data={data} />
    <PhotoAppCardWrapper data={data} />
  </>
);

export const CoffeeVariantion1 = ({
  data,
  coffeeMap,
  coffeeMapPersons,
  setCountryToGetUsers,
  instagramInformations,
}: CoffeeVariationProps) => (
  <>
    <S.InstramFeed>
      <InstagramCard instagramInformations={instagramInformations} />
    </S.InstramFeed>

    <MapCardWrapper
      coffeeMap={coffeeMap}
      coffeeMapPersons={coffeeMapPersons}
      setCountryToGetUsers={setCountryToGetUsers}
    />
    <ExplorationCardWrapper data={data} />

    <PhotoAppCardWrapper data={data} />
    <WallpaperCardWrapper data={data} />
  </>
);

export const CoffeeVariations = {
  default: {
    component: CoffeeMain,
    gridTemplateAreas: [
      ['map', 'map', 'instagram-feed', 'instagram-feed'],
      ['map', 'map', 'instagram-feed', 'instagram-feed'],
      ['map', 'map', 'exploration', ' exploration'],
      ['photo-app', 'photo-app', 'exploration', 'exploration'],
    ],
  },
  variation1: {
    component: CoffeeVariantion1,
    gridTemplateAreas: [
      ['exploration', 'exploration', 'instagram-feed', 'instagram-feed'],
      ['exploration', 'exploration', 'instagram-feed', 'instagram-feed'],
      ['photo-app', 'photo-app', 'map', 'map'],
      ['wallpaper-card', 'wallpaper-card', 'map', 'map'],
      ['-', '-', 'map', 'map'],
      ['-', '-', 'map', 'map'],
    ],
  },
};

export type CoffeeLayoutProps = {
  data?: CoffeeData;
  coffeeMap?: UserOnline;
  layout: keyof typeof CoffeeVariations;
};

export const CoffeeLayout = ({
  data,
  coffeeMap,
  layout,
}: CoffeeLayoutProps) => {
  const [countryToGetUsers, setCountryToGetUsers] = useState(
    BRAZIL_COUNTRY_CODE,
  );
  const coffeMapPersons = useUsersOnlineByCountry(countryToGetUsers);

  const variation = CoffeeVariations[layout];

  return (
    <S.ContentWrapperItems gridTemplateAreas={variation.gridTemplateAreas}>
      {variation.component({
        data,
        coffeeMap,
        setCountryToGetUsers,
        coffeeMapPersons: coffeMapPersons?.data,
        instagramInformations: data?.integrations?.find(
          ({ type }) => type === IntegrationsTypes.INSTAGRAM,
        ) || {
          title: '',
          description: '',
          type: IntegrationsTypes.INSTAGRAM,
        },
      })}
    </S.ContentWrapperItems>
  );
};
