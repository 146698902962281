import { useForm } from 'react-hook-form';
import { useEventById } from 'useCases/events';
import { useUploadPhoto, useUserById } from 'useCases/users';
import {
  useEnv,
  useTranslate,
  useToast,
  useMapUserToBusinessCard,
} from 'hooks';
import { retrieveUserSession } from 'lib/core/session';
import { useBusinessCardContext } from 'contexts';
import { ChoosePhoto } from 'components/contexts/users';
import { MediaMatch, FooterPoweredBy } from 'components/structure';
import { Photo } from 'interfaces/user';
import { Skeleton } from './ConfigurationsChoosePhoto.skeleton';
import * as S from './ConfigurationsChoosePhoto.styles';

type FormData = {
  allowChat?: boolean;
  userImage?: File;
};

export const ConfigurationsChoosePhoto = () => {
  const { setToast } = useToast();
  const translate = useTranslate();
  const { eventId } = useEnv();
  const { id: userId } = retrieveUserSession();
  const { setCardProps, cardProps } = useBusinessCardContext();
  const { isPending: isUploadPending, uploadPhoto } = useUploadPhoto();

  const userById = useUserById(userId!);
  const eventById = useEventById(eventId);

  const { setValue } = useForm<FormData>({
    defaultValues: userById?.data?.user,
  });

  const handleOnImgPreviewChange = (image: string) =>
    setCardProps({ ...cardProps, image });

  const onSubmit = async (data: Photo) => {
    await uploadPhoto(data);
    setToast({
      type: 'success',
      hasChat: false,
      description: translate('configurations.photoSuccessMessage'),
    });
  };

  useMapUserToBusinessCard(userById.data?.user);

  if (eventById.isLoading || userById.isLoading) {
    return <Skeleton />;
  }

  return (
    <S.Container>
      <ChoosePhoto
        setValue={setValue}
        isPending={isUploadPending}
        onSubmit={onSubmit}
        currentImage={userById.data?.user.avatar}
        alt={userById.data?.user.name}
        onImgPreviewChange={handleOnImgPreviewChange}
      />
      <MediaMatch lessThan="large">
        <FooterPoweredBy />
      </MediaMatch>
    </S.Container>
  );
};
