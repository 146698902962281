/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslate } from 'hooks';
import { useIntlContext } from 'contexts';
import { MaterialsTypes } from 'components/contexts/Materials';
import {
  ButtonGroup,
  Heading,
  ReimagineVideoAccordionItem,
} from 'components/structure';
// import { Skeleton } from './materials.skeleton';
// eslint-disable-next-line no-restricted-imports
import * as S from './materials.styles';
import agenda from './reimagineVideo.json';
import agenda02 from './reimagineVideo02.json';

export type Request = {
  materials: Material[];
};

export type Material = {
  title: string;
  id: string;
  description: string;
  materiais: SubMaterial[];
};

export type SubMaterial = {
  id: string;
  cover: string;
  downloadUrl: string;
  label: string;
  link: string;
  name: string;
  type: MaterialsTypes;
  more?: string;
  url: string;
  'button-name'?: string;
};

export const ReimagineMaterial = () => {
  const translate = useTranslate();
  const { language } = useIntlContext();

  const [materials, setMaterials] = useState<Material[]>();
  const [materials02, setMaterials02] = useState<Material[]>();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedDateParams = searchParams.get('selectedDate');
  const [selectedDate, setSelectedDate] = useState(
    selectedDateParams ?? '07/11',
  );

  // const url =
  //   'https://storage-files-tm1-eventos-prd.s3.us-east-2.amazonaws.com/metasummitlatam/material/reimagine_video_pt.json';

  useEffect(() => {
    const getData = async () => {
      // const response = await axios.get<Request>(url);
      // setMaterials(response.data.materials);

      setMaterials02(agenda02.materials as any);
      setMaterials(agenda.materials as any);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  // if (isLoading) {
  //   return (
  //     <S.Wrapper>
  //       <Heading>{translate('materials.title')}</Heading>
  //       <Skeleton />
  //     </S.Wrapper>
  //   );
  // }

  useEffect(() => {
    if (selectedDate) {
      setSearchParams({ selectedDate });
    }
  }, [setSearchParams, selectedDate]);

  const handleSelectDate = (value: string) => setSelectedDate(value);

  const dates = [
    {
      text: 'Hablo Mesmo',
      value: '07/11',
    },
    {
      text: 'Se liga, hein',
      value: '10/11',
    },
  ];

  return (
    <>
      <S.Wrapper>
        <Heading>{translate('materials.titleVideo')}</Heading>
        <S.subTitle>{translate('materials.subTitleVideo')}</S.subTitle>
        <ButtonGroup
          onChange={handleSelectDate}
          value={selectedDate}
          options={dates}
        />
        {selectedDate === '10/11' ? (
          <>
            <h3>
              O contéudo completo das sessões estará disponível na nossa área de
              transmissão a partir do dia 10/11. Não perca!
            </h3>
            {materials02?.map((material) => (
              <ReimagineVideoAccordionItem
                description={material.description}
                title={material.title}
                key={material.id}
                subMaterials={material.materiais}
              />
            ))}
          </>
        ) : (
          materials?.map((material) => (
            <ReimagineVideoAccordionItem
              description={material.description}
              title={material.title}
              key={material.id}
              subMaterials={material.materiais}
            />
          ))
        )}
      </S.Wrapper>
    </>
  );
};
