import { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef } from 'react';
import * as S from './Button.styles';

export type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = {
  as?: React.ElementType;
  to?: string;
  color?: 'primary' | 'secondary' | 'tertiary';
  fullWidth?: boolean;
  fullWidthMobile?: boolean;
  isSmall?: boolean;
  isLoading?: boolean;
  isRound?: boolean;
} & ButtonTypes;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      color = 'primary',
      fullWidth = false,
      fullWidthMobile = false,
      isSmall = false,
      isLoading = false,
      isRound = false,
      ...props
    },
    ref,
  ) => (
    <S.Wrapper
      ref={ref}
      color={color}
      fullWidth={fullWidth}
      fullWidthMobile={fullWidthMobile}
      isSmall={isSmall}
      isLoading={isLoading}
      isRound={isRound}
      {...props}
    >
      {isLoading ? (
        <S.Loading>
          <div />
          <div />
        </S.Loading>
      ) : (
        children
      )}
    </S.Wrapper>
  ),
);

export default Button;
