import {
  AccreditationProvider,
  BusinessCardProvider,
  ModalProvider,
} from 'contexts';
import { WithChildren } from 'interfaces/children';

export const PublicContexts = ({ children }: WithChildren) => (
  <ModalProvider>
    <AccreditationProvider>
      <BusinessCardProvider>{children}</BusinessCardProvider>
    </AccreditationProvider>
  </ModalProvider>
);
