import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { WithChildren } from 'interfaces/children';

type ContextType = {
  hasInteraction: boolean;
  hasFooter: boolean;
  setHasFooter: (isOpen: boolean) => void;
  setHasInteraction: (isOpen: boolean) => void;
  hasTranslationChange: boolean;
  setHasTranslationChange: (hasChange: boolean) => void;
  isSpaceFull: boolean;
  setIsSpaceFull: (isOpen: boolean) => void;
  isBlockScroll: boolean;
  setIsBlockScroll: (blocked: boolean) => void;
  hasStream: boolean;
  setHasStream: (hasStream: boolean) => void;
  playerHeight: number;
  setPlayerHeight: (height: number) => void;
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
  hasNotification: boolean;
  setHasNotification: (hasNotification: boolean) => void;
  streamPage: boolean;
  setStreamPage: Dispatch<SetStateAction<boolean>>;
};

export const defaultValue: ContextType = {
  hasFooter: true,
  setHasFooter: () => undefined,
  hasInteraction: true,
  setHasInteraction: () => undefined,
  hasTranslationChange: false,
  setHasTranslationChange: () => undefined,
  isSpaceFull: false,
  setIsSpaceFull: () => undefined,
  isBlockScroll: false,
  setIsBlockScroll: () => undefined,
  hasStream: false,
  setHasStream: () => undefined,
  playerHeight: 200,
  setPlayerHeight: () => undefined,
  hasError: true,
  setHasError: () => undefined,
  hasNotification: true,
  setHasNotification: () => undefined,
  streamPage: true,
  setStreamPage: () => undefined,
};

export const SettingsContext = createContext<ContextType>(defaultValue);

export const SettingsProvider = ({ children }: WithChildren) => {
  const [hasInteraction, setHasInteraction] = useState(
    defaultValue.hasInteraction,
  );
  const [hasFooter, setHasFooter] = useState(defaultValue.hasFooter);
  const [hasTranslationChange, setHasTranslationChange] = useState(
    defaultValue.hasTranslationChange,
  );
  const [isSpaceFull, setIsSpaceFull] = useState(defaultValue.isSpaceFull);
  const [isBlockScroll, setIsBlockScroll] = useState(
    defaultValue.isBlockScroll,
  );
  const [hasNotification, setHasNotification] = useState(
    defaultValue.hasNotification,
  );
  const [hasStream, setHasStream] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [streamPage, setStreamPage] = useState(true);

  const [playerHeight, setPlayerHeight] = useState(defaultValue.playerHeight);

  return (
    <SettingsContext.Provider
      value={{
        hasFooter,
        setHasFooter,
        hasInteraction,
        setHasInteraction,
        isSpaceFull,
        setIsSpaceFull,
        hasTranslationChange,
        setHasTranslationChange,
        isBlockScroll,
        setIsBlockScroll,
        hasStream,
        setHasStream,
        playerHeight,
        setPlayerHeight,
        hasError,
        setHasError,
        hasNotification,
        setHasNotification,
        streamPage,
        setStreamPage,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => useContext(SettingsContext);
