import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Wrapper as AvatarWrapper } from 'components/contexts/users/Avatar/Avatar.styles';
import * as Typography from 'components/structure/Typography/Typography';

export const Container = styled.article`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.md};
    align-items: center;
    background: ${theme.secondary};
    width: 100%;
    height: 100%;
    border-radius: ${theme.shapes.borderRadius.md};
    border: 1px solid ${theme.secondary};
    margin-bottom: ${theme.spacing.sm};
    break-inside: avoid;
    max-width: 450px;

    > img {
      width: 128px;
      height: 128px;
      border-radius: 50%;
      background: #ddd;
      object-fit: cover;
    }

    > ${AvatarWrapper} {
      width: 128px;
      height: 128px;
    }

    > a {
      margin-top: ${theme.spacing.sm};
      color: ${theme.secondary};
      text-decoration: underline;
      font-size: ${theme.typography.sizes.sm};
    }

    ${Typography.Heading} {
      color: ${theme.text.primary};
      font-size: ${theme.typography.sizes.lg};
      margin: ${theme.spacing.sm} 0 0;
    }

    ${media.lessThan('large')`
      max-width: 100%;
    `}
    ${media.greaterThan('huge')`
      max-width: 550px;
    `}
  `}
`;

export const DescriptionContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: ${theme.spacing.sm};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.text.secondary};
    line-height: ${theme.spacing.md};
  `}
`;
