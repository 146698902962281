import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

export const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 930 420"
    >
      <rect x="24" y="6" width="262" height="20" />

      <rect x="24" y="46" width="200" height="140" rx="8" />
      <rect x="244" y="46" width="200" height="140" rx="8" />
      <rect x="464" y="46" width="200" height="140" rx="8" />

      <rect x="24" y="230" width="262" height="20" />

      <rect x="24" y="270" width="200" height="140" rx="8" />
      <rect x="244" y="270" width="200" height="140" rx="8" />
      <rect x="464" y="270" width="200" height="140" rx="8" />
    </ContentLoader>
  );
};

export const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 327 740"
    >
      <rect x="24" y="6" width="262" height="20" />

      <rect x="24" y="68" width="200" height="16" />

      <rect x="24" y="100" width="200" height="140" rx="8" />
      <rect x="244" y="100" width="200" height="140" rx="8" />
      <rect x="464" y="100" width="200" height="140" rx="8" />

      <rect x="24" y="282" width="242" height="16" />

      <rect x="24" y="320" width="200" height="140" rx="8" />
      <rect x="244" y="320" width="200" height="140" rx="8" />
      <rect x="464" y="320" width="200" height="140" rx="8" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <SkeletonMobile />
    </MediaMatch>
  </>
);
