import { useContext } from 'react';
import { useTranslate } from 'hooks';
import { ToastContext, ToastContextProps } from 'contexts/Toast';

const typesTitles = {
  default: '',
  success: 'labels.success',
  danger: 'labels.danger',
  alert: 'labels.alert',
  conversion: '',
};

export const useToast = () => {
  const { setToast } = useContext(ToastContext);
  const translate = useTranslate();

  const handleToast = ({
    title,
    type = 'default',
    ...rest
  }: ToastContextProps) => {
    const toastTitle =
      !title && type !== 'default'
        ? translate(typesTitles[type], typesTitles[type])
        : title;

    setToast({ title: toastTitle, type, ...rest });
  };

  return {
    setToast: handleToast,
  };
};
