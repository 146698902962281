import { getEventThemeName } from 'lib/core/event';
import { ProjectNames, CF_CODE } from 'constants/enums';
import * as S from './CFCode.styles';

type CFCodeProps = {
  isDesktop?: boolean;
  isCenter?: boolean;
  marginTop?: boolean;
  marginBottom?: boolean;
  marginLeft?: boolean;
};

export const CFCode = ({
  isDesktop,
  isCenter,
  marginTop,
  marginBottom,
  marginLeft,
}: CFCodeProps) =>
  getEventThemeName() === ProjectNames.BAYER ? (
    <S.Content
      isDesktop={isDesktop}
      isCenter={isCenter}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
    >
      {CF_CODE}
    </S.Content>
  ) : (
    <></>
  );

export default CFCode;
