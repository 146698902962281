import { ThemeProvider } from 'styled-components';
import { useTheme } from 'hooks';
import { WithChildren } from 'interfaces/children';
import {
  ThemePartial,
  ThemePrivatePartial,
  ThemeCountdownPartial,
} from 'interfaces/theme';

type ThemeProps = WithChildren &
  ThemePartial &
  ThemePrivatePartial &
  ThemeCountdownPartial;

export const ThemeCustomProvider = ({
  children,
  colors,
  typography,
  options,
}: ThemeProps) => {
  const theme = useTheme();
  return (
    <ThemeProvider
     theme={theme} >
      {children}
    </ThemeProvider>
  );
};
