import { useEffect } from 'react';
import { useCountries } from 'useCases/assets';
import { useEventById } from 'useCases/events';
import { useEnv } from 'hooks';
import { isBayer } from 'lib/contexts';
import { getEventThemeName } from 'lib/core/event';
import { useBusinessCardContext } from 'contexts';
import { brazilianStates } from 'components/contexts/accreditation/BusinessCardForm/Default/BusinessCardForm.mock';
import { Area, User } from 'interfaces/user';
import { ProjectNames } from 'constants/enums';

export const useMapUserToBusinessCard = (user?: User) => {
  const { setCardProps } = useBusinessCardContext();
  const { eventId } = useEnv();
  const { data } = useEventById(eventId);
  const { data: countries } = useCountries();

  useEffect(() => {
    if (user) {
      const {
        firstName,
        lastName,
        country,
        state,
        profile,
        linkedInUrl,
        email,
        avatar,
      } = user;
      const isThemeBayer = getEventThemeName() === ProjectNames.BAYER;
      const isEmailBayer = isBayer(email ?? '');
      const isBayerEventAndEmail = isEmailBayer && isThemeBayer;

      const profileCardProp = isBayerEventAndEmail
        ? 'Bayer'
        : data?.event.profiles.find(
            ({ id }) =>
              id ===
              (isThemeBayer
                ? profile?.specialty
                : ((profile?.area as unknown) as Area)?.id),
          )?.value;

      const countryCardProp =
        typeof country === 'object' && country !== null
          ? country
          : countries?.find(({ value }) => value === country);

      const stateCardProp = brazilianStates.find(({ value }) => value === state)
        ?.label;

      setCardProps({
        country: countryCardProp,
        name: `${firstName} ${lastName}`,
        state: stateCardProp,
        profile: profileCardProp,
        linkedIn: linkedInUrl,
        email,
        image: avatar,
      });
    }
  }, [setCardProps, user, data, countries]);
};
