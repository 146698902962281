import { mutate } from 'swr';
import { OffersResponse } from 'useCases/offers';
import { OfferItemProps } from 'components/contexts/interactions/Offers/OfferItem/OfferItem';

type AppendOffersProps = {
  url: string;
  offers: OfferItemProps[];
  isFetchingMore?: boolean;
};

export const appendOffers = ({
  url,
  offers,
  isFetchingMore,
}: AppendOffersProps) =>
  mutate(
    url,
    (prevState: OffersResponse) => {
      const prevOffers = prevState?.offers ?? [];

      const appendedOffers = isFetchingMore
        ? [...prevOffers, ...offers]
        : [...offers, ...prevOffers];

      return {
        ...prevState,
        offers: appendedOffers,
      };
    },
    false,
  );
