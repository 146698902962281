import styled, { css } from 'styled-components';

export const IconContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.xs};
    cursor: pointer;
  `}
`;
