import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { CodeCardProps } from './CodeCard';

type CardProps = Pick<CodeCardProps, 'largeDescription'>;

const CardModifiers = {
  largeDescription: () => css`
    height: 208px;
  `,
};

export const Container = styled.article<CardProps>`
  ${({ theme, largeDescription }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing.md};
    background: ${theme.secondary};
    min-height: 176px;
    height: auto;
    border-radius: ${theme.shapes.borderRadius.md};
    justify-content: space-between;

    ${media.lessThan('huge')`
      width: 456px;
    `}

    ${media.lessThan('medium')`
      width: 327px;

      ${largeDescription && CardModifiers.largeDescription()}
    `}

    svg {
      margin-right: ${theme.spacing.xs};
    }
  `}
`;

export const AsideContainer = styled.aside`
  ${({ theme }) => css`
    height: 100%;
    max-width: 302px;
    padding-right: ${theme.spacing.sm};
    align-self: flex-start;

    ${media.lessThan('medium')`
      padding-right: ${theme.spacing.xs};
    `}
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    svg {
      width: 22px;
      height: 22px;
      path {
        color: ${theme.text.primary};
        fill: ${theme.text.primary};
        stroke: ${theme.text.primary};
      }
    }
  `}
`;

export const Heading = styled.h2`
  ${({ theme }) => css`
    color: ${theme.text.primary};
    font-size: ${theme.typography.sizes.lg};
    word-wrap: break-word;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.text.secondary};
    line-height: ${theme.spacing.md};
    margin-top: ${theme.spacing.sm};
    font-size: 14px;
    word-wrap: break-word;

    ${media.lessThan('medium')`
      max-width: 159px;
    `}
  `}
`;

export const CodeContainer = styled.aside`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.xs};
    position: relative;
    flex-direction: column;

    &::after {
      display: block;
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      top: -1px;
      right: -1px;
      border-top: 3px solid ${theme.primary};
      border-right: 3px solid ${theme.primary};
      border-top-right-radius: ${theme.shapes.borderRadius.sm};
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: -1px;
      left: -1px;
      border-top: 3px solid ${theme.primary};
      border-left: 3px solid ${theme.primary};
      border-top-left-radius: ${theme.shapes.borderRadius.sm};
    }

    > div {
      position: relative;
      width: 100%;

      &::after {
        display: block;
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        bottom: -9px;
        left: -9px;
        border-bottom: 3px solid ${theme.primary};
        border-left: 3px solid ${theme.primary};
        border-bottom-left-radius: ${theme.shapes.borderRadius.sm};
      }

      &::before {
        display: block;
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        bottom: -9px;
        right: -9px;
        border-bottom: 3px solid ${theme.primary};
        border-right: 3px solid ${theme.primary};
        border-bottom-right-radius: ${theme.shapes.borderRadius.sm};
      }
    }
  `}

  img {
    width: 104px;

    ${media.lessThan('medium')`
      width: 72px;
    `}
  }
`;
