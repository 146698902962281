import { useLocation } from 'react-router-dom';

export const useQueryString = () => {
  const { search } = useLocation();
  const items = search.replace('?', '').split('=');
  let params = {};
  if (items.length >= 2) {
    items.forEach((item, index) => {
      params = {
        ...params,
        [item]: items[index + 1],
      };
      return params;
    });
  }
  return params;
};
