import {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { YoutubeContainer } from 'components/contexts/interactions/VirtualRoom/YoutubeContainer';
import { WithChildren } from 'interfaces/children';
import { ROUTES } from 'constants/urls';

type ContextType = {
  url: string;
  isActive: boolean;
  setUrl: (url: string) => void;
  setPlenaryId: (id: string) => void;
  toggle: () => void;
  setIsActive: (isActive: boolean) => void;
};

const defaultValue: ContextType = {
  url: '',
  isActive: false,
  setUrl: () => undefined,
  setPlenaryId: () => undefined,
  toggle: () => undefined,
  setIsActive: () => undefined,
};

export const MiniPlayerContext = createContext<ContextType>(defaultValue);

export const MiniPlayerProvider = ({ children }: WithChildren) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [plenaryId, setPlenaryId] = useState<string>('');

  const toggle = useCallback(() => setIsActive(!isActive), [isActive]);

  useEffect(() => {
    if (isActive) {
      navigate(ROUTES.app.base);
    } else if (plenaryId !== '') {
      navigate(ROUTES.stream.getLink('id', plenaryId));
    }
  }, [isActive, navigate, plenaryId]);

  const value = useMemo(
    () => ({
      isActive,
      url,
      setUrl,
      setPlenaryId,
      toggle,
      setIsActive,
    }),
    [isActive, toggle, url],
  );

  return (
    <MiniPlayerContext.Provider value={value}>
      {isActive && <YoutubeContainer url={url} isMiniPlayer />}

      {children}
    </MiniPlayerContext.Provider>
  );
};

export const useMiniPlayerContext = () => useContext(MiniPlayerContext);

MiniPlayerContext.displayName = 'MiniPlayerContext';
