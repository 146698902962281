
import styled, { css } from 'styled-components';

export type HelperTextProps = {
  error?: boolean;
};

export const HelperText = styled.span<HelperTextProps>`
  ${({ theme, error }) => css`
    color: ${error
      ? 'red'
      : theme.text.secondary};
    font-size: ${theme.typography.sizes.xs};
    font-family: ${theme.typography.family.primary};
    font-weight: 400;
    line-height: 16px;
    padding: 0 10px 0;
    margin-top: 4px;

    @media (max-width: 320px) {
      font-size: 10px;
    }
  `}
`;
