import { Link } from 'react-router-dom';
import { ROUTES } from 'constants/urls';
import * as S from './ExplorationCard.styles';

export type ExplorationCardProps = {
  title?: string;
  description?: string;
  image?: string;
};

export const ExplorationCard = ({
  title,
  description,
  image,
}: ExplorationCardProps) => (
  <Link to={ROUTES.app.getLink('expo')}>
    <S.Container>
      <S.Heading>{title}</S.Heading>
      <S.Description>{description}</S.Description>
      <S.ImageContainer image={image} />
    </S.Container>
  </Link>
);
