import axios from 'axios';
import { getEventId } from 'lib/core/event';
import { correctLanguage } from 'lib/core/language';
import { baseURL } from 'constants/endpoints';

const api = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_X_API_KEY,
    'event-id': getEventId(),
    language:
      localStorage.getItem('language') || correctLanguage(navigator.language),
  },
});

// TODO add 401 and refresh token
export default api;
