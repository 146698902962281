import {
  createContext,
  useContext,
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { isMobile, isSafari } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useEnv } from 'hooks';
import { WithChildren } from 'interfaces/children';
import { Players } from 'constants/enums';
import { ROUTES } from 'constants/urls';
import IcPip from 'assets/icons/interactions/pip.svg';

type VideoPresentationModeType = 'inline' | 'picture-in-picture';

interface IHTMLVideoElement extends HTMLVideoElement {
  requestPictureInPicture: () => Promise<void>;
  disablePictureInPicture: boolean;
  webkitPresentationMode?: VideoPresentationModeType;
  webkitSetPresentationMode: (mode: VideoPresentationModeType) => void;
  webkitSupportsPresentationMode: boolean;
}

type StreamType = { id: string; url: string };

type ContextType = {
  handlePip: () => Promise<void>;
  stream: StreamType;
  setStream: (params: StreamType) => void;
  openPip: () => Promise<void>;
};

const defaultValue: ContextType = {
  handlePip: async () => undefined,
  stream: { id: '', url: ' ' },
  setStream: () => undefined,
  openPip: async () => undefined,
};

const PIP_CONFIG = {
  icon: IcPip,
  tooltipText: 'Picture In Picture',
  id: 'pip',
};

export const PictureInPictureContext = createContext<ContextType>(defaultValue);

export const PictureInPictureProvider = ({ children }: WithChildren) => {
  const { reactJWPlayer } = useEnv();
  const navigate = useNavigate();
  const pipRef = useRef(document.createElement('div'));
  const [stream, setStream] = useState<StreamType>({ id: '', url: '' });

  const isPipAvailable = useCallback(
    (): boolean => Boolean(document.pictureInPictureEnabled),
    [],
  );

  const enterPictureInPicture = useCallback(
    async (videoElement: IHTMLVideoElement) => {
      if (
        document.pictureInPictureEnabled &&
        !videoElement.disablePictureInPicture
      ) {
        try {
          if (document.pictureInPictureElement) {
            document.exitPictureInPicture();
          }

          if (isSafari && videoElement.webkitSupportsPresentationMode) {
            videoElement.webkitSetPresentationMode('picture-in-picture');
          } else {
            await videoElement.requestPictureInPicture();
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [],
  );

  const leavePip = useCallback(() => {
    const start = () => {
      const startTime = document.querySelector('.jw-text-elapsed')!.textContent;
      const split = startTime?.split(':');
      if (split?.length == 3) {
        const result =
          parseInt(split[0]) * 60 * 60 +
          parseInt(split[1]) * 60 +
          parseInt(split[2]);
        return result;
      }
      if (split?.length == 2) {
        const result = parseInt(split[0]) * 60 + parseInt(split[1]);
        return result;
      } else {
        const toSting = String(split);
        const result = parseInt(toSting);
        return result;
      }
    };
    const starttime = start();
    localStorage.setItem('video-time', String(starttime));
    window.jwplayer(Players.PIP).remove();
    navigate(ROUTES.stream.getLink('id', stream.id));
  }, [navigate, stream.id]);

  const bindLeavePictureInPictureEvent = useCallback(
    (video: IHTMLVideoElement) => {
      video.addEventListener('leavepictureinpicture', () => leavePip());
    },
    [leavePip],
  );

  const openPip = useCallback(async () => {
    const player = window.jwplayer(Players.PIP);
    const container = player.getContainer() as HTMLDivElement;
    const video = container.querySelector('.jw-video') as IHTMLVideoElement;
    await video.play();
    await enterPictureInPicture(video);
  }, [enterPictureInPicture]);

  const onReady = useCallback(() => {
    const player = window.jwplayer(Players.PIP);
    const container = player.getContainer() as HTMLDivElement;
    const video = container.querySelector('.jw-video') as IHTMLVideoElement;

    if (isMobile || !isPipAvailable()) {
      const { icon, tooltipText, id } = PIP_CONFIG;
      player.addButton(icon, tooltipText, leavePip, id);
      player.setFloating(true);
    }
    bindLeavePictureInPictureEvent(video);
    navigate(ROUTES.app.getLink('home'));
  }, [bindLeavePictureInPictureEvent, isPipAvailable, leavePip, navigate]);

  const handlePip = useCallback(async () => {
    if (typeof window.jwplayer !== 'function') return;

    const player = window.jwplayer(Players.PIP);

    const { url: file } = stream;

    const start = () => {
      const startTime = document.querySelector('.jw-text-elapsed')!.textContent;
      const split = startTime?.split(':');
      if (split?.length == 3) {
        const result =
          parseInt(split[0]) * 60 * 60 +
          parseInt(split[1]) * 60 +
          parseInt(split[2]);
        return result;
      }
      if (split?.length == 2) {
        const result = parseInt(split[0]) * 60 + parseInt(split[1]);
        return result;
      } else {
        const toSting = String(split);
        const result = parseInt(toSting);
        return result;
      }
    };

    if (!player) return;
    const starttime = start();
    player.setup({
      playerId: Players.PIP,
      playerScript: reactJWPlayer,
      playlist: {
        file,
        starttime,
      },
    });

    const promise = new Promise<void>((resolve) => {
      player.on('ready', () => {
        onReady();
        resolve();
      });
    });

    player.play();
    return promise;
  }, [onReady, reactJWPlayer, stream]);

  const value = useMemo(
    () => ({
      handlePip,
      stream,
      setStream,
      openPip,
    }),
    [handlePip, openPip, stream],
  );

  useEffect(() => {
    if (!pipRef.current) return;
    const element = pipRef.current;
    element.setAttribute('id', Players.PIP);
    document.body.appendChild(element);

    return () => {
      if (element && document.body) {
        document.body.removeChild(element);
      }
    };
  }, []);

  return (
    <PictureInPictureContext.Provider value={value}>
      {children}
    </PictureInPictureContext.Provider>
  );
};

export const usePictureInPictureContext = () =>
  useContext(PictureInPictureContext);

PictureInPictureContext.displayName = 'PictureInPictureContext';
