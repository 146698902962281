import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { LogoProps } from './Logo';

type WrapperProps = Omit<LogoProps, 'url' | 'alt'>;

const wrapperModifiers = {
  center: () => css`
    height: 65px;

    svg,
    img {
      height: 100%;
    }

    ${media.lessThan('medium')`
      width: 60px;
    `}
  `,
  large: () => css`
    width: 390px;

    svg,
    img {
      height: auto;
      width: 100%;
    }

    ${media.lessThan('medium')`
      width: 240px;
    `}
  `,
  medium: () => css`
    height: 65px;

    svg,
    img {
      height: 138%;
      width: auto;
    }

    ${media.lessThan('medium')`
      width: 180px;
    `}
  `,
  hideOnMobile: () => css`
    ${media.lessThan('medium')`
      display: none;
    `}
  `,
  light: (theme: DefaultTheme) => css`
    color: ${theme.text.primary};
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, center, large, medium, hideOnMobile, light }) => css`
    color: ${theme.secondary};
    height: auto;
    width: auto;
    transition: all 0.3s linear;

    svg,
    img {
      transition: all 0.3s linear;
      /* height: 45px; */
      width: auto;
    }

    &:not(:last-child) {
      margin-bottom: ${theme.spacing.lg};
    }

    ${media.lessThan('medium')`
      display: flex;
      align-items: center;
      /* width: 135px; */

      svg,
   
    `}

    ${center && wrapperModifiers.center()};
    ${large && wrapperModifiers.large()};
    ${medium && wrapperModifiers.medium()};
    ${hideOnMobile && wrapperModifiers.hideOnMobile()};
    ${light && wrapperModifiers.light(theme)};
  `}
`;
