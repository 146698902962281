import { useGetGift } from 'useCases/memberGetMember';
import { useTranslate } from 'hooks';
import { LevelProps } from 'components/contexts/rewards';
import { Icon } from 'components/structure';
import * as S from './ProgressBox.styles';

type RewardStatusProps = Pick<LevelProps, 'giftLink' | 'redeemedGift' | 'id'>;

const RewardStatus = ({ id, giftLink, redeemedGift }: RewardStatusProps) => {
  const translate = useTranslate();
  const { handleGetGift, isPending } = useGetGift();

  return giftLink ? (
    <S.Anchor
      onClick={() => handleGetGift(id)}
      href={giftLink}
      target="_blank"
      rel="noopener noreferrer"
      aria-disabled={isPending}
      isPending={isPending}
    >
      {translate('rewards.indicationProgress.getItem')}
    </S.Anchor>
  ) : redeemedGift ? (
    <S.AltSmall>{translate('rewards.indicationProgress.redeemed')}</S.AltSmall>
  ) : (
    <S.AltSmall>
      <Icon icon="IcLock" />
      {translate('rewards.indicationProgress.blocked')}
    </S.AltSmall>
  );
};

type ProgressBoxProps = {
  userPoints: number;
  level: LevelProps;
};

export const ProgressBox = ({
  userPoints,
  level: {
    id,
    title,
    giftLink,
    redeemedGift,
    neededPoints,
    hasProgressTop,
    hasProgressBottom,
  },
}: ProgressBoxProps) => {
  const translate = useTranslate();

  return (
    <S.ProgressBox
      key={title}
      hasProgressTop={hasProgressTop}
      hasProgressBottom={hasProgressBottom}
      active={userPoints >= neededPoints}
    >
      <Icon role="img" icon="IcCheckBox" />
      <S.Aside>
        <strong>{title}</strong>
        <S.Divisor>
          <S.NeededPoints>
            {neededPoints} {translate('rewards.indicationProgress.points')}
          </S.NeededPoints>

          <RewardStatus
            id={id}
            giftLink={giftLink}
            redeemedGift={redeemedGift}
          />
        </S.Divisor>
      </S.Aside>
    </S.ProgressBox>
  );
};
