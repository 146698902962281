import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslate } from 'hooks';
import { facebookLink, twitterLink, whatsappLink } from 'lib/contexts/rewards';
import { Icon, SubTitle } from 'components/structure';
import * as S from './Share.styles';

export type ShareProps = {
  title: string;
  userLink: string;
};

// TODO remove after a title definition (design)
const title = 'Compartilhe seu link para acumular pontos';

const socialLinks = {
  whatsapp: {
    icon: () => <Icon icon="IcWhatsapp" />,
    link: (link: string) =>
      whatsappLink(link, {
        title,
        separator: ': ',
      }),
  },
  facebook: {
    icon: () => <Icon icon="IcFacebook" />,
    link: (link: string) => facebookLink(link, title),
  },
  twitter: {
    icon: () => <Icon icon="IcTwitter" color="#00ACEE" />,
    link: (link: string) => twitterLink(link, title),
  },
};

export const Share = ({ title, userLink }: ShareProps) => {
  const translate = useTranslate();
  const [isCopied, setIsCopied] = useState(false);

  const handleShareLink = async (link: string) => {
    if (isMobile) {
      return navigator.share({
        title,
        url: userLink,
      });
    }
    window.open(link, '_blank');
  };

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(userLink);
    setIsCopied(true);
  };

  return (
    <S.Wrapper>
      <SubTitle>{title}</SubTitle>

      <S.ShareInput isCopied={isCopied}>
        <S.ShareLabel>{translate('rewards.share.linkLabel')}</S.ShareLabel>
        <span>{userLink}</span>
        <Icon
          aria-label="copy link"
          onClick={handleCopyLink}
          icon={isCopied ? 'IcCheck' : 'IcCopy'}
        />
      </S.ShareInput>

      <S.ShareText>{translate('rewards.share.shareOnSocialMedia')}</S.ShareText>

      <S.SocialLinks>
        {Object.entries(socialLinks).map(([key, { icon: Icon, link }]) => (
          <S.ShareButton
            onClick={() => handleShareLink(link(userLink))}
            title={key}
            key={key}
          >
            <Icon />
          </S.ShareButton>
        ))}
      </S.SocialLinks>
    </S.Wrapper>
  );
};
