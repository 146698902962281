import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { TopicProps } from 'components/contexts/schedule/Topic/Topic';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.xs};
    width: 100%;

    ${media.lessThan('medium')`
      align-items: flex-start;
      flex-direction: column;
    `}
  `}
`;

export const ScheduleTimeContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.text.secondary};
    font-size: ${theme.typography.sizes.sm};
    margin-right: 40px;
    min-width: 140px;
    justify-content: flex-end;

    > small {
      &:nth-child(2) {
        margin: 0 2px;
      }
    }

    ${media.lessThan('medium')`
      justify-content: flex-start;
      margin-bottom: ${theme.spacing.xs};
    `}
  `}
`;

type ContainerProps = Pick<TopicProps, 'hasDetails'>;
export const ScheduleEventsContainer = styled.ul<ContainerProps>`
  ${({ theme, hasDetails }) => css`
    width: 100%;
    cursor: ${hasDetails ? 'pointer' : 'auto'};
    max-width: 680px;
    background: ${theme.secondary};
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
    border-radius: ${theme.shapes.borderRadius.xs};
    /* border: 1px solid ${theme.input}; */
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover {
      transform: scale(1.04);
    }
  `}
`;

export const ScheduleEvent = styled.li`
  ${({ theme }) => css`
    color: ${theme.text.secondary};
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: ${theme.spacing.md};

    & + li {
      margin-top: ${theme.spacing['2xs']};
    }

    svg {
      text-align: center;
      margin: auto;

      & > path {
        fill: currentColor;
      }
    }
  `}
`;

export const Description = styled.span`
  ${({ theme }) => css`
    padding-right: ${theme.spacing.sm};
    display: -webkit-box;
    line-clamp: 2;
    color: ${theme.text.primary};
    /* -webkit-line-clamp: 2; */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const ContainerDetails = styled.div`
  display: flex;
`;

export const Details = styled.span`
  ${({ theme }) => css`
    font-size: 16px;
    color: ${theme.primary};
    white-space: nowrap;
    font-weight: 600;
  `}
`;
