import styled, { css } from 'styled-components';

export const ChangeLanguage = styled.div`
  ${({ theme }) => css`
	z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${theme.secondary};

    > button + button {
      margin-top: 11px;
    }
  `}
`;
