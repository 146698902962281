import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

export const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 962 570"
    >
      <rect width="962" height="422" />
      <rect x="0" y="444" width="110" height="18" />
      <rect x="0" y="484" width="170" height="13" />
      <rect x="0" y="519" width="592" height="16" />
    </ContentLoader>
  );
};

export const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 327 300"
    >
      <rect width="327" height="169" />
      <rect x="0" y="188" width="84" height="14" />
      <rect x="0" y="221" width="170" height="13" />
      <rect x="0" y="253" width="325" height="15" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <SkeletonMobile />
    </MediaMatch>
  </>
);
