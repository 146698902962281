import { ChangeEvent, InputHTMLAttributes } from 'react';
import { UseFormMethods } from 'react-hook-form';
import * as S from './RadioButton.styles';

export type RadioButtonProps = {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  currentValue?: string;
} & InputHTMLAttributes<HTMLInputElement> &
  Pick<UseFormMethods, 'register'>;

export const RadioButton = ({
  register,
  name,
  label,
  onChange,
  id,
  currentValue,
  ...props
}: RadioButtonProps) => (
  <S.Wrapper checked={currentValue === id}>
    <S.Input
      ref={register}
      type="radio"
      onChange={(event) => onChange && onChange(event)}
      name={name}
      id={id}
      {...props}
    />
    <S.Label htmlFor={id}>
      <S.Radio />
      {!!label && <span>{label}</span>}
    </S.Label>
  </S.Wrapper>
);
