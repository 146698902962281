import { useSocketListener } from 'hooks';
import { useToast, useAPI } from 'hooks';
import { appendOffers } from 'lib/contexts/offers';
import {
  useSocketContext,
  useIntlContext,
  useNotificationContext,
  Language,
  useSettingsContext,
} from 'contexts';
import { OfferItemProps } from 'components/contexts/interactions/Offers/OfferItem/OfferItem';
import { Stream } from 'interfaces/stream';
import { OFFERS, STREAMS } from 'constants/endpoints';
import {
  NotificationEvents,
  StreamEvents,
  NotificationTypes,
} from 'constants/enums';

type LanguageItem = {
  language: string;
  text: string;
};

export type Notification = {
  createdAt: string;
  eventId: string;
  id: string;
  payload: {
    description: LanguageItem[];
    optionalText: LanguageItem[];
    buttonLabel: LanguageItem[];
    title: LanguageItem[];
    link: string;
    image?: string;
    isExternalLink?: boolean;
    expiresInSeconds: number;
    notExpires: boolean;
    isOffer?: boolean;
  };
  type: string;
  updatedAt: string;
};

export type NotificationStream = {
  id: string;
};

const getValueByLanguage = (item: LanguageItem[], language: Language) =>
  item?.find((item) => item.language === language)!.text;

const TO_MILLISECONDS = 1000;

export const useHandleNotification = () => {
  const { setToast } = useToast();
  const { language } = useIntlContext();
  const { playerHeight, hasStream } = useSettingsContext();

  return ({ id, payload }: Notification) => {
    const {
      link,
      image,
      buttonLabel,
      title,
      description,
      isExternalLink,
      optionalText,
      expiresInSeconds,
      notExpires,
      isOffer,
    } = payload;

    const notificationPayload = {
      title: getValueByLanguage(title, language),
      description: getValueByLanguage(description, language),
      optionalText: getValueByLanguage(optionalText, language),
      buttonLabel: getValueByLanguage(buttonLabel, language),
      isExternalLink,
      link,
    };

    if (isOffer && image) {
      const offer: OfferItemProps = {
        id,
        image,
        ...notificationPayload,
      };

      appendOffers({ url: OFFERS.ROOT, offers: [offer] });
    }

    setToast({
      type: image ? 'conversion' : 'default',
      image,
      duration: expiresInSeconds * TO_MILLISECONDS,
      pin: notExpires,
      playerHeight: hasStream ? playerHeight : 0,
      ...notificationPayload,
    });
  };
};

export const useHandleNotificationStream = () => {
  const api = useAPI();
  const notification = useNotificationContext();

  return async ({ id }: NotificationStream) => {
    const { data } = await api.get<{ stream: Stream }>(STREAMS.BY_ID(id));
    notification.push({
      id,
      type: NotificationTypes.NEW_LIVE_STREAM,
      name: data.stream.title,
      value: id,
    });
  };
};

export const useNotification = () => {
  const {
    notificationSocket: socket,
    surveySocket: socketStream,
  } = useSocketContext();

  const onConnect = (name = 'Notification') => () =>
    console.info(
      `%c${name} socket connected`,
      `color: #06c16a; font-weight: bold;`,
    );

  const onNotification = useHandleNotification();
  const onNotificationStream = useHandleNotificationStream();

  useSocketListener(socket, NotificationEvents.CONNECTED, onConnect());
  useSocketListener(
    socket,
    NotificationEvents.NEW_NOTIFICATION,
    onNotification,
  );

  useSocketListener(socketStream, StreamEvents.CONNECTED, () => {
    onConnect('Stream Notification')();
  });

  useSocketListener(
    socketStream,
    StreamEvents.NEW_LIVE_STREAM,
    onNotificationStream,
  );
};
