import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { DefaultScroll } from 'components/structure';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.sm};
    overflow-y: overlay;
    height: auto;
    max-height: 100%;

    ${media.greaterThan('medium')`
      max-height: calc(100% - 80px);
    `}

    ${media.lessThan('medium')`
      padding-bottom: ${theme.spacing.xl};
    `}

    ${DefaultScroll}
  `}
`;

export const EmptyContent = styled.div`
  height: 60%;
`;

export const List = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${theme.spacing.xs};
    padding-bottom: ${theme.spacing.lg};

    ${media.lessThan('medium')`
      grid-template-columns: 1fr;
    `}
  `}
`;

export const SpinerWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 80px;
    margin-top: ${theme.spacing.md};
  `}
`;
