import { useEffect, useState } from 'react';
import { dispatchGAEvent } from 'services/google';
import { useTerms } from 'useCases/service-terms';
import { Term } from 'useCases/service-terms';
import { useClickOutside, useTranslate } from 'hooks';
import { useTheme } from 'hooks';
import { Terms } from 'components/contexts/accreditation/';
import { Avatar } from 'components/contexts/users';
import { Icon, Popover } from 'components/structure';
import { AnalyticsEvent } from 'interfaces/analytics';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { GA_EVENTS } from 'constants/enums';
import { FloatingLink } from './FloatingLink/FloatingLink';
import * as S from './UserMenu.styles';

export type UserMenuProps = {
  avatar?: string;
  avatarLabel?: string;
  isOpen: boolean;
  handleToggle: (open: boolean) => void;
  items: UserMenuItem[];
  handleLogout: () => void;
};

export type UserMenuItem = {
  label: string;
  url: string;
  internal?: boolean;
  text?: string;
  name?: string;
  action?: string;
  handleOpenTerm?: () => void;
  ga: AnalyticsEvent;
  disabled?: boolean;
  onClick?: () => void;
};

export type TermsOptions = {
  [key: string]: UserMenuItem;
};

export const UserMenu = ({
  avatar,
  isOpen,
  handleToggle,
  avatarLabel,
  items,
  handleLogout,
}: UserMenuProps) => {
  const translate = useTranslate();
  const { primary } = useTheme();
  const clickOutSideRef = useClickOutside(() => handleToggle(false));
  const { data } = useTerms();
  const [showTerm, setShowTerm] = useState(false);
  const [currentTerm, setCurrentTerm] = useState({});
  const [termsOptions, setTermsOptions] = useState<UserMenuItem[]>([]);
  const afterTermsOptions = [
    {
      label: 'navbar.userMenu.faq',
      url: '/help-center',
      internal: true,
      ga: {
        action: EventAction.click,
        label: GA_EVENTS.navBar.labels.faq,
        category: EventCategory.Navigation,
      },
    },
  ];

  useEffect(() => {
    const termsOptions: TermsOptions = {
      privacyTerm: {
        label: 'navbar.userMenu.privacyPolicy',
        url: '',
        ga: {
          action: EventAction.click,
          label: GA_EVENTS.navBar.labels.privacyPolicy,
          category: EventCategory.Navigation,
        },
      },
      useTerm: {
        label: 'navbar.userMenu.term',
        url: '',
        action: 'term',
        ga: {
          action: EventAction.click,
          label: GA_EVENTS.navBar.labels.termOfUse,
          category: EventCategory.Navigation,
        },
      },
    };

    if (data?.terms) {
      const termsWithOptions = Object.entries(data?.terms).map(
        ([key, value]) => ({
          ...termsOptions[key],
          ...value,
        }),
      );

      setTermsOptions(termsWithOptions);
    }
  }, [data]);

  return (
    <Popover.Root
      aria-label="floating"
      isOpen={isOpen}
      onClick={() => handleToggle(!isOpen)}
      ref={clickOutSideRef}
      arrowDirection="left"
      distance="40px"
    >
      <Popover.Trigger>
        <S.MenuActions isOpen={isOpen}>
          <S.UserAvatar>
            <Avatar src={avatar} alt={avatarLabel} size="full" />
          </S.UserAvatar>
          <Icon icon="IcChevron" color={primary} />
        </S.MenuActions>
      </Popover.Trigger>

      <Popover.ContentConfiguration>
        <S.FloatingOptions isOpen={isOpen}>
          {items.map(({ label, url, internal, ga, disabled, onClick }) => (
            <FloatingLink
              key={label}
              isInternal={internal}
              label={translate(label)}
              disabled={disabled}
              url={url}
              onClick={() => {
                dispatchGAEvent(ga);
                handleToggle(false);
                onClick && onClick();
              }}
            />
          ))}
          {termsOptions.map(({ label, ga }, index) => (
            <S.MenuButtonLogout
              type="button"
              key={label}
              onClick={() => {
                dispatchGAEvent(ga);
                setShowTerm(!showTerm);
                handleToggle(false);
                setCurrentTerm(termsOptions[index]);
              }}
            >
              {translate(label)}
            </S.MenuButtonLogout>
          ))}
          {/*TODO order and elements from backend */}
          {afterTermsOptions.map(({ label, url, internal, ga }) => (
            <FloatingLink
              key={label}
              isInternal={internal}
              label={translate(label)}
              url={url}
              onClick={() => {
                dispatchGAEvent(ga);
                handleToggle(false);
              }}
            />
          ))}
          <S.MenuButtonLogout type="button" onClick={handleLogout}>
            {translate('navbar.userMenu.logout')}
          </S.MenuButtonLogout>
        </S.FloatingOptions>
      </Popover.ContentConfiguration>
      <Terms
        term={currentTerm as Term}
        onConfirm={() => setShowTerm(false)}
        show={showTerm}
      />
    </Popover.Root>
  );
};
