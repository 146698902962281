import { useParams } from 'react-router';
import { useUpdatePassword } from 'useCases/auth';
import { ChangePasswordForm } from 'components/contexts/authentication';

export const ChangePassword = () => {
  const { handleUpdatePassword, isPending } = useUpdatePassword();
  const { token } = useParams();

  return (
    <ChangePasswordForm
      onSubmit={(values) =>		handleUpdatePassword({
				newPassword: values.newPassword,
				rePassword: values.newPassword,
				token
			})}
			handleUpdatePassword={handleUpdatePassword}
      isPending={isPending}
    />
  );
};
