/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import { useCertificate } from 'useCases/assets';
import { useEventById } from 'useCases/events';
import { useUserById } from 'useCases/users';
import {
  useEnv,
  useTranslate,
  useThemeOptions,
  useSession,
  useFetch,
} from 'hooks';
import { LanguageStream, useIntlContext } from 'contexts';
import { CountdownPage } from 'components/contexts/accreditation';
// import { Footer } from 'components/structure';
import { floatingMenuItemsMock } from 'components/structure/Navbar/Navbar.mock';
import * as CountdownTheme from 'styles/overrides/Countdown.theme';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { User } from 'interfaces/user';
import { USERS } from 'constants/endpoints';
import { Certificate, GA_EVENTS, ProjectNames } from 'constants/enums';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL!;

export const Countdown = () => {
  const navigate = useNavigate();
  const { id: userId } = useSession();
  const userById: any = useUserById(userId!);

  const isLoadingUser = useMemo(() => userById.isLoading, [userById.isLoading]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const errorMessage = useMemo(
    () => userById?.error?.message?.includes('401'),
    [userById?.error?.message],
  );
  const [eventSocket, setEventSocket] = useState<SocketIOClient.Socket>();
  const [error, setError] = useState(false);
  const [cookies, setCookies, removeCookie] = useCookies(['Authorization']);
  const result = useFetch<{ user: User; accessToken: string }>(
    USERS.BY_ID(localStorage.getItem('id')!),
  );

  useEffect(() => {
    if (result.data?.accessToken)
      setEventSocket(
        io.connect(SOCKET_URL, {
          path: '/event',
          auth: {
            accessToken: result.data?.accessToken,
          },
          transports: ['websocket'],
        }),
      );
  }, [result.data?.accessToken]);
  useEffect(() => {
    if (!isLoadingUser) {
      if (errorMessage) {
        localStorage.clear();
        sessionStorage.clear();
        document.cookie = '';
        setError(true);
      }
    }
  }, [errorMessage, isLoadingUser]);
  useEffect(() => {
    eventSocket?.on('change', (params: any) => {
      if (params.status === 'show-streams') {
        window.location.href = '/home';
      }
    });
  }, [eventSocket]);
  const { eventId } = useEnv();
  const { data, isLoading } = useEventById(eventId);
  const { languageStream, setLanguageStream } = useIntlContext();
  const eventById = useEventById(eventId);
  const handleDownloadCertificate = useCertificate();
  const eventCertificate = eventById?.data?.event.certificate;
  const eventName = eventById?.data?.event.name;
  const hasCertificate = eventCertificate !== Certificate.NOTEXISTS;
  const hasMemberGetMember = data?.event.hasMemberGetMember;
  const certificateOption = useMemo(
    () => ({
      label: 'navbar.userMenu.generateCertificate',
      onClick: () => handleDownloadCertificate(eventName),
      url: '',
      internal: false,
      disabled: eventCertificate === Certificate.UNAVAILABLE,
      ga: {
        action: EventAction.click,
        label: GA_EVENTS.navBar.labels.certificate,
        category: EventCategory.Navigation,
      },
    }),
    [eventCertificate, eventName, handleDownloadCertificate],
  );
  const filteredMenuItems = useMemo(() => {
    const mergedOptions = hasCertificate
      ? [certificateOption, ...floatingMenuItemsMock]
      : floatingMenuItemsMock;

    return mergedOptions.filter(({ label }) =>
      hasMemberGetMember ? label : label !== 'navbar.userMenu.rewards',
    );
  }, [certificateOption, hasCertificate, hasMemberGetMember]);

  const { themeName } = useThemeOptions();
  const translate = useTranslate();

  if (isLoading) {
    return null;
  }
  const themeNameParam =
    ProjectNames.BAYER === themeName || ProjectNames.VALE === themeName
      ? `.${themeName}`
      : '';

  // const userEmailDomain = userById.data?.user?.email
  //   ?.split('@')[1]
  //   .split('.')[0];
  // const allowedDomains = ['fb', 'facebook', 'meta'];

  // const isUnauthorized =
  //   Number(country) !== BRAZIL_COUNTRY_CODE &&
  //   !allowedDomains?.includes(userEmailDomain!) &&
  //   !userById.isLoading;

  if (error) {
    return <Navigate to="/accreditation" />;
  }

  return (
    <>
      {/* {
        <Footer
          blockedScreen={true}
          avatar={userById.data?.user.avatar}
          userName={userById.data?.user.firstName}
          show={true}
          onChangeLanguage={(lang: LanguageStream) => setLanguageStream(lang)}
          isTranslationChange={true}
          language={languageStream}
          hasInteractions={false}
          hasNotification={false}
          hasMemberGetMember={hasMemberGetMember}
          menuItems={filteredMenuItems}
        />
      } */}

      <CountdownPage
        socialLinks={data?.event?.socialNetworks}
        eventDate={data?.event.dates[0] ?? ''}
        hasFooterTitle={CountdownTheme.options?.hasFooterTitle}
        hasFooterDescription={CountdownTheme.options?.hasFooterDescription}
        footerTitle={translate(
          `accreditation.countdown${themeNameParam}.footerTitle`,
        )}
        hasMemberGetMember={hasMemberGetMember}
      />
    </>
  );
};
