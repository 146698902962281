import styled, { css, DefaultTheme } from 'styled-components';

type WrapperProps = {
  hasError?: boolean;
};

const wrapperModifiers = {
  hasError: (theme: DefaultTheme) => css`
    .custom-select {
      &__control {
        border-color: ${'red'};
      }
    }

    &,
    &:active,
    &:focus {
      border-color: ${theme.primary};
    }

    & + ${Label} {
      color: ${'red'};
    }
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, hasError = false }) => css`
    position: relative;
    color: ${theme.input.label};

    .custom-select {
      border: 1px solid red;

      &:focus {
        border-color: red;
        box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
      }

      & option {
        color: ${theme.primary};
      }
      &__control {
        background-color: ${theme.input.background};
        border: 1px solid ${theme.secondary};
        min-height: 48px;
        transition: all 0.2s linear;
        outline: none;

        &--menu-is-open {
          border-color: ${theme.primary};

          svg {
            color: ${theme.primary};
          }
        }

        &--is-focused {
          box-shadow: none;
        }
      }

      &__value-container {
        padding: 0 20px;
      }

      &__single-value {
        /* color: ${theme.primary}; */
        margin: 0;
        margin-top: 4px;
      }

      &__indicator-separator {
        display: none;
      }

      &__menu-list {
        background-color: ${theme.input.background};
        padding: 0;
        border: 1px solid ${theme.primary};
      }

      &__option {
        color: ${theme.primary};

        display: flex;
        align-items: center;
        font-size: ${theme.typography.sizes.base};
        line-height: 20px;

        &--is-focused {
          background-color: ${theme.background};

          color: ${theme.primary};
        }
        &--is-selected {
          background-color: ${theme.background};
          color: ${theme.primary};
        }
      }
      &__input {
        color: ${theme.primary};
      }
    }

    ${hasError && wrapperModifiers.hasError(theme)};
  `}
`;

const labelModifiers = {
  hide: () => css`
    top: 58%;
    opacity: 0;
    visibility: hidden;
  `,
};

export const Label = styled.label<{ hide?: boolean; size?: number }>`
  ${({ theme, hide = true, size }) => css`
    color: ${theme.secondary ?? theme.primary};
    font-size: 10px;
    font-family: inherit;
    transition: all 0.2s linear;
    position: absolute;
    left: 21px;
    top: ${size ? `${size}px` : '10px'};
    transform: translateY(-50%);
    max-width: 90%;
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical; */
    ${hide && labelModifiers.hide()};
  `}
`;
