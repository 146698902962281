import { useMaterials } from 'useCases/materials';
import { useTranslate } from 'hooks';
import { SectionMaterial, MaterialsTypes } from 'components/contexts/Materials';
import { Heading } from 'components/structure';
import { Skeleton } from './materials.skeleton';
import * as S from './materials.styles';

export const Materials = () => {
  const translate = useTranslate();
  const { data, isLoading } = useMaterials();

  if (isLoading) {
    return (
      <S.Wrapper>
        <Heading>{translate('materials.title')}</Heading>
        <Skeleton />
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <Heading>{translate('materials.title')}</Heading>

      {data?.materials.map(({ category, options }, index) => (
        <SectionMaterial
          key={index}
          category={category}
          isVideo={options.some((item) => item.type === MaterialsTypes.Video)}
          options={options}
        />
      ))}
    </S.Wrapper>
  );
};
