import styled, { css } from 'styled-components/macro';
import media from 'styled-media-query';
import { Container } from 'components/structure';
import * as TypographyStyles from 'components/structure/Typography/Typography';
import { Divider } from './divider/Divider';

export const Wrapper = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.lg};

    ${media.lessThan('large')`
      display:none;
    `}
  `}
`;

export const Heading = styled(TypographyStyles.Heading)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.md};
  `}
`;

export const OutletWrapper = styled.div`
  ${({ theme }) => css`
    background-color: transparent;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    padding-top: ${theme.spacing.lg};
  `};
`;

export const TabContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 50%;

    ${Divider} {
      background-color: ${theme.secondary};
      width: 100%;
    }

    ${media.lessThan('large')`
      width: 100%;
    `}
  `}
`;

export const BussinessCardContainer = styled.div<{ show: boolean }>`
  ${({ theme, show }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    margin-top: 110px;

    ${media.lessThan('large')`
      display: ${show ? 'flex' : 'none'};
      margin-top: ${theme.spacing.xl};
    `}
  `}
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;

  ${media.lessThan('large')`
    flex-direction: column-reverse;
  `}
`;
