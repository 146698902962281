import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { requiredParam } = messages;

export const schema = Yup.object().shape({
  email: Yup.string().required(requiredParam('password')),
});

export const defaultValues = {
  email: '',
};

export type ForgotPassword = Yup.InferType<typeof schema>;
