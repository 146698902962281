import * as Yup from 'yup';
import messages from 'lib/core/errors';
import { getEventThemeName } from 'lib/core/event';
import { LINKEDIN_DEFAULT_URL, ProjectNames } from 'constants/enums';

export const passwordStrength = {
  hasMinLength: /.{8,}/,
  hasNumber: /(?=.*\d)/,
  hasSymbol: /(?=.*[`~!@#$%^&*=(+){}|'";\\:[><.\],?/-])/,
  hasCapitalLetter: /(?=.*[A-Z])/,
  hasLowercaseLetter: /^(?=.*[a-z])/,
};

export const passwordStrengthForVale = {
  hasOnlySixNumbers: /^\d{6}$/,
};

export const validatePassword = (password: string | null | undefined) =>
  getEventThemeName() === ProjectNames.VALE
    ? Yup.string()
        .matches(
          passwordStrengthForVale.hasOnlySixNumbers,
          messages.invalidParam('passwordHasNumber'),
        )
        .validate(password, { abortEarly: false })
    : Yup.string()
        .matches(
          passwordStrength.hasMinLength,
          messages.invalidParam('password'),
        )
        .matches(
          passwordStrength.hasNumber,
          messages.invalidParam('passwordHasNumber'),
        )
        .matches(
          passwordStrength.hasSymbol,
          messages.invalidParam('passwordHasSymbol'),
        )
        .matches(
          passwordStrength.hasCapitalLetter,
          messages.invalidParam('passwordHasCapitalLetter'),
        )
        .matches(
          passwordStrength.hasLowercaseLetter,
          messages.invalidParam('passwordHasLowercaseLetter'),
        )
        .validate(password, { abortEarly: false });

export const setReadOnlyDefaultValue = (input: HTMLInputElement) => {
  const defaultValueLength = LINKEDIN_DEFAULT_URL.length;
  const BACKSPACE_KEY = 'Backspace';
  const DELETE_KEY = 'Delete';
  const ARROW_LEFT_KEY = 'ArrowLeft';
  const ARROW_RIGHT_KEY = 'ArrowRight';

  const handleKeyEvent = (event: KeyboardEvent) => {
    if (
      event.key === BACKSPACE_KEY &&
      input.selectionStart! <= defaultValueLength
    ) {
      event.preventDefault();
    }

    if (
      event.key === DELETE_KEY &&
      input.selectionStart! < defaultValueLength
    ) {
      event.preventDefault();
    }

    if (
      event.key !== ARROW_LEFT_KEY &&
      event.key !== ARROW_RIGHT_KEY &&
      input.selectionStart! < defaultValueLength
    ) {
      event.preventDefault();
    }
  };

  input.addEventListener('keypress', handleKeyEvent);
  input.addEventListener('keydown', handleKeyEvent);
  input.addEventListener('paste', (event) => {
    if (input.selectionStart! < defaultValueLength) {
      event.preventDefault();
    }
  });
};
