import { dispatchGAEvent } from 'services/google';
import { Icon } from 'components/structure';
import { EventAction, EventCategory } from 'interfaces/analytics';
import * as S from './CodeCard.styles';

export type CodeCardProps = {
  title?: string;
  hasIcon?: boolean;
  description?: string;
  image?: string;
  largeDescription?: boolean;
  url: string;
  gaLabel?: string;
};

export const CodeCard = ({
  title,
  hasIcon,
  description,
  largeDescription,
  image,
  url,
  gaLabel,
}: CodeCardProps) => {
  const handleClick = () => {
    gaLabel &&
      dispatchGAEvent({
        category: EventCategory.Coffee,
        action: EventAction.click,
        label: gaLabel,
      });
  };

  return (
    <a
      download
      href={url}
      onClick={handleClick}
      target="_blank"
      rel="noreferrer"
    >
      <S.Container largeDescription={largeDescription}>
        <S.AsideContainer>
          <S.Header>
            {hasIcon && <Icon icon="IcInstragram" />}
            <S.Heading>{title}</S.Heading>
          </S.Header>

          <S.Description>{description}</S.Description>
        </S.AsideContainer>

        <S.CodeContainer>
          <img src={image} alt="qr-code" />
          <div />
        </S.CodeContainer>
      </S.Container>
    </a>
  );
};
