import { useEffect } from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router';
import { useTranslate } from 'hooks';
import { Heading, SubTitle } from 'components/structure';
import { ROUTES } from 'constants/urls';
import animationData from 'assets/lotties/endPlenary.json';
import * as S from './RedirectMessage.styles';

const options = {
  loop: true,
  autoplay: true,
  animationData,
};

export const RedirectMessage = () => {
  const navigate = useNavigate();
  const translate = useTranslate();

  useEffect(() => {
    const timeOut = setTimeout(
      () => navigate(ROUTES.app.getLink('home')),
      10000,
    );
    return () => clearTimeout(timeOut);
  }, [navigate]);

  return (
    <S.Wrapper>
      <Lottie options={options} width={100} height={100} isClickToPauseDisabled/>
      <SubTitle brand>{translate('stream.redirectSubTitle')}</SubTitle>
      <Heading light center>{translate('stream.redirectTitle')}</Heading>
    </S.Wrapper>
  );
};
