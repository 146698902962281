/* eslint-disable react/jsx-filename-extension */
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { dispatchGAEvent } from 'services/google';
import { useSignOut } from 'useCases/auth';
import { useEvent } from 'useCases/events';
import { Term, useTerms } from 'useCases/service-terms';
import { usePortal, useTranslate, useZendesk } from 'hooks';
import {
  LanguageStream,
  useInteractionsContext,
  useStreamContext,
  ThemeCustomProvider,
} from 'contexts';
import { Terms } from 'components/contexts/accreditation';
import { UserMenuItem, TermsOptions } from 'components/contexts/users/UserMenu';
import {
  CFCode,
  Icon,
  Logo,
  MediaMatch,
  Notification,
} from 'components/structure';
import { ChangeLanguageSelect } from 'components/structure';
import EventOptions from 'styles/overrides/EventOptions.theme';
import * as NavbarTheme from 'styles/overrides/Navbar.theme';
import {
  AnalyticsEvent,
  EventAction,
  EventCategory,
} from 'interfaces/analytics';
//import { EventStatus } from 'interfaces/event';
import { GA_EVENTS, InteractionsOpenState } from 'constants/enums';
import { floatingMenuItemsMock } from './Navbar.mock';
import * as S from './Navbar.styles';

export type NavbarProps = {
  options?: {
    label: string;
    url: string;
    icon: JSX.Element;
    isExternal?: false;
    ga: AnalyticsEvent;
  }[];
  logoUrl?: string;
  logoLabel?: string;
  hasInteractions?: boolean;
  setIsBlockScroll?: (isOpen: boolean) => void;
  language: LanguageStream;
  onChangeLanguage?: (language: LanguageStream) => void;
  isTranslationChange: boolean;
  hasNotification: boolean;
  hasMemberGetMember?: boolean;
  menuItems?: UserMenuItem[];
};

const linkExternalProps = { target: '_blank', rel: 'noopener noreferrer' };

export const Navbar = ({
  options = [],
  logoUrl,
  logoLabel,
  setIsBlockScroll,
  language,
  onChangeLanguage,
  isTranslationChange,
  hasInteractions = true,
  hasNotification,
  // hasMemberGetMember,
  menuItems,
}: NavbarProps) => {
  const translate = useTranslate();
  const { handleLogout } = useSignOut();
  const { data } = useTerms();
  const { data: dataCountdown } = useEvent();
  const eventStatus = dataCountdown?.event.status;

  const Portal = usePortal();
  const [isMenuMobileOpen, setMenuMobileOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const subMenuOptions = menuItems?.map(({ label }) => label);
  // const filteredMenuItems = floatingMenuItemsMock.filter(({ label }) =>
  //   hasMemberGetMember ? label : label !== 'navbar.userMenu.rewards',
  // );
  // const subMenuOptions = filteredMenuItems.map(({ label }) => label);
  const [optionsInFocus, setOptionsInFocus] = useState('');
  const [currentTerm, setCurrentTerm] = useState({});
  const [termsOptions, setTermsOptions] = useState<UserMenuItem[]>([]);
  const [showTerm, setShowTerm] = useState(false);
  const { setOpenState } = useInteractionsContext();
  const [duplavalidacao, setDuplaValidacao] = useState(false);
  const Event = useEvent();
  const handleSupport = useZendesk();
  const { streamLanguages } = useStreamContext();
  const { pathname } = useLocation();
  const currentPath = pathname.split('/')[1];

  const handleOnClick = (option: string) => {
    setMenuMobileOpen(false);
    setOptionsInFocus(option);
    setOpenState(InteractionsOpenState.closed);
  };

  const handleChangeLanguage = (lang: LanguageStream) => {
    dispatchGAEvent({
      category: EventCategory.Stream,
      action: EventAction.click,
      label: GA_EVENTS.stream.labels.selectedAudio(lang),
    });
    onChangeLanguage && onChangeLanguage(lang);
  };

  useEffect(() => {
    setIsBlockScroll && setIsBlockScroll(isMenuMobileOpen);
    !isMenuMobileOpen &&
      !subMenuOptions?.includes(optionsInFocus) &&
      optionsInFocus !== 'navbar.userMenu.faq' &&
      setHasMore(false);
  }, [isMenuMobileOpen, optionsInFocus, setIsBlockScroll, subMenuOptions]);

  const afterTermsOptions = [
    {
      label: 'navbar.userMenu.faq',
      url: '/help-center',
      internal: true,
    },
  ];
  useEffect(() => {
    const termsOptions: TermsOptions = {
      privacyTerm: {
        label: 'navbar.userMenu.privacyPolicy',
        url: '',
        ga: {
          action: EventAction.click,
          label: GA_EVENTS.navBar.labels.privacyPolicy,
          category: EventCategory.Navigation,
        },
      },
      useTerm: {
        label: 'navbar.userMenu.term',
        url: '',
        action: 'term',
        ga: {
          action: EventAction.click,
          label: GA_EVENTS.navBar.labels.termOfUse,
          category: EventCategory.Navigation,
        },
      },
    };

    if (data?.terms) {
      const termsWithOptions = Object.entries(data?.terms).map(
        ([key, value]) => ({
          ...termsOptions[key],
          ...value,
        }),
      );

      setTermsOptions(termsWithOptions);
    }
  }, [data]);

  // useEffect(() => {}, [Event.data?.event?.zendesk?.open, handleSupport]);
  // setTimeout(() => {

  //   if (Event.data?.event?.zendesk?.open !== duplavalidacao) {
  //     handleSupport();
  //     setDuplaValidacao(Event.data?.event?.zendesk?.open);
  //   }
  // }, 1000);

  return (
    <ThemeCustomProvider {...NavbarTheme}>
      <S.Wrapper hasInteractions={hasInteractions}>
        <S.LogoWrapper>
          <Logo url={logoUrl} alt={logoLabel} aria-label={logoLabel} light />
        </S.LogoWrapper>

        <MediaMatch lessThan="large">
          <S.NavbarActions>
            {Event.data?.event.zendesk?.active && (
              <S.IconButton
                fullWidth
                // isRound
                onClick={handleSupport}
                color="primary"
              >
                <Icon icon="IcSupport" />
                {/* {translation('footer.support')} */}
                {/* <p style={{ fontSize: '10px' }}>Suporte Técnico</p> */}
              </S.IconButton>
            )}
            <S.WrapperTranslation>
              {currentPath === 'stream' && streamLanguages.length > 1 && (
                <ChangeLanguageSelect
                  bottom
                  activatedOnlyFocus
                  hasLabel={false}
                  language={language}
                  handleChangeLanguage={handleChangeLanguage}
                />
              )}
            </S.WrapperTranslation>

            {!isMenuMobileOpen &&
              EventOptions.hasNotification &&
              hasNotification && <Notification />}
            <S.IconWrapper>
              <S.MenuIconContainer
                type="checkbox"
                id="menu-mobile-icon"
                checked={isMenuMobileOpen}
                onChange={() => setMenuMobileOpen(!isMenuMobileOpen)}
                aria-label={
                  isMenuMobileOpen
                    ? translate('navbar.close')
                    : translate('navbar.open')
                }
              />
              <S.MenuIconLabel htmlFor="menu-mobile-icon">
                <S.MenuIcon />
              </S.MenuIconLabel>
            </S.IconWrapper>
          </S.NavbarActions>
        </MediaMatch>

        <MediaMatch greaterThan="large">
          <S.MenuGroup>
            <S.MenuNav>
              {options.map(({ label, url, icon, ga }) => {
                {
                  if (eventStatus === 'show-countdown') {
                    return '';
                  } else {
                    return (
                      <S.MenuLink
                        onClick={() => dispatchGAEvent(ga)}
                        activeClassName="active"
                        to={url}
                        key={label}
                      >
                        {icon}
                        {translate(label)}
                      </S.MenuLink>
                    );
                  }
                }
              })}
            </S.MenuNav>
          </S.MenuGroup>
        </MediaMatch>

        <Portal>
          <S.MenuFull aria-hidden={!isMenuMobileOpen} isOpen={isMenuMobileOpen}>
            <S.MenuNav disableFocus={hasMore}>
              {options.map(({ label, url, icon, ga }) => {
                {
                  if (eventStatus === 'show-countdown') {
                    return '';
                  } else {
                    return (
                      <S.MenuLink
                        onClick={() => {
                          dispatchGAEvent(ga);
                          handleOnClick(label);
                        }}
                        activeClassName="active"
                        to={url}
                        key={label}
                      >
                        {icon}
                        {translate(label)}
                      </S.MenuLink>
                    );
                  }
                }
              })}
              <S.MenuItemMore show={hasMore}>
                <span onClick={() => setHasMore(!hasMore)}>
                  {translate('navbar.userMenu.more')}
                </span>
                {floatingMenuItemsMock?.map(({ label, url, internal, ga }) => (
                  <S.MenuLink
                    key={label}
                    onClick={() => {
                      dispatchGAEvent(ga);
                      handleOnClick(label);
                      // onClick && onClick();
                    }}
                    as={url ? NavLink : 'button'}
                    // disabled={disabled}
                    activeClassName="active"
                    to={url}
                    {...(internal ? {} : linkExternalProps)}
                  >
                    {translate(label)}
                  </S.MenuLink>
                ))}
                {termsOptions.map(({ label, ga }, index) => (
                  <S.MenuLink
                    key={label}
                    to="/home"
                    activeClassName="active-disable"
                    onClick={() => {
                      setCurrentTerm(termsOptions[index]);
                      setShowTerm(!showTerm);
                      dispatchGAEvent(ga);
                    }}
                  >
                    {translate(label)}
                  </S.MenuLink>
                ))}
                {afterTermsOptions.map(({ label, url, internal }) => (
                  <S.MenuLink
                    key={label}
                    onClick={() => handleOnClick(label)}
                    activeClassName="active"
                    to={url}
                    {...(internal ? {} : linkExternalProps)}
                  >
                    {translate(label)}
                  </S.MenuLink>
                ))}
                <S.MenuButtonLogout type="button" onClick={handleLogout}>
                  {translate('navbar.userMenu.logout')}
                </S.MenuButtonLogout>
              </S.MenuItemMore>
            </S.MenuNav>
            <CFCode isCenter marginBottom />
          </S.MenuFull>
        </Portal>
        <Terms
          term={currentTerm as Term}
          onConfirm={() => setShowTerm(false)}
          show={showTerm}
        />
      </S.Wrapper>
    </ThemeCustomProvider>
  );
};
