import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as TypographyStyles from 'components/structure/Typography/Typography';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: 100%;

    ${TypographyStyles.Heading} {
      margin-bottom: ${theme.spacing.lg};
    }

    ${TypographyStyles.Paragraph} {
      margin-bottom: 0;
      font-size: ${theme.typography.sizes.sm};

      ${media.greaterThan('medium')`
        ${TypographyStyles.Anchor} {
          margin-left: ${theme.spacing.xs};
        }
      `}
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${TypographyStyles.Anchor} {
      margin-top: ${theme.spacing['2xs']};
    }
  `}
`;
