import styled, { css, DefaultTheme } from 'styled-components';
import { SectionMaterialProps } from 'components/contexts/Materials';

type ThumbProps = {
  src: string;
} & Pick<SectionMaterialProps, 'isVideo'>;

export const Container = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.shapes.borderRadius.md};
    /* background: ${theme.secondary}; */
    background: ${theme.tertiary};
    border: 1px solid ${theme.text.secondary};
    box-shadow: 0px 8px 20px transparent;
    cursor: pointer;
    padding: ${theme.spacing.xs};
    transition: all 0.2s linear;
    min-width: 283px;
    width: 283px;
    border: 1px solid ${theme.secondary};
    position: relative;
    height: 287px;

    @media (max-width: 1309px) {
      margin: 0 20px ${theme.spacing.md} 0;
    }
    @media (max-width: 1287px) {
      margin: 0 5px ${theme.spacing.md} 0;
    }

    &:hover {
      border: 1px solid ${theme.primary};
      box-shadow: 0px 8px 20px rgba(39, 161, 245, 0.1);
    }
  `}
`;

const thumbImageModifiers = {
  isVideo: (theme: DefaultTheme) => css`
    svg {
      display: block;
      width: 26px;
      height: 26px;
    }

    &::before {
      background-color: ${theme.primary};
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  `,
};

export const ThumbImage = styled.div<ThumbProps>`
  ${({ theme, src, isVideo }) => css`
    background-image: url(${src});
    background-position: center center;
    background-size: cover;
    border-radius: ${theme.shapes.borderRadius.md};
    height: 153px;
    overflow: hidden;
    width: 100%;
    position: relative;

    svg {
      display: none;
      color: ${theme.text.secondary};
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    ${isVideo && thumbImageModifiers.isVideo(theme)}
  `}
`;

export const MaterialName = styled.p`
  ${({ theme }) => css`
    color: ${theme.text.secondary};
    line-height: ${theme.spacing.md};
    margin-top: ${theme.spacing.xs};
    display: -webkit-box;
    text-align: flex-end;
    white-space: pre-line;
    /* line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
    /* overflow: hidden;
    text-overflow: ellipsis; */
    align-self: flex-start;
    * {
      white-space: pre-line;
    }
  `}
`;

export const WrapperItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 60px;
`;

export const WatchButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: black;
  border: 2px solid black;
  width: 80%;
  height: 40px;
  border-radius: ${({ theme }) => theme.shapes.borderRadius.full};
  margin-top: 10px;
  position: absolute;
  bottom: 10px;
  left: 30px;
`;
