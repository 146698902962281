import { isMobile } from 'react-device-detect';
import { Term } from 'useCases/service-terms';
import { usePortal } from 'hooks';
import { Heading, Icon } from 'components/structure';
import * as S from './Terms.styles';

export type TermsProps = {
  show: boolean;
  onConfirm: () => void;
  term?: Term;
};

const Terms = ({ show = false, term = {} as Term, onConfirm }: TermsProps) => {
  const Portal = usePortal();

  if (isMobile && show) {
    window.open(term.url, '_blank');
    onConfirm();
  }

  return show ? (
    <Portal>
      <S.Wrapper show={show}>
        <S.BoxTerms>
          <S.Header>
            <S.IconWrapper show={show} onClick={onConfirm}>
              <Icon color="black" icon="IcClose" />
            </S.IconWrapper>
          </S.Header>
          <S.Content>
            <Heading dark center>
              {term.name}
            </Heading>
            <S.Document data={term.url} type="text/html" />
          </S.Content>
        </S.BoxTerms>
      </S.Wrapper>
    </Portal>
  ) : (
    <></>
  );
};

export default Terms;
