/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSocketListener, useTranslate } from 'hooks';
import { useModalContext, useSocketContext } from 'contexts';
import { Icon, Logo } from 'components/structure';
import { ROUTES } from 'constants/urls';
import { useSignOut } from './auth';

export const useRealTimeSession: any = () => {
  // const socket = useRef<SocketIOClient.Socket>();
  const { setModalProps, setShowModal } = useModalContext();
  const translate = useTranslate();
  const { sessionSocket: socket } = useSocketContext();

  // const accessToken = useFetch(USERS.BY_ID(localStorage.getItem('id')!))
  const { handleLogout } = useSignOut();
  const onConnect = () => {
    console.info(
      `%cSession socket connected`,
      `color: #06c16a; font-weight: bold;`,
    );

    socket.emit('new_session_instance');
  };

  const navigate = useNavigate();
  useSocketListener(socket, 'connected', onConnect);
  useSocketListener(socket, 'session_expired', () => {
    setModalProps({
      title: translate('auth.connectedFromAnotherDevice.title'),
      description: translate('auth.connectedFromAnotherDevice.description'),
      buttonLabel: translate('labels.understood'),
      onClose: () => {
        localStorage.clear();
        return navigate(ROUTES.accreditation.getLink('gettingStarted'));
      },
      icon: <Logo medium light />,
      noBackground: true,
    });
    setShowModal(true);
  });
  useEffect(() => {
    //
  }, [setModalProps]);
  // return {
  //     handleSessionStatus
  // };
};
