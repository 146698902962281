import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { gridTemplatesAreasToString } from 'lib/contexts';
import * as CodeCardStyles from 'components/contexts/coffee/CodeCard/CodeCard.styles';
import * as ExplorationCardStyle from 'components/contexts/coffee/ExplorationCard/ExplorationCard.styles';
import * as MapsCardStyles from 'components/contexts/coffee/MapsCard/MapsCard.styles';
import * as WallpaperCardStyles from 'components/contexts/coffee/WallpaperCard/WallpaperCard.styles';
import { Heading } from 'components/structure';
import { CoffeeVariations } from './Coffee.layouts';

export type ContentWrapperItemsProps = {
  gridTemplateAreas?: Array<Array<string>>;
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  ${media.greaterThan('medium')`
    & > ${Heading} {
      display: none;
    }
  `}
`;

export const ContentWrapperItems = styled.div<ContentWrapperItemsProps>`
  ${({ theme, gridTemplateAreas }) => css`
    display: grid;
    grid-template-rows: repeat(7, auto) 1fr;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: ${theme.spacing.lg};

    grid-template-areas: ${gridTemplatesAreasToString(
      gridTemplateAreas || CoffeeVariations.default.gridTemplateAreas,
    )};
    ${media.lessThan('medium')`
      display: flex;
      flex-direction: column;
      grid-gap: 0px;
    `};
  `}
`;

export const Map = styled.div`
  ${({ theme }) => css`
    grid-area: map;

    ${MapsCardStyles.Wrapper} {
      height: 100%;
      width: 100%;
    }

    ${media.lessThan('medium')`
      height: 450px;
      margin-bottom: ${theme.spacing['3xl']};
    `}
  `}
`;

export const Explorador = styled.div`
  ${({ theme }) => css`
    grid-area: exploration;
    ${media.lessThan('medium')`
      margin-bottom: ${theme.spacing.lg};
    `}

    ${ExplorationCardStyle.Container} {
      width: auto;
    }
  `}
`;

export const WallpaperCard = styled.div`
  ${({ theme }) => css`
    grid-area: wallpaper-card;
    ${media.lessThan('medium')`
      margin-bottom: ${theme.spacing.lg};
    `}

    ${WallpaperCardStyles.Container} {
      width: auto;
    }
  `}
`;

export const PhotoApp = styled.div`
  ${({ theme }) => css`
    grid-area: photo-app;
    ${media.lessThan('medium')`
      margin-bottom: ${theme.spacing.lg};
    `}

    ${CodeCardStyles.Container} {
      width: auto;
    }
  `}
`;

export const InstramFeed = styled.div`
  ${({ theme }) => css`
    grid-area: instagram-feed;

    ${media.lessThan('medium')`
      margin-bottom: ${theme.spacing.lg};
    `}

    ${CodeCardStyles.Container} {
      width: auto;
    }
  `}
`;

export const Cloud = styled.div`
  grid-area: cloud-text;

  ${CodeCardStyles.Container} {
    width: auto;

    ${media.lessThan('medium')`
      height: auto;
    `}
  }
`;
