import imageCompression from 'browser-image-compression';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 300,
  useWebWorker: false,
};
export const defaultAttributes = {
  crop: { x: 0, y: 0 },
  zoom: 1,
  aspect: 4 / 3,
};

type ImageAttributes = {
  image?: string;
  crop: {
    x: number;
    y: number;
  };
  zoom: number;
  aspect: number;
};

export const useImageCompression = (
  imageToCrop: File,
): [ImageAttributes, Dispatch<SetStateAction<ImageAttributes>>] => {
  const [imageAttributes, setImageAttributes] = useState<ImageAttributes>(
    defaultAttributes,
  );

  useEffect(() => {
    async function getImageCompressed() {
      const fileCompression = await imageCompression(imageToCrop, options);
      const fileImage = new File([fileCompression], imageToCrop.name);

      if (imageToCrop && fileImage) {
        setImageAttributes((oldValues) => ({
          ...oldValues,
          image: URL.createObjectURL(fileImage),
        }));
      }

      return fileCompression;
    }

    if (imageToCrop) {
      getImageCompressed();
    }
  }, [imageToCrop]);

  return [imageAttributes, setImageAttributes];
};
