/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { mutate } from 'swr';
import {
  useAPI,
  useFetch,
  usePrefixMessage,
  useSession,
  useSocketListener,
  useTranslate,
  useToast,
} from 'hooks';
import { useIpAddress } from 'hooks';
import {
  appendUserChats,
  mutateLatestMessages,
  mutateMessageSended,
  mutateOnMessageDeleted,
  mutateOnPagination,
  mutateOnSendMessage,
  mutateUserChats,
  hasLastMessage,
} from 'lib/contexts/chat';
import { getURLWithParams } from 'lib/contexts/urls';
import { responseErrors } from 'lib/core/errors';
import { getEventId } from 'lib/core/event';
import {
  useChatContext,
  useSocketContext,
  useNotificationContext,
  useChatHandlersContext,
  useInteractionsContext,
} from 'contexts';
import {
  ChatListAPI,
  ChatParams,
  LastEvaluatedKey,
  LatestMessages,
  Message,
} from 'interfaces/chat';
import { Event } from 'interfaces/event';
import { CHAT, EVENTS } from 'constants/endpoints';
import {
  ChatEvents,
  ChatTypes,
  InteractionsTabs,
  NotificationTypes,
} from 'constants/enums';

const messageContainABadWord = 'message-contain-a-bad-word';

const USER_CHATS_SIZE = 10;
const LIMIT = 30;

type OnError = {
  msg: string;
  status: number;
};

type LatestMessagesURLs = {
  [key: string]: string;
};

export const useChat = () => {
  const api = useAPI();
  const session = useSession();
  const ip = useIpAddress();
  const { id } = useSession();
  const translate = useTranslate();
  const notification = useNotificationContext();
  const chatIdRef = useRef('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isChatConnected, setIsChatConnected] = useState(false);
  const [userChatsPage, setUserChatsPage] = useState(1);
  const [maxUserChatsPage, setMaxUserChatsPage] = useState(1);

  const [alreadyJoinStreamChat, setAlreadyJoinStreamChat] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<LastEvaluatedKey>();
  const [
    latestMessagesURLs,
    setLatestMessagesURLs,
  ] = useState<LatestMessagesURLs>({});
  const [lastUserChatsURL, setLastUserChatsURL] = useState('');
  const [lastPlenaryChatURL, setLastPlenaryChatURL] = useState('');
  const { setToast } = useToast();
  const prefixMessage = usePrefixMessage();
  const { setPlenary, setShowRoom, setRoom } = useChatContext();
  const {
    handleJoinPrivateChannel,
    handleJoinStreamChannel: handleJoinStreamChannelContext,
  } = useChatHandlersContext();
  const { chatSocket: socket } = useSocketContext();
  const { setAvailableTabs } = useInteractionsContext();

  const isChatPrivate = (chatId = '') => chatId.startsWith(ChatTypes.PRIVATE);

  const updatePlenaryChat = useCallback(
    (message: string, mentions?: { userId: string; username: string }[]) =>
      setPlenary((prevState) => ({
        ...prevState,
        message,
      })),
    [setPlenary],
  );

  const updateUsersChat = useCallback(
    (message: Message) => {
      if (!isChatPrivate(message.chatId)) {
        return updatePlenaryChat(prefixMessage(message));
      }

      mutateUserChats(lastUserChatsURL, message);
    },
    [lastUserChatsURL, prefixMessage, updatePlenaryChat],
  );

  const onMessageSended = useCallback(
    (message: Message) => {
      delete message.avatar;
      mutateMessageSended(latestMessagesURLs[message.chatId], message);

      if (isChatPrivate(message.chatId)) {
        return updateUsersChat(message);
      }

      updatePlenaryChat(message.content);
    },
    [latestMessagesURLs, updatePlenaryChat, updateUsersChat],
  );

  const handleReadNotification = useCallback(
    (userId: string) => {
      notification.setViewed(userId, NotificationTypes.MESSAGE);
    },
    [notification],
  );

  const handleReadMessageById = useCallback(
    (id: string) => socket.emit(ChatEvents.READ_MESSAGE, { id }),
    [socket],
  );

  const handleDeleteMessage = useCallback(
    (id: string) => socket.emit(ChatEvents.MESSAGE_DELETED, { id }),
    [socket],
  );


  const onNewMessage = useCallback(
    //editar
    (message: Message) => {
      if (message.mentions) {
        if (message.mentions.some((m) => m.id === id)) {
          notification.push({
            name: message.name,
            type: NotificationTypes.MENTION,
            id: message.userId,
            value: message.chatId,
            group: true,
          });
        }
      }

      mutateLatestMessages(latestMessagesURLs[message.chatId], message);
      if (
        chatIdRef.current === message.chatId &&
        isChatPrivate(message.chatId)
      ) {
        handleReadMessageById(message.id);
      }

      updateUsersChat(message);
    },
    [handleReadMessageById, latestMessagesURLs, updateUsersChat],
  );

  const onSendMessage = useCallback(
    (content: string) => {
      mutateOnSendMessage(
        latestMessagesURLs[chatIdRef.current],
        content,
        session,
      );
    },
    [latestMessagesURLs, session],
  );
  const isStream = window.location.pathname.includes('stream')
  const paths = window.location.pathname.split('/').filter(i => i.length >= 1);
  const _streamId = isStream ? paths.slice(paths.length - 1, paths.length)[0] : '';
  const handleSendMessage = useCallback(
    (message: string, mentions?: any[], customMentions?: any[]) => {
      onSendMessage(message);
      socket.emit(ChatEvents.SEND_MESSAGE, {
        content: message,
        chatId: chatIdRef.current/*  || isStream ? 'STREAM-'+ _streamId : '' */,
        origin: ip,
        mentions: mentions,
        customMentions,
      });
    },
    [onSendMessage, socket, ip],
  );

  const onJoinStreamChannel = useCallback(() => {
    setIsLoading(true);
    setShowRoom(true);
    setAlreadyJoinStreamChat(true);
  }, [setShowRoom]);

  const onChannelJoined = useCallback(
    (message: Message) => {
      const isPrivate = isChatPrivate(message?.chatId);

      if (isPrivate) {
        if (!message.startedByUser) return;

        delete message?.latestMessage?.avatar;

        mutateUserChats(lastUserChatsURL, message.latestMessage ?? message);
      }

      if (message.latestMessage?.id) {
        handleReadNotification(message.latestMessage?.userId);
        handleReadMessageById(message.latestMessage?.id);
      }

      setIsLoading(false);
      chatIdRef.current = message.chatId;
      setRoom((prevState) => ({
        ...prevState,
        id: message.chatId,
        avatar: message.avatar,
        isPrivate,
        name: isPrivate ? message.name : translate('interactions.chatPlenary'),
      }));
    },
    [
      handleReadMessageById,
      lastUserChatsURL,
      setRoom,
      translate,
      handleReadNotification,
    ],
  );

  const onError = ({ msg }: OnError) => {
    const errorWithTranslate = translate(
      responseErrors(msg),
      translate('errors.generic-error'),
    );
    setError(errorWithTranslate);
    mutate(latestMessagesURLs[chatIdRef.current]);

    if (msg !== messageContainABadWord) {
      setToast({
        hasChat: true,
        type: 'danger',
        description: errorWithTranslate,
      });
    }
    console.info(`%cChat error`, `color: red; font-weight: bold;`, msg);
  };

  const onConnect = () => {
    setIsChatConnected(true);
    console.info(
      `%cChat socket connected`,
      `color: #06c16a; font-weight: bold;`,
    );
  };

  const loadMoreMessages = async () => {
    if (!lastEvaluatedKey) return;

    setTimeout(() => {
      //
    }, 3000);
    try {
      setIsLoadingMore(true);
      const { data } = await api.get<LatestMessages>(
        CHAT.LATEST_MESSAGES(lastEvaluatedKey?.chatId),
        {
          params: {
            lastEvaluatedId: lastEvaluatedKey.id,
            lastEvaluatedCreatedAt: lastEvaluatedKey.createdAt,
            limit: LIMIT,
          },
        },
      );
      mutateOnPagination(
        latestMessagesURLs[lastEvaluatedKey.chatId],
        data?.messages ?? [],
      );
      setLastEvaluatedKey(data?.lastEvaluatedKey);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const useLatestMessages = (chatId: string) => {
    const url = getURLWithParams(CHAT.LATEST_MESSAGES(chatId), LIMIT);
    const fetchResult = useFetch<LatestMessages>(url);

    useEffect(() => {
      if (fetchResult.isValidating && error) {
        setTimeout(() => {
          setError('');
        }, 5000);
      }
    }, [fetchResult.isValidating]);

    useEffect(() => {
      setLastPlenaryChatURL(url);
      setLatestMessagesURLs((prevState) => ({ ...prevState, [chatId]: url }));
    }, [chatId, url]);

    useEffect(() => {
      setLastEvaluatedKey(fetchResult.data?.lastEvaluatedKey);
    }, [fetchResult.data?.lastEvaluatedKey]);

    return {
      ...fetchResult,
      isLoadingMore,
      hasMore: !!lastEvaluatedKey,
      loadMoreMessages,
    };
  };

  const onNewMention = useCallback(
    (message: Message) => {
      if (
        chatIdRef.current !== message.chatId &&
        !isChatPrivate(message.chatId)
      ) {
        notification.push({
          name: message.name,
          type: NotificationTypes.MENTION,
          id: message.userId,
          value: message.chatId,
          group: true,
        });
      }
    },
    [notification],
  );

  const loadMoreChats = useCallback(
    async (page) => {
      if (page === 1 || page > maxUserChatsPage) return;
      const url = new URL(lastUserChatsURL);
      const name = url.searchParams.get('name');

      try {
        setIsLoadingMore(true);
        const { data } = await api.get<ChatListAPI>(CHAT.USER_CHATS, {
          params: {
            size: USER_CHATS_SIZE,
            page,
            name,
          },
        });
        appendUserChats(lastUserChatsURL, data.chats);
      } finally {
        setIsLoadingMore(false);
      }
    },
    [api, lastUserChatsURL, maxUserChatsPage],
  );

  const handleJoinStreamChannel = useCallback(
    (streamId: string) =>
      handleJoinStreamChannelContext(streamId, onJoinStreamChannel),
    [handleJoinStreamChannelContext, onJoinStreamChannel],
  );

  const useUserChats = (params: ChatParams = {}) => {
    const url = getURLWithParams(CHAT.USER_CHATS, {
      ...params,
      page: 1,
      size: USER_CHATS_SIZE,
    });
    const fetchResult = useFetch<ChatListAPI>(url);

    useEffect(() => {
      setLastUserChatsURL(url);
    }, [url]);

    useEffect(() => {
      setUserChatsPage(1);
    }, [params]);

    useEffect(() => {
      const { total = USER_CHATS_SIZE } = fetchResult.data ?? {};

      if (fetchResult?.data?.chats) {
        const { chatId, lastMessage, name } = fetchResult.data.chats[0];

        const isNotCurrentChat =
          chatIdRef.current !== chatId && isChatPrivate(chatId);

        if (
          hasLastMessage(lastMessage) &&
          isNotCurrentChat &&
          session.id !== lastMessage.userId
        ) {
          notification.pushMessage({
            name,
            id: lastMessage.userId,
            time: lastMessage.createdAt,
          });
        }
      }

      setMaxUserChatsPage(Math.ceil(total / USER_CHATS_SIZE));
    }, [fetchResult.data]);

    return {
      ...fetchResult,
      isLoadingMore,
      hasMore: userChatsPage < maxUserChatsPage,
      loadMoreChats: () => setUserChatsPage((prevValue) => prevValue + 1),
    };
  };

  const onNewPrivateChat = useCallback(
    ({ userId }: Message) => handleJoinPrivateChannel(userId, false),
    [handleJoinPrivateChannel],
  );

  const onMessageRead = useCallback(
    (message: Message) =>
      mutateUserChats(lastUserChatsURL, { ...message, read: true }),
    [lastUserChatsURL],
  );

  const onMessageDeleted = useCallback(
    async (message: Message) => {
      const { messages } = await mutateOnMessageDeleted(
        lastPlenaryChatURL,
        message.id,
      );
      const [lastMessage] = messages;
      updatePlenaryChat(lastMessage.content);
    },
    [lastPlenaryChatURL, updatePlenaryChat],
  );

  const onChatDisabled = useCallback(
    ({ disableChat }: Pick<Event, 'disableChat'>) => {
      const eventId = getEventId()!;

      if (disableChat) {
        setShowRoom(false);
        setRoom(undefined);
        setAvailableTabs((prevState) =>
          prevState.filter((tab) => tab !== InteractionsTabs.chat),
        );
      } else {
        setAvailableTabs((prevState) => [...prevState, InteractionsTabs.chat]);
      }

      mutate(EVENTS.BY_ID(eventId), (prevState: Event) => ({
        ...prevState,
        disableChat,
      }));
    },

    [setAvailableTabs, setShowRoom, setRoom],
  );

  useEffect(() => {
    loadMoreChats(userChatsPage);
  }, [loadMoreChats, userChatsPage]);

  useSocketListener(socket, ChatEvents.ERROR, onError);
  useSocketListener(socket, ChatEvents.CONNECTED, onConnect);
  useSocketListener(socket, ChatEvents.CHANNEL_JOINED, onChannelJoined);
  useSocketListener(socket, ChatEvents.NEW_MESSAGE, onNewMessage);
  useSocketListener(socket, ChatEvents.MESSAGE_SENDED, onMessageSended);
  useSocketListener(socket, ChatEvents.NEW_PRIVATE_CHAT, onNewPrivateChat);
  useSocketListener(socket, ChatEvents.MESSAGE_READ, onMessageRead);
  useSocketListener(socket, ChatEvents.NEW_MENTION, onNewMention);
  useSocketListener(socket, ChatEvents.MESSAGE_DELETED, onMessageDeleted);
  useSocketListener(socket, ChatEvents.DISABLED_CHAT, onChatDisabled);

  return {
    error,
    alreadyJoinStreamChat,
    isLoading,
    isChatConnected,
    useLatestMessages,
    useUserChats,
    handleOnLeaveChatRoom: () => (chatIdRef.current = ''),
    handleSendMessage,
    handleJoinStreamChannel,
    handleJoinPrivateChannel,
	handleDeleteMessage,
    setError,
  };
};
