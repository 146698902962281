import styled, { css, DefaultTheme } from 'styled-components';
import { ChipProps } from './Chip';

type WrapperProps = Pick<ChipProps, 'hasDot' | 'isCenter' | 'isClickable'>;

const wrapperModifiers = {
  withDot: (theme: DefaultTheme) => css`
    ${Name} {
      font-weight: 700;
    }

    ${Description} {
      padding-right: ${theme.spacing.xs};
    }

    &::after {
      background-color: ${theme.primary};
      border-radius: 50%;
      content: '';
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      height: 12px;
      width: 12px;
    }
  `,
  center: () => css`
    ${Name} {
      grid-row: 1/-1;
      align-self: center;
      cursor: default;
    }
  `,
  clickable: (theme: DefaultTheme) => css`
    transition: all 0.2s linear;
    padding: ${theme.spacing.xs};

    &:hover {
      background-color: ${theme.secondary};
      border-color: ${theme.primary};
    }
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, hasDot, isCenter, isClickable }) => css`
    border-radius: ${theme.shapes.borderRadius.lg};
    border: 1px solid transparent;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 0 ${theme.spacing.xs};
    justify-items: flex-start;
    position: relative;

    &:not(:last-child) {
      margin-bottom: ${theme.spacing.sm};
    }

    ${hasDot && wrapperModifiers.withDot(theme)};
    ${isCenter && wrapperModifiers.center()};
    ${isClickable && wrapperModifiers.clickable(theme)};
  `}
`;

export const AvatarWrapper = styled.div`
  ${({ theme }) => css`
    grid-row: 1/-1;
    border-radius: 50%;
    color: ${theme.primary};
    height: 40px;
    width: 100%;
    overflow: hidden;

    svg,
    img {
      height: 100%;
      width: 100%;
    }
  `}
`;

export const Name = styled.span`
  ${({ theme }) => css`
    color: ${theme.text.primary};
    font-family: ${theme.typography.family.primary};
    font-size: ${theme.typography.sizes.base};
    font-weight: 600;
    line-height: 1;
  `}
`;

export const Description = styled.small`
  ${({ theme }) => css`
    color: ${theme.text.primary};
    font-family: ${theme.typography.family.primary};
    font-size: ${theme.typography.sizes.sm};
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &:empty {
      display: none;
    }
  `}
`;
