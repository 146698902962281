import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSettingsContext } from 'contexts';
import { Logo, Button, SubTitle } from 'components/structure';
import * as S from './ErrorGeneric.styles';

export type ErrorGenericProps = {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  description: string | JSX.Element;
  buttonLabel: string | JSX.Element;
  actionNavigate?: string;
  actionReload?: () => void;
  backgroundImage?: string;
  isSpaceFull?: boolean;
};

export const ErrorGeneric = ({
  title,
  subtitle,
  description,
  buttonLabel,
  actionNavigate,
  actionReload,
  backgroundImage,
  isSpaceFull = true,
}: ErrorGenericProps) => {
  const { setIsSpaceFull } = useSettingsContext();
  const navigate = useNavigate();

  useEffect(() => {
    setIsSpaceFull(true);

    return () => {
      setIsSpaceFull(false);
    };
  }, [setIsSpaceFull]);

  return (
    <S.Container src={backgroundImage} isSpaceFull={isSpaceFull}>
      {isSpaceFull && <Logo />}

      <S.Content>
        <S.Title>{title}</S.Title>

        {subtitle && <SubTitle>{subtitle}</SubTitle>}

        <p>{description}</p>
        <Button
          color={backgroundImage ? 'primary' : 'secondary'}
          to={actionNavigate}
          onClick={() => {
            actionReload && actionReload();
            actionNavigate && navigate(actionNavigate);
          }}
        >
          {buttonLabel}
        </Button>
      </S.Content>
    </S.Container>
  );
};
