import { useCallback } from 'react';
import { getCroppedImg } from 'lib/contexts';

export const useCropImage = () =>
  useCallback(async ({ imageToCrop, imageAttr, cropArea }) => {
    try {
      const croppedImage = await getCroppedImg(imageAttr.image, cropArea, 0);
      const fileImageCropped = new File(
        [croppedImage as BlobPart],
        imageToCrop.name,
      );

      return fileImageCropped;
    } catch (e) {
      console.error(e);
    }
  }, []);
