import { useState } from 'react';
import ReactPlayer from 'react-player';
import { usePortal, useTranslate } from 'hooks';
import { Button, Icon } from 'components/structure';
import * as S from './Preview.styles';

export type PreviewProps = {
  showModal: boolean;
  handleCloseModal: (value: boolean) => void;
  videoUrl?: string;
  downloadUrl?: string;
  fileUrl?: string;
  showButton?: boolean;
};

export const Preview = ({
  showModal,
  handleCloseModal,
  videoUrl,
  fileUrl,
  showButton,
}: PreviewProps) => {
  const Portal = usePortal();
  const translate = useTranslate();
  const [playing, setPlaying] = useState(false);

  return (
    <Portal>
      {showModal && (
        <S.Wrapper>
          <S.IconWrapper
            onClick={(e) => {
              e.stopPropagation();
              setPlaying(false);
              handleCloseModal(false);
            }}
          >
            <Icon icon="IcClose" aria-label="Fechar" />
          </S.IconWrapper>

          <S.Content>
            {videoUrl ? (
              <S.DisplayContainer isVideo={!!videoUrl}>
                <ReactPlayer
                  url={videoUrl}
                  width="100%"
                  height="100%"
                  controls
                  playing={playing}
                  onPlay={() => setPlaying(true)}
                />
              </S.DisplayContainer>
            ) : (
              <S.DisplayContainer>
                {/* <S.Document type="text/html" data={fileUrl} /> */}
                <video
                  style={{ width: '100%', height: '100%' }}
                  controlsList="nodownload"
                  controls
                  src={fileUrl}
                ></video>
                {showButton && (
                  <Button
                    download
                    as="a"
                    href={fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="tertiary"
                  >
                    {translate('labels.download')}
                  </Button>
                )}
              </S.DisplayContainer>
            )}
          </S.Content>
        </S.Wrapper>
      )}
    </Portal>
  );
};
