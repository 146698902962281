import { contextsPT, contextsEN, contextsES } from './contexts';

export type Locales = {
  [key: string]: string;
};

export default {
  pt: {
    ...contextsPT,
  },
  en: {
    ...contextsEN,
  },
  es: {
    ...contextsES,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;
