import { isAndroid, isIOS } from 'react-device-detect';
import styled, { createGlobalStyle, css } from 'styled-components';
import media from 'styled-media-query';



export const InputWrapper = styled.div<{focused: boolean}>`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.sm} ${theme.spacing.md};
    position: relative;
    width: 100%;
    height: 72px;
    flex: 0 0 72px;

    :focus-within {
      background: transparent !important;
    }
    ${media.lessThan('medium')`
      position: fixed;
      bottom: 0;
      z-index: 1001;
      display: flex;
      align-items: flex-end;
      width: 100%;

      form {
        width: 100%;
      }

      &:after {
        content: none;
      }

      :focus-within {
        ${(props: any) => props.focused && css`
				height: 100%;
        background-color:rgba(0, 0, 0, 0.5) !important;
				overflow: hidden;
				`}
      }
    `}
  `}
`;

export const GlobalStyles = createGlobalStyle<{focus?: boolean}>`
	html,
  body {

    ${props => props.focus && (isIOS || isAndroid) && css`
    touch-action: none;
    -ms-touch-action: none;
    `}
	}
`

type MessageRejectedProps = {
  show?: boolean;
};

const messageRejectedModifiers = {
  show: () => css`
    transform: translate(-50%, 0);
    pointer-events: all;
    opacity: 1;
  `,
};

export const MessageRejected = styled.div<MessageRejectedProps>`
  ${({ theme, show }) => css`
    background-color: ${theme.secondary};
    border: 1px solid ${'red'};
    box-shadow: 0px 21px 34px rgba(255, 2, 2, 0.2);
    backdrop-filter: blur(50px);
    border-radius: ${theme.shapes.borderRadius.md};
    bottom: calc(100% + ${theme.spacing.sm});
    padding: ${theme.spacing.xs} ${theme.spacing.md};
    position: absolute;
    pointer-events: none;
    opacity: 0;
    transition: all 0.2s ease-in;
    transform: translate(-50%, ${theme.spacing.lg});
    left: 50%;
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    width: 312px;
    will-change: transform;
    z-index: 9999;

    @media (max-width: 768px) {
      bottom: ${theme.spacing['3xl']};
      display: flex;
      z-index: 9999;
      width: 90%;
      left: calc(100vw - 50%);
    }

    span {
      color: ${theme.text.secondary};
      font-size: ${theme.typography.sizes.sm};
      line-height: ${theme.typography.lineHeight.base};
      margin-left: ${theme.spacing.sm};
    }

    &::after {
      background-color: ${theme.secondary};
      border: 1px solid ${'red'};
      border-bottom: none;
      border-right: none;
      content: '';
      display: block;
      height: 15px;
      width: 15px;
      position: absolute;
      bottom: -8px;
      left: 32px;
      transform: rotate(225deg);
    }

    ${show && messageRejectedModifiers.show()};
  `}
`;
