import { createContext, useState } from "react";
import { useIsChatInputFocused } from "hooks/useIsChatInputFocused";

export const IsChatInputContext = createContext<any>({
	setIsFocused: null,
	isFocused: false
})

export function IsChatInputProvider({children}: any) {
	const [isFocused, setIsFocused] = useState(false)
	return (
		<IsChatInputContext.Provider value={{isFocused, setIsFocused}}>
			{children}
		</IsChatInputContext.Provider>
	)
}
