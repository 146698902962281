import * as Yup from 'yup';
import { passwordStrengthForVale } from 'lib/contexts/input';
import messages from 'lib/core/errors';
import { getEventThemeName } from 'lib/core/event';
//import EventOptions from 'styles/overrides/EventOptions.theme';
import { EventOptions } from 'styles/themes/tm1';
import { DefaultValues, ShapeSchema } from 'interfaces/validations';
import { BRAZIL_COUNTRY_CODE, ProjectNames } from 'constants/enums';

const { requiredParam, paramsDontMatch } = messages;

const values: DefaultValues = {
  firstName: '',
  lastName: '',
  uuid: '',
  country: '',
  company: '',
  profile: {
    area: '',
  },
  preferableLanguage: '',
  state: '',
  inviteCode: '',
  ageGroup: '',
  password: '',
  rePassword: '',
  terms: false,
  acceptedShareData: false,
  acceptedNews: false,
};

// eslint-disable-next-line no-useless-escape
//const validateDuplicateURL = /^(https:\/\/|http:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-])*\/?$/;

const additionalFields = {
  ask1: Yup.boolean()
    .test(
      'is-true',
      'Confirme o termo para prosseguir',
      (value) => value === true,
    )
    .required(requiredParam('ask1')),
  ask2: Yup.boolean()
    .test(
      'is-true',
      'Confirme o termo para prosseguir',
      (value) => value === true,
    )
    .required(requiredParam('ask2')),
  ask3: Yup.boolean().optional(),
  professionalId: Yup.string()
    .required('Este Campo é obrigatórioo')
    .matches(/^[A-Za-z]{2}\d{6,7}$/, 'O valor deve seguir o formato "RJ0000000')
    .max(9)
    .uppercase(),
};

export const shape: ShapeSchema = {
  firstName: Yup.string().required(requiredParam('firstName')),
  lastName: Yup.string().required(requiredParam('lastName')),
  // company: Yup.string().required(requiredParam('company')),
  uuid: Yup.string()
    .test(
      'has-accented-characters',
      'O e-mail não pode conter acentos.',
      (value) => {
        const regex = /[À-ÿ]/;
        return !regex.test(value || '');
      },
    )
    .email()
    .required(requiredParam('uuid')),
  email: Yup.string()
    .test(
      'has-accented-characters',
      'O e-mail não pode conter acentos.',
      (value) => {
        const regex = /[À-ÿ]/;
        return !regex.test(value || '');
      },
    )
    .email()
    .required(requiredParam('uuid')),
  // password: Yup.string().required(requiredParam('password')),
  // rePassword: Yup.string().when('password', {
  //   is: (value: string) => !!value,
  //   then: Yup.string()
  //     .required(requiredParam('confirm-password'))
  //     .oneOf([Yup.ref('password'), null], paramsDontMatch('password')),
  //   otherwise: Yup.string().notRequired().default(undefined),
  // }),
  terms: Yup.bool().oneOf([true], requiredParam('terms')),
  country: Yup.string().required(requiredParam('country')).default('76'),
  state: Yup.string(),
  referrer: Yup.string(),
  ...additionalFields,
};

if (getEventThemeName() === ProjectNames.VALE) {
  shape.numberOfPeopleWatchingWithMe = Yup.number()
    .typeError(requiredParam('numberOfPeopleWatchingWithMe'))
    .min(0, requiredParam('numberOfPeopleWatchingWithMe'))
    .required(requiredParam('numberOfPeopleWatchingWithMe'));

  values.numberOfPeopleWatchingWithMe = 0;

  shape.password = Yup.string()
    .matches(
      passwordStrengthForVale.hasOnlySixNumbers,
      messages.invalidParam('passwordHasSixNumbers'),
    )
    .required(requiredParam('password'));
}

export const schema = Yup.object().shape(shape);

// export const schemaHasAdicionalFields = (res: boolean) =>
//   Yup.object().shape(res ? { ...shape, ...adicionalFields } : shape);

export const defaultValues = values;
