/* eslint-disable prettier/prettier */
/* eslint-disable no-restricted-imports */
import { yupResolver } from '@hookform/resolvers/yup';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
//import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { useTranslate } from 'hooks';
import { getEventThemeName } from 'lib/core/event';
// import { PublicFaqAnchor } from 'components/extra/PublicFaqAnchor';
import { TextField } from 'components/form';
import {
  Button,
  CFCode,
  FormActions,
  FormGroup,
  Form,
  Heading,
  Paragraph,
  ButtonSupport,
} from 'components/structure';
import { UserExistsParams } from 'interfaces/user';
import { ProjectNames } from 'constants/enums';
import { schema } from './GettingStartedForm.validations';

type GettingStartedFormProps = {
  onSubmit: (uuid: UserExistsParams) => void;
  isPending?: boolean;
  showSupportButton?: boolean;
};

export const AboutName = styled.div`
  font-size: 0.75rem;
  ${media.greaterThan('medium')`
    display:none;
    `}
`;

export const GettingStartedForm = ({
  onSubmit,
  isPending,
  showSupportButton,
}: GettingStartedFormProps) => {
  const translate = useTranslate();
  const { register, handleSubmit, errors } = useForm<UserExistsParams>({
    resolver: yupResolver(schema),
  });

  const keyTranslationUuid =
    ProjectNames.TM1 === getEventThemeName() ? `.${getEventThemeName()}` : '';

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Heading>{translate('accreditation.gettingStarted.title')}</Heading>
      <Paragraph>
        {translate(`accreditation.gettingStarted.wellcome`)}
        {/* <b>{translate(`accreditation.gettingStarted.nameEvent`)}</b>
        <br />
        {translate(`accreditation.gettingStarted.description`)}{' '} */}
      </Paragraph>

      <FormGroup half>
        <TextField
          name="uuid"
          autoComplete="uuid"
          ref={register}
          label={translate(`labels.uuid`)}
          placeholder={translate(`labels.uuid`)}
          error={!!errors?.uuid?.message}
          helperText={translate(errors?.uuid?.message)}
        />
        {/* <PublicFaqAnchor /> */}
      </FormGroup>
      {showSupportButton && (
        <FormGroup lgMargin>
          <ButtonSupport
            onlyIcon={isMobile}
            fixedDesktop={!isMobile}
            icon={isMobile ? 'IcHelp' : undefined}
          />
        </FormGroup>
      )}
      <FormActions>
        <CFCode />
        <Button
          data-testid="getting-started-btn-submit"
          fullWidthMobile
          isLoading={isPending}
          disabled={isPending}
          type="submit"
        >
          {translate('labels.next')}
        </Button>
        <AboutName style={{ fontSize: '0.75rem', alignItems: 'end' }}>
          Blazek O, et al. Am Heart J Plus 2022;19:100187
        </AboutName>
      </FormActions>
    </Form>
  );
};

export default GettingStartedForm;
