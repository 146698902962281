import { LanguageStream } from 'contexts';
import { VideoPlayers } from 'constants/enums';
import { Message } from './chat';

type Speaker = {
  name: string;
  avatar: string;
  jobTitle: string;
  bio: string;
  socialMedias: [
    {
      name: string;
      url: string;
    },
  ];
};

export enum StreamState {
  WatchAgain = 'watch-again',
  Live = 'live',
  Finished = 'finished',
}

export type Stream = {
  id: string;
  stage: number;
  title: string;
  description: string;
  time: string;
  details: string;
  scheduledStartTime: string;
  publishedAt: string;
  scheduledEndTime: string;
  eventId: string;
  thumbnail: string;
  secondaryThumbnail: string;
  duration: string;
  streamingDetails: {
    liveStreamId: string;
    videoUrl: string;
    playbackUrl: string;
    streamName: string;
    language: string;
    videoPlayer: VideoPlayers;
  };
  speaker: Speaker[];
  about: string;
  usingBackup: boolean;
  state: StreamState;
  topics: string[];
  isSegmentedStream: boolean;
  segmentedType: string;
  createdAt: string;
  updatedAt: string;
  latestMessage?: Message;
  languages: LanguageStream[];
  customPlayer: boolean;
  reactions?: {
    active: boolean;
    position: string;
  };
};

export type StreamingDetails = {
  videoUrl: string;
  language: string;
  streamingService: number;
  videoPlayer: VideoPlayers;
};

export type StreamBackup = {
  usingBackup: boolean;
  id: string;
  streamingDetails: StreamingDetails[];
};
