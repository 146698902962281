import { LevelProps } from 'components/contexts/rewards';

export const addPropsOnLevelList = (levels: LevelProps[]) =>
  levels.map((level, index) => {
    const firstLevelIndex = 0;
    const finalLevelIndex = levels.length - 1;

    const hasProgressBottom = index !== finalLevelIndex;
    const hasProgressTop = index !== firstLevelIndex;

    return {
      ...level,
      hasProgressBottom,
      hasProgressTop,
    };
  });

type ObjectParams = {
  [key: string]: string | undefined;
};

export const objectToGetParams = (object: ObjectParams) => {
  const params = Object.entries(object)
    .filter(([, value]) => !!value)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
    );

  return `?${params.join('&')}`;
};

export const facebookLink = (url: string, quote: string) =>
  'https://www.facebook.com/sharer/sharer.php' +
  objectToGetParams({
    u: url,
    quote,
  });

export const whatsappLink = (
  url: string,
  { title, separator }: { title?: string; separator?: string },
) =>
  'https://web.whatsapp.com/send' +
  objectToGetParams({
    text: title ? title + separator + url : url,
  });

export const twitterLink = (url: string, title: string) =>
  'https://twitter.com/share' +
  objectToGetParams({
    url,
    text: title,
  });
