/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Picker, { IEmojiData } from 'emoji-picker-react';
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNetworking, Connections } from 'useCases/networking';
import { useClickOutside, useTheme } from 'hooks';
import { useDebounce } from 'hooks/useDebounce';
import { Avatar } from 'components/contexts/users';
import { Button, Icon } from 'components/structure';
import * as S from './MessageField.styles';

export type MessageFieldProps = {
	name: string;
	mentionsValue: any;
	setMentionsValue: any;
	inputRef?: HTMLInputElement | null;
	isDisabled?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'step'> &
	Pick<UseFormMethods, 'register' | 'setValue' | 'getValues'>;

export type Params = {
	name?: string;
};

export const MessageField = ({
	name,
	register,
	setValue,
	getValues,
	mentionsValue,
	setMentionsValue,
	isDisabled,
	inputRef,
	...props
}: MessageFieldProps) => {
	const theme = useTheme();
	const { formatMessage } = useIntl();
	const [showEmojis, setShowEmojis] = useState(false);
	const emojiSelectRef = useClickOutside(() => setShowEmojis(false));
	const [inputPosition, setInputPosition] = useState(0);

	const [params, setParams] = useState<Params>({
		name: '',
	});
	const { useOnlineUsers } = useNetworking();
	const { data: { connections } = {} } = useOnlineUsers(params);
	const [trigger, setTrigger] = useState(false);
	const [messageContent, setMessageContent] = useState('');
	const onEmojiClick = (_: React.MouseEvent, data: IEmojiData) => {
		setValue(name, getValues(name) + data?.emoji);
		setShowEmojis(false);
		(inputRef as any)?.current?.focus();
	};
	const [cachedConnections, setCachedConnections] = useState<any>(connections);
	/* eslint-disable @typescript-eslint/no-explicit-any */
	function updateMessageContent(event: any) {
		setInputPosition(event.target.selectionStart);
		const content = event.target.value as string;
		const mentionCount = event.target.value?.match(/[@]/g)?.length;


		if (event.key === 'Enter') {
			setTrigger(false);
		}

		if (trigger) {
			const atSignLastIndex = event.target.value
				.slice(0, event.target.selectionStart + 1)
				.lastIndexOf('@');
			const targetWords = content.slice(atSignLastIndex + 1);
			if (atSignLastIndex > 0) {
				setCachedConnections(
					connections?.filter((conn: any) => conn.name.startsWith(targetWords)),
				);
			} /* else {
        setCachedConnections(connections)
      } */
		}
		setValue(name, content);
	}
	function removeBoxIfNoMetionFound(event: any) {
		if (event?.target?.value?.length <= 0) {
			setTrigger(false);
		}
		if (event.target.value[event.target.selectionStart - 1] === '@') {
			setTrigger(true);
		}
		const _ = event.target.value
			.slice(0, event.target.selectionStart + 1)
			.lastIndexOf('@');

		setInputPosition(event.target.selectionStart);
		if (event.target.value[event.target.selectionStart - 1] === '@') {
			setTrigger(true);
		}

		// if(event.target.selectionStart)
		if (!event.target.value.includes('@')) {
			setTrigger(false);
		} else {
			const content = event.target.value as string;

			// Pega o último "@" entre o começo da frase a posição de digitação atual
			const atSignLastIndex = event.target.value
				.slice(0, event.target.selectionStart + 1)
				.lastIndexOf('@');
			if (
				mentionsValue.some((i: any) =>
					i.username.includes(
						event.target.value.slice(
							atSignLastIndex + 1,
							event.target.selectionStart,
						),
					),
				)
			) {
				const username = event.target.value.slice(
					atSignLastIndex + 1,
					event.target.selectionStart,
				);
				const stringResult =
					event.target.value.slice(0, atSignLastIndex + 1) +
					event.target.value.slice(atSignLastIndex + 1 + username.length);
				setMessageContent(stringResult);
				setValue(name, stringResult);
				setTrigger(true);
				setInputPosition(atSignLastIndex + 1);
				event.target.selectionStart = atSignLastIndex + 1;
				event.target.selectionEnd = atSignLastIndex + 1;
			}

			// Usa como filtro a parte da string que começa 1 índice após a índice do @ até a posição atual de digitação
			let targetWords = content.slice(
				atSignLastIndex + 1,
				event.target.selectionStart,
			);

			// Caso o primeiro caractér após o @ seja um espaço, entendemos que estamos criando uma menção no meio da frase,
			// então vamos considerar como se fosse uma string vazia ('') para não retornar uma lista vazia de usuários após
			// filtragem.
			targetWords = targetWords === ' ' ? '' : targetWords;

			if (atSignLastIndex > -1) {
				setCachedConnections(
					connections?.filter((conn: any) =>
						conn.name.toUpperCase().startsWith(targetWords.toUpperCase()),
					),
				);
			}
			if (
				connections?.filter((conn: any) =>
					conn.name.toUpperCase().startsWith(targetWords.toUpperCase()),
				).length == 0
			) {
				setTrigger(false);
			}
			if (
				event.target.value[atSignLastIndex - 1] !== ' ' &&
				event.target.value.slice(0, atSignLastIndex + 1).length > 1
			) {
				setTrigger(false);
			}
		}
		// Filtra o array de menções deixando somente as menções em que o username está contida no input.
		setMentionsValue([
			...(mentionsValue as {
				userId?: string;
				username?: string;
			}[]).filter((m) =>
				event?.target?.value
					?.toUpperCase()
					.includes(m?.username?.toUpperCase()),
			),
		]);
	}

	function mentionUser(username: string, id: string, avatar: string) {
		let words = getValues(name) as string;
		const atSignLastIndex = words.slice(0, inputPosition).lastIndexOf('@'); // Pega o último "@" entre o começo da frase a posição de digitação atual

		// Pega o valor do input atual, do primeiro caractér até o último, considerando o último a POSIÇÃO ATUAL de digitação e não todo o input,
		// com o resultado dessa string concatena no final o Nome e Sobrenome do usuário mencionado, em seguida concatena esse resultado com o resto
		// do valor do input (tudo que vem após o '@');
		const userSearchInputToRemove = words.slice(
			atSignLastIndex + 1,
			inputPosition,
		);

		words =
			words.slice(0, atSignLastIndex + 1) +
			username +
			(words[atSignLastIndex + 1] === ' ' ? '' : ' ') +
			words.slice(atSignLastIndex + 1 + userSearchInputToRemove.length);

		setMessageContent(words);
		setValue(name, words);

		setTrigger(false);

		setMentionsValue([
			...mentionsValue,
			{
				userId: id,
				username,
			},
		]);

		(inputRef as any).current?.focus();
	}
	return (
		<S.Wrapper>
			<S.ButtonEmoji type="button" onClick={() => setShowEmojis(!showEmojis)}>
				<Icon icon="IcEmoji" />
			</S.ButtonEmoji>
			<S.Field
				type="text"
				onKeyPress={updateMessageContent}
				onKeyDown={updateMessageContent}
				onChange={removeBoxIfNoMetionFound}
				ref={(event) => {
					register(event);
					if (inputRef) {
						(inputRef as any).current = event;
					}
				}}
				id={name}
				name={name}
				defaultValue={getValues()[name]}
				{...props}
			/>

			<S.ButtonWrapper>
				<Button
					onClick={() => {
						if ((inputRef as any)?.current) {
							(inputRef as any)?.current.blur();
							if ((inputRef as any)?.current.value === '@') {
								setTrigger(false);
							}
						}
					}}
					disabled={isDisabled}
					style={{
						backgroundColor: isDisabled === true ? 'gray' : theme.primary,
					}}
					type="submit"
					isRound
				>
					<Icon icon="IcSend" color={'white'} />
				</Button>
			</S.ButtonWrapper>
			<S.EmojiSelectWrapper ref={emojiSelectRef} show={showEmojis}>
				<Picker
					onEmojiClick={onEmojiClick}
					groupNames={{
						smileys_people: formatMessage({ id: 'interactions.emojis.people' }),
						animals_nature: formatMessage({
							id: 'interactions.emojis.animals',
						}),
						food_drink: formatMessage({ id: 'interactions.emojis.food' }),
						travel_places: formatMessage({ id: 'interactions.emojis.travel' }),
						activities: formatMessage({ id: 'interactions.emojis.active' }),
						objects: formatMessage({ id: 'interactions.emojis.objects' }),
						symbols: formatMessage({ id: 'interactions.emojis.symbols' }),
						flags: formatMessage({ id: 'interactions.emojis.flags' }),
						recently_used: formatMessage({
							id: 'interactions.emojis.recently',
						}),
					}}
				/>
			</S.EmojiSelectWrapper>
			<S.MarkingWrapper show={trigger && cachedConnections.length >= 1}>
				{cachedConnections?.map(({ avatar, name, id }: any) => (
					<S.MarkingContainer
						key={id}
						onClick={() => mentionUser(name, id, avatar)}
					>
						<S.Img>
							<Avatar src={avatar} size={'small'} />
						</S.Img>
						<S.MarkingNameUser>{name}</S.MarkingNameUser>
						<S.Marking01></S.Marking01>
					</S.MarkingContainer>
				))}
			</S.MarkingWrapper>
		</S.Wrapper>
	);
};
