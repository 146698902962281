import styled, { css } from 'styled-components';
import { SubTitle } from 'components/structure';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    /* background-color: ${theme.secondary} rgba(221, 221,221, 0.4 ); */
		background-color: rgba(255, 255, 255, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    backdrop-filter: blur(15px);
    right: 0;
    left: 0;
    margin: 0 auto;
		/* z-index: 9999; */

    ${SubTitle} {
      color: ${theme.text.secondary};
      font-size: ${theme.typography.sizes.base};
      line-height: ${theme.typography.lineHeight.base};
			text-align: center;
    }

    svg {
      * {
        stroke: ${theme.text.secondary};
        fill: ${theme.text.secondary};
      }
    }
  `}
`;
