import { useState } from 'react';
import { mutate } from 'swr';
import { useAPI, useFetch } from 'hooks';
import { LevelProps, RankedUser } from 'components/contexts/rewards';
import { MEMBER_GET_MEMBER } from 'constants/endpoints';

export type UserGetMember = {
  userPoints: number;
  inviteLink: string;
  levels: LevelProps[];
};

export type Ranking = {
  afterKey: string;
  items: RankedUser[];
};

export const useUserGetMember = () =>
  useFetch<UserGetMember>(MEMBER_GET_MEMBER.ROOT);

export const useRankings = () => useFetch<Ranking>(MEMBER_GET_MEMBER.RANKINGS);

export const useGetGift = () => {
  const api = useAPI();
  const [isPending, setIsPending] = useState(false);

  const handleGetGift = async (levelId: string) => {
    setIsPending(true);
    try {
      await api.post(MEMBER_GET_MEMBER.ROOT, { levelId });
      mutate(MEMBER_GET_MEMBER.ROOT);
    } finally {
      setIsPending(false);
    }
  };

  return { isPending, handleGetGift };
};
