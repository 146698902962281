import { useStreams } from 'useCases/streams';
import { substractDays, datesInterval } from 'lib/contexts/date';
import { WatchAgain } from 'components/contexts/home';
import { Event } from 'interfaces/event';
import { Skeleton } from './HomeWatchAgain.skeleton';

export type HomeWatchAgainProps = {
  event?: Event;
  startDate: string;
};

export const HomeWatchAgain = ({ startDate }: HomeWatchAgainProps) => {
  const { data, isLoading } = useStreams(
    datesInterval(new Date(startDate), substractDays(1)),
  );

  return isLoading ? (
    <Skeleton />
  ) : (
    <WatchAgain streams={data?.streams || []} />
  );
};
