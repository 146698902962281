import { useFetch } from 'hooks';
import { Option } from 'components/contexts/Materials/SectionMaterial/SectionMaterial';
import { MATERIALS } from 'constants/endpoints';

type Materials = {
  eventId: string;
  materials: [
    {
      category: string;
      labelId: string;
      options: Option[];
    },
  ];
};

export const useMaterials = () => useFetch<Materials>(MATERIALS.ROOT);
