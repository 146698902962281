import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { Button } from 'components/structure';

type buttonProps = {
  onlyIcon?: boolean;
  fixedDesktop?: boolean;
};

const iconButtonModifiers = {
  onlyIcon: () => css`
    border-radius: 50%;
    min-width: 44px;
    width: 44px;
    height: 44px;
    margin-left: auto;
    svg {
      margin-right: 0px;
    }
  `,

  fixedDesktop: (theme: DefaultTheme) => css`
    ${media.greaterThan('medium')`
      position: fixed;
      bottom: 140px;
      right: ${theme.spacing['2xl']};
      z-index: 3;
    `}
  `,
};

export const ButtonSupport = styled(Button)<buttonProps>`
  ${({ theme, onlyIcon = false, fixedDesktop = false }) => css`
    && {
      width: 158px;
      min-width: 158px;
      color: ${theme.text.details};
      min-height: 32px;
      height: 32px;

      border-radius: ${theme.shapes.borderRadius.lg};
      background-color: ${theme.primary};
      font-size: ${theme.typography.sizes.sm};
      font-weight: normal;
      border: 1px solid white;

      svg {
        color: ${theme.text.details};
        width: 18px;
        height: 18px;
        margin-right: ${theme.spacing.xs};
      }

      ${onlyIcon && iconButtonModifiers.onlyIcon()}

      ${fixedDesktop && iconButtonModifiers.fixedDesktop(theme)}
    }
  `}
`;
