import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 100%;
    width: 100%;
    background: ${theme.secondary};
    position: relative;

    iframe {
      position: absolute;
      z-index: ${theme.secondary};
    }
  `}
`;

export const SpinnerWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${theme.secondary};
  `}
`;
