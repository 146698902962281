import { EventAction, EventCategory } from 'interfaces/analytics';
import { NavbarItem } from 'interfaces/navbar';
import { GA_EVENTS } from 'constants/enums';
import {
  IcCoffee,
  IcDoc,
  IcTelevision,
  IcCalendar,
  IcWorld,
} from 'assets/icons';

export const floatingMenuItemsMock = [
  // {
  //   label: 'navbar.userMenu.rewards',
  //   url: '/rewards',
  //   internal: true,
  //   ga: {
  //     action: EventAction.click,
  //     label: GA_EVENTS.navBar.labels.rewards,
  //     category: EventCategory.Navigation,
  //   },
  // },
  {
    label: 'navbar.userMenu.profile',
    url: '/configurations',
    internal: true,
    ga: {
      action: EventAction.click,
      label: GA_EVENTS.navBar.labels.profile,
      category: EventCategory.Navigation,
    },
  },
];

export default [
  {
    icon: <IcTelevision />,
    label: 'navbar.plenary',
    url: '/home',
    ga: {
      action: EventAction.click,
      label: GA_EVENTS.navBar.labels.home,
      category: EventCategory.Navigation,
    },
  },
  {
    icon: <IcWorld />,
    label: 'navbar.expo',
    url: '/virtual-expo',
    ga: {
      action: EventAction.click,
      label: GA_EVENTS.navBar.labels.expo,
      category: EventCategory.Navigation,
    },
  },
  {
    icon: <IcCalendar />,
    label: 'navbar.schedule',
    url: '/schedule',
    ga: {
      action: EventAction.click,
      label: GA_EVENTS.navBar.labels.schedule,
      category: EventCategory.Navigation,
    },
  },
  {
    icon: <IcCoffee />,
    label: 'navbar.sponsors',
    url: '/sponsors',
    ga: {
      action: EventAction.click,
      label: GA_EVENTS.navBar.labels.sponsors,
      category: EventCategory.Navigation,
    },
  },
  {
    icon: <IcCalendar />,
    label: 'navbar.reimagineVideo',
    url: '/reimagineVideo',
    ga: {
      action: EventAction.click,
      label: GA_EVENTS.navBar.labels.reimagineVideo,
      category: EventCategory.Navigation,
    },
  },
  {
    icon: <IcDoc />,
    label: 'navbar.pressMaterial',
    url: '/material',
    ga: {
      action: EventAction.click,
      label: GA_EVENTS.navBar.labels.materials,
      category: EventCategory.Navigation,
    },
  },
  {
    icon: <IcCoffee />,
    label: 'navbar.coffee',
    url: '/coffee',
    ga: {
      action: EventAction.click,
      label: GA_EVENTS.navBar.labels.interactions,
      category: EventCategory.Navigation,
    },
  },
] as NavbarItem[];
