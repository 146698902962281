import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Wrapper as AvatarWrapper } from 'components/contexts/users/Avatar/Avatar.styles';
import { DefaultScroll } from 'components/structure';
import {
  SubTitle,
  Paragraph,
} from 'components/structure/Typography/Typography';
import { PositionKeys } from './Ranking';

type PositionProps = {
  position: PositionKeys;
};

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: 350px;
    width: 100%;

    ${SubTitle} {
      text-align: center;
      color: ${theme.text.primary};
      font-size: ${theme.typography.sizes.md};
    }

    ${media.lessThan('large')`
      max-width: 100vw;
      padding: 0 ${theme.spacing.md};

      ${SubTitle} {
        font-size: ${theme.typography.sizes.base};
      }
    `}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    position: relative;
    max-height: 220px;
    overflow-y: auto;
    padding: 0 ${theme.spacing['2xs']} ${theme.spacing['2xs']} 0;

    ${DefaultScroll}
  `}
`;

export const User = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    background: ${theme.text.primary};
    padding: ${theme.spacing.xs};
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    border-radius: ${theme.shapes.borderRadius.sm};

    & + div {
      margin-top: ${theme.spacing['2xs']};
    }

    ${Paragraph} {
      position: relative;
      flex: 1;
      color: ${theme.text.primary};
      margin-bottom: 0;
      margin-left: ${theme.spacing.sm};
      overflow: hidden;
      white-space: nowrap;
      padding-right: ${theme.spacing.xs};

      &:after {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0;
        background: linear-gradient(
          80.89deg,
          rgba(255, 255, 255, 0.8) 21.15%,
          ${theme.text.primary} 29.81%
        );
      }
    }
  `}
`;

const positionModifiers = {
  first: () => css`
    color: #ffc308;
  `,
  second: () => css`
    color: #757575;
  `,
  third: () => css`
    color: #945d41;
  `,
};

export const Position = styled.h3<PositionProps>`
  ${({ theme, position }) => css`
    margin-right: ${theme.spacing.sm};
    color: ${theme.text.primary};

    ${position && positionModifiers[position]}
  `}
`;

export const Avatar = styled(AvatarWrapper)``;

const pointsModifiers = {
  show: () => css`
    > svg {
      opacity: 1;
      visibility: visible;
    }
  `,
};

export const Points = styled.strong<{ showIcon: boolean }>`
  ${({ theme, showIcon }) => css`
    color: ${theme.text.primary};
    display: flex;
    align-items: center;

    > svg {
      height: 16px;
      margin: 0 0 ${theme.spacing['2xs']} ${theme.spacing.xs};
      opacity: 0;
      visibility: hidden;
    }

    ${showIcon && pointsModifiers.show()}
  `}
`;
