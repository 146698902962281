import { useFetch } from 'hooks';
import { Questions } from 'components/contexts/help-center';
import { HELP_CENTER } from 'constants/endpoints';

export interface Params {
  name?: string;
  limit?: number;
  text?: string;
  page?: number;
}

export const useQuestions = (params: Params) => {
  const serializedParams = params.text ? params : { ...params, limit: 20 };
  return useFetch<{ faqs: Questions }>(
    `${HELP_CENTER.ROOT}?text=${serializedParams?.text || ''}&page=${
      serializedParams?.page || 1
    }&limit=${serializedParams.limit}`,
  );
};
