import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 331 900"
    >
      <rect width="17" height="17" />
      <rect x="25" width="142" height="17" />

      <rect y="47" width="297" height="21" />
      <rect y="98" width="322" height="16" />
      <rect y="122" width="298" height="16" />
      <rect y="146" width="220" height="16" />
      <rect y="170" width="317" height="16" />
      <rect y="194" width="56" height="16" />
      <rect y="218" width="322" height="16" />
      <rect y="242" width="121" height="16" />

      <circle cx="165" cy="380" r="64" />

      <rect x="55" y="460" width="224" height="20" />
      <rect y="500" width="330" height="16" />
      <rect y="524" width="278" height="16" />
      <rect y="548" width="310" height="16" />
      <rect y="572" width="290" height="16" />

      <circle cx="165" cy="690" r="64" />

      <rect x="55" y="774" width="224" height="20" />
      <rect y="814" width="330" height="16" />
      <rect y="838" width="278" height="16" />
      <rect y="862" width="310" height="16" />
      <rect y="886" width="290" height="16" />
    </ContentLoader>
  );
};

const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 1000 650"
    >
      <rect width="17" height="17" />
      <rect x="25" width="142" height="17" />

      <rect y="47" width="297" height="21" />
      <rect y="98" width="631" height="16" />
      <rect y="122" width="584" height="16" />
      <rect y="146" width="602" height="16" />
      <rect y="170" width="614" height="16" />
      <rect y="194" width="619" height="16" />
      <rect y="218" width="615" height="16" />
      <rect y="242" width="403" height="16" />

      <circle cx="199" cy="380" r="64" />
      <rect x="90" y="460" width="224" height="20" />

      <rect y="500" width="398" height="16" />
      <rect y="524" width="365" height="16" />
      <rect y="548" width="388" height="16" />
      <rect y="572" width="370" height="16" />

      <circle cx="755" cy="380" r="64" />
      <rect x="645" y="460" width="224" height="20" />

      <rect x="560" y="500" width="398" height="16" />
      <rect x="560" y="524" width="365" height="16" />
      <rect x="560" y="548" width="398" height="16" />
      <rect x="560" y="572" width="370" height="16" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <Container>
        <SkeletonMobile />
      </Container>
    </MediaMatch>
  </>
);
