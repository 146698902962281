import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

export const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 930 770"
    >
      <rect x="0" y="10" width="300" height="40" />

      <rect x="0" y="90" width="420" height="56" rx="4" />
      <rect x="460" y="90" width="420" height="56" rx="4" />

      <rect x="460" y="166" width="220" height="14" />

      <rect x="0" y="650" width="240" height="56" rx="4" />

      <rect x="0" y="736" width="330" height="14" />
      <rect x="350" y="736" width="200" height="14" />
    </ContentLoader>
  );
};

export const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 327 660"
    >
      <rect x="0" y="5" width="230" height="40" />

      <rect x="0" y="75" width="300" height="40" rx="4" />
      <rect x="0" y="150" width="300" height="40" rx="4" />

      <rect x="0" y="200" width="220" height="14" />

      <rect x="0" y="550" width="100%" height="56" rx="4" />

      <rect x="0" y="636" width="200" height="14" />
      <rect x="205" y="636" width="200" height="14" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <SkeletonMobile />
    </MediaMatch>
  </>
);
