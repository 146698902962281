import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { SectionMaterialProps } from './SectionMaterial';

type ItemProps = Pick<SectionMaterialProps, 'isVideo'>;

export const Container = styled.div``;

const contentModifiers = {
  isVideo: () => css`
    ${media.lessThan('medium')`
      display: flex;
      flex-direction: row;
    `}
  `,
};

export const Content = styled.div<ItemProps>`
  ${({ theme, isVideo }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    ${media.lessThan('medium')`
      overflow-x: auto;
      width: calc(100vw - ${theme.spacing.sm});
      flex-wrap: nowrap;
      padding-bottom: ${theme.spacing.sm};
    `}

    ${isVideo && contentModifiers.isVideo()}
  `}
`;
