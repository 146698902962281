import { useEffect, useState } from 'react';
import { useEvent } from 'useCases/events';
import { useStreams } from 'useCases/streams';
import { useEventChanges } from 'useCases/useEventChanges';
import { dayInterval } from 'lib/contexts/date';
import { HomeWatchAgain } from 'pages/home';
import { useSettingsContext } from 'contexts';
import { EventSchedule, EventCardImage } from 'components/contexts/home';
import { Container } from 'components/structure';
import { EventStatus } from 'interfaces/event';
import { StreamState } from 'interfaces/stream';
import { Skeleton } from './Home.skeleton';
import * as S from './Home.styles';

export const Home = () => {
  const { setHasTranslationChange } = useSettingsContext();
  const { data: eventData } = useEvent();
  const [showEventFinish, setShowEventFinish] = useState(false);
  const [showWatchAgain, setShowWatchAgain] = useState(false);
  const [startDate, setStartDate] = useState('');
  useEffect(() => {
		setHasTranslationChange(true);
  }, [setHasTranslationChange]);

  const { data, isLoading } = useStreams(dayInterval());
	const [streams, setStreams ] = useState(data?.streams || [])
	useEventChanges({streams, setStreams});
	useEffect(() => {
		if(data?.streams){
			setStreams(data.streams)
		}
		console.log(data?.streams)
	}, [data])
  useEffect(() => {
    if (eventData?.event) {
      const { dates, status } = eventData?.event;
      const [firstEventDate] = dates ?? [];

      firstEventDate &&
        setShowWatchAgain(status === EventStatus.ShowWatchAgain);

      setShowEventFinish(status === EventStatus.Finished);
    }
  }, [eventData?.event]);
  // const session = useRealTimeSession()
  useEffect(() => {
    if (eventData?.event) {
      const { dates } = eventData.event;
      const [firstEventDate] = dates ?? [];
      if (firstEventDate && showWatchAgain) {
        setStartDate(firstEventDate);
      }
    }
  }, [eventData?.event, showWatchAgain]);

  const hasCardImage = () => {
    if (showEventFinish) return eventData?.event.watchAgainImageAfterEvent;

    if (
      EventStatus.ShowAwaitWatchAgain === eventData?.event.status ||
      EventStatus.ShowWatchAgain === eventData?.event.status
    )
      return eventData?.event.watchAgainImageDuringEvent;

    if (EventStatus.ShowBeforeStreams === eventData?.event.status)
      return eventData?.event.beforeStreamsImage;
  };

  const showStreams = () => EventStatus.ShowStreams === eventData?.event.status;

  if (isLoading) {
    return (
      <Container>
        <Skeleton />
      </Container>
    );
  }

  return (
    <Container>
      <S.Container>
        <S.Content>
          {hasCardImage() && (
            <EventCardImage
              title={eventData?.event.name}
              thumbnail={hasCardImage()}
              isWatchAgain={showWatchAgain}
              id={data?.streams[0].id}
            />
          )}
          {showStreams() && (
            <EventSchedule
              streams={
                streams.filter(
									// data?.streams?.filter(
                  (stream) => stream.state !== StreamState.WatchAgain,
                ) || []
              }
            />
          )}
          {showWatchAgain && startDate && (
            <HomeWatchAgain event={eventData?.event} startDate={startDate} />
          )}
        </S.Content>
      </S.Container>
    </Container>
  );
};
