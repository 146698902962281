export default {
  genericError: 'errors.generic',
  paramsDontMatch: (param: string) => `errors.${param}-dont-match`,
  requiredParam: (param: string) => `errors.required.${param}`,
  shortParam: (param: string) => `errors.${param}-to-short`,
  invalidParam: (param: string) => `errors.invalid.${param}`,
  validationError: 'errors.validation',
};

export const responseErrors = (error?: string) => `errors.${error}`;
