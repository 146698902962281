import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as TextFieldStyles from 'components/form/TextField/TextField.styles';

export const TermsContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.lg};
    ${media.greaterThan('large')`
       margin-bottom: 25px !important;
    `}
  `}
`;

export const Term = styled.span`
  ${({ theme }) => css`
    color: ${theme.text.secondary};
  `}
`;

export const ButtonTerms = styled.button`
  ${({ theme }) => css`
    text-decoration: underline;
    margin: 0 ${theme.spacing['2xs']};
  `}
`;

export const TextFieldNumberWatchOfPeople = styled.div`
  ${TextFieldStyles.Field} {
    padding: 10px 10px 0 20px;

    & + ${TextFieldStyles.Label} {
      opacity: 1;
      visibility: visible;
      top: 10px;
    }
  }
`;

export const LargeField = styled.div<{ isLarge: boolean }>`
  ${({ isLarge }) => css`
    ${media.greaterThan('large')`
      grid-column: ${isLarge ? '1 / -1' : 'initial'};
    `}
  `}
`;

export const DivCheckbox = styled.div`
  @media (min-width: 1584px) {
    margin-left: 10px;
  }
`;

export const ContainerButton = styled.div`
  width: 150px;
  @media ((max-width: 768px)) {
    width: 100%;
    margin-left: 10px;
  }
`;

export const CustomSelectForm = styled.div`
  @media (min-width: 1240px) {
    > div {
      height: 90px;
      > div {
        height: 90px;
        > div {
          height: 90px;
          > div {
            height: 90px;
          }
        }
      }
    }
  }
  @media (max-width: 1240px) {
    > div {
      height: 100px;
      > div {
        height: 100px;
        > div {
          height: 100px;
          > div {
            height: 100px;
          }
        }
      }
    }
  }
  @media (max-width: 980px) {
    > div {
      height: 120px;
      > div {
        height: 120px;
        > div {
          height: 120px;
          > div {
            height: 120px;
          }
        }
      }
    }
  }
  @media (max-width: 880px) {
    > div {
      height: 140px;
      > div {
        height: 140px;
        > div {
          height: 140px;
          > div {
            height: 140px;
          }
        }
      }
    }
  }
  @media (max-width: 800px) {
    > div {
      height: 160px;
      > div {
        height: 160px;
        > div {
          height: 160px;
          > div {
            height: 160px;
          }
        }
      }
    }
  }
  @media (max-width: 760px) {
    > div {
      height: 90px;
      > div {
        height: 90px;
        > div {
          height: 90px;
          > div {
            height: 90px;
          }
        }
      }
    }
  }
  @media (max-width: 690px) {
    > div {
      height: 100px;
      > div {
        height: 100px;
        > div {
          height: 100px;
          > div {
            height: 100px;
          }
        }
      }
    }
  }
  @media (max-width: 570px) {
    > div {
      height: 120px;
      > div {
        height: 120px;
        > div {
          height: 120px;
          > div {
            height: 120px;
          }
        }
      }
    }
  }
  @media (max-width: 510px) {
    > div {
      height: 140px;
      > div {
        height: 140px;
        > div {
          height: 140px;
          > div {
            height: 140px;
          }
        }
      }
    }
  }
  @media (max-width: 464px) {
    > div {
      height: 160px;
      > div {
        height: 160px;
        > div {
          height: 160px;
          > div {
            height: 160px;
          }
        }
      }
    }
  }
`;
