import { useTranslate } from 'hooks';
import { addPropsOnLevelList } from 'lib/contexts/rewards';
import { Paragraph } from 'components/structure';
import * as S from './IndicationProgress.styles';
import { ProgressBox } from './ProgressBox/ProgressBox';

export type LevelProps = {
  neededPoints: number;
  id: string;
  title: string;
  redeemedGift: boolean;
  giftLink?: string;
  hasProgressTop?: boolean;
  hasProgressBottom?: boolean;
};

export type IndicationProgressProps = {
  levels: LevelProps[];
  title: string;
  tip?: string;
  userPoints: number;
};

export const IndicationProgress = ({
  title,
  tip,
  levels,
  userPoints,
}: IndicationProgressProps) => {
  const translate = useTranslate();

  const levelsWithProps = addPropsOnLevelList(levels);

  return (
    <S.Wrapper>
      <Paragraph>
        {title}{' '}
        <strong>
          {userPoints} {translate('rewards.indicationProgress.points')}
        </strong>{' '}
        {translate('rewards.indicationProgress.byAppointment')}
      </Paragraph>

      <div>
        {levelsWithProps.map((level) => (
          <ProgressBox key={level.id} level={level} userPoints={userPoints} />
        ))}
      </div>

      {!!tip && (
        <span>
          {translate('rewards.indicationProgress.tip')}: {tip}
        </span>
      )}
    </S.Wrapper>
  );
};
