/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-target-blank */
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Params } from 'useCases/help-center';
import { useTranslate } from 'hooks';
import { SearchField } from 'components/form';
import { ErrorSearch, Heading, Icon } from 'components/structure';
import { Skeleton } from './MostWantedQuestions.skeleton';
import * as S from './MostWantedQuestions.styles';

export type Questions = {
  pt: {
    id?: string;
    question: string;
    answer: string;
  }[];
};

export type MostWantedQuestionsProps = {
  questions: Questions;
  handleNextPageOfFilter: (params: Params) => void;
  isLoading?: boolean;
};
const schema = Yup.object().shape({
  text: Yup.string(),
});
export const MostWantedQuestions = ({
  questions,
  // handleNextPageOfFilter,
  isLoading,
}: MostWantedQuestionsProps) => {
  const translation = useTranslate();
  const [collapseOpen, setCollapseOpen] = useState('');
  const [filter, setFilter] = useState('');
  const { register, watch } = useForm<{ text: string }>({
    resolver: yupResolver(schema),
  });
  const text = watch('text');

  // useEffect(() => {
  //   handleNextPageOfFilter({ text });
  // }, [handleNextPageOfFilter, text]);

  if (isLoading && !text) {
    return (
      <S.Wrapper>
        <S.QuestionsWrapper>
          <Skeleton />
        </S.QuestionsWrapper>
      </S.Wrapper>
    );
  }

  const answerURL = (answer: any) => {
    const mensagem = answer.split(' ').map((e: any) => {
      if (e.startsWith('https://') || e.startsWith('http://')) {
        return (
          <a
            style={{
              wordBreak: 'break-all',
              textDecoration: 'underline',
              color: 'white',
            }}
            href={e}
            target={'_blank'}
            rel={'noreferrer'}
          >
            {e}
          </a>
        );
      }

      if (e.startsWith('www') || e.includes('.com.br')) {
        return (
          <a
            style={{
              wordBreak: 'break-all',
              textDecoration: 'underline',
              color: 'white',
            }}
            href={`https://${e}`}
            target={'_blank'}
            rel={'noreferrer'}
          >
            {e}
          </a>
        );
      }

      return <> {e} </>;
    });

    return mensagem;
  };

  return (
    <S.Wrapper>
      <S.QuestionsWrapper>
        <Heading>{translation('helpCenter.subTitle')}</Heading>
        <S.QuestionSearchWrap>
          <SearchField
            placeholder={translation('labels.keyWordType')}
            ref={register}
            type="text"
            onChange={(e) => setFilter(e.target.value)}
            name="text"
          />
        </S.QuestionSearchWrap>
        <S.QuestionsWrap>
          {questions?.pt?.length
            ? questions?.pt
                ?.filter((i) =>
                  i.question.toLowerCase().includes(filter.toLowerCase()),
                )
                .map(({ question, answer }) => (
                  <S.QuestionCollapse
                    open={question === collapseOpen}
                    onClick={() =>
                      question === collapseOpen
                        ? setCollapseOpen('')
                        : setCollapseOpen(question)
                    }
                    key={question}
                  >
                    <S.QuestionCollapseWrap>
                      <span>{question}</span>
                      <Icon icon="IcChevron" />
                    </S.QuestionCollapseWrap>
                    <S.QuestionText open={question === collapseOpen}>
                      <>{answerURL(answer)}</>
                    </S.QuestionText>
                  </S.QuestionCollapse>
                ))
            : !!text && (
                <S.ErrorWrapper>
                  <ErrorSearch color="primary" />
                </S.ErrorWrapper>
              )}
        </S.QuestionsWrap>
      </S.QuestionsWrapper>
    </S.Wrapper>
  );
};
