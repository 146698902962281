import { useEffect } from 'react';
import { useConfirmRegistration } from 'useCases/auth';
import { useQueryString } from 'hooks';
import { Skeleton } from './ConfirmRegistration.skeleton';

type Params = {
  token?: string;
};

export const ConfirmRegistration = () => {
  const { handleConfirmRegistration } = useConfirmRegistration();
  const params = useQueryString() as Params;

  useEffect(() => {
    handleConfirmRegistration(params.token!);
  }, [handleConfirmRegistration, params.token]);

  return <Skeleton />;
};
