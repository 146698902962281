import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      width="331"
      height="875"
      viewBox="0 0 331 875"
    >
      <rect x="0" width="327" height="48" />
      <rect x="0" y="80" width="327" height="48" />
      <rect x="0" y="160" width="327" height="48" />
      <rect x="0" y="240" width="327" height="48" />
      <rect x="0" y="320" width="327" height="48" />
      <rect x="0" y="400" width="327" height="48" />
      <rect x="0" y="480" width="327" height="48" />
      <rect x="0" y="560" width="327" height="48" />

      <rect x="0" y="620" width="327" height="48" />
      <rect x="0" y="676" width="327" height="4" />
      <rect x="0" y="686" width="327" height="11" />

      <rect x="0" y="732" width="327" height="48" />

      <rect x="10" y="812" width="311" height="48" />
    </ContentLoader>
  );
};

const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 630 630"
    >
      <rect x="0" width="230" height="48" />
      <rect x="268" width="230" height="48" />

      <rect x="0" y="80" width="230" height="48" />
      <rect x="268" y="80" width="230" height="48" />

      <rect x="0" y="160" width="230" height="48" />
      <rect x="268" y="160" width="230" height="48" />

      <rect x="0" y="240" width="230" height="48" />
      <rect x="268" y="240" width="230" height="48" />

      <rect x="0" y="320" width="230" height="48" />
      <rect x="268" y="320" width="230" height="48" />

      <rect x="0" y="376" width="230" height="20" />

      <rect x="0" y="466" width="230" height="48" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <Container>
        <SkeletonMobile />
      </Container>
    </MediaMatch>
  </>
);
