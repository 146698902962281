import { THEME } from 'styles/themes';
import { ThemeOptions } from 'interfaces/theme';

//TODO: future events, change here
const options: ThemeOptions = {
  hasNotification: true,
  hasTranslationChange: true,
  hasPasswordStrength: false,
  hasInterests: false,
  hasCountry: true,
  hasJobArea: false,
  accreditation: {
    hasBtnSupport: false,
  },
  ...(THEME.EventOptions ?? {}),
};

export default options;
