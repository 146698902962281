import styled, { css } from 'styled-components';
import * as WatchAgainStyles from 'components/contexts/home/WatchAgain/WatchAgain.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    ${WatchAgainStyles.Wrapper} {
      margin-top: ${theme.spacing.sm};
    }
  `}
`;
