import { Icon } from 'components/structure';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { InteractionsTabs } from 'constants/enums';
import { GA_EVENTS } from 'constants/enums';
import { InteractiveTab } from './Interactions';

export const interactiveTabs: {
  [key: string]: InteractiveTab;
} = {
  networking: {
    icon: <Icon icon="IcAbout" />,
    title: 'interactions.header.networking',
    tabPosition: InteractionsTabs.networking,
    ga: {
      action: EventAction.click,
      label: GA_EVENTS.stream.labels.networking,
      category: EventCategory.Navigation,
    },
  },
  chat: {
    icon: <Icon icon="IcChat" />,
    title: 'interactions.header.chat',
    tabPosition: InteractionsTabs.chat,
    ga: {
      action: EventAction.click,
      label: GA_EVENTS.stream.labels.chat,
      category: EventCategory.Navigation,
    },
  },
  survey: {
    icon: <Icon icon="IcPoll" />,
    title: 'interactions.header.survey',
    tabPosition: InteractionsTabs.survey,
    ga: {
      action: EventAction.click,
      label: GA_EVENTS.stream.labels.survey,
      category: EventCategory.Navigation,
    },
  },
  offers: {
    icon: <Icon icon="IcHighlight" />,
    title: 'interactions.header.offers',
    tabPosition: InteractionsTabs.offers,
    ga: {
      action: EventAction.click,
      label: GA_EVENTS.stream.labels.offers,
      category: EventCategory.Navigation,
    },
  },
};

export const chatRoomMock = {
  id: '123',
  name: 'Chat Plenaria',
  avatar: 'https://i.pravatar.cc/300',
};
