/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

export function useDeviceActivity() {
  let timer: any = null;
  const seconds = 7;
  const timeout = seconds * 1000;
  useEffect(() => {
    // if (device !== 'desktop') {
    timer = setInterval(() => {
      const lastActivity = localStorage.getItem('lastActivity');
      if (lastActivity) {
        const diff = (new Date() as any) - (new Date(lastActivity) as any);
        if (diff >= timeout) {
          localStorage.removeItem('lastActivity');
          // return window.location.reload();
        }
      }
      localStorage.setItem('lastActivity', new Date().toISOString());
    }, 3000);
    return () => {
      localStorage.removeItem('lastActivity');
      clearInterval(timer);
    };
    // }
  }, []);
}
