import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    height: 48px;
    display: flex;
    max-width: 148px;
    position: relative;

    & + div {
      margin-left: ${theme.spacing.sm};
    }

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    &:hover {
      > article {
        opacity: 1;
        visibility: visible;
      }
    }
  `}
`;

export const AsideContent = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.xs};
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
      color: ${theme.text.secondary};
      width: 100%;
      display: -webkit-box;
      text-align: flex-end;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: ${theme.typography.sizes.sm};
    }
    small {
      color: ${theme.text.secondary};
      font-size: ${theme.typography.sizes.sm};
      margin-top: ${theme.spacing['2xs']};
    }
  `}
`;
