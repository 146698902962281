import { useCallback, useRef } from 'react';

const observerParams = {
  threshold: 0.5,
};

export const useInfiniteScroll = (
  isPending: boolean,
  hasMore: boolean,
  callback: () => void,
) => {
  const observerRef = useRef<IntersectionObserver>();

  const onIntersection = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      if (entry.isIntersecting && hasMore && !isPending) {
        callback();
      }
    },
    [callback, hasMore, isPending],
  );

  const lastItemRef = useCallback(
    (node) => {
      if (isPending || !node) return;

      if (observerRef.current) {
        observerRef.current.disconnect();
      }

      const observer = new IntersectionObserver(onIntersection, observerParams);

      observer.observe(node);
      observerRef.current = observer;
    },
    [isPending, onIntersection],
  );

  return lastItemRef;
};
