import ReactGA from 'react-ga';
import { AnalyticsEvent } from 'interfaces/analytics';

export const dispatchGAEvent = (
  { category, action, label }: AnalyticsEvent,
  callback?: () => void,
) => {
  callback && callback();
  ReactGA.event({
    category,
    action,
    label: label,
  });
};
