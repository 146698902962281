// import { useTheme } from 'hooks';
import { ThemeContext } from 'app.contexts';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
//import { THEME } from 'styles/themes';
//import { THEME } from 'styles/themes';
import { ROUTES } from 'constants/urls';
import * as S from './Logo.styles';

export type LogoProps = {
  url?: string;
  alt?: string;
  center?: boolean;
  large?: boolean;
  medium?: boolean;
  hideOnMobile?: boolean;
  light?: boolean;
};

export const Logo = ({
  center = false,
  large = false,
  medium = false,
  hideOnMobile = false,
  light = false,
  url,
  alt,
}: LogoProps) => {
  // const { options } = useTheme();
  const { eventTheme } = useContext(ThemeContext);
  const logo = eventTheme?.logo;

  return (
    <Link to={ROUTES.app.getLink('signIn')}>
      <S.Wrapper
        center={center}
        large={large}
        medium={medium}
        hideOnMobile={hideOnMobile}
        light={light}
      >
        {url ? (
          <img src={url} alt={alt} />
        ) : (
          //TODO: verify this
          // options?.logo ||
          //<THEME.Logo />
          <img src={logo} alt={alt} />
        )}
      </S.Wrapper>
    </Link>
  );
};
