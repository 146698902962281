import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useEventById } from 'useCases/events';
import { useUpdateUser, useUploadPhoto, useUserById } from 'useCases/users';
import {
  useEnv,
  useMapUserToBusinessCard,
  useSession,
  useTranslate,
} from 'hooks';
import { useBusinessCardContext, useModalContext } from 'contexts';
import { InterestsForm } from 'components/contexts/accreditation';
import { ChoosePhoto } from 'components/contexts/users';
import { Logo } from 'components/structure';
import { User } from 'interfaces/user';
import { ROUTES } from 'constants/urls';
import { Skeleton } from './Interests.skeleton';

type FormData = {
  allowChat?: boolean;
  userImage?: File;
};

export const Interests = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { id: userId } = useSession();
  const { eventId } = useEnv();
  const { setModalProps, setShowModal } = useModalContext();
  const { setShow, setCardProps, cardProps } = useBusinessCardContext();
  const { isPending: isUpdatePending, updateUser } = useUpdateUser();
  const userById = useUserById(userId!);
  const eventById = useEventById(eventId);
  const hasEmailConfirmation = eventById.data?.event.confirmationEmail;

  useMapUserToBusinessCard(userById.data?.user);

  const successModalKey = hasEmailConfirmation
    ? 'confirmationEmail'
    : 'almostThere';

  const onCloseLink = hasEmailConfirmation
    ? ROUTES.login.getLink('signIn')
    : ROUTES.app.getLink('home');

  const { isPending: isUploadPending, uploadPhoto } = useUploadPhoto();

  const { register, setValue, handleSubmit, reset } = useForm<FormData>({
    defaultValues: { ...userById.data?.user },
  });

  const handleOnImgPreviewChange = (image: string) =>
    setCardProps({ ...cardProps, image });

  const onSubmit = async (user: User) => {
    await updateUser(user);

    setModalProps({
      title: translate(`accreditation.${successModalKey}.title`),
      icon: <Logo center light />,
      buttonLabel: translate('labels.close'),
      noBackground: true,
      description: translate(`accreditation.${successModalKey}.description`),
      onClose: () => navigate(onCloseLink),
    });
    setShowModal(true);
  };

  useEffect(() => {
    reset({ ...userById.data?.user, allowChat: true });
  }, [userById.data?.user, reset]);

  useEffect(() => {
    setShow(true);

    return () => setShow(false);
  }, [setShow]);

  if (eventById.isLoading || userById.isLoading) {
    return <Skeleton />;
  }

  return (
    <>
      <ChoosePhoto
        setValue={setValue}
        isPending={isUploadPending}
        onSubmit={uploadPhoto}
        currentImage={userById.data?.user?.avatar}
        alt={userById.data?.user?.name}
        onImgPreviewChange={handleOnImgPreviewChange}
      />
      <InterestsForm
        register={register}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setValue={setValue}
        interests={eventById.data?.event.interests}
        isPending={isUpdatePending}
        selectedInterests={userById.data?.user?.interests}
        submitLabel={translate('labels.conclude')}
        showSupportButton={true}
      />
    </>
  );
};
