import { useCallback, useEffect, useState } from 'react';
import { useAPI, useFetch } from 'hooks';
import { appendUsersConnected } from 'lib/contexts/networking';
import { getNetworkingUrl } from 'lib/contexts/urls';
import { ChatParams } from 'interfaces/chat';
import { CHAT } from 'constants/endpoints';

export type Connections = {
  id: string;
  avatar: string;
  name: string;
  state?: string;
  country: {
    value: number;
    label: string;
  };
};

export type OnlineUsersAPI = {
  total?: number;
  connections: Connections[];
};

const USERS_CONNECTED_SIZE = 12;

export const useNetworking = () => {
  const api = useAPI();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [maxUsersConnectedPage, setMaxUsersConnectedPage] = useState(1);
  const [usersConnectedPage, setUsersConnectedPages] = useState(1);
  const [lastUsersConnectedUrl, setLastUsersConnectedUrl] = useState('');
  const [lastUsersParams, setLastUsersParams] = useState<
    Pick<ChatParams, 'name' | 'interests'>
  >({});

  const loadMoreUsers = useCallback(
    async (page) => {
      if (page === 1 || page > maxUsersConnectedPage) return;

      try {
        setIsLoadingMore(true);
        const { data } = await api.get<OnlineUsersAPI>(
          getNetworkingUrl(CHAT.ROOT, {
            ...lastUsersParams,
            size: USERS_CONNECTED_SIZE,
            page,
          }),
        );

        appendUsersConnected(lastUsersConnectedUrl, data.connections);
      } finally {
        setIsLoadingMore(false);
      }
    },
    [api, lastUsersConnectedUrl, maxUsersConnectedPage, lastUsersParams],
  );

  const useOnlineUsers = (params: ChatParams) => {
    const urlWithParams = getNetworkingUrl(CHAT.ROOT, {
      ...params,
      page: 1,
      size: USERS_CONNECTED_SIZE,
    });

    const usersConnected = useFetch<OnlineUsersAPI>(urlWithParams);

    useEffect(() => {
      setLastUsersConnectedUrl(urlWithParams);
      setLastUsersParams(params);
    }, [urlWithParams, params]);

    useEffect(() => {
      setUsersConnectedPages(1);
    }, [params]);

    useEffect(() => {
      const { total = USERS_CONNECTED_SIZE } = usersConnected.data ?? {};

      setMaxUsersConnectedPage(Math.ceil(total / USERS_CONNECTED_SIZE));
    }, [usersConnected.data]);

    return {
      ...usersConnected,
      isLoadingMore,
      hasMore: usersConnectedPage < maxUsersConnectedPage,
      loadMoreUsers: () => setUsersConnectedPages((prevValue) => prevValue + 1),
    };
  };

  useEffect(() => {
    loadMoreUsers(usersConnectedPage);
  }, [loadMoreUsers, usersConnectedPage]);

  return {
    useOnlineUsers,
  };
};
