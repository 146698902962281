import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { invalidParam, requiredParam } = messages;
// const dominio = 'tm1.com.br';
export const schema = Yup.object().shape({
  uuid: Yup.string()
    .trim()
    .email(invalidParam('email'))
    .required(requiredParam('uuidToContinue'))
    .lowercase()
    .test(
      'has-accented-characters',
      'O e-mail não pode conter acentos.',
      (value) => {
        const regex = /[À-ÿ]/;
        return !regex.test(value || '');
      },
    ),
  // .matches(
  //   new RegExp(`^[^@]+@${dominio}$`),
  //   `O email deve ser do domínio ${dominio}`,
  // ),
});

export const defaultValues = {
  uuid: '',
};
