import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

export const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 962 300"
    >
      <rect x="0" y="0" width="243" height="20" />
      <rect x="0" y="49" width="223" height="132" />
      <rect x="0" y="200" width="177" height="13" />
    </ContentLoader>
  );
};

export const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 327 450"
    >
      <rect x="0" y="0" width="244" height="19" />
      <rect x="0" y="38" width="132" height="74" />
      <rect x="151" y="45" width="178" height="13" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <SkeletonMobile />
    </MediaMatch>
  </>
);
