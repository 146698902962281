import { useEffect } from 'react';
import { useEventById } from 'useCases/events';
import {
  useRankings,
  UserGetMember,
  useUserGetMember,
} from 'useCases/memberGetMember';
import { useEnv, useTranslate } from 'hooks';
import { todayIsTheDay } from 'hooks/useCountdown';
import { ThemeCustomProvider, useSettingsContext } from 'contexts';
import {
  IndicationProgress,
  Ranking,
  Share,
} from 'components/contexts/rewards';
import { Heading } from 'components/structure';
import * as UserCountdownTheme from 'styles/overrides/UserCountdown.theme';
import { Skeleton } from './Rewards.skeleton';
import * as S from './Rewards.styles';

export const Rewards = () => {
  const translate = useTranslate();
  const { setHasInteraction, setHasNotification } = useSettingsContext();
  const { eventId } = useEnv();
  const { data: event, isLoading: isEventLoading } = useEventById(eventId);
  const { data: rankings, isLoading: isRankingLoading } = useRankings();
  const {
    data: userGetMember = {} as UserGetMember,
    isLoading: isUserGetMemberLoading,
  } = useUserGetMember();

  const isToday = todayIsTheDay(event?.event.dates[0]);

  useEffect(() => {
    setHasInteraction(false);
    setHasNotification(false);

    return () => {
      setHasInteraction(false);
      setHasNotification(false);
    };
  }, [setHasInteraction, setHasNotification]);

  const isLoading =
    isUserGetMemberLoading || isRankingLoading || isEventLoading;

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <ThemeCustomProvider {...UserCountdownTheme}>
      <S.Wrapper>
        <Heading>{translate('navbar.userMenu.rewards')}</Heading>
        <S.Content>
          {!isToday && (
            <Share
              title={translate('accreditation.userCountdown.shareTitle')}
              userLink={userGetMember?.inviteLink}
            />
          )}
          <IndicationProgress
            title={translate('rewards.indicationProgress.receivedTitle')}
            levels={userGetMember?.levels ?? []}
            userPoints={userGetMember?.userPoints}
          />
          {!!rankings?.items?.length && (
            <Ranking
              title={translate('rewards.ranking.title')}
              rankedUsers={rankings.items}
            />
          )}
        </S.Content>
      </S.Wrapper>
    </ThemeCustomProvider>
  );
};
