import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      width="331"
      height="875"
      viewBox="0 0 331 875"
    >
      <rect x="0" width="202" height="21" />

      <rect x="0" y="46" width="106" height="40" rx="24" />
      <rect x="114" y="46" width="106" height="40" rx="24" />
      <rect x="228" y="46" width="52" height="40" rx="24" />

      <rect x="0" y="102" width="189" height="40" rx="24" />
      <rect x="197" y="102" width="78" height="40" rx="24" />

      <rect x="0" y="158" width="140" height="40" rx="24" />
      <rect x="148" y="158" width="103" height="40" rx="24" />

      <rect x="0" y="214" width="133" height="40" rx="24" />
      <rect x="141" y="214" width="87" height="40" rx="24" />

      <rect x="0" y="270" width="120" height="40" rx="24" />
      <rect x="128" y="270" width="78" height="40" rx="24" />
      <rect x="212" y="270" width="98" height="40" rx="24" />

      <rect x="0" y="326" width="130" height="40" rx="24" />
      <rect x="136" y="326" width="176" height="40" rx="24" />

      <rect x="0" y="382" width="259" height="40" rx="24" />

      <rect x="0" y="464" width="154" height="32" />

      <rect x="0" y="504" width="24" height="24" />
      <rect x="32" y="504" width="496" height="21" />

      <rect x="32" y="535" width="80" height="12" />

      <rect x="0" y="615" width="160" height="48" />
    </ContentLoader>
  );
};

const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 700 500"
    >
      <rect x="0" width="202" height="21" />

      <rect x="0" y="46" width="106" height="40" rx="24" />
      <rect x="114" y="46" width="106" height="40" rx="24" />
      <rect x="228" y="46" width="212" height="40" rx="24" />
      <rect x="448" y="46" width="197" height="40" rx="24" />

      <rect x="0" y="102" width="106" height="40" rx="24" />
      <rect x="112" y="102" width="218" height="40" rx="24" />
      <rect x="338" y="102" width="82" height="40" rx="24" />
      <rect x="428" y="102" width="186" height="40" rx="24" />

      <rect x="0" y="158" width="122" height="40" rx="24" />
      <rect x="130" y="158" width="220" height="40" rx="24" />
      <rect x="358" y="158" width="147" height="40" rx="24" />
      <rect x="513" y="158" width="62" height="40" rx="24" />

      <rect x="0" y="214" width="143" height="40" rx="24" />
      <rect x="151" y="214" width="120" height="40" rx="24" />
      <rect x="279" y="214" width="187" height="40" rx="24" />

      <rect x="0" y="310" width="154" height="32" />

      <rect x="0" y="350" width="24" height="24" />
      <rect x="32" y="350" width="496" height="21" />

      <rect x="32" y="381" width="80" height="12" />

      <rect x="0" y="461" width="160" height="48" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <SkeletonMobile />
    </MediaMatch>
  </>
);
