import { forwardRef, Ref } from 'react';
import { Avatar, AvatarProps } from 'components/contexts/users';
import * as S from './Chip.styles';

export type ChipProps = {
  id: string;
  icon?: JSX.Element | null;
  name: string;
  description?: string;
  avatar?: string;
  hasDot?: boolean;
  isCenter?: boolean;
  isClickable?: boolean;
  onClick?: (id: string) => void;
} & Pick<AvatarProps, 'size'>;

export const Chip = forwardRef((props: ChipProps, ref: Ref<HTMLDivElement>) => {
  const {
    id,
    icon,
    name,
    avatar,
    description,
    hasDot = false,
    isCenter = false,
    isClickable = false,
    size = 'small',
    onClick = () => undefined,
  } = props;

  return (
    <S.Wrapper
      ref={ref}
      role="button"
      onClick={() => onClick(id)}
      hasDot={hasDot}
      isCenter={isCenter}
      isClickable={isClickable}
    >
      <Avatar icon={icon} src={avatar} alt={name} size={size} />
      <S.Name>{name}</S.Name>
      <S.Description>{description}</S.Description>
    </S.Wrapper>
  );
});
