import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

const TO_MILLISECONDS = 60000;

// TODO: WIP ideia de utilizar em outros lugares
export const dayJs = dayjs;

export const dateFormatHandler = (date: string) =>
  new Date(date).toISOString().split('T')[0];

export const dateWithTz = (date = new Date()) => {
  const tzOffset = date.getTimezoneOffset() * TO_MILLISECONDS;
  return new Date(Date.now() - tzOffset);
};

export const substractDays = (days: number, date: Date | string = new Date()) =>
  dayjs(date).utc().subtract(days, 'days').toDate();

export const hasDateGreaterThanToday = (dates: Array<string>) => {
  const dateCurrent = dateWithTz();
  return dates.some((date) => dateWithTz(new Date(date)) > dateCurrent);
};

export const dayInterval = () => {
  const date = dateWithTz();

  return datesInterval(date, date);
};

export const datesInterval = (
  startDate: Date,
  endDate: Date,
): { startDate: string; endDate: string } => ({
  startDate: `${dayjs(startDate).format('YYYY-MM-DDT00:00:00')}`,
  endDate: `${dayjs(endDate).format('YYYY-MM-DDT23:59:59')}`,
});

export const getLocalHourFromDate = (date: string, local?: string) => {
  const dateLocal = new Date(date).toLocaleTimeString(local);
  return dateLocal;
};

export const removeSecondsFromHour = (time: string) =>
  time.replace(/(.*)\D\d+/, '$1');

// export const hoursIntervalWithoutSeconds = (
//   startHour: string,
//   endHour: string,
//   local?: string,
//   isPlenaryTime?: boolean,
// ) =>
//   isPlenaryTime
//     ? `${removeSecondsFromHour(
//         getLocalHourFromDate(startHour, local),
//       )} | ${removeSecondsFromHour(getLocalHourFromDate(endHour, local))}`
//     : `${removeSecondsFromHour(getLocalHourFromDate(startHour, local))}`;

// CODE with START and END dates on schedule page
export const hoursIntervalWithoutSeconds = (
  startHour: string,
  endHour: string,
  local?: string,
  isPlenaryTime?: boolean,
) =>
  `${removeSecondsFromHour(
    getLocalHourFromDate(startHour, local),
  )} - ${removeSecondsFromHour(getLocalHourFromDate(endHour, local))}`;
