import { useTheme, useTranslate } from 'hooks';
import { Icon, IconsKeys } from 'components/structure';
import * as S from './EmptySearch.styles';

export type EmptySearchProps = {
  title: string;
  icon: IconsKeys;
};

export const EmptySearch = ({ title, icon }: EmptySearchProps) => {
  const translate = useTranslate();
  const { colors } = useTheme();

  return (
    <S.EmptyState data-testid="empty-state">
      <Icon icon={icon} color={'white'} />
      <p>{translate(title)}</p>
    </S.EmptyState>
  );
};
