import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactions } from 'useCases/reactions';
import { useSession } from 'hooks';
import { AnimatedIcon } from 'components/structure';
import * as S from './FloatingHeart.styles';

export type FloatProps = {
  position?: string;
};

export const FloatingHeart = ({ position }: FloatProps) => {
  const { pathname } = useLocation();
  const { id: userId } = useSession();
  const streamId = pathname.split('/')[2];
  const {
    handleSendReaction,
    reactions,
    setReactions,
    handleReceiveReaction,
  } = useReactions({
    streamId: streamId,
  });

  const removeReactions = useCallback(
    (id: string) => {
      setReactions((state) => state.filter((reaction) => reaction.id !== id));
    },
    [setReactions],
  );
  useEffect(() => {
    handleReceiveReaction();
  }, [handleReceiveReaction]);
  return (
    <S.Container>
      <S.LikeIconStyled
        icon="IcLike"
        onClick={() =>
          handleSendReaction({
            eventId: streamId,
            userId: userId,
            reactType: 'like',
          })
        }
      />
      <S.LikeIconStyled
        icon="IcHappy"
        onClick={() =>
          handleSendReaction({
            eventId: streamId,
            userId: userId,
            reactType: 'happy',
          })
        }
      />
      <S.LikeIconStyled
        icon="IcIloved"
        onClick={() =>
          handleSendReaction({
            eventId: streamId,
            userId: userId,
            reactType: 'loved',
          })
        }
      />
      <S.LikeIconStyled
        icon="IcSuprise"
        onClick={() =>
          handleSendReaction({
            eventId: streamId,
            userId: userId,
            reactType: 'suprise',
          })
        }
      />

      {reactions.map((value) => (
        <AnimatedIcon
          reactType={value.reactType}
          key={value.id}
          onAnimationComplete={() => removeReactions(value.id)}
          position={position}
        />
      ))}
    </S.Container>
  );
};
