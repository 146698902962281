export enum EventCategory {
  Navigation = 'navigation',
  Register = 'register',
  Stream = 'stream',
  Home = 'home',
  Chat = 'chat',
  Schedule = 'schedule',
  Materials = 'materials',
  Coffee = 'coffee',
}

export enum EventAction {
  click = 'click',
}

export type AnalyticsEvent = {
  action: EventAction;
  category: EventCategory;
  label: string;
};
