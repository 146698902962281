import { createContext, useContext, useState } from 'react';
import { WithChildren } from 'interfaces/children';
import { SurveyState } from 'constants/enums';

type AlternativesType = {
  id: string;
  language: string;
  text: string;
};

type QuestionType = {
  language: string;
  text: string;
  type?: string;
};

type AlternativesResultType = {
  count: number;
  id: string;
  percent: number;
};

type ResultType = {
  alternatives: AlternativesResultType[];
  total: number;
};

type SurveyType = {
  id: string;
  alternatives: AlternativesType[];
  question: QuestionType;
  result?: ResultType;
};

type ContextType = {
  survey: SurveyType;
  setSurvey: (state: SurveyType) => void;
  surveyState: SurveyState;
  setSurveyState: (state: SurveyState) => void;
  setAwaitingResult: (awaitingResult: boolean) => void;
  awaitingResult: boolean;
};

const defaultValue: ContextType = {
  survey: {} as SurveyType,
  setSurvey: () => undefined,
  surveyState: SurveyState.notAvailable,
  setSurveyState: () => undefined,
  awaitingResult: false,
  setAwaitingResult: () => undefined,
};

export const SurveyContext = createContext<ContextType>(defaultValue);

export const SurveyProvider = ({ children }: WithChildren) => {
  const [survey, setSurvey] = useState<SurveyType>({} as SurveyType);
  const [surveyState, setSurveyState] = useState(defaultValue.surveyState);

  const [awaitingResult, setAwaitingResult] = useState(
    defaultValue.awaitingResult,
  );
  return (
    <SurveyContext.Provider
      value={{
        survey,
        setSurvey,
        surveyState,
        setSurveyState,
        awaitingResult,
        setAwaitingResult,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

export const useSurveyContext = () => useContext(SurveyContext);

SurveyContext.displayName = 'SurveyContext';
