import styled, { css, DefaultTheme } from 'styled-components/macro';
import media from 'styled-media-query';
import * as TypographyStyles from 'components/structure/Typography/Typography';

export const Form = styled.form`
  ${({ theme }) => css`
    font-size: 12px;
    display: flex;
    flex-direction: column;
    //height: 100%;
    flex: 1;

    ${TypographyStyles.Heading} {
      margin-bottom: ${theme.spacing.xs};
    }

    ${TypographyStyles.Paragraph} {
      opacity: 0.3;
      max-width: 405px;
      margin-bottom: ${theme.spacing.lg};
    }

    ${media.lessThan('large')`
      padding-bottom: ${theme.spacing['4xl']};
    `}
  `}
`;

type FormGroupProps = {
  half?: boolean;
  hasMargin?: boolean;
  xlMargin?: boolean;
  lgMargin?: boolean;
};

const formGroupModifiers = {
  half: () => css`
    width: 50%;

    ${media.lessThan('medium')`
      width: 100%;
    `}
  `,
  withMargin: (theme: DefaultTheme) => css`
    margin-bottom: ${theme.spacing['md']};

    ${media.lessThan('medium')`
      margin-bottom: ${theme.spacing.lg};
    `}
  `,
  xlMargin: (theme: DefaultTheme) => css`
    margin-bottom: ${theme.spacing.xl};
  `,
  lgMargin: (theme: DefaultTheme) => css`
    margin-bottom: ${theme.spacing.lg};
  `,
};

export const FormGroup = styled.div<FormGroupProps>`
  ${({
    theme,
    half = false,
    hasMargin = true,
    xlMargin = false,
    lgMargin = false,
  }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-gap: ${theme.spacing.lg};

    ${media.lessThan('small')`
      grid-template-columns: 1fr;
    `}

    ${half && formGroupModifiers.half()};
    ${hasMargin && formGroupModifiers.withMargin(theme)};
    ${xlMargin && formGroupModifiers.xlMargin(theme)};
    ${lgMargin && formGroupModifiers.lgMargin(theme)};
  `}
`;

export const FormActions = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: ${theme.spacing.md} ${theme.spacing.lg};
    margin-top: auto;

    ${media.greaterThan('large')`
      &:not(:last-child) {
        margin-bottom: ${theme.spacing.lg};

      }
    `}

    ${media.lessThan('medium')`
      background-color: ${theme.background};
      grid-template-columns: repeat(1, 1fr);
      position: fixed;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      padding: ${theme.spacing.sm} ${theme.spacing.md} 20px;
      width: 100%;




      &::after {
        position: absolute;
        left: 0;
        top: -${theme.spacing.md};
        display: block;
        height: 24px;
        width: 100%;
        content: '';
      }
    `}
  `}
`;

export const TrickSpace = styled.span`
  ${media.lessThan('large')`
    display: none;
  `}
`;
