/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { yupResolver } from '@hookform/resolvers/yup';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { ConsoleView, isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { responseInterface } from 'swr';
import * as Yup from 'yup';
import { dispatchGAEvent } from 'services/google';
import { useChat } from 'useCases/chat';
import { useLikes } from 'useCases/likes';
import { useUserByUuid } from 'useCases/users';
import {
	useInfiniteScroll,
	usePortal,
	useSession,
	useTransformMessages,
	useTranslate,
	useClickOutside,
} from 'hooks';
import { useCountries } from 'hooks/useCountries';
import { useIsChatInputFocused } from 'hooks/useIsChatInputFocused';
import { useChatContext, useNotificationContext } from 'contexts';
import {
	Message,
	PlenaryIcon,
	UserChat,
} from 'components/contexts/interactions';
import { ConversationMessage } from 'components/contexts/interactions/Chat';
import { Chip, UserCard } from 'components/contexts/users';
import { ButtonClose } from 'components/contexts/users/CropPhoto/CropPhoto.styles';
import { Spinner, Icon, Modal, Button } from 'components/structure';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { LatestMessages } from 'interfaces/chat';
import { InteractionsTabs, BRAZIL_COUNTRY_CODE } from 'constants/enums';
import {
	ChatTypes,
	GA_EVENTS,
	CHAT_FIELD_MAX_LENGTH,
	NotificationTypes,
} from 'constants/enums';
import { Skeleton } from './Conversation.skeleton';
import * as S from './Conversation.styles';

export const schema = Yup.object().shape({
	uuid: Yup.string().max(CHAT_FIELD_MAX_LENGTH),
});

type LatestMessagesReturn = responseInterface<LatestMessages, unknown> & {
	isLoading: boolean;
	isLoadingMore: boolean;
	hasMore: boolean;
	loadMoreMessages: () => void;
};

export type ConversationProps = {
	room: UserChat;
	onClick?: () => void;
	handleSendMessage: (
		message: string,
		mentions?: any[],
		customMentions?: any[],
	) => void;
	useLatestMessages: (roomId: string) => LatestMessagesReturn;
	error?: string;
	isOpen?: boolean;
};

type FormData = {
	message: string;
	mentions?: string;
};
interface UseCard {
	name: string;
	userId?: string;
	avatar?: string;
	email?: string;
	country?: any;
	company?: string;
	state?: string;
}
interface deleteMessage {
	id: string;
}
export const Conversation = memo(
	({
		room,
		error,
		onClick,
		handleSendMessage,
		useLatestMessages,
		isOpen,
	}: ConversationProps) => {
		const { handleJoinPrivateChannel, handleDeleteMessage } = useChat();
		const { pathname } = useLocation();
		const { id: userIdSession } = useSession();
		// const [inputRef, setInputRef] = useState<any>(null);
		const inputRef = useRef<HTMLInputElement | null>();
		const [isLiked, setIsLiked] = useState(false);
	const blurRef = useRef(null);

		const streamId = pathname.split('/')[2];
		const translate = useTranslate();
		const Portal = usePortal();
		const [isLastMessageVisible, setIsLastMessageVisible] = useState(true);
		const { getUseCountryLabel, getUseStateLabel } = useCountries();
		const messageRef = useRef<any>(null);
		const device = localStorage.getItem('checkDevice');

		const {isFocused} = useIsChatInputFocused()



		const theme = useTheme();

		const [showError, setShowError] = useState(false);
		const {
			isLoading,
			isLoadingMore,
			hasMore,
			loadMoreMessages,
			data,
		} = useLatestMessages(room.id);
		const [modal, setModal] = useState<UseCard>({
			name: '',
			userId: '',
			avatar: '',
			email: '',
			country: '',
		});
		const [deleteMessage, setDeleteMessage] = useState<deleteMessage>({
			id: '',
		});

		const clickOutSideRef = useClickOutside(() =>
			setModal({ name: '', userId: '', avatar: '', email: '' }),
		);
		const { plenary, showRoom, setRoom, setShowRoom } = useChatContext();
		const messages = useTransformMessages(data?.messages);
		const {
			handleReceiveReaction,
			handleSendReaction,
			likes: realTimeLikes,
			setLikes,
		} = useLikes(streamId);
		const [likeUpdateNeeded, setLikeUpdateNeeded] = useState(false);
		const [receivedFirstLikeUpdate, setFirstLikeUpdate] = useState(false);
		const testref = useRef<any>(null);

		const lastMessageRef = useInfiniteScroll(
			isLoadingMore,
			hasMore,
			loadMoreMessages,
		);
		useEffect(() => {
			const observer = new IntersectionObserver(
				async ([entry]) => {
					if (entry.isIntersecting) {
						setIsLastMessageVisible(true);
					} else {
						setIsLastMessageVisible(false);
					}
				},
				{ rootMargin: '0px', threshold: 0.05 },
			);
			if (messageRef.current) {
				observer.observe(messageRef.current);
			}
			return () => {
				if (testref.current) {
					observer.unobserve(messageRef.current);
				}
			};
		}, [messages, messageRef]);
		const { handleSubmit, register, setValue, getValues } = useForm<FormData>({
			resolver: yupResolver(schema),
		});
		const focus = !isMobile;

		const [alreadySaved, setAlreadySaved] = useState(false);
		useEffect(() => {
			if (messages.length > 0 && !alreadySaved) {
				setLikes(
					messages
						.map((msg) => msg.likes?.content)
						.reduce((prev: any, curr: any) => [...prev, ...(curr || [])], []),
				);
				setAlreadySaved(true);
			}
			if (likeUpdateNeeded) {
				setLikes(
					[
						...realTimeLikes,
						...messages
							.map((msg) => msg.likes?.content)
							.reduce(
								(prev: any, curr: any) => [...prev, ...(curr || [])],
								[],
							)!,
					].filter(
						(item, index, arr) =>
							arr.findIndex((v) => v.id === item.id) === index,
					),
				);
				setLikeUpdateNeeded(false);
				setFirstLikeUpdate(true);
			}
			if (!receivedFirstLikeUpdate) {
				setLikes(
					[
						...messages
							.map((msg) => msg.likes?.content)
							.reduce(
								(prev: any, curr: any) => [...prev, ...(curr || [])],
								[],
							)!,
					].filter(
						(item, index, arr) =>
							arr.findIndex((v) => v.id === item.id) === index,
					),
				);
				setFirstLikeUpdate(true);
			}
			if (messageRef.current && isLastMessageVisible && messages.length > 0) {
				if (device === 'iPhone' && !isFocused) {
					setTimeout(() => {
						messageRef?.current?.scrollIntoView({
							behavior: 'smooth',
							block: 'end',
							inline: 'nearest',
						});
					}, 100);
				} else {
					messageRef?.current?.scrollIntoView();
				}
			}
		}, [messages, messageRef]);
		useEffect(() => {
			if (isLoadingMore) {
				setLikeUpdateNeeded(true);
			}
		}, [isLoadingMore]);
		useEffect(() => {
			handleReceiveReaction();
		}, [handleReceiveReaction]);
		const notification = useNotificationContext();
		const [slowMode, setSlowMode] = useState(false);
		const getMessageRef = (position: number) =>
			position === messages?.length - 1 ? lastMessageRef : null;
		useEffect(() => {
			(async () => {
				if (slowMode) {
					await new Promise((resolve) => setTimeout(resolve, 1500));
					setSlowMode(false);
				}
			})();
		}, [slowMode]);

		const onSubmit = useCallback(
			(data: FormData) => {
				(messageRef as any).current?.scrollIntoView();
				if (device === 'iPhone' && inputRef?.current) {
					inputRef.current.blur();
				}
				if (slowMode) {
					setShowError(true);
					setTimeout(() => {
						setShowError(false);
					}, 3500);
					return;
				}
				if (data.message) {
					const serializedMentions: any[] = JSON.parse(data?.mentions || '');
					if (!data.message.includes(serializedMentions[0]?.username)) {
						data.mentions = '[]';
					}
					serializedMentions.forEach((sM) => {
						if (!data.message.includes(sM?.username)) {
							data.mentions = JSON.stringify(
								(JSON.parse(data.mentions || '[]') as any[]).filter(
									(m) => m.username != sM.username,
								),
							);
						}
					});
					handleSendMessage(data.message, serializedMentions);
					setSlowMode(true);

					setValue('message', '');
				}

				dispatchGAEvent({
					category: EventCategory.Chat,
					action: EventAction.click,
					label: GA_EVENTS.chat.labels.sendMessage,
				});
			},
			[handleSendMessage, setValue, slowMode],
		);

		useEffect(() => {
			if (error) {
				setShowError(true);

				setTimeout(() => {
					setShowError(false);
				}, 5000);
			}
		}, [error]);

		if (isLoading) {
			return <Skeleton />;
		}

		const handlePrivateChat = async (id: string) => {
			// handleOpenPrivateChat(id);
			setRoom(undefined);
			// await new Promise(resolve => setTimeout(resolve, 0));
			setTimeout(() => {
				handleJoinPrivateChannel(id);
			}, 0);
			// setShowRoom(true);
		};
		const DeleteMessageByStaff = (id: string) => {
			handleDeleteMessage(id);
			setDeleteMessage({ id: '' });
		};

		return (
			<>
				<S.Header>
					<S.ReturnButton type="button" onClick={onClick}>
						<Icon color={theme.primary} icon="IcChevron" />
					</S.ReturnButton>
					<Chip
						id={room.id}
						name={room.name}
						avatar={room.avatar}
						icon={room.isPrivate ? null : <PlenaryIcon size="small" />}
						isCenter
					/>
				</S.Header>
				{modal.name != '' && modal.userId != '' && (
					<S.Modal>
						<UserCard
							handlePrivateChat={async () => {
								await handlePrivateChat(modal.userId || '');
								dispatchGAEvent({
									action: EventAction.click,
									category: EventCategory.Chat,
									label: GA_EVENTS.chat.labels.talk,
								});
							}}
							handleCloseCard={() =>
								setModal({
									name: '',
									userId: '',
									avatar: '',
									email: '',
									country: '',
									state: '',
								})
							}
							user={{
								id: modal.userId,
								name: modal.name,
								avatar: modal.avatar,
								email: modal.userId === userIdSession ? '' : modal.email,
								country:
									(modal.state ? getUseStateLabel(modal.state) + ', ' : '') +
									getUseCountryLabel(Number(modal.country)),
								company: modal.company,
								state: modal.state,
							}}
							forwardRef={modal.userId ? clickOutSideRef : null}
							isChatDisabled={modal.userId === userIdSession}
						/>
					</S.Modal>
				)}
				{deleteMessage?.id != '' && (
					<S.Modal>
						<S.DeleteMessage>
							<S.ButtonClose
								type="button"
								onClick={() => setDeleteMessage({ id: '' })}
							>
								<Icon
									icon="IcClose"
									width="16px"
									aria-label={translate('labels.close')}
								/>
							</S.ButtonClose>
							<S.tittle>
								Você tem certeza que deseja deletar a messagem ?
							</S.tittle>

							<S.BoxButton>
								<button
									className="buttonDelete button"
									onClick={() => DeleteMessageByStaff(deleteMessage?.id)}
								>
									Deletar
								</button>
								<button
									className="buttonCancel button"
									onClick={() => setDeleteMessage({ id: '' })}
								>
									Cancelar
								</button>
							</S.BoxButton>

							{/* {deleteMessage.id} */}
						</S.DeleteMessage>
					</S.Modal>
				)}
				<S.Messages device={device || ''}>
					{messages?.map(
						(
							{
								avatar,
								name,
								message,
								country,
								company,
								email,
								state,
								id,
								received,
								isSended,
								isStaff,
								createdAt,
								likes,
								mentions,
								userId,
							},
							index,
						) => (
							<>
								<Message
									setUserCard={setModal}
									handleDeleteMessage={handleDeleteMessage}
									handleOpenPrivateChat={() => userId}
									setTab={() => InteractionsTabs.chat}
									ref={getMessageRef(index)}
									messageRef={index === 0 ? messageRef : null}
									id={id}
									key={id}
									userIdTest={userId}
									avatar={avatar}
									name={name}
									email={email}
									company={company}
									country={country}
									state={state}
									createdAt={createdAt}
									message={message}
									received={received}
									isSended={isSended}
									isPrivate={room.id.startsWith(ChatTypes.PRIVATE)}
									isStaff={isStaff}
									likes={likes}
									mentions={mentions}
									currentUserId={userIdSession}
									handleReceiveReaction={handleReceiveReaction}
									handleSendReaction={handleSendReaction}
									realTimeLikes={realTimeLikes?.filter(
										(l: any) => l.messageId == id,
									)}
									setLikes={setLikes}
									userId={userId}
									streamId={streamId}
									isLastMessage={index == 0}
								/>
							</>
						),
					)}
					{isLoadingMore && (
						<S.SpinnerWrapper>
							<Spinner />
						</S.SpinnerWrapper>
					)}
				</S.Messages>
				{isMobile && isOpen && (
					<>
					<Portal>
						<ConversationMessage
							showError={showError}
							error={
								error?.length
									? error
									: 'Você deve esperar antes de enviar a próxima mensagem'
							}
							blurRef={blurRef}
							inputRef={inputRef as any}
							focus={false}
							handleSubmit={handleSubmit(onSubmit)}
							setValue={setValue}
							getValues={getValues}
							placeholder={translate('interactions.typeHere')}
							register={register}
						/>
					</Portal>
				<input ref={blurRef} hidden />
					</>

				)}
				{!isMobile && (
					<ConversationMessage
						showError={showError}
						error={
							error?.length
								? error
								: 'Você deve esperar antes de enviar a próxima mensagem'
						}
						focus={focus}
						handleSubmit={handleSubmit(onSubmit)}
						setValue={setValue}
						getValues={getValues}
						placeholder={translate('interactions.typeHere')}
						register={register}
						inputRef={inputRef as any}
					/>
				)}
				<input ref={blurRef} hidden />
			</>
		);
	},
);
