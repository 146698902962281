import { ThemeContext } from 'app.contexts';
import { useContext } from 'react';
import { Icon } from 'components/structure';
import * as S from './FooterPoweredBy.styles';
export type FooterPoweredByProps = {
  fixed?: boolean;
};
export const FooterPoweredBy = ({ fixed = false }: FooterPoweredByProps) => {
  const { themeChosen } = useContext(ThemeContext);

  return (
    <S.Container fixed={fixed}>
      <Icon
        icon={
          themeChosen === 'dark' ? 'IcPoweredByTm1Dark' : 'IcPoweredByTm1Light'
        }
      />
    </S.Container>
  );
};
