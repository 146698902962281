import { Language } from 'contexts';

// TODO clean up e refactor enums
export const GA_EVENTS = {
  navBar: {
    labels: {
      home: 'menu-transmissao',
      schedule: 'menu-agenda',
      interactions: 'menu-interacoes',
      expo: 'menu-tour-virtual',
      materials: 'menu-materiais',
      reimagineVideo: 'reimagine-video',
      profile: 'editar-perfil',
      termOfUse: 'termos-de-uso',
      privacyPolicy: 'politicas-de-privacidade',
      faq: 'faq',
      exit: 'sair',
      support: 'suporte',
      rewards: 'rewards',
      certificate: 'certificate',
      sponsors: 'menu-patrocinadores',
    },
  },
  stream: {
    labels: {
      selectedStream: (dateOfStream: string) => `plenaria:${dateOfStream}`,
      selectedAudio: (language: string, hour?: string) =>
        `plenaria:${hour || '18h00 - 20h00'}-audio:${language}`,
      about: 'about',
      survey: 'enquete',
      chatStream: 'chat-plenaria',
      chat: 'chat',
      networking: 'networking',
      offers: 'offers',
    },
  },
  chat: {
    labels: {
      talk: 'conversar',
      sendMessage: 'enviar-mensagem',
    },
  },
  materials: {
    labels: {
      selectedMaterial: (nameOfMaterial: string) =>
        `material:${nameOfMaterial}`,
    },
  },
  map: {
    labels: {
      map: 'mapa',
    },
  },
  support: {
    labels: {
      support: 'suporte',
    },
  },
  coffee: {
    labels: {
      wallpaperCard: 'fundo-de-tela',
      filter: 'filtro',
      wordsCloud: 'nuvem-de-palavras',
    },
  },
} as const;

export const DEFAULT_LANGUAGE = 'pt' as Language;

export const LANGUAGE_KEY = 'language';

export const POSSIBLE_LANGUAGES = ['en', 'pt'];
export const CHAT_FIELD_MAX_LENGTH = 240;

export const BRAZIL_COUNTRY_CODE = 76;

export const CHAT_DISABLED_COUNTDOWN_TIME = 10;

export const CF_CODE = 'PP-XAR-BR-0801-1 | PP-XAR-MX-0965-1';

export const LINKEDIN_DEFAULT_URL = 'https://www.linkedin.com/in/';

export const LINK_PREFIX = 'https://www.';

export type PopoverArrowDirection = 'center' | 'left' | 'right';

export enum InteractionsOpenState {
  closed = 'closed',
  open = 'open',
  full = 'full',
}

export enum SurveyState {
  notAvailable,
  available,
  answered,
  resultAvailable,
}

export enum InteractionsTabs {
  networking = 'networking',
  chat = 'chat',
  survey = 'survey',
  discovery = 'discovery',
  offers = 'offers',
}

export enum ChatEvents {
  CONNECT = 'connect',
  CONNECTION = 'connection',
  CONNECTED = 'connected',
  DISCONNECT = 'disconnect',
  SEND_MESSAGE = 'send_message',
  NEW_MESSAGE = 'new_message',
  MESSAGE_SENDED = 'message_sended',
  MESSAGE_DELETED = 'deleted_message',
  MESSAGE_READ = 'message_read',
  JOIN_PRIVATE_CHANNEL = 'join_private_channel',
  JOIN_STREAM_CHANNEL = 'join_stream_channel',
  LEAVE_CHANNEL = 'leave_channel',
  LEFT_CHANNEL = 'left',
  NEW_PRIVATE_CHAT = 'new_private_chat',
  READ_MESSAGE = 'read_message',
  CHANNEL_JOINED = 'channel_joined',
  NEW_MENTION = 'new_mention',
  ERROR = 'error',
  DISABLED_CHAT = 'disable_chat',
}

export enum StreamEvents {
  CONNECTION = 'connection',
  CONNECTED = 'connected',
  DISCONNECT = 'disconnect',
  JOIN = 'join',
  LEAVEPAGE = 'leavepage',
  JOINED = 'joined',
  ERROR = 'error',
  FINISHED = 'finished',
  USING_BACKUP = 'using_backup',
  SURVEY_AVAILABLE = 'survey_available',
  SURVEY_UNAVAILABLE = 'survey_unavailable',
  SURVEY_RESULT = 'survey_result',
  NEW_LIVE_STREAM = 'new_live_stream',
}

export enum NotificationEvents {
  CONNECTION = 'connection',
  CONNECTED = 'connected',
  DISCONNECT = 'disconnect',
  NEW_NOTIFICATION = 'new_notification',
  ERROR = 'error',
}

export enum NotificationTypes {
  UNKNOWN = 'unknown',
  MENTION = 'mention',
  NOTIFICATION = 'notification',
  EXTERNAL = 'external',
  MESSAGE = 'message',
  NEW_LIVE_STREAM = 'new_live_stream',
}

export enum ChatTypes {
  PRIVATE = 'PRIVATE',
  STREAM = 'STREAM',
}

export enum LanguagesAcronyms {
  en = 'en',
  pt = 'pt',
  libras = 'libras',
  original = 'original',
  'close-caption' = 'cc',
}

export enum ProjectNames {
  BAYER = 'BAYER',
  CNN = 'CNN',
  VALE = 'VALE',
  MOVILE = 'MOVILE',
  SXSW = 'SXSW',
  NETFLIX = 'NETFLIX',
  SERASA = 'SERASA',
  CREATORS = 'CREATORS',
  AMPRO = 'AMPRO',
  TM1 = 'TM1',
}

export enum Players {
  MAIN = 'player-tm1',
  PIP = 'player-pip',
}

export enum Certificate {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  NOTEXISTS = 'does-not-have',
}

export enum VideoPlayers {
  JWPLAYER = 'jwPlayer',
  YOUTUBE = 'iframe',
  REACTPLAYER = 'reactPlayer',
}
