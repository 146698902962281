import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

export const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 864 488"
    >
      <rect x="48" y="88" width="368" height="48" rx="4"></rect>
      <rect x="48.5" y="88.5" width="367" height="47" rx="3.5"></rect>
      <rect id="Rectangle 620" x="48" y="86" width="368" height="51"></rect>
      <rect x="48" y="168" width="768" height="48" rx="4"></rect>
      <rect x="48" y="328" width="768" height="48" rx="4"></rect>
      <rect x="48" y="248" width="768" height="48" rx="4"></rect>
      <rect id="Rectangle 620_2" x="43" y="38" width="318" height="26"></rect>
    </ContentLoader>
  );
};

export const SkeletonMobile = () => {
  const { colors } = useTheme();
  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 327 543"
    >
      <rect x="24" y="26" width="262" height="30" rx="4"></rect>
      <rect x="24" y="170" width="280" height="48" rx="4"></rect>
      <rect x="25" y="250" width="280" height="48" rx="4"></rect>
      <rect x="24" y="90" width="280" height="48" rx="4"></rect>
      <rect x="25" y="330" width="280" height="48" rx="4"></rect>
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <SkeletonMobile />
    </MediaMatch>
  </>
);
