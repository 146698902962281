import { useFetch } from 'hooks';
import { getURLWithParams } from 'lib/contexts/urls';
import { COFFEE, CHAT, INTEGRATIONS } from 'constants/endpoints';

export enum IntegrationsTypes {
  INSTAGRAM = 'instagram',
}

export type IntegrationType = {
  description: string;
  hashtags?: string[];
  hashtag?: string;
  type: IntegrationsTypes;
  title: string;
};

export interface Coffee {
  eventId: string;
  id: string;
  exploration: {
    title: string;
    description: string;
    image: string;
  };
  wallpaper: {
    title: string;
    description: string;
    image: string;
    url: string;
  };
  photoOpp: {
    title: string;
    description: string;
    qrCode: string;
    url: string;
  };
  digitalActivision: {
    title: string;
    description: string;
    qrCode: string;
  };
  wordsCloud?: {
    description: string;
    qrCode: string;
    title: string;
    url: string;
  };
  integrations: IntegrationType[];
}

export type UsersByCountry = {
  id: string;
  value: number;
  eventId: string;
  usersActive: number;
  createdAt: string;
  updatedAt: string;
  longitude: number;
  latitude: number;
  label: string;
};

export type User = {
  allowChat: boolean;
  country: string;
  eventId: string;
  id: string;
  interests: string[];
  name: string;
  profile: string;
  avatar: string;
};

export type UserOnline = {
  countries?: UsersByCountry[];
  total: number;
};

export type UserOnlineByCountry = {
  connections: User[];
  total: number;
};

export type InstagramPost = {
  commentsCount?: string;
  id: string;
  likeCount: number;
  mediaType?: string;
  mediaUrl: string;
};

export type InstagramFeed = {
  posts: InstagramPost[];
  count: number;
};

export const useCoffee = () => useFetch<Coffee>(COFFEE.ROOT);

export const useUsersOnlineByCountries = () =>
  useFetch<UserOnline>(COFFEE.USERS_ONLINE);

export const useUsersOnlineByCountry = (country: number) =>
  useFetch<UserOnlineByCountry>(
    `${CHAT.USER_CHATS_BY_COUNTRY}?country=${country}`,
  );

export const useInstagramFeed = (hashtags: string[], page = 1) =>
  useFetch<InstagramFeed>(
    getURLWithParams(INTEGRATIONS.INSTAGRAM, {
      hashtags,
      page,
    }),
  );
