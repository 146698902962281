/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import { useSocketListener } from 'hooks';
import { useSocketContext } from 'contexts';
import { Stream, StreamState } from 'interfaces/stream';


export const useEventChanges: any = ({setStreams, streams}: {setStreams: React.Dispatch<React.SetStateAction<Stream[]>>, streams: Stream[]}) => {
  const { eventSocket: socket } = useSocketContext();
  const onConnect = () => {
    console.info(
      `%cEvent socket connected`,
      `color: #06c16a; font-weight: bold;`,
    );

    socket.emit('new_session_instance');
  };

  const onChange = () => {
    navigate('/home');
  };

	const streamChange = (data: {streamId: string, state: StreamState}) => {
		const targetId = data.streamId
		const targetStream = streams.find(stream => stream.id === targetId)
		if(targetStream){
			targetStream.state = data.state
		}
		setStreams([...streams])
	}
  const navigate = useNavigate();
  useSocketListener(socket, 'connected', onConnect);
  useSocketListener(socket, 'onchange', onChange);
  useSocketListener(socket, 'stream_change', streamChange);


};
