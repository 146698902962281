/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { useCreateUser } from 'useCases/users';
import { setUserId } from 'lib/core/session';
import { Language, useIntlContext } from 'contexts';
import { Spinner } from 'components/structure';
import { User } from 'interfaces/user';
import { ROUTES } from 'constants/urls';

/* eslint-disable prettier/prettier */
export function FacebookAuth() {
  const navigate = useNavigate();
  // const { language } = useIntlContext();
  const createUser = useCreateUser();
  const [params] = useSearchParams();
  const { setLanguage } = useIntlContext();
  const email = params.get('email') || '';
  const name = params.get('name') || '';
  const lastName = params.get('lastName') || '';

  // const userEmailDomain = email
  //   ?.replace('%40', '@')
  //   ?.split('@')[1]
  //   .split('.')[0];
  // const allowedDomains = ['fb', 'facebook', 'meta'];

  // const { setGettingStarted } = useAccreditationContext();
  // const onUserNotExists = (uuid: string, userGettingStarted?: User) => {
  //   createUser({
  //     email,
  //     firstName,
  //     lastName,
  //     uuid: email?.toUpperCase(),
  //     password: btoa(email!),
  //   });
  // };

  // const onEventIsClosedAndUserNotExists = () => {
  //   setToast({
  //     type: 'alert',
  //     description: translate('errors.userNotExists'),
  //   });
  // };

  // const { handleUserExists } = useHandleUserExists({
  //   onUserNotExists,
  //   onEventIsClosedAndUserNotExists,
  // });
  // const { handleAuthenticate } = useSignIn();
  async function checkExist() {
    try {
      // const exists = await api.get(`/users/exists/${email}`);
      return true;
    } catch (e: any) {
      if (e.response.status === 404) {
        return false;
      }
    }
  }
  function setDataForSuccessLoginOrSignup(userCreated: User) {
    setUserId(userCreated.id!);
    setLanguage(userCreated.preferableLanguage as Language);

    // responsavel por redirecionar o usuario para agenda
    const data = new Date();
    const dia = String(data.getDate()).padStart(2, '0');
    if (Number(dia) >= 7) {
      navigate(ROUTES.app.getLink('home'));
    } else {
      navigate('/reimagineVideo?selectedDate=07%2F11');
    }

    setUserId(userCreated.id!);
  }

  async function createIfDoesNotExist() {
    const newUser = await createUser({
      email,
      firstName: name,
      lastName,
      uuid: email?.toUpperCase(),
      password: btoa(email!),
      allowChat: true,
    });
    if (newUser.user) {
      setDataForSuccessLoginOrSignup(newUser.user);
    }
  }
  async function loginIfExist() {
    const {
      data: { user },
    } = await api.post('/auth/sign-in', {
      uuid: email,
      password: btoa(email!),
    });
    setDataForSuccessLoginOrSignup(user);
  }
  useEffect(() => {
    (async () => {
      if (!(await checkExist())) {
        await createIfDoesNotExist();
      } else {
        await loginIfExist();
      }
    })();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Spinner />
    </div>
  );
}
