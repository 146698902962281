import { useFetch } from 'hooks';
import { Speaker } from 'components/contexts/schedule';
import { SCHEDULES } from 'constants/endpoints';

export type TimeSlot = {
  end: string;
  start: string;
};

export type TopicDetails = {
  description: string;
  title: string;
  timeSlot: TimeSlot;
  live: boolean;
  speakers: Speaker[];
  ics: Ics[];
};

export type Topic = {
  id: string;
  streamId?: string;
  timeSlot?: string;
  description: string;
  details: TopicDetails;
  live: boolean;
};

export type Ics = {
  id: string;
  title: string;
  description: string;
  startTime: Date;
  endTime: Date;
  location: string;
};

export type ScheduleListProps = {
  id?: string;
  name?: string;
  position: number;
  topics: Topic[];
  ics: Ics[];
};

export type ScheduleList = {
  id?: string;
  name?: string;
  position: number;
  topics: Topic[];
  ics: Ics[];
};

type ScheduleByDateResponse = {
  eventId: string;
  date: string;
  streamGroups: ScheduleList[];
};

type ScheduleByIdResponse = {
  title: string;
  description: string;
  timeSlot: TimeSlot;
  speakers: Speaker[];
  ics: Ics[];
  live: boolean;
};

type ScheduleByEventResponse = {
  eventId: string;
  eventDays: ScheduleByDateResponse[];
};

export const useSchedulesByDate = ({ date }: { date: string }) =>
  useFetch<ScheduleByDateResponse>(date && `${SCHEDULES.ROOT}?date=${date}`);

export const useSchedulesByEvent = () =>
  useFetch<ScheduleByEventResponse>(SCHEDULES.BY_EVENT);

export const useScheduleById = (id: string) =>
  useFetch<ScheduleByIdResponse>(SCHEDULES.BY_ID(id));
