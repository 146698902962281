import styled, { css } from 'styled-components/macro';
import media from 'styled-media-query';
import * as Avatar from 'components/contexts/users/Avatar/Avatar.styles';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    z-index: 1;

    ${media.lessThan('medium')`
      background-color: ${theme.secondary};
      width: 100%;
      height: 100%;
      /* transform: translateX(0); */

      display: flex;
      justify-content: center;
      align-items: center;
    `}
  `}
`;

export const Container = styled.article`
  ${({ theme }) => css`
    background: ${theme.secondary};
    border: 1px solid ${theme.primary};
    border-radius: ${theme.shapes.borderRadius.md};
    padding: ${theme.spacing.sm};
    width: 288px;

    ${media.greaterThan('medium')`
      transform: translateY(-50%);
    `}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: ${theme.spacing.sm};
    position: relative;

    ${Avatar.Wrapper} {
      min-width: 48px;
      height: 48px;
    }
  `}
`;

export const ContentInfos = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-left: ${theme.spacing.xs};

    strong {
      color: ${theme.text.primary};
      word-break: break-word;
      max-width: 150px;
      padding: 10px 0 0;
    }
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.primary};
    position: absolute;
    right: 0;
    top: 0;

    svg {
      color: ${theme.text.primary};
    }
  `}
`;

export const SocialsContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.sm};

    a {
      cursor: pointer;

      & + a {
        margin-left: ${theme.spacing.xs};
      }
    }

    svg {
      width: 18px;
      height: 16px;
      color: ${theme.text.primary};
    }
  `}
`;

export const UserInfoSmall = styled.small`
  ${({ theme }) => css`
    color: ${theme.text.primary};
    line-height: ${theme.spacing.md};
  `}
`;
