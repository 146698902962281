import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

export const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 930 500"
    >
      <rect x="0" y="26" width="262" height="20" />
      <rect x="0" y="65" width="184" height="193" rx="4" />
      <rect x="196" y="65" width="184" height="193" rx="4" />
      <rect x="392" y="65" width="184" height="193" rx="4" />
      <rect x="588" y="65" width="184" height="193" rx="4" />
    </ContentLoader>
  );
};

export const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 327 480"
    >
      <rect x="0" y="0" width="262" height="20" />
      <rect x="0" y="39" width="155" height="193" rx="4" />
      <rect x="169" y="39" width="155" height="193" rx="4" />
      <rect x="0" y="251" width="155" height="193" rx="4" />
      <rect x="169" y="251" width="155" height="193" rx="4" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <SkeletonMobile />
    </MediaMatch>
  </>
);
