import GoogleMapReact from 'google-map-react';
import { useState } from 'react';
import { UserOnline, UserOnlineByCountry } from 'useCases/coffee';
import { useEnv } from 'hooks';
import { Avatar } from 'components/contexts/users/Avatar/Avatar';
import { WithChildren } from 'interfaces/children';
import { useStyles } from './GoogleMaps.styles';
import * as S from './MapsCard.styles';

type MarkerProps = {
  lat: number;
  lng: number;
} & WithChildren;

const Marker = ({ children }: MarkerProps) => <>{children}</>;

type MapsCardProps = {
  mapItems: UserOnline;
  connectionItems: UserOnlineByCountry;
  getConnectionsByCountry: (value: number) => void;
};

export const MapsCard = ({ mapItems, connectionItems }: MapsCardProps) => {
  const [markerId] = useState('');
  const { googleMap } = useEnv();
  const googleMapsStyle = useStyles();

  return (
    <S.Wrapper>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMap }}
        defaultCenter={{ lat: -23.533773, lng: -46.62529 }}
        defaultZoom={1}
        yesIWantToUseGoogleMapApiInternals
        options={{
          styles: googleMapsStyle,
          fullscreenControl: false,
          zoomControl: false,
        }}
      >
        {mapItems.countries?.map(({ value, latitude, longitude, label }) => (
          <Marker key={`marker-${value}`} lat={latitude} lng={longitude}>
            <S.Loading>
              <div />
              <div />
            </S.Loading>
            <S.BodyBallon show={markerId === `marker-${value}`}>
              <S.HeaderBallon>
                <S.DotBallon />
                <span>{label}</span>
              </S.HeaderBallon>
              {connectionItems?.connections.map(({ name, id, avatar }) => (
                <S.UserRow key={id}>
                  <Avatar size="xs" src={avatar} />
                  <S.UserName key={id}>{name}</S.UserName>
                </S.UserRow>
              ))}
            </S.BodyBallon>
          </Marker>
        ))}
      </GoogleMapReact>
    </S.Wrapper>
  );
};
