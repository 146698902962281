import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing.md};

    background: ${theme.secondary};
    height: 176px;
    border-radius: ${theme.shapes.borderRadius.md};
    justify-content: space-between;

    ${media.lessThan('huge')`
      width: 456px;
    `}

    ${media.lessThan('medium')`
      width: 327px;
      height: 308px;
      flex-direction: column;
    `}
  `}

  > img {
    width: 228px;
    height: 128px;

    ${media.lessThan('huge')`
      width: 193px;
      height: 108px;
    `}

    ${media.lessThan('medium')`
      width: 100%;
      height: 156px;
    `}
  }
`;

export const AsideContainer = styled.aside`
  ${media.lessThan('medium')`
    align-self: flex-start;
  `}
`;

export const Heading = styled.h2`
  ${({ theme }) => css`
    color: ${theme.text.primary};
    font-size: ${theme.typography.sizes.lg};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.text.primary};
    margin-top: ${theme.spacing.sm};
    line-height: ${theme.spacing.md};
    font-size: ${theme.typography.sizes.sm};
  `}
`;
