import styled, { css } from 'styled-components';
import { EmptyState } from 'components/structure/EmptySearch/EmptySearch.styles';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.secondary};
    padding: ${theme.spacing.md} ${theme.spacing.sm};
    height: 100%;
    width: 100%;
  `}
`;

export const AnsweredWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const AnsweredState = styled(EmptyState)`
  ${({ theme }) => css`
    background: ${theme.text.secondary};
    border-radius: ${theme.shapes.borderRadius.md};
    color: ${theme.text.primary};
    font-size: ${theme.typography.sizes.lg};
    font-weight: bold;
    line-height: ${theme.typography.lineHeight.md};
    padding: 40px 52px ${theme.spacing.lg};
    height: auto;

    svg {
      color: ${theme.primary};
    }
  `}
`;
