/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useTerms, Term } from 'useCases/service-terms';
import { useTranslate, useThemeOptions, useQueryString } from 'hooks';
import { setReadOnlyDefaultValue } from 'lib/contexts/input';
import { useAccreditationContext, useBusinessCardContext } from 'contexts';
import { Terms } from 'components/contexts/accreditation';
import {
  Checkbox,
  Option,
  PasswordField,
  TextField,
  CustomSelect,
  HelperText,
} from 'components/form';
import {
  Button,
  // FormActions,
  FormGroup,
  Form,
  Heading,
  Paragraph,
  TrickSpace,
  ButtonSupport,
} from 'components/structure';
import SwitchToggleCheckbox from 'components/structure/SwitchToggle';
import { User } from 'interfaces/user';
import {
  ProjectNames,
  BRAZIL_COUNTRY_CODE,
  LINKEDIN_DEFAULT_URL,
} from 'constants/enums';
import { brazilianStates } from './BusinessCardForm.mock';
import * as S from './BusinessCardForm.styles';
import {
  schema,
  //schemaHasAdicionalFields,
  defaultValues as defaultValuesFromValidations,
} from './BusinessCardForm.validations';

type FormModel = {
  rePassword: string;
  terms: boolean;
  acceptedShareData: boolean;
  acceptedNews: boolean;
  numberOfPeopleWatchingWithMe?: number;
  ageGroup: number;
  ask1: string;
  ask3: string;
} & User;

export type BusinessCardFormProps = {
  onSubmit: (user: User) => void;
  isPending?: boolean;
  countries: Option[];
  profiles?: Option[];
  languages?: Option[];
  defaultValues?: User;
  showSupportButton?: boolean;
  hasLinkedInField?: boolean;
  hasMemberGetMember?: boolean;
  hasPasswordStrength?: boolean;
  hasCountry?: boolean;
  hasJobArea?: boolean;
};

const BusinessCardForm = ({
  onSubmit,
  isPending,
  defaultValues,
  showSupportButton,
  languages = [],
  countries,
  profiles = [],
  hasLinkedInField = false,
  hasCountry = true,
  hasJobArea = false,
}: BusinessCardFormProps) => {
  const { themeName } = useThemeOptions();
  const translate = useTranslate();
  const { data: { terms } = {} } = useTerms();
  const [showTerms, setShowTerms] = useState(false);
  const [term, setTerm] = useState<Term>({} as Term);

  const linkedinInputRef = useRef<HTMLInputElement | null>(null);
	const {gettingStarted} = useAccreditationContext();
  const { setCardProps } = useBusinessCardContext();
  const {
    errors,
    register,
    handleSubmit,
    watch,
    reset,
    control,
  } = useForm<FormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValuesFromValidations,
      ...defaultValues,
    },
  });
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const ask3 = watch('ask3');
  const ask2 = watch('ask2');
  const ask1 = watch('ask1');
  const email = watch('uuid');
  const profile = watch('profile');
  const linkedIn = watch('linkedInUrl');
  const professionalId = watch('professionalId');
  const country = watch('country', {
    label: 'Brasil',
    value: '76',
  });
  const areaAsk2 = watch('ask2');
  const company = watch('company');
  useEffect(() => {
    setCardProps({
      name: firstName ? `${firstName} ${lastName}` : undefined,
      email,
      ask3,
      company,
      profile: profiles.find(({ value }) => value === profile?.area)?.label,
      linkedIn,
      country: {
        label: 'Brasil',
        value: '76',
      },
    });
  }, [
    firstName,
    lastName,
    email,
    country,
    linkedIn,
    profile?.area,
    countries,
    ask1,
    ask2,
    ask3,
    company,
    professionalId,
  ]);

  // useEffect(() => {
  // 	reset(defaultValues);
  // }, [defaultValues, reset]);

  useEffect(() => {
    if (linkedinInputRef.current && hasLinkedInField) {
      setReadOnlyDefaultValue(linkedinInputRef.current || LINKEDIN_DEFAULT_URL);
    }
  }, []);
  const onHandleSubmit = (user: User) => {
    onSubmit({
      ...user,
      password: user.professionalId,
      rePassword: user.professionalId,
			referrer: gettingStarted.referrer,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);
    // return onSubmit({ ...user, referrer: referrer ?? undefined });
  };

  const handleSetIndexTerm = (currentTerm: string) => {
    if (!terms) return;

    setTerm(terms[currentTerm]);
    setShowTerms(true);
  };

  const themeNameParam = ProjectNames.VALE === themeName ? `.${themeName}` : '';
  const LabelTerms = () => (
    <S.Term>
      {translate(
        `accreditation.businessCard.acceptTerms${themeNameParam}.accept`,
      )}
      <S.ButtonTerms
        onClick={() => {
          const currentTerm = 'useTerm';
          handleSetIndexTerm(currentTerm);
        }}
        type="button"
      >
        {translate(
          `accreditation.businessCard.acceptTerms${themeNameParam}.conditions`,
        )}
      </S.ButtonTerms>
      {translate(`accreditation.businessCard.acceptTerms${themeNameParam}.and`)}
      <S.ButtonTerms
        onClick={() => {
          const currentTerm = 'privacyTerm';
          handleSetIndexTerm(currentTerm);
        }}
        type="button"
      >
        {translate(
          `accreditation.businessCard.acceptTerms${themeNameParam}.policies`,
        )}
      </S.ButtonTerms>
      {/* {translate(
        `accreditation.businessCard.acceptTerms${themeNameParam}.policiesAnd`,
      )} */}
    </S.Term>
  );
  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      <Heading>{translate('accreditation.businessCard.title')}</Heading>
      {/* <Paragraph>
        {translate('accreditation.businessCard.description')}
      </Paragraph> */}

      <FormGroup>
        <TextField
          name="firstName"
          ref={register}
          label={translate('labels.name')}
          placeholder={translate('labels.name')}
          error={!!errors?.firstName?.message}
          helperText={translate(errors?.firstName?.message)}
        />
        <TextField
          name="lastName"
          ref={register}
          label={translate('labels.lastName')}
          placeholder={translate('labels.lastName')}
          error={!!errors?.lastName?.message}
          helperText={translate(errors?.lastName?.message)}
        />
      </FormGroup>

      <FormGroup>
        <TextField
          name="email"
          ref={register}
          label={translate(`labels${themeNameParam}.email`)}
          placeholder={translate(`labels${themeNameParam}.email`)}
          error={!!errors?.email?.message}
          helperText={translate(errors?.email?.message)}
          // TODO check if uuid is email to enable edit
          readOnly
        />
      </FormGroup>

      <FormGroup lgMargin>
        <TextField
          ref={register}
          label={'CRM/Id profissional'}
          name="professionalId"
          placeholder="CRM/Professional ID"
          error={!!errors?.professionalId?.message}
          helperText={errors?.professionalId?.message}
        />
        <TrickSpace />
      </FormGroup>

      {areaAsk2 === '6' ? (
        <TextField
          name="ask4"
          ref={register}
          label={translate('labels.anotherArea')}
          placeholder={translate('labels.anotherArea')}
          error={!!errors?.ask4?.message}
          helperText={translate(errors?.ask4?.message)}
        />
      ) : (
        <TrickSpace />
      )}
      <FormGroup hasMargin={false}>
        {hasJobArea && (
          <>
            <CustomSelect
              control={control}
              name="profile.area"
              label={translate('labels.profile')}
              placeholder={translate('labels.profile')}
              error={errors?.profile?.area?.message}
              options={profiles}
            />
            <CustomSelect
              control={control}
              name="preferableLanguage"
              label={translate('labels.language')}
              placeholder={translate('labels.language')}
              error={errors?.preferableLanguage?.message}
              options={languages}
              defaultValue={languages.find(
                ({ value }) => value === defaultValues?.preferableLanguage,
              )}
            />
          </>
        )}
        {/* {hasLinkedInField && (
          <TextField
            name="linkedInUrl"
            ref={(e) => {
              register(e);
              linkedinInputRef.current = e;
            }}
            label={translate('labels.linkedin')}
            placeholder={translate('labels.linkedin')}
            error={!!errors?.linkedInUrl?.message}
            helperText={translate(errors?.linkedInUrl?.message)}
            defaultValue={LINKEDIN_DEFAULT_URL}
          />
        )} */}

        {/* <PasswordField
          name="password"
          autoComplete="new-password"
          ref={register}
          label={translate('labels.password')}
          placeholder={translate('labels.password')}
          error={!!errors?.password?.message}
          helperText={translate(errors?.password?.message)}
          minLength={6}
          maxLength={ProjectNames.VALE === themeName ? 6 : 100}
          showStrength
        /> */}
        {/* <FormGroup lgMargin> */}
        <input ref={register} type="hidden" name="uuid" />
        {/* <PasswordField
            name="rePassword"
            autoComplete="new-password"
            ref={register}
            label={translate('labels.passwordConfirmation')}
            placeholder={translate('labels.passwordConfirmation')}
            error={!!errors?.rePassword?.message}
            helperText={translate(errors?.rePassword?.message)}
            minLength={6}
            maxLength={ProjectNames.VALE === themeName ? 6 : 100}
          /> */}
        {/* </FormGroup> */}
      </FormGroup>

      <S.TermsContainer style={{ display: 'flex', columnGap: '.5rem' }}>
        <SwitchToggleCheckbox name={'ask1'} register={register} />
        <p>
          Eu declaro que sou um profissional de saúde habilitado a prescrever ou
          dispensar medicamentos, e que os dados preenchidos acima, inclusive o
          meu número no Conselho Regional da minha categoria estão corretos e
          válidos.
          <HelperText error={true}>{errors.ask1?.message}</HelperText>
        </p>
      </S.TermsContainer>
      <S.TermsContainer style={{ display: 'flex', columnGap: '.5rem' }}>
        <SwitchToggleCheckbox name={'ask2'} register={register} />
        <p>
          Eu autorizo a Bayer coletar meus dados pessoais acima para que eu
          acesse os conteúdos desta plataforma. Para saber mais detalhes e como
          revogar este consentimento, acesse nossos termos de uso e nossa
          política de privacidade.
          <HelperText error={true}>{errors.ask2?.message}</HelperText>
        </p>
      </S.TermsContainer>
      <S.TermsContainer style={{ display: 'flex', columnGap: '.5rem' }}>
        <SwitchToggleCheckbox name={'ask3'} register={register} />
        <p>
          Aceito receber novidades sobre este e demais eventos da Bayer por
          e-mail, SMS e/ou WhatsApp.
        </p>
      </S.TermsContainer>

      {showSupportButton && (
        <FormGroup lgMargin>
          <ButtonSupport
            onlyIcon={isMobile}
            fixedDesktop={!isMobile}
            icon={isMobile ? 'IcHelp' : undefined}
          />
        </FormGroup>
      )}
      <S.TermsContainer>
        <Checkbox
          style={{
            marginBottom: 'auto',
            width: '34px',
          }}
          register={register}
          name="terms"
          error={errors.terms?.message}
          noCheckOnLabel
          defaultChecked={true}
          label={((<LabelTerms />) as unknown) as string}
        />
      </S.TermsContainer>
      {/* <FormActions> */}
      <S.ContainerButton>
        <Button
          fullWidth
          style={{
            marginBottom: '10px',
          }}
          type="submit"
          isLoading={isPending}
          disabled={isPending}
        >
          {translate('labels.next')}
        </Button>
      </S.ContainerButton>

      {/* </FormActions> */}
      <Terms
        term={term}
        show={showTerms}
        onConfirm={() => setShowTerms(false)}
      />
    </Form>
  );
};

export default BusinessCardForm;
