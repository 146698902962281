import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import {
  InputWrapper,
  Label,
} from 'components/form/TextField/TextField.styles';
import { SubTitle } from 'components/structure/Typography/Typography';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: 385px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${theme.spacing.lg};

    ${SubTitle} {
      text-align: center;
      color: ${theme.text.primary};
      font-size: ${theme.typography.sizes.md};
    }

    ${media.lessThan('large')`
      max-width: 100vw;
      padding: 0 ${theme.spacing.md};
      margin-bottom: ${theme.spacing.xl};

      ${SubTitle} {
        font-size: ${theme.typography.sizes.base};
      }
    `}
  `}
`;

const shapreInputModifiers = {
  isCopied: (theme: DefaultTheme) => css`
    border-color: ${theme.primary};
  `,
};

export const ShareInput = styled(InputWrapper)<{ isCopied: boolean }>`
  ${({ theme, isCopied }) => css`
    background: #f7f7f7;
    border: 1px dashed ${theme.text.secondary};
    padding-left: ${theme.spacing.xs};
    margin-bottom: ${theme.spacing.lg};
    width: 370px;

    &:after {
      content: '';
      width: 1px;
      height: 32px;
      background: ${theme.text.secondary};
      position: absolute;
      right: ${theme.spacing.lg};
    }

    > span {
      margin-top: ${theme.spacing.xs};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: ${theme.spacing.lg};
      margin-right: ${theme.spacing['2xs']};
    }

    > svg {
      position: absolute;
      right: ${theme.spacing.xs};
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    ${media.lessThan('large')`
      width: 100%;
    `}

    ${isCopied && shapreInputModifiers.isCopied(theme)}
  `}
`;

export const ShareLabel = styled(Label)`
  ${({ theme }) => css`
    left: ${theme.spacing.xs};
    font-size: ${theme.typography.sizes.xs};
    color: ${theme.text.secondary};
  `}
`;

export const ShareText = styled.small`
  ${({ theme }) => css`
    position: relative;
    color: ${theme.text.primary};
    font-size: ${theme.typography.sizes.sm};
    margin-bottom: ${theme.spacing.md};

    &:after,
    &:before {
      position: absolute;
      content: '';
      background: ${theme.text.secondary};
      width: 64px;
      height: 1px;
      top: 50%;
      right: 100%;
      margin-right: ${theme.spacing.xs};
      transform: translateY(50%);
    }

    &:after {
      left: 100%;
      margin-left: ${theme.spacing.xs};
    }
  `}
`;

export const SocialLinks = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing.sm};
  `}
`;

export const ShareButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.05);
  width: 40px;
  height: 40px;
  border-radius: 50%;

  > svg {
    width: 16px;
    height: 16px;
  }
`;
