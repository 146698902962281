import { createContext, useContext, useState } from 'react';
import { Modal, ModalProps, Icon } from 'components/structure';
import { WithChildren } from 'interfaces/children';

type ModalPropsContext = Omit<ModalProps, 'showModal' | 'setShowModal'>;

type ContextType = {
  setShowModal: (value: boolean) => void;
  showModal: boolean;
  setModalProps: (props: ModalPropsContext) => void;
  modalProps: ModalPropsContext;
};

const defaultValue = {
  setShowModal: () => undefined,
  setModalProps: () => undefined,
  modalProps: {
    title: '',
    description: '',
    icon: <Icon icon="IcUser" />,
  },
  showModal: false,
};

export const ModalContext = createContext<ContextType>(defaultValue);

export const ModalProvider = ({ children }: WithChildren) => {
  const [showModal, setShowModal] = useState(defaultValue.showModal);
  const [modalProps, setModalProps] = useState<ModalPropsContext>(
    defaultValue.modalProps,
  );
  return (
    <ModalContext.Provider
      value={{
        showModal,
        setShowModal,
        modalProps,
        setModalProps,
      }}
    >
      {children}
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        noBackground={true}
        {...modalProps}
      />
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);

ModalContext.displayName = 'ModalContext';
