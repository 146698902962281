import { mutate } from 'swr';
import { OnlineUsersAPI } from 'useCases/networking';
import { Connections } from 'useCases/networking';

export const appendUsersConnected = (
  url: string,
  connections: Connections[] = [],
) =>
  mutate(
    url,
    (prevState: OnlineUsersAPI = { connections: [] }) => ({
      ...prevState,
      connections: [...prevState.connections, ...connections],
    }),
    false,
  );
