import ReactPlayer from 'react-player';
import Slider, { CustomArrowProps, Settings } from 'react-slick';
import { usePortal } from 'hooks';
import { useTranslate } from 'hooks';
import { Button, Icon } from 'components/structure';
import * as S from './Gallery.styles';

export type GalleryProps = {
  showModal: boolean;
  handleCloseModal: (value: boolean) => void;
  videos?: string[];
  images?: string[];
  url?: string;
  showButton?: boolean;
};

type CustomIcon = {
  prefix: 'next' | 'prev';
  icon: JSX.Element;
} & CustomArrowProps;

export const Gallery = ({
  showModal,
  handleCloseModal,
  videos,
  images,
  url,
}: GalleryProps) => {
  const translate = useTranslate();
  const Portal = usePortal();

  const SlickButtonFix = ({
    currentSlide,
    slideCount,
    icon,
    prefix,
    ...props
  }: CustomIcon) => (
    <button
      {...props}
      className={`slick-${prefix} slick-arrow ${
        prefix === 'prev'
          ? currentSlide === 0 && ' slick-disabled'
          : currentSlide === slideCount! - 1 && ' slick-disabled'
      }`}
      type="button"
    >
      {icon}
    </button>
  );

  const settings: Settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Portal>
      <S.Wrapper showModal={showModal}>
        <S.IconWrapper
          aria-label="Close Modal"
          onClick={() => handleCloseModal(false)}
        >
          <Icon icon="IcClose" />
        </S.IconWrapper>

        <S.Content>
          <S.WrapperSlider isVideo={!!videos?.length}>
            <Slider
              {...settings}
              nextArrow={
                <SlickButtonFix
                  prefix="next"
                  icon={<Icon icon="IcArrowRight" />}
                />
              }
              prevArrow={
                <SlickButtonFix
                  prefix="prev"
                  icon={<Icon icon="IcArrowLeft" />}
                />
              }
            >
              {videos?.length
                ? videos?.map((video) => (
                    <div key={video}>
                      <ReactPlayer
                        role="iframe"
                        key={video}
                        url={video}
                        width="100%"
                        height="100%"
                        controls
                      />

                      <S.DownloadButton>
                        <Button
                          download
                          color="tertiary"
                          as="a"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={url}
                        >
                          {translate('labels.download')}
                        </Button>
                      </S.DownloadButton>
                    </div>
                  ))
                : images?.map((image) => (
                    <div key={image}>
                      <S.SliderImage role="img" src={image} />
                      <S.DownloadButton>
                        <Button
                          download
                          color="tertiary"
                          as="a"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={image}
                        >
                          {translate('labels.download')}
                        </Button>
                      </S.DownloadButton>
                    </div>
                  ))}
            </Slider>
          </S.WrapperSlider>
        </S.Content>
      </S.Wrapper>
    </Portal>
  );
};
