import styled, { css } from 'styled-components';
import { MAX_PASSWORD_STRENGTH } from 'components/form/PasswordField/usePasswordStrength';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.xs};
    cursor: pointer;
    user-select: none;
  `}
`;

export const PasswordStrength = styled.div<{ strength: number }>`
  ${({ theme, strength = 0 }) => css`
    background-color: rgba(0, 0, 0, 0.2);
    margin: 8px auto 0;
    height: 4px;
    width: calc(100% - 40px);
    color: ${theme.primary};
    position: relative;

    &::after {
      content: '';
      background-color: ${theme.primary};
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      transition: width 0.2s linear;
      width: ${`${(strength / MAX_PASSWORD_STRENGTH) * 100}%` || '0%'};
    }
  `}
`;
