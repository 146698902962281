import { useContext} from 'react'
import { IsChatInputContext } from 'contexts/IsChatInputFocusedContext';

export function useIsChatInputFocused(){
	const {isFocused, setIsFocused} = useContext(IsChatInputContext);
	return {
		isFocused,
		setIsFocused
	}
}
