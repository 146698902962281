import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  IntegrationType,
  useInstagramFeed,
  InstagramPost,
} from 'useCases/coffee';
import { useInfiniteScroll, useTranslate } from 'hooks';
import { Button, Spinner, Icon } from 'components/structure';
import { Heading, Paragraph } from 'components/structure/Typography/Typography';
import * as S from './InstagramCard.styles';

export type InstagramCardProps = {
  instagramInformations: IntegrationType;
};

export const InstagramCard = ({
  instagramInformations,
}: InstagramCardProps) => {
  const translate = useTranslate();
  const [feed, setFeed] = useState<InstagramPost[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const coffeeInstagramFeed = useInstagramFeed(
    instagramInformations?.hashtags || [],
    currentPage,
  );

  const onHandleNextPage = () => hasMore && setCurrentPage(currentPage + 1);

  const lastPostRef = useInfiniteScroll(
    coffeeInstagramFeed.isLoading,
    hasMore,
    onHandleNextPage,
  );

  const getPostRef = (position: number, arraySize: number) =>
    position === arraySize - 1 ? lastPostRef : null;

  useEffect(() => {
    setFeed((prevState) =>
      prevState.concat(coffeeInstagramFeed?.data?.posts || []),
    );
  }, [coffeeInstagramFeed?.data?.posts]);

  useEffect(() => {
    if (!coffeeInstagramFeed?.data?.posts && feed.length) {
      setHasMore(false);
    }
  }, [feed, coffeeInstagramFeed?.data?.posts]);

  useEffect(
    () => () => {
      setFeed([]);
      setCurrentPage(1);
      setHasMore(true);
    },
    [],
  );

  const [hashtagItem] = instagramInformations?.hashtags || [];

  return (
    <S.Wrap>
      <Heading>{hashtagItem}</Heading>
      <Paragraph>{instagramInformations.description}</Paragraph>
      <S.FeedWrap data-testid="posts">
        {feed?.map(({ likeCount, mediaUrl, id }, index) => (
          <S.FeedCard
            key={id}
            ref={!isMobile ? getPostRef(index, feed.length) : null}
          >
            <S.HeaderCard>
              <Icon icon="IcInstragram" />
            </S.HeaderCard>
            <S.ImageCard src={mediaUrl} />
            <S.FooterCard>
              <Icon icon="IcHeart" />
              <span> {likeCount}</span>
            </S.FooterCard>
          </S.FeedCard>
        ))}
        {coffeeInstagramFeed.isLoading && (
          <S.Spinner>
            <Spinner size={50} />
          </S.Spinner>
        )}
        {isMobile && !coffeeInstagramFeed.isLoading && (
          <Button disabled={!hasMore} onClick={() => onHandleNextPage()}>
            {!hasMore
              ? translate('labels.dataLoaded')
              : translate('labels.seeMore')}
          </Button>
        )}
      </S.FeedWrap>
    </S.Wrap>
  );
};
