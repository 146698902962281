import { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import * as S from './vimeo.styles';

const IFrame = styled.iframe`
  border: 0;
  width: 100%;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function VimeoJS(props: any) {
  // const { videoRef } = useContext(PlayerContext) as any;

  return (
    <S.VideoContainer>
      <ReactPlayer
        url={props.url}
        width="100%"
        height="100%"
        controls={true}
        playing={true}
        playsinline={true}
      ></ReactPlayer>
    </S.VideoContainer>
  );
}
