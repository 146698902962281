import { ThemeContext } from 'app.contexts';
import { useContext, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useEvent } from 'useCases/events';
import { useTheme } from 'hooks';
import {
	useIntlContext,
	ThemeCustomProvider,
	useBusinessCardContext,
} from 'contexts';
import { BusinessCard } from 'components/contexts/users';
import { Logo, CFCode } from 'components/structure';
import * as PublicTheme from 'styles/overrides/Public.theme';
//import { THEME } from 'styles/themes';
import { PublicContexts } from './Public.contexts';
import * as S from './Public.styles';

// const bgs = {
//   pt: THEME.BackgroundAccreditationFallback,
//   en: THEME.BackgroundAccreditationFallback,
// };

type BusinessCardConsumerProps = {
	background?: string;
};

const BusinessCardConsumer = ({ background }: BusinessCardConsumerProps) => {
	const { show, cardProps } = useBusinessCardContext();
	const videoRef = useRef<HTMLVideoElement>(null);
	const autoVideoPlay = () => {
		videoRef &&
			videoRef.current &&
			videoRef.current.play().catch((error) => {
				console.error('Error attempting to play', error);
			});
	};
	useEffect(() => {
		window.document.body.addEventListener('click', () => autoVideoPlay());
		window.document.body.addEventListener('touchend', () => autoVideoPlay());
		autoVideoPlay();
		return () => {
			window.document.body.removeEventListener('click', () => autoVideoPlay());
			window.document.body.removeEventListener('touchend', () =>
				autoVideoPlay(),
			);
		};
	});
	const isAnimatedKv = background?.split('/').slice(-1)[0].endsWith('.mp4')
	return (
		<>

			<S.HeroWrapper>
				{isAnimatedKv ? <S.BlurEffect
					playsInline
					loop
					muted
					controls={false}
					src={background}
					ref={videoRef}
				/> : <S.KvImage
					bgUrl={background}
					role="img"
					aria-label="background image"
					blur={show && !!cardProps?.name}
				/>}

				<S.BusinessCardWrapper show={show && !!cardProps?.name}>
					<BusinessCard {...cardProps} />
				</S.BusinessCardWrapper>
			</S.HeroWrapper>
		</>
	);
};

const Public = () => {
	const { data } = useEvent();
	const { eventTheme } = useContext(ThemeContext);
	const { language } = useIntlContext();
	const kv = eventTheme?.accreditation?.kv;
	const logo = eventTheme?.logo;
	return (
		<PublicContexts>
			<ThemeCustomProvider {...PublicTheme}>
				<S.Wrapper>
					<S.Content>
						<S.OutletWrapper>
							<S.WrapperLogo>
								<Logo url={logo} alt={data?.event?.name} />
							</S.WrapperLogo>
							<Outlet />
							<CFCode isDesktop marginTop />
						</S.OutletWrapper>
						<BusinessCardConsumer background={kv ? kv![language] : ''} />
					</S.Content>
				</S.Wrapper>
			</ThemeCustomProvider>
		</PublicContexts>
	);
};

export default Public;
