import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';

export const Skeleton = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      width="60"
      height="20"
      viewBox="0 0 60 20"
    >
      <rect width="329" height="48" />
      <rect y="64" width="329" height="48" />
      <circle cx="45.5" cy="176.5" r="24.5" />
      <circle cx="45.5" cy="402.5" r="24.5" />
      <circle cx="45.5" cy="289.5" r="24.5" />
      <circle cx="45.5" cy="515.5" r="24.5" />
      <circle cx="45.5" cy="634.5" r="24.5" />
      <circle cx="165.5" cy="176.5" r="24.5" />
      <circle cx="165.5" cy="402.5" r="24.5" />
      <circle cx="165.5" cy="289.5" r="24.5" />
      <circle cx="165.5" cy="515.5" r="24.5" />
      <circle cx="165.5" cy="634.5" r="24.5" />
      <circle cx="280.5" cy="176.5" r="24.5" />
      <circle cx="280.5" cy="402.5" r="24.5" />
      <circle cx="280.5" cy="289.5" r="24.5" />
      <circle cx="280.5" cy="515.5" r="24.5" />
      <circle cx="280.5" cy="634.5" r="24.5" />
      <rect x="9" y="214" width="72" height="14" />
      <rect x="9" y="326" width="72" height="14" />
      <rect x="9" y="439" width="72" height="14" />
      <rect x="9" y="558" width="72" height="14" />
      <rect x="9" y="677" width="72" height="14" />
      <rect x="129" y="214" width="72" height="14" />
      <rect x="129" y="326" width="72" height="14" />
      <rect x="129" y="439" width="72" height="14" />
      <rect x="129" y="558" width="72" height="14" />
      <rect x="129" y="677" width="72" height="14" />
      <rect x="249" y="214" width="72" height="14" />
      <rect x="249" y="326" width="72" height="14" />
      <rect x="249" y="439" width="72" height="14" />
      <rect x="249" y="558" width="72" height="14" />
      <rect x="249" y="677" width="72" height="14" />
    </ContentLoader>
  );
};
