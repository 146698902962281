/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Term, useTerms } from 'useCases/service-terms';
import { useTranslate } from 'hooks';
import { isBayer } from 'lib/contexts';
import { useBusinessCardContext } from 'contexts';
import { Terms } from 'components/contexts/accreditation';
import {
  Checkbox,
  CustomSelect,
  HelperText,
  Option,
  PasswordField,
  TextField,
} from 'components/form';
import {
  Button,
  CFCode,
  FormActions,
  FormGroup,
  Form,
  Heading,
  Paragraph,
  TrickSpace,
} from 'components/structure';
import SwitchToggleCheckbox from 'components/structure/SwitchToggle';
import { User } from 'interfaces/user';
import { BRAZIL_COUNTRY_CODE } from 'constants/enums';
// eslint-disable-next-line no-restricted-imports
import { brazilianStates, enabledCities } from './BusinessCardForm.mock';
import * as S from './BusinessCardForm.styles';
import {
  schemaForBayer,
  schemaForNotBayer,
  defaultValues as defaultValuesFromValidations,
  schema,
} from './BusinessCardForm.validations';

type FormModel = {
  rePassword: string;
  terms: boolean;
  healthProfessional: boolean;
} & User;

export type BusinessCardFormProps = {
  onSubmit: (user: User) => void;
  isPending?: boolean;
  countries?: Option[];
  profiles?: Option[];
  languages?: Option[];
  firstDayWorkshops?: Option[];
  secondDayWorkshops?: Option[];
  thirdDayWorkshops?: Option[];
  defaultValues?: User;
};

const BusinessCardForm = ({
  onSubmit,
  isPending,
  countries = [],
  languages = [],
  profiles = [],
  firstDayWorkshops = [],
  secondDayWorkshops = [],
  thirdDayWorkshops = [],
  defaultValues,
}: BusinessCardFormProps) => {
  const translate = useTranslate();

  const { data: { terms } = {} } = useTerms();
  const [showTerms, setShowTerms] = useState(false);
  const [term, setTerm] = useState<Term>({} as Term);

  const isEmailBayer = isBayer(defaultValues?.email || '');
  const flagIsBayer = isEmailBayer ? 'bayerId' : 'professionalId';
  const { setCardProps } = useBusinessCardContext();
  const {
    errors,
    register,
    handleSubmit,
    watch,
    reset,
    control,
  } = useForm<FormModel>({
    resolver: yupResolver(schemaForBayer),
    defaultValues: {
      ...defaultValuesFromValidations,
      ...defaultValues,
    },
  });

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const profile = watch('profile');
  const email = watch('uuid');
  const country = watch('country');
  const state = watch('state');
  const professionalId = watch('professionalId');
  const ask1 = watch('ask1');

  useEffect(() => {
    const cardProfile = isEmailBayer
      ? 'Bayer'
      : profiles.find(({ value }) => value === profile?.specialty)?.label;
    setCardProps({
      name: firstName ? `${firstName} ${lastName}` : undefined,
      profile: cardProfile,
      email,
      country: countries.find(({ value }) => value === country),
      state: brazilianStates.find(({ value }) => value === state)?.label,
    });
  }, [
    firstName,
    lastName,
    profile?.specialty,
    email,
    country,
    state,
    countries,
    professionalId,
    ask1,
  ]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onHandleSubmit = (user: User) =>
    onSubmit({
      ...user,
      password: user.ask1,
      rePassword: user.ask1,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const handleSetIndexTerm = (currentTerm: string) => {
    if (!terms) return;

    setTerm(terms[currentTerm]);
    setShowTerms(true);
  };

  const HealthProfessionalTerms = () => (
    <S.Term>
      {translate(
        'accreditation.businessCard.acceptTermsBayer.healthProfessional',
      )}
    </S.Term>
  );

  const PrivacyTerms = () => (
    <S.Term>
      {translate('accreditation.businessCard.acceptTermsBayer.accept')}
      <S.ButtonTerms
        onClick={() => {
          const currentTerm = 'useTerm';
          handleSetIndexTerm(currentTerm);
        }}
        type="button"
      >
        {translate('accreditation.businessCard.acceptTermsBayer.conditions')}
      </S.ButtonTerms>
      {translate('accreditation.businessCard.acceptTermsBayer.and')}
      <S.ButtonTerms
        onClick={() => {
          const currentTerm = 'privacyTerm';
          handleSetIndexTerm(currentTerm);
        }}
        type="button"
      >
        {translate('accreditation.businessCard.acceptTermsBayer.policies')}
      </S.ButtonTerms>
    </S.Term>
  );

  const InfoNewsTerms = () => (
    <S.Term>
      {translate('accreditation.businessCard.acceptTermsBayer.infoNews')}
    </S.Term>
  );

  const CountryField = () => (
    <CustomSelect
      control={control}
      name="country"
      label={translate('labels.country')}
      placeholder={translate('labels.country')}
      error={translate(errors?.country?.message)}
      options={countries}
    />
  );

  const LanguageField = () => (
    <CustomSelect
      control={control}
      name="preferableLanguage"
      label={translate('labels.language')}
      placeholder={translate('labels.language')}
      error={errors?.preferableLanguage?.message}
      options={languages}
      defaultValue={languages.find(
        ({ value }) => value === defaultValues?.preferableLanguage,
      )}
    />
  );

  const ProfessionalIdField = () => (
    <TextField
      name="professionalId"
      ref={register}
      label={translate(`labels.${flagIsBayer}`)}
      placeholder={translate(`labels.${flagIsBayer}`)}
      error={!!errors?.professionalId?.message}
      helperText={translate(errors?.professionalId?.message)}
    />
  );

  const EmailField = () => (
    <TextField
      name="email"
      ref={register}
      label={translate('labels.email')}
      placeholder={translate('labels.email')}
      error={!!errors?.email?.message}
      helperText={translate(errors?.email?.message)}
      // TODO check if uuid is email to enable edit
      readOnly
    />
  );

  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      <Heading>{translate('accreditation.businessCard.title')}</Heading>
      {/* <Paragraph>
        {translate('accreditation.businessCard.description')}
      </Paragraph> */}
      <FormGroup lgMargin>
        <TextField
          name="firstName"
          ref={register}
          label={translate('labels.name')}
          placeholder={translate('labels.name')}
          error={!!errors?.firstName?.message}
          helperText={translate(errors?.firstName?.message)}
        />
        <TextField
          name="lastName"
          ref={register}
          label={translate('labels.lastName')}
          placeholder={translate('labels.lastName')}
          error={!!errors?.lastName?.message}
          helperText={translate(errors?.lastName?.message)}
        />
      </FormGroup>
      {/* <FormGroup lgMargin> */}
      {/* {Number(country) === BRAZIL_COUNTRY_CODE ? (
          <>
            {CountryField()}
            <CustomSelect
              control={control}
              name="state"
              label={translate('labels.state')}
              placeholder={translate('labels.state')}
              error={errors?.state?.message}
              options={brazilianStates}
            />
          </>
        ) : (
          CountryField()
        )} */}
      {/* </FormGroup> */}

      <>
        <FormGroup lgMargin>
          <EmailField />
          {/* {LanguageField()} */}
          {/* {ProfessionalIdField()} */}
        </FormGroup>
        {/* <FormGroup lgMargin>
            <EmailField />
          </FormGroup> */}
      </>
      <FormGroup lgMargin>
        <TextField
          ref={register}
          name="ask1"
          placeholder="CWID"
          helperText={'Este campo é obrigatório'}
          error={!!errors?.ask1?.message}
        />
        <TrickSpace />
      </FormGroup>

      {/* <FormGroup xlMargin> */}
      <input ref={register} type="hidden" name="uuid" />
      {/* <PasswordField
          name="password"
          autoComplete="new-password"
          ref={register}
          label={translate('labels.password')}
          placeholder={translate('labels.password')}
          error={!!errors?.password?.message}
          helperText={translate(errors?.password?.message)}
          minLength={8}
          showStrength
        />
        <PasswordField
          name="rePassword"
          autoComplete="new-password"
          ref={register}
          label={translate('labels.passwordConfirmation')}
          placeholder={translate('labels.passwordConfirmation')}
          error={!!errors?.rePassword?.message}
          helperText={translate(errors?.rePassword?.message)}
          minLength={8}
        /> */}
      {/* </FormGroup> */}

      <S.TermsContainer>
        <S.TermsContainer style={{ display: 'flex', columnGap: '.5rem' }}>
          <SwitchToggleCheckbox name={'ask2'} register={register} />
          <p>
            Eu autorizo a Bayer coletar meus dados pessoais acima para que eu
            acesse os conteúdos desta plataforma. Para saber mais detalhes e
            como revogar este consentimento, acesse nossos termos de uso e nossa
            política de privacidade.
          </p>
        </S.TermsContainer>

        <S.TermsContainer
          style={{ display: 'flex', columnGap: '.5rem', marginTop: '2rem' }}
        >
          <SwitchToggleCheckbox name={'ask3'} register={register} />

          <p>
            Aceito receber novidades sobre este e demais eventos da Bayer por
            e-mail, SMS e/ou WhatsApp.
          </p>
        </S.TermsContainer>

        {/* <Checkbox
          register={register}
          name="terms"
          error={errors.terms?.message}
          noCheckOnLabel
          label={((<PrivacyTerms />) as unknown) as string}
        />
        <Checkbox
          register={register}
          name="infoNews"
          noCheckOnLabel
          label={((<InfoNewsTerms />) as unknown) as string}
        /> */}
      </S.TermsContainer>
      <FormActions>
        <CFCode />
        <Button type="submit" isLoading={isPending} disabled={isPending}>
          {translate('labels.next')}
        </Button>
      </FormActions>
      <Terms
        term={term}
        show={showTerms}
        onConfirm={() => setShowTerms(false)}
      />
    </Form>
  );
};

export default BusinessCardForm;
