import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

const Skeleton = ({ width, height }: { width: number; height: number }) => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox={`0 0 ${width} ${height}`}
    >
      <circle cx="40" cy="40" r="40" />

      <rect x="96" y="10" width="201" height="23" />
      <rect x="96" y="50" width="127" height="24" />
    </ContentLoader>
  );
};

export const SkeletonChoosePhoto = () => (
  <>
    <MediaMatch greaterThan="medium">
      <Skeleton width={630} height={130} />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <Skeleton width={331} height={130} />
    </MediaMatch>
  </>
);
