import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { requiredParam, paramsDontMatch } = messages;

export const schema = Yup.object().shape({
  newPassword: Yup.string()
	.required('Este Campo é obrigatório')
	.matches(/^[A-Za-z]{2}\d{6,7}$/, 'O valor deve seguir o formato "RJ000000')
	.uppercase(),
  token: Yup.string(),
});

export const defaultValues = {
  newPassword: '',
  rePassword: '',
  token: '',
};

export type ChangePassword = Yup.InferType<typeof schema>;
