import { useRef, useState } from 'react';
import { useTranslate } from 'hooks';
import { Avatar, CropPhoto } from 'components/contexts/users';
import { Button, SubTitle } from 'components/structure';
import { Photo } from 'interfaces/user';
import * as S from './ChoosePhoto.styles';

export type ChoosePhotoProps = {
  alt?: string;
  currentImage?: string;
  name?: string;
  isPending?: boolean;
  setValue: (name: string, value: File) => void;
  onSubmit: (payload: Photo) => void;
  onImgPreviewChange?: (imgPreview: string) => void;
  onUploadProgress?: (progress: number) => void;
};

export const ChoosePhoto = ({
  alt,
  currentImage = '',
  isPending = false,
  name = 'userImage',
  setValue,
  onSubmit,
  onImgPreviewChange,
}: ChoosePhotoProps) => {
  const translate = useTranslate();
  const inputFile = useRef<HTMLInputElement>(null);
  const [imgPreview, setImgPreview] = useState(currentImage);
  const [showCrop, setShowCrop] = useState(false);
  const [photoToCrop, setPhotoToCrop] = useState<File>();

  const handleChooseImage = () => {
    if (inputFile?.current) {
      inputFile.current.value = '';
      inputFile.current.click();
    }
  };

  const handleCroppedImage = (file: File) => {
    const photoUrl = URL.createObjectURL(file);
    setImgPreview(photoUrl);
    onImgPreviewChange && onImgPreviewChange(photoUrl);

    if (photoToCrop) {
      onSubmit({
        file,
        fileContext: 'photo',
        originalFile: photoToCrop,
      });
    }
  };

  const handleOnChange = (fileEvent: React.ChangeEvent<HTMLInputElement>) => {
    const image = fileEvent.target?.files?.item(0);

    if (image) {
      updateImagePreview(URL.createObjectURL(image));
      setPhotoToCrop(image);
      setValue(name, image);
      setShowCrop(true);
    }
  };

  const updateImagePreview = (image: string) => {
    onImgPreviewChange && onImgPreviewChange(image);
  };

  return (
    <>
      <S.Wrapper>
        <Avatar src={imgPreview} alt={alt} size="large" />

        <SubTitle>{translate('users.chooseYourPhoto')}</SubTitle>
        <Button
          isSmall
          color="secondary"
          onClick={handleChooseImage}
          isLoading={isPending}
        >
          {translate('users.insertYourImage')}
        </Button>
        <input
          onChange={handleOnChange}
          ref={inputFile}
          accept="image/*"
          type="file"
        />
      </S.Wrapper>

      <CropPhoto
        showModal={showCrop}
        setShowModal={setShowCrop}
        imageToCrop={photoToCrop!}
        setValue={handleCroppedImage}
      />
    </>
  );
};
