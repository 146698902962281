import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    ${media.greaterThan('medium')`
      display: grid;
      gap: ${theme.spacing.lg};
      grid-template-columns: repeat(auto-fit, minmax(435px, 1fr));
    `}
  `}
`;
