import { useEffect, useState } from 'react';
import { useClickOutside, useTranslate } from 'hooks';
import { LanguageStream, useStreamContext } from 'contexts';
import { Icon } from 'components/structure';
import { Popover } from 'components/structure/Popover/Popover';
import { LanguagesAcronyms } from 'constants/enums';
import { ChangeLanguage } from './ChangeLanguage';
import * as S from './ChangeLanguageSelect.styles';

export type ChangeLanguageSelectProps = {
  handleChangeLanguage: (language: LanguageStream) => void;
  language: LanguageStream;
  hasLabel?: boolean;
  bottom?: boolean;
  activatedOnlyFocus?: boolean;
};

export const ChangeLanguageSelect = ({
  handleChangeLanguage,
  language,
  hasLabel = true,
  bottom = false,
  activatedOnlyFocus = false,
}: ChangeLanguageSelectProps) => {
  const translation = useTranslate();
  const [isShowChangeLanguage, setIsShowChangeLanguage] = useState(false);
  const clickOutSideRef = useClickOutside(() => setIsShowChangeLanguage(false));
  const { streamLanguages } = useStreamContext();

  useEffect(() => {
    if (streamLanguages.length && !streamLanguages.includes(language)) {
      handleChangeLanguage(streamLanguages[0]);
    }
  }, [handleChangeLanguage, language, streamLanguages]);

  return (
    <Popover.Root
      isOpen={isShowChangeLanguage}
      onClick={() => setIsShowChangeLanguage(!isShowChangeLanguage)}
      ref={clickOutSideRef}
      placement={bottom ? 'bottom' : undefined}
      distance={bottom ? '20px' : '40px'}
    >
      <Popover.Trigger>
        <S.LanguageContainer>
          {hasLabel && <small>{translation('labels.changeLanguage')}</small>}
          <S.LanguageDisplay
            show={isShowChangeLanguage}
            activatedOnlyFocus={activatedOnlyFocus}
          >
            <strong>{LanguagesAcronyms[language]}</strong>
            <Icon icon="IcChevronDown" />
          </S.LanguageDisplay>
        </S.LanguageContainer>
      </Popover.Trigger>

      <Popover.ContentLanguage>
        <ChangeLanguage
          streamLanguages={streamLanguages}
          show={isShowChangeLanguage}
          onSelectedOption={(value) => {
            handleChangeLanguage(value);
            setIsShowChangeLanguage(true);
          }}
        />
      </Popover.ContentLanguage>
    </Popover.Root>
  );
};
