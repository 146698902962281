import styled, { css } from 'styled-components';
import { Paragraph } from 'components/structure';

export const Wrapper = styled.div``;

export const PictureWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 0;
  width: 100%;
  padding-bottom: 48%;
  cursor: pointer;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: 100%;
  }
`;

export const Excerpt = styled(Paragraph)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.xs};
    font-size: ${theme.typography.sizes.base};
    font-weight: 400;
    line-height: ${theme.typography.lineHeight.base};
    margin-bottom: 45px;
  `}
`;
