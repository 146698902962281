import styled from 'styled-components';
import media from 'styled-media-query';

export const VideoContainer = styled.div`
  display: flex;
  width: 100%;
  height: 95%;
  ${media.lessThan('large')`
  width: 100%;
  height: 260px;
    `}
`;
