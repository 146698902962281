type TourProps = {
  src: string;
  onLoad: () => void;
};

export const Tour = ({ src, onLoad }: TourProps) => (
  <iframe
    title="VirtualExpo"
    width="100%"
    height="100%"
    frameBorder="0"
    allow="camera;microphone;gyroscope;accelerometer"
    src={src}
    onLoad={onLoad}
  />
);
