import { useNavigate } from 'react-router';
import { Icon } from 'components/structure';
import { ROUTES } from 'constants/urls';
import * as S from './SmallCard.styles';

export type SmallCardProps = {
  thumbnail: string;
  title: string;
  duration?: string;
  id: string;
};

export const SmallCard = ({
  title,
  thumbnail,
  duration,
  id,
}: SmallCardProps) => {
  const navigate = useNavigate();
  const handlerStreamOrShowDialog = () => {
    navigate(ROUTES.stream.getLink('id', id));
  };
  return (
    <S.Wrapper onClick={handlerStreamOrShowDialog}>
      <S.PictureWrapper>
        <img src={thumbnail} alt={title} />
        <Icon icon="IcPlayVideo" width="26px" height="26px" />
      </S.PictureWrapper>
      <S.Title role="heading">{title}</S.Title>
      {duration && <S.Duration>{duration}</S.Duration>}
    </S.Wrapper>
  );
};
