import styled, { css } from 'styled-components/macro';
import { Form, FormActions } from 'components/structure';

export const Container = styled.div`
  ${({ theme }) => css`
    ${Form} {
      padding-bottom: ${theme.spacing['3xl']};
    }
    ${FormActions} {
      &::after {
        content: none;
      }
    }
  `}
`;
