import styled, { css, DefaultTheme } from 'styled-components';

type WrapperProps = {
  hasError?: boolean;
};

const wrapperModifiers = {
  hasError: (theme: DefaultTheme) => css`
    .custom-select {
      &__control {
        border-color: ${'red'};
      }
    }

    &,
    &:active,
    &:focus {
      border-color: ${'red'};
    }

    & + ${Label} {
      color: ${'red'};
    }
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, hasError = false }) => css`
    position: relative;
    z-index: 1;

    .custom-select {
      &__control {
        background: none;
        background-color: ${theme.input.background};
        border: 1px solid ${theme.secondary};
        height: 100%;
        transition: all 0.2s linear;
        box-shadow: 0;
        min-height: 48px;

        svg {
          color: ${theme.text.secondary};
        }

        .custom-select__dropdown-indicator {
          transition: transform 0.2s ease-in-out;
          transform: rotate(0deg);
        }

        &--menu-is-open {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          position: relative;
          border-color: ${theme.text.secondary ?? theme.primary};
          border-bottom-color: transparent;
          box-shadow: 0;

          &:hover {
            border-color: ${theme.text.secondary ?? theme.primary};
            border-bottom-color: transparent;
          }

          &:before {
            content: '';
            background-color: ${theme.input.background};
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }

          svg {
            color: ${theme.text.secondary ?? theme.primary};
          }

          .custom-select__dropdown-indicator {
            transition: transform 0.2s ease-in-out;
            transform: rotate(180deg);
          }

          .custom-select__value-container {
            color: ${theme.text.secondary};
          }
        }

        &--is-focused {
          box-shadow: 0;
        }
      }

      &__placeholder {
        color: ${theme.text.secondary};
      }

      &__value-container {
        padding: ${theme.spacing.xs} ${theme.spacing.sm};
        color: ${theme.text.secondary};

        &--has-value {
          padding: ${theme.spacing.sm};
        }
      }

      &__single-value {
        color: ${theme.text.primary};
        margin: 0;
        margin-top: ${theme.spacing['2xs']};
      }

      &__indicator-separator {
        display: none;
      }

      &__menu {
        margin-top: -2px;
        border-radius: ${theme.shapes.borderRadius.xs};
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        position: absolute;
        width: 100%;
        backdrop-filter: blur(50px);
        background-color: ${theme.input.background};
      }

      &__menu-list {
        padding: 0 ${theme.spacing.sm};
        max-height: 180px;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${theme.secondary};
        }

        &:first-child {
          padding-top: ${theme.spacing.xs};
        }
      }

      &__option {
        color: ${theme.text.primary};
        display: flex;
        align-items: center;
        font-size: ${theme.typography.sizes.base};
        line-height: 20px;
        margin-right: ${theme.spacing.xs};

        &--is-focused {
          background-color: ${theme.input.background};
        }
        &--is-selected {
          background-color: ${theme.text.secondary};
        }
      }

      &__multi-value {
        background: none;
      }

      &__multi-value__remove {
        display: none;
      }

      &__multi-value__label {
        color: ${theme.text.primary};
        font-size: ${theme.typography.sizes.base};
        padding: 0;
        margin-right: ${theme.spacing.xs};
      }
    }

    ${hasError && wrapperModifiers.hasError(theme)};
  `}
`;

const labelModifiers = {
  hide: () => css`
    top: 58%;
    opacity: 0;
    visibility: hidden;
  `,
  isOpen: (theme: DefaultTheme) => css`
    color: ${theme.text.secondary ?? theme.primary};
  `,
};

type LabelProps = {
  hide?: boolean;
  isOpen?: boolean;
};

export const Label = styled.label<LabelProps>`
  ${({ theme, hide = true, isOpen }) => css`
    font-size: ${theme.typography.sizes.xs};
    font-family: inherit;
    transition: all 0.2s linear;
    position: absolute;
    left: 18px;
    top: 10px;
    transform: translateY(-50%);

    ${hide && labelModifiers.hide()};
    ${isOpen && labelModifiers.isOpen(theme)};
  `}
`;

export const RowContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    span {
      color: ${theme.text.secondary};
    }
  `}
`;

export const SelectOptions = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    margin-bottom: ${theme.spacing.md};
  `}
`;

export const Checkbox = styled.div`
  ${({ theme }) => css`
    width: 20px;
    height: 20px;
    border-radius: ${theme.shapes.borderRadius.xs};
    border: 1px solid ${theme.text.secondary ?? theme.primary};
    margin-right: ${theme.spacing.xs};
    text-align: center;
    color: ${theme.text.secondary ?? theme.primary};

    svg {
      color: ${theme.text.secondary ?? theme.primary};
    }
  `}
`;
