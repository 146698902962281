import { useTranslate } from 'hooks';
import { Avatar } from 'components/contexts/users';
import { Heading } from 'components/structure';
import * as S from './SpeakerCard.styles';

export type Speaker = {
  id: string;
  name: string;
  avatar?: string;
  bio: string;
  linkedin?: string;
  language?: string;
};

export const SpeakerCard = ({
  name,
  avatar = '',
  bio,
  linkedin,
  language,
}: Speaker) => {
  const translate = useTranslate();

  return (
    <S.Container>
      {avatar ? (
        <img
          src={avatar}
          alt={language === 'pt' ? `Foto de ${name}` : `Photo of ${name}`}
        />
      ) : (
        <Avatar />
      )}

      <Heading dangerouslySetInnerHTML={{__html: name}}></Heading>

      <S.DescriptionContainer>
        <S.Description dangerouslySetInnerHTML={{__html: bio}}></S.Description>
      </S.DescriptionContainer>

      {!!linkedin && (
        <a
          key={linkedin}
          href={linkedin}
          target="_blank"
          rel="noopener noreferrer"
        >
          {translate('labels.altLinkedin')}
        </a>
      )}
    </S.Container>
  );
};
