/* eslint-disable no-restricted-imports */
import { useCookies } from 'react-cookie';
import CountriesEN from '../assets/dataCountries/countries_en.json';
import CountriesPT from '../assets/dataCountries/countries_pt.json';
import { brazilianStates } from '../components/contexts/accreditation/BusinessCardForm/Default/BusinessCardForm.mock';

export function useCountries() {
  const [cookies, setCookie] = useCookies();

  const { language } = cookies;
  const currentLanguage = language === 'pt' ? CountriesPT : CountriesEN;
  function getUseCountryLabel(countryCode: number) {
    return currentLanguage.find((value) => value.value === countryCode)?.label;
  }

  function getUseStateLabel(statesValues: string) {
    return brazilianStates.find((value) => value.value === statesValues)?.label;
  }

  return { getUseCountryLabel, getUseStateLabel };
}
