import { Divider } from 'pages/configurations/divider';
import { SubTitle } from 'components/structure';
import * as S from './TabRouter.styles';

type TabRouterProps = {
  icon?: JSX.Element;
  path: string;
  label: string;
};

export const TabRouter = ({ icon: Icon, path, label }: TabRouterProps) => (
  <S.TabRouterContainer to={path} activeClassName="active">
    <S.Container>
      {Icon}
      <SubTitle>{label}</SubTitle>
    </S.Container>
    <Divider height="2px" />
  </S.TabRouterContainer>
);
