import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import * as Button from 'components/structure/Button/Button.styles';
import { Heading } from 'components/structure/Typography/Typography';
import { TermsProps } from './Terms';

type WrapperProps = Pick<TermsProps, 'show'>;

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, show }) => css`
    background-color: #000000b3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s linear;
    padding: ${theme.spacing.xl} ${theme.spacing.md};
    opacity: ${show ? '1' : '0'};
    pointer-events: ${show ? 'all' : 'none'};
    z-index: 99999;
  `}
`;

type ContentProps = {
  ref?: React.Ref<HTMLDivElement | null>;
};

export const Content = styled.div<ContentProps>`
  ${({ theme }) => css`
    background-color: ${theme.tertiary};
    font-family: ${theme.text.primary};
    max-width: 1090px;
    max-height: 992px;
    padding: 0 0 ${theme.spacing.lg} 0;
    height: 100%;
    width: 100%;
    margin-top: ${theme.spacing.xs};

    ${Heading} {
      margin: 0 0 0 ${theme.spacing.md};
      color: ${theme.text.secondary};
    }

    > div {
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing.md};

      ${Button.Wrapper} {
        align-self: flex-end;
      }
    }

    ${media.lessThan('medium')`
      word-break: break-word;
    `}
  `}
`;

export const TermsStepsWrapper = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    justify-content: flex-start;
    margin-bottom: ${theme.spacing.lg};
    position: relative;

    &::after {
      background: ${theme.primary};
      content: '';
      position: absolute;
      height: 1px;
      top: 22px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 20px);
      z-index: 999;
    }

    ${media.lessThan('medium')`
      &::after {
        top: 28px;
      }
    `}
  `}
`;

export const TermStep = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 68px;
  z-index: 10;

  &:not(:last-child) {
    margin-right: 80px;

    ${media.lessThan('medium')`
      margin-right: 50px;
    `}
  }
`;

type StepNumberProps = {
  active: boolean;
};

const stepNumberModifiers = {
  active: (theme: DefaultTheme) => css`
    background-color: ${theme.primary};
    color: ${theme.text.primary};
  `,
};
export const BoxTerms = styled.div`
  ${({ theme }) => css`
    display: flex;
    background-color: ${theme.tertiary};
    width: 70%;
    height: 90%;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.primary};
    border-radius: 10px;
  `}
`;

export const StepNumber = styled.span<StepNumberProps>`
  ${({ theme, active = false }) => css`
    background-color: ${theme.text.primary};
    border-radius: 50%;
    border: 1px solid ${theme.primary};
    color: ${theme.primary};
    font-weight: bold;
    font-size: ${theme.typography.sizes.lg};
    text-align: center;
    letter-spacing: 1px;
    line-height: 55px;
    height: 55px;
    width: 55px;
    transition: all 0.2s linear;
    margin-bottom: ${theme.spacing.xs};

    ${active && stepNumberModifiers.active(theme)};
  `}
`;

export const StepLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.text.secondary};
    font-weight: 500;
    font-size: ${theme.typography.sizes.sm};
    line-height: ${theme.typography.lineHeight.sm};
    text-align: center;
  `}
`;

export const TermText = styled.div`
  ${({ theme }) => css`
    color: ${theme.text.secondary};
    font-weight: 500;
    font-size: ${theme.typography.sizes.base};
    line-height: ${theme.typography.lineHeight.base};
    margin-bottom: ${theme.spacing.lg};
    p:not(:last-child) {
      margin-bottom: ${theme.spacing.sm};
    }
  `}
`;

export const BeginnersTipWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.secondary};
    backdrop-filter: blur(50px);
    border-radius: 50%;
    height: 96px;
    width: 96px;
    position: absolute;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg {
      height: 31px;
      width: auto;
    }

    span {
      font-size: 12px;
      font-weight: 500;
    }

    ${media.lessThan('medium')`
      display: none;
    `}
  `}
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  padding: 0 20px;
  justify-content: flex-end;
`;

export const IconWrapper = styled.button<WrapperProps>`
  ${({ theme }) => css`
    appearance: none;
    color: ${theme.primary};
    height: 24px;
    width: 24px;
  `}
`;

export const Document = styled.object`
  ${({ theme }) => css`
    padding: ${theme.spacing.md};
    width: 100%;
    height: 100%;

    ${media.lessThan('medium')`
      padding: ${theme.spacing.md} ${theme.spacing.xs} ${theme.spacing.md};
    `}
  `}
`;
