import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorGeneric } from 'components/structure';
// import { THEME } from 'styles/themes';
import { WithChildren } from 'interfaces/children';
import { ROUTES } from 'constants/urls';

type ErrorBoundaryState = {
  hasError?: boolean;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: WithChildren) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <ErrorGeneric
          // backgroundImage={THEME.BackgroundOutlet}
          actionNavigate={ROUTES.app.getLink('home')}
          title={<FormattedMessage id="errors.internalServerError.title" />}
          description={
            <FormattedMessage id="errors.internalServerError.description" />
          }
          buttonLabel={<FormattedMessage id="labels.goHome" />}
        />
      );
    }
    return children;
  }
}
