import styled from 'styled-components';
import { Icon } from 'components/structure/Icon/Icon';

export const Container = styled.div`
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 10px;
`;

export const LikeIconStyled = styled(Icon)`
  width: 43.62px;
  height: 39.1px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 414px) {
    width: 35px;
    height: 32px;
  }
`;
