import styled, { css } from 'styled-components';
import * as TypographyStyles from 'components/structure/Typography/Typography';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  ${({ theme }) => css`
    ${TypographyStyles.Paragraph} {
      margin-bottom: ${theme.spacing.sm};
      color: ${theme.text.primary};
    }
  `}
`;
