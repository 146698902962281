import { mutate } from 'swr';
import {
  ChatList,
  ChatListAPI,
  LatestMessages,
  Message,
} from 'interfaces/chat';
import { User } from 'interfaces/user';
import { ChatTypes } from 'constants/enums';

export const mutateLatestMessages = (url: string, message: Message) =>
  mutate(
    url,
    (prevState: LatestMessages = { messages: [] }) => ({
      ...prevState,
      messages: [
        {
          ...message,
          received: true,
        },
        ...prevState?.messages,
      ],
    }),
    false,
  );

export const mutateUserChats = (url: string, message: Message) => {
  const {
    chatId,
    content = '',
    id,
    name,
    avatar,
    userId,
    read,
    peer,
  } = message;

  mutate(
    url,
    (prevState: ChatListAPI = { chats: [] }) => {
      const targetChat = (prevState?.chats?.find(
        (chat) => chat.chatId === chatId,
      ) ?? {}) as ChatList;

      const chatsWithoutTarget =
        prevState.chats?.filter((chat) => chat.chatId !== chatId) ?? [];

      return {
        ...prevState,
        chats: [
          {
            ...message,
            ...targetChat,
            peer: targetChat?.peer ?? peer ?? userId,
            chatType: ChatTypes.PRIVATE,
            lastMessage: {
              id,
              chatId,
              userId,
              content,
              avatar,
              name,
              read: !!read,
            },
          },
          ...chatsWithoutTarget,
        ],
      };
    },
    false,
  );
};

export const mutateOnSendMessage = (
  url: string,
  content: string,
  session: User,
) =>
  mutate(
    url,
    (prevState: LatestMessages) => {
      const currentState = prevState || { messages: [] };

      return {
        ...currentState,
        messages: [
          {
            id: content,
            userId: session.id,
            name: session.firstName,
            content,
            isSended: false,
          },
          ...currentState?.messages,
        ],
      };
    },
    false,
  );

export const mutateMessageSended = (url: string, message: Message) =>
  mutate(
    url,
    (prevState: LatestMessages = { messages: [] }) => {
      const targetMessage =
        prevState.messages.find(
          ({ content, id }) => id === content && content === message.content,
        ) ?? [];

      const messagesWithoutTarget =
        prevState.messages.filter(({ id, content }) => id !== content) ?? [];

      return {
        ...prevState,
        messages: [
          {
            ...targetMessage,
            ...message,
            isSended: true,
            read: true,
          },
          ...messagesWithoutTarget,
        ],
      };
    },
    false,
  );

export const mutateOnPagination = (url: string, messages: Message[]) =>
  mutate(
    url,
    (prevState: LatestMessages = { messages: [] }) => ({
      ...prevState,
      messages: [...prevState?.messages, ...messages],
    }),
    false,
  );

export const mutateOnError = (url: string) =>
  mutate(
    url,
    (prevState: LatestMessages = { messages: [] }) => ({
      ...prevState,
      messages: [
        ...(prevState?.messages?.filter(({ isSended }) => !isSended) ?? []),
      ],
    }),
    false,
  );

export const mutateOnMessageDeleted = (
  url: string,
  messageId: string,
): Promise<LatestMessages> =>
  mutate(
    url,
    (prevState: LatestMessages = { messages: [] }) => {
      const filterByDeletedMessage = [
        ...prevState?.messages.filter(({ id }) => id !== messageId),
      ];

	  
      return {
        ...prevState,
        messages: filterByDeletedMessage,
      };
    },
    false,
  );

export const appendUserChats = (url: string, chats: ChatList[] = []) =>
  mutate(
    url,
    (prevState: ChatListAPI = { chats: [] }) => ({
      ...prevState,
      chats: [...prevState.chats, ...chats],
    }),
    false,
  );

export const hasLastMessage = (lastMessage: Message) =>
  !!lastMessage?.content && !lastMessage.read;
