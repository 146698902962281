import { useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';

function usePortal() {
  const portalElRef = useRef(document.createElement('div'));

  useEffect(() => {
    const portalEffectRef = portalElRef.current;
    document.body.appendChild(portalEffectRef);

    return () => {
      if (portalEffectRef && document?.body) {
        document.body.removeChild(portalEffectRef);
      }
    };
  }, [portalElRef]);

  const Portal = useCallback(
    ({ children }) =>
      portalElRef.current !== null
        ? createPortal(children, portalElRef.current)
        : null,
    [portalElRef],
  );

  return Portal;
}

export default usePortal;
