import { memo } from 'react';
import { getEventThemeName } from 'lib/core/event';
import { ProjectNames } from 'constants/enums';
import { ConfigurationsPersonalData as ConfigurationsPersonalDataDefault } from './ConfigurationsPersonalData';
import { ConfigurationsPersonalData as ConfigurationsPersonalDataBayer } from './ConfigurationsPersonalData.Bayer';

export const ConfigurationsPersonalData = memo(() => {
  const isBayerEvent = getEventThemeName() === ProjectNames.BAYER;

  return isBayerEvent ? (
    <ConfigurationsPersonalDataBayer />
  ) : (
    <ConfigurationsPersonalDataDefault />
  );
});
