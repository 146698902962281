import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

export const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 900 506"
    >
      <rect x="0" y="0" width="100%" height="506" />
    </ContentLoader>
  );
};

const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 331 200"
    >
      <rect x="0" y="0" width="100%" height="200px" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch lessThan="medium">
      <SkeletonMobile />
    </MediaMatch>

    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
  </>
);
