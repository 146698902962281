import { createContext, useContext, useState } from 'react';
import { getId } from 'lib/contexts';
// import { getLocalHourFromDate } from 'lib/contexts';
import { WithChildren } from 'interfaces/children';
import { NotificationItem } from 'interfaces/notification';
import { NotificationTypes } from 'constants/enums';

export type NotificationProps = {
  name: string;
  id: string;
  index?: string;
  type: NotificationTypes;
  value: string;
  time?: string;
  excerpt?: string;
  group?: boolean;
};

export type NotificationMessageProps = {
  name: string;
  id: string;
  time?: string;
};

type ContextType = {
  notifications: NotificationItem[];
  push: (notification: NotificationProps) => void;
  get: (id: string, type: NotificationTypes) => NotificationItem | undefined;
  setViewed: (id: string, type: NotificationTypes, value?: boolean) => void;
  clearAll: () => void;
  pushMessage: (notification: NotificationMessageProps) => void;
};

const defaultValue: ContextType = {
  notifications: [],
  push: () => undefined,
  clearAll: () => undefined,
  setViewed: () => undefined,
  get: () => undefined,
  pushMessage: () => undefined,
};
export const NotificationContext = createContext<ContextType>(defaultValue);

export const NotificationProvider = ({ children }: WithChildren) => {
  const [notifications, setNotifications] = useState(
    defaultValue.notifications,
  );

  const push = (notification: NotificationProps) => {
    setNotifications((prevState) => {
      const current = prevState;
      const index = getId(notification.id, notification.type);
      const first = prevState.find((item) => item.index === index);
      if (first && notification.group) {
        current.splice(current.indexOf(first), 1, {
          ...first,
          index,
          count: (first?.count || 0) + 1,
          viewed: false,
          value: notification.value,
          time: notification.time ? new Date(notification.time) : new Date(),
        });

        return [...current];
      }
      current.push({
        ...notification,
        index,
        count: 1,
        viewed: false,
        time: notification.time ? new Date(notification.time) : new Date(),
      });

      return [...current];
    });
  };

  const setViewed = (id: string, type: NotificationTypes, value = true) => {
    setNotifications((prevState) =>
      prevState.map((notificationRow) => {
        const isNotification = getId(id, type) === notificationRow.index;
        const notificationId = getId(notificationRow.id, notificationRow.type);
        return {
          ...notificationRow,
          viewed: isNotification ? value : notificationRow.viewed,
          index:
            isNotification || notificationRow.viewed
              ? `${notificationId}-${notificationRow.time.toISOString()}-${value}`
              : notificationId,
        };
      }),
    );
  };

  const pushMessage = (notification: NotificationMessageProps) => {
    push({
      ...notification,
      value: notification.id,
      group: true,
      type: NotificationTypes.MESSAGE,
    });
  };

  const get = (id: string, type: NotificationTypes) =>
    notifications.find((notification) => notification.id === getId(id, type));

  const clearAll = () => setNotifications(() => []);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        push,
        clearAll,
        setViewed,
        get,
        pushMessage,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);
