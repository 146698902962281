import { useState } from 'react';
import { dispatchGAEvent } from 'services/google';
import { MaterialItem } from 'components/contexts/Materials';
import { Gallery } from 'components/contexts/Materials';
import { Preview } from 'components/contexts/Materials';
import { SubTitle } from 'components/structure';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { GA_EVENTS } from 'constants/enums';
import * as S from './SectionMaterial.syles';

export enum MaterialsTypes {
  Video = 'videos',
  Document = 'document',
  Phtos = 'photos',
  Image = 'image',
  Chart = 'chart',
  BROLL = 'b-roll',
  link = 'link',
  url = 'url',
}

export type Option = {
  name: string;
  cover: string;
  type: MaterialsTypes;
  url?: string;
  items?: string[];
  downloadUrl?: string;
  link?: React.ElementType;
};

export type SectionMaterialProps = {
  category: string;
  options: Option[];
  isVideo?: boolean;
};

type GenericMaterial = {
  show?: boolean;
  url?: string;
  downloadUrl?: string;
  videos?: string[];
  images?: string[];
};

const shouldBeLinks = ['chart', 'external'];

export const SectionMaterial = ({
  isVideo,
  options,
  category,
}: SectionMaterialProps) => {
  const [document, setDocument] = useState<GenericMaterial>({
    show: false,
    url: '',
  });
  const [video, setVideo] = useState<GenericMaterial>({
    show: false,
    url: '',
    downloadUrl: '',
  });
  const [photos, setPhotos] = useState<GenericMaterial>({
    show: false,
    images: [''],
  });
  const [bRoll, setBRoll] = useState<GenericMaterial>({
    show: false,
    videos: [''],
  });

  const handleClick = (
    url: string,
    type: MaterialsTypes,
    images: string | string[],
  ) => {
    if (type === MaterialsTypes.link || type === MaterialsTypes.url) {
      const isUrlDev = url.includes('https://storage-dev.tm1.com.br/');

      const newUrlPrd = url.split('https://storage.metasummitlatam.com/');
      const newUrlDev = url.split('https://storage-dev.tm1.com.br/');

      window.open(isUrlDev ? newUrlDev[1] : newUrlPrd[1], '_blank');
      return;
    }
    if (type === MaterialsTypes.Image) {
      return setPhotos({
        show: true,
        images: Array.isArray(images) ? images : [images],
      });
    }

    if (type === MaterialsTypes.Video) {
      return setVideo({
        show: true,
        url,
      });
    }

    if (!shouldBeLinks.includes(type)) {
      setDocument({ show: true, url });
    }
  };

  // const handleSelectMaterial = (type: MaterialTypes) => {
  //   const handles = {
  //     document: ({ url }: Option) => setDocument({ show: true, url }),
  //     chart: ({ url }: Option) => setDocument({ show: false, url }),
  //     photos: ({ items, url }: Option) =>
  //       setPhotos({ show: true, images: items, url }),
  //     video: ({ url, downloadUrl }: Option) =>
  //       setVideo({ show: true, url, downloadUrl }),
  //     'b-roll': ({ items }: Option) => setBRoll({ show: true, videos: items }),
  //   };

  //   return handles[type] || handles.chart;
  // };
  // TODO check crash in safari when use handleSelectMaterial
  return (
    <S.Container>
      <SubTitle>{category}</SubTitle>
      <S.Content isVideo={isVideo}>
        {options
          .slice(0, 3)
          .map(({ url = '', type, cover, name }: Option, index) => (
            <MaterialItem
              onClick={(e) => {
                e.stopPropagation();
                handleClick(url, type, url);
                dispatchGAEvent({
                  category: EventCategory.Materials,
                  action: EventAction.click,
                  label: GA_EVENTS.materials.labels.selectedMaterial(name),
                });
              }}
              as={shouldBeLinks.includes(type) ? 'a' : 'div'}
              isVideo={type === MaterialsTypes.Video}
              key={name}
              cover={cover}
              material={options[index]}
              href={url}
              target={shouldBeLinks.includes(type) ? '_blank' : '_self'}
            />
          ))}
      </S.Content>

      <Gallery
        handleCloseModal={() =>
          setBRoll((prevState) => ({ ...prevState, show: false }))
        }
        videos={bRoll.videos}
        showModal={bRoll.show!}
      />
      <Gallery
        handleCloseModal={() =>
          setPhotos((prevState) => ({ ...prevState, show: false }))
        }
        images={photos.images}
        showModal={photos.show!}
      />
      <Preview
        handleCloseModal={() =>
          setVideo((prevState) => ({ ...prevState, show: false }))
        }
        videoUrl={video.url}
        showModal={video.show!}
      />
      <Preview
        handleCloseModal={() =>
          setDocument((prevState) => ({ ...prevState, show: false }))
        }
        fileUrl={document.url}
        showModal={document.show!}
      />

    </S.Container>
  );
};
