import { useIntl } from 'react-intl';
import { dateFormatHandler } from 'lib/contexts/date';
import { ucFirst } from 'lib/contexts/text';

export const useFormatDate = (dates: string[]) => {
  const { formatTimeToParts } = useIntl();

  return dates.map((date: string) => {
    const [day, month] = formatTimeToParts(date, {
      month: 'short',
      day: '2-digit',
      timeZone: 'utc',
    }).filter((part) => ['day', 'month'].includes(part.type));

    return {
      value: dateFormatHandler(date),
      text: `${day.value} ${ucFirst(month.value.replace('.', ''))}`,
    };
  });
};
