/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useSponsors } from 'useCases/sponsors';
import { useTranslate } from 'hooks';
import { SponsorItem } from 'components/contexts/sponsors/SponsorItem';
import { Heading } from 'components/structure';
import { Skeleton } from './Sponsors.skeleton';
import * as S from './Sponsors.styles';

export const Sponsors = () => {
  const translate = useTranslate();
  // const { data, isLoading } = useSponsors();
  const [data, setData] = useState<any>();
  useEffect(() => {
    (async () => {
      const result = await fetch(process.env.REACT_APP_SPONSORS_BASE_PATH!)
      const resultJson = await result.json()
      setData(resultJson)
    })()
  }, [])
  if (!data) {
    return <Skeleton />;
  }

  return (
    <S.Wrapper>
      <Heading>{translate('labels.sponsors')}</Heading>
      <S.Content>
        {data?.map((sponsor: any) => (
          <SponsorItem key={sponsor.category} {...sponsor} />
        ))}
      </S.Content>
    </S.Wrapper>
  );
};
