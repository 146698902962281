import PublicIp from 'public-ip';
import { useEffect, useState } from 'react';

export const useIpAddress = (): string => {
  const [ip, setIp] = useState<string>('');

  useEffect(() => {
    const getIp = async () => {
      try {
        const ip = await PublicIp.v4({ onlyHttps: true });
        setIp(ip);
      } catch (error) {
        throw new Error(error.message);
      }
    };

    getIp();
  }, []);

  return ip;
};
