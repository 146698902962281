import { AxiosRequestConfig } from 'axios';
import useSWR, { ConfigInterface } from 'swr';
import { useAPI } from './useAPI';

const useFetch = <Data = unknown, Error = unknown>(
  url: string,
  axiosConfigs?: AxiosRequestConfig | undefined,
  config?: ConfigInterface,
) => {
  const api = useAPI();
  const { data, error, mutate, revalidate, isValidating } = useSWR<Data, Error>(
    url,
    async (url) => {
      const response = await api.get(url, axiosConfigs);
      return response.data;
    },
    config,
  );

  return {
    data,
    error,
    isError: !!error,
    isLoading: !error && !data && String(data) !== '',
    isValidating,
    mutate,
    revalidate,
  };
};

export default useFetch;
