import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as UserCardStyles from 'components/contexts/users/UserCard/UserCard.styles';
import { DefaultScroll } from 'components/structure';

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px ${theme.spacing.sm} 0;
    position: relative;
    height: 64px;
    width: 100%;
    flex: 0 0 64px;

    ${media.lessThan('medium')`
      padding: ${theme.spacing.xs} ${theme.spacing.sm};
    `}
  `}
`;

export const ReturnButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.text.primary};
    margin-right: 20px;
    height: 24px;
    width: 24px;

    svg {
      transform: rotate(90deg);
      height: 10px;
      width: 14px;
    }
  `}
`;

type MessagesProps = {
  device?: string;
};

export const Messages = styled.div<MessagesProps>`
  ${({ device }) => css`
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    ${device === 'iPhone'
      ? css`
          scroll-behavior: smooth;
        `
      : ''}
    /* -webkit-overflow-scrolling: touch; */
    overflow-anchor: none;
    padding: ${device === 'iPhone' ? '24px 0px 16px 8px' : '24px 0px 16px 8px'};
    margin-top: auto;
    z-index: -1;

    ${DefaultScroll};
  `}
`;

export const SpinnerWrapper = styled.div`
  height: 80px;
`;
export const DeleteMessage = styled.div`
  ${({ theme }) => css`
    background-color: white;
    padding: 20px 20px;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    width: 90%;
    height: 220px;
    position: absolute;
    opacity: 1;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    visibility: visible;
    z-index: 1;

    ${media.lessThan('medium')`
      background-color: white;
      width: 90%;
      height: 220px;
      left: 50%;
      top: 20%;
      /* transform: translateX(0); */

      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 15px;
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    color: ${theme.text.primary};
    display: flex;
    justify-content: end;
    height: 24px;
    width: 100%;
  `}
  svg {
    color: black;
  }
`;
export const tittle = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: black;
`;
export const BoxButton = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;
  .button {
    width: 120px;
    height: 30px;
    color: white;
    border-radius: 5px;
    font-weight: 600;
  }
  .buttonDelete {
    background-color: green;
  }
  .buttonCancel {
    background-color: red;
  }
`;

export const Modal = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #0f0f0f7f;
  top: 0;
  left: 0;

  ${UserCardStyles.Wrapper} {
    visibility: visible;
    opacity: 1;
    /* transform: translate(-50%, -44%); */
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999 !important;

    ${media.lessThan('medium')`
      left: 0;
      top: -21px;
      right: 0;
      /* bottom: 100%; */
      transform: unset;
    `}

    ${UserCardStyles.Container} {
      transition: transform 0.3s ease-in-out;
      z-index: 9999;
    }
  } ;
`;
