import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

export const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 930 300"
    >
      <rect x="24" y="10" width="262" height="20" />

      <rect x="80" y="80" width="200" height="14" />
      <rect x="80" y="100" width="200" height="14" />
      <rect x="60" y="140" width="240" height="30" rx="4" />
      <rect x="80" y="190" width="200" height="14" />

      <rect x="340" y="60" width="220" height="14" />

      <rect x="360" y="100" width="30" height="30" rx="15" />
      <rect x="400" y="100" width="120" height="30" rx="4" />

      <rect x="360" y="160" width="30" height="30" rx="15" />
      <rect x="400" y="160" width="120" height="30" rx="4" />

      <rect x="360" y="210" width="30" height="30" rx="15" />
      <rect x="400" y="210" width="120" height="30" rx="4" />

      <rect x="640" y="110" width="160" height="14" />
      <rect x="610" y="130" width="220" height="30" rx="4" />
      <rect x="610" y="165" width="220" height="30" rx="4" />
      <rect x="610" y="200" width="220" height="30" rx="4" />
    </ContentLoader>
  );
};

export const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 327 740"
    >
      <rect x="24" y="0" width="262" height="20" />

      <rect x="60" y="80" width="200" height="14" />
      <rect x="60" y="100" width="200" height="14" />
      <rect x="40" y="140" width="240" height="30" rx="4" />
      <rect x="60" y="190" width="200" height="14" />

      <rect x="105" y="220" width="30" height="30" rx="15" />
      <rect x="145" y="220" width="30" height="30" rx="15" />
      <rect x="185" y="220" width="30" height="30" rx="15" />

      <rect x="24" y="290" width="280" height="14" />

      <rect x="60" y="320" width="30" height="30" rx="15" />
      <rect x="100" y="320" width="140" height="30" rx="4" />

      <rect x="60" y="390" width="30" height="30" rx="15" />
      <rect x="100" y="390" width="140" height="30" rx="4" />

      <rect x="60" y="460" width="30" height="30" rx="15" />
      <rect x="100" y="460" width="140" height="30" rx="4" />

      <rect x="45" y="550" width="240" height="14" />
      <rect x="24" y="590" width="280" height="30" rx="4" />
      <rect x="24" y="630" width="280" height="30" rx="4" />
      <rect x="24" y="670" width="280" height="30" rx="4" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <SkeletonMobile />
    </MediaMatch>
  </>
);
