import { ProjectNames } from 'constants/enums';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const url = new URL(window.location.href);

export const isDevHost = () =>
  process.env.NODE_ENV === 'development' ||
  url.hostname.startsWith('plataforma.dev') ||
  url.hostname.startsWith('host.tm1');

export const getEventId = () => {
  const eventId = urlParams.get('eventId');
  if (isDevHost() && urlParams.has('eventId')) {
    return eventId ?? process.env.REACT_APP_EVENT_ID;
  }
  return process.env.REACT_APP_EVENT_ID;
};

export const getEventThemeName = () => {
  const theme = urlParams.get('theme');
  const name = process.env.REACT_APP_THEME ?? ProjectNames.SXSW;

  if (isDevHost() && urlParams.has('theme')) {
    return theme?.toUpperCase() ?? name;
  }

  return name;
};

export const getFont = (font = 'Silka') => {
  const fallbackFonts =
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif";

  return `${font}, ${fallbackFonts}`;
};
