import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 331 875"
    >
      <circle cx="40" cy="40" r="40" />

      <rect x="96" y="10" width="201" height="23" />
      <rect x="96" y="50" width="127" height="24" />
    </ContentLoader>
  );
};

const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 630 630"
    >
      <circle cx="40" cy="40" r="40" />

      <rect x="96" y="10" width="201" height="23" />
      <rect x="96" y="50" width="127" height="24" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <SkeletonMobile />
    </MediaMatch>
  </>
);
