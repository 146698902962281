/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { UpdateForgotPassword } from 'useCases/auth';
import { useTranslate } from 'hooks';
import { getEventThemeName } from 'lib/core/event';
import { PasswordField, TextField } from 'components/form';
import {
  Button,
  CFCode,
  Form,
  FormActions,
  FormGroup,
  Heading,
  Paragraph,
	TrickSpace,
} from 'components/structure';
import { ProjectNames } from 'constants/enums';
import { defaultValues, schema } from './ChangePasswordForm.validations';

export type ChangePassword = Yup.InferType<typeof schema>;
export type ChangePasswordFormProps = {
  onSuccess: () => void;
  onFailure: () => void;
};

type ChangePasswordProps = {
  onSubmit: (payload: ChangePassword) => void;
  isPending?: boolean;
	handleUpdatePassword(params: UpdateForgotPassword): any
};

export const ChangePasswordForm = ({
  onSubmit,
  isPending,
	// handleUpdatePassword
}: ChangePasswordProps) => {
	const translate = useTranslate();
  const { register, errors, handleSubmit, watch } = useForm({
		resolver: yupResolver(schema),
    defaultValues,
    shouldFocusError: false,
  });
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Heading>{'Redefinir ID profissional'}</Heading>
      <Paragraph>{'Excluímos o seu ID Profissional incorreto. Por favor, preencha com o ID correto.'}</Paragraph>

      <FormGroup>
        <TextField
          ref={register}
          name="newPassword"
          autoComplete="new-password"
          label={'ID Profissional/CRM'}
          placeholder={'Seu ID Profissional/CRM'}
          error={!!errors?.newPassword?.message}
          helperText={translate(errors?.newPassword?.message)}
          minLength={6}
          maxLength={ProjectNames.VALE === getEventThemeName() ? 6 : 100}
          // showStrength
        />
				<TrickSpace />
        {/* <PasswordField
          ref={register}
          name="rePassword"
          autoComplete="new-password"
          label={translate('labels.rePassword')}
          placeholder={translate('labels.rePassword')}
          error={!!errors?.rePassword?.message}
          helperText={translate(errors?.rePassword?.message)}
          minLength={6}
          maxLength={ProjectNames.VALE === getEventThemeName() ? 6 : 100}
        /> */}
        <input type="hidden" name="token" ref={register} />
      </FormGroup>

      <FormActions>
        <CFCode />
        <Button
          fullWidth
          type="submit"
          disabled={isPending}
          isLoading={isPending}
        >
          {translate('labels.updatePassword')}
        </Button>
      </FormActions>
    </Form>
  );
};
