import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { UserChat } from 'components/contexts/interactions';
import { ChatParams } from 'interfaces/chat';
import { WithChildren } from 'interfaces/children';

type Room = UserChat | undefined;
type ConversationParams = {
  [key: string]: string;
};

type ContextType = {
  plenary: UserChat;
  setPlenary: Dispatch<SetStateAction<UserChat>>;
  room: Room;
  setRoom: Dispatch<SetStateAction<Room>>;
  showRoom: boolean;
  setShowRoom: (show: boolean) => void;
  conversationParams: ConversationParams;
  handleConversationParams: (url: string) => void;
  userChatsParams: ChatParams;
  setUserChatsParams: (params: ChatParams) => void;
};

const defaultValue: ContextType = {
  plenary: { id: '', name: '' },
  setPlenary: () => undefined,
  room: undefined,
  setRoom: () => undefined,
  showRoom: false,
  setShowRoom: () => undefined,
  conversationParams: {},
  handleConversationParams: () => undefined,
  userChatsParams: {},
  setUserChatsParams: () => undefined,
};

export const ChatContext = createContext<ContextType>(defaultValue);

export const ChatProvider = ({ children }: WithChildren) => {
  const [plenary, setPlenary] = useState<UserChat>(defaultValue.plenary);
  const [room, setRoom] = useState<Room>(defaultValue.room);
  const [showRoom, setShowRoom] = useState(defaultValue.showRoom);

  const [
    conversationParams,
    setConversationParams,
  ] = useState<ConversationParams>(defaultValue.conversationParams);
  const [userChatsParams, setUserChatsParams] = useState<ChatParams>(
    defaultValue.userChatsParams,
  );

  const handleConversationParams = (url: string) => {
    if (room?.id) {
      setConversationParams({
        ...conversationParams,
        [room?.id]: url,
      });
    }
  };
  return (
    <ChatContext.Provider
      value={{
        plenary,
        setPlenary,
        room,
        setRoom,
        showRoom,
        setShowRoom,
        conversationParams,
        handleConversationParams,
        userChatsParams,
        setUserChatsParams,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => useContext(ChatContext);
