import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  flex: none;
`;

const ToggleSlider = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 12px; /* Ajuste o tamanho da altura da bolinha */
    width: 12px; /* Ajuste o tamanho da largura da bolinha */
    left: 6px; /* Ajuste a posição esquerda da bolinha */
    bottom: 6px; /* Ajuste a posição inferior da bolinha */
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.4s;
  }
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSlider} {
    background-color: ${({ theme }) => theme.primary};
  }

  &:checked + ${ToggleSlider}:before {
    transform: translateX(16px);
  }
`;

const SwitchToggle = (props: any) => {
  const [isChecked, setIsChecked] = useState(true);

  const handleToggleChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <ToggleSwitch>
      <ToggleInput
        type="checkbox"
        checked={isChecked}
        onChange={handleToggleChange}
        {...props}
        ref={props.register}
      />
      <ToggleSlider />
    </ToggleSwitch>
  );
};

export default SwitchToggle;
