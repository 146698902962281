import accreditation from './accreditation.json';
import auth from './auth.json';
import coffee from './coffee.json';
import configurations from './configurations.json';
import errorGuard from './error-guard.json';
import errors from './errors.json';
import footer from './footer.json';
import helpCenter from './help-center.json';
import interactions from './interactions.json';
import labels from './labels.json';
import materials from './materials.json';
import navbar from './navbar.json';
import notification from './notification.json';
import rewards from './rewards.json';
import routes from './routes.json';
import schedule from './schedule.json';
import stream from './stream.json';
import users from './users.json';

export const accreditationEN = {
  accreditation: accreditation,
};
export const authEN = {
  auth: auth,
};
export const coffeeEN = {
  coffee: coffee,
};
export const configurationsEN = {
  configurations: configurations,
};
export const errorGuardEN = {
  errorGuard: errorGuard,
};
export const footerEN = {
  footer: footer,
};
export const helpCenterEN = {
  helpCenter: helpCenter,
};
export const interactionsEN = {
  interactions: interactions,
};
export const materialsEN = {
  materials: materials,
};
export const navbarEN = {
  navbar: navbar,
};
export const notificationEN = {
  notification: notification,
};
export const rewardsEN = {
  rewards: rewards,
};
export const routesEN = {
  routes: routes,
};
export const scheduleEN = {
  schedule: schedule,
};
export const streamEN = {
  stream: stream,
};
export const usersEN = {
  users: users,
};
export const labelsEN = {
  labels: labels,
};
export const errorsEN = {
  errors: errors,
};
