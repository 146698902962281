import { useState, useEffect } from 'react';
import { useQuestions, Params } from 'useCases/help-center';
import { useTranslate, useDebounce } from 'hooks';
import { useSettingsContext } from 'contexts';
import { MostWantedQuestions } from 'components/contexts/help-center';
import { Heading } from 'components/structure';
import * as S from './HelpCenter.styles';

export type Questions = {
  pt: {
    id: string;
    question: string;
    answer: string;
  }[];
};

export const HelpCenter = () => {
  const translation = useTranslate();
  const { setHasInteraction, setHasNotification } = useSettingsContext();
  const [params, setParams] = useState<Params>({});
  const { data, isLoading } = useQuestions(params);
  const handleNextPageOfFilter = useDebounce(
    ({ text, page = 1 }: Params) => setParams({ text, page }),
    500,
  );

  //setando interaction como false
  useEffect(() => {
    setHasInteraction(false);
    setHasNotification(false);

    return () => {
      setHasInteraction(true);
      setHasNotification(true);
    };
  }, [setHasInteraction, setHasNotification]);

  return (
    <>
      <S.Container>
        <S.Content>
          <Heading>{translation('helpCenter.title')}</Heading>
          <MostWantedQuestions
            isLoading={isLoading}
            handleNextPageOfFilter={(values) => handleNextPageOfFilter(values)}
            questions={data?.faqs as Questions}
          />
        </S.Content>
      </S.Container>
    </>
  );
};
