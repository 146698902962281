import styled, { css, keyframes } from 'styled-components';

export const LoaderWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.sm};
    position: relative;
    height: 110px;
    width: 110px;
  `}
`;

export const Value = styled.span`
  ${({ theme }) => css`
    color: ${theme.primary};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: ${theme.typography.sizes.lg};
    font-family: ${theme.typography.family.primary};
    font-weight: 700;
    line-height: 1;
  `}
`;

const rotate = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const rotateInside = keyframes`
  0% {
    transform: rotate(0) translate(-50%, -50%);
  }
  100% {
    transform: rotate(360deg) translate(-50%, -50%);
  }
`;

export const Loader = styled.div`
  ${({ theme }) => css`
    animation: 1.5s ${rotate} ease-out infinite;
    border: 3px solid ${theme.primary};
    border-left-color: transparent;
    border-top-color: transparent;
    border-radius: 50%;
    position: relative;
    height: 100%;
    width: 100%;
  `}
`;

export const LoaderInside = styled.div`
  ${({ theme }) => css`
    animation: 2s ${rotateInside} ease-out infinite reverse;
    border: 3px solid ${theme.primary};
    border-left-color: transparent;
    border-top-color: transparent;
    border-radius: 50%;
    position: absolute;
    transform-origin: top left;
    top: 50%;
    left: 50%;
    opacity: 0.5;
    height: 80%;
    width: 80%;
  `}
`;
