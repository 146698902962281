export const base = process.env.REACT_APP_API_BASE_PATH;
export const baseWebSocket = process.env.REACT_APP_API_BASE_WEB_SOCKET_PATH;
const baseJobs = process.env.REACT_APP_JOBS_BASE_PATH;

const version = '';
const assetsPrefix = 'assets';
const authPrefix = 'auth';
const servicesTerms = 'service-terms';
const eventPrefix = 'events';
const streamsPrefix = 'streams';
const surveysPrefix = 'surveys';
const schedulesPrefix = 'schedules';
const questionsPrefix = 'faq';
const materialsPrefix = 'materials';
const coffePrefix = 'coffees';
const chatPrefix = 'chats';
const integrations = 'integrations';
const memberGetMembersPrefix = 'member-get-members';
const offersPrefix = 'offers';
const certificatePrefix = 'certificates';

export const WEBSOCKET_NOTIFICATIONS = `${baseWebSocket}/notifications`;

export const ASSETS = {
  COUNTRIES: `${base}/${assetsPrefix}/countries`,
  PRE_SIGNED: `${base}/${assetsPrefix}/s3/pre-signed-url`,
  CERTIFICATE: `${baseJobs}/api/v1/${certificatePrefix}`,
};

export const AUTH = {
  SIGN_IN: `${base}/${authPrefix}/sign-in`,
  FORGOT_PASSWORD: `${base}/${authPrefix}/forgot-password`,
  REFRESH_TOKEN: `${base}/${authPrefix}/refresh-token`,
  LOGOUT: `${base}/${authPrefix}/logout`,
  CONFIRMATION: `${base}/${authPrefix}/confirmation`,
  //TODO change endpoint after back fix
  UPDATE_PASSWORD: (token: string | undefined) =>
    `${base}/${authPrefix}/${token}/reset-password`,
};

export const SERVICES_TERMS = {
  BY_EVENT_ID: (id: string) => `${base}/${servicesTerms}/by-event/${id}`,
};

export const SURVEYS = {
  BY_ID: (id: string) => `${base}/${surveysPrefix}/${id}`,
  ANSWERS: (id: string) => `${base}/${surveysPrefix}/${id}/answers`,
};

export const EVENTS = {
  BY_ID: (id: string) => `${base}/${eventPrefix}/${id}`,
};

export const SCHEDULES = {
  ROOT: `${base}/${schedulesPrefix}`,
  BY_ID: (id: string) => `${base}/${schedulesPrefix}/topics/${id}`,
  BY_EVENT: `${base}/${schedulesPrefix}/by-event`,
};

export const USERS = {
  ROOT: `${base}/users`,
  BY_ID: (id: string) => `${base}/users/${id}`,
  BY_UUID: (uuid: string) => `${base}/users/exists/${uuid}`,
  ONLINE: `${base}/chats`,
  ONLINE_BY_COUNTRIES: `${base}/countries`,
  ONLINE_BY_INTERESTS: (interests: string[]) =>
    `${base}/chats/online-by-interests?interests=${interests.join(
      '&interests=',
    )}`,
};

export const STREAMS = {
  ROOT: `${base}/${streamsPrefix}`,
  BY_ID: (id: string) => `${base}/${streamsPrefix}/${id}`,
};

export const MATERIALS = {
  ROOT: `${base}/${materialsPrefix}`,
};

export const HELP_CENTER = {
  ROOT: `${base}/${questionsPrefix}`,
};

export const COFFEE = {
  ROOT: `${base}/${coffePrefix}`,
  USERS_ONLINE: `${base}/${coffePrefix}/countries`,
};

export const CHAT = {
  ROOT: `${base}/${chatPrefix}`,
  USER_CHATS: `${base}/${chatPrefix}/user-chats`,
  LATEST_MESSAGES: (chatId: string) =>
    `${base}/${chatPrefix}/${chatId}/latest-messages`,
  USER_CHATS_BY_COUNTRY: `${base}/${chatPrefix}`,
};

export const INTEGRATIONS = {
  INSTAGRAM: `${base}/${integrations}/instagram`,
};

export const MEMBER_GET_MEMBER = {
  ROOT: `${base}/${memberGetMembersPrefix}`,
  RANKINGS: `${base}/${memberGetMembersPrefix}/rankings`,
};

export const OFFERS = {
  ROOT: `${base}/${offersPrefix}`,
};

export const baseURL = `${base}/${version}/`;
