import { createContext, useContext, useCallback, useEffect } from 'react';
import { useSocketContext, useChatContext, useModalContext } from 'contexts';
import { WithChildren } from 'interfaces/children';
import { ChatEvents } from 'constants/enums';
import { useTranslate } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/urls';
import { Logo } from 'components/structure';

type ContextType = {
  handleJoinPrivateChannel: (
    userId: string,
    startedByUser?: boolean,
    cb?: () => void,
  ) => void;
  handleJoinStreamChannel: (streamId: string, callback?: () => void) => void;
};

const defaultValue = {
  handleJoinPrivateChannel: () => undefined,
  handleJoinStreamChannel: () => undefined,
};

export const ChatHandlersContext = createContext<ContextType>(defaultValue);

export const ChatHandlersProvider = ({ children }: WithChildren) => {
  const { chatSocket: socket, resetChatSocket } = useSocketContext();
  const { setShowRoom } = useChatContext();

  const translate = useTranslate();
  const navigate = useNavigate();
  const { setModalProps, setShowModal } = useModalContext();
  const handleJoinPrivateChannel = useCallback(
    
    (userId: string, startedByUser = true, callback) => {
      if (startedByUser) {
        setShowRoom(true);
      }

      socket.emit(ChatEvents.JOIN_PRIVATE_CHANNEL, {
        userId,
        startedByUser,
      });

      callback && callback();
    },
    [setShowRoom, socket],
  );

  const handleJoinStreamChannel = useCallback(
    (streamId: string, callback) => {
      socket.emit(ChatEvents.JOIN_STREAM_CHANNEL, {
        streamId,
      });

      callback && callback();
    },
    [socket],
  );
    // useEffect(() => {
      // socket.on('connect_error', async () => {
        // setModalProps({
        //   title: translate('auth.connectedFromAnotherDevice.title'),
        //   description: translate('auth.connectedFromAnotherDevice.description'),
        //   buttonLabel: translate('labels.understood'),
        //   onClose: () => {
        //     localStorage.clear();
        //     return navigate(ROUTES.accreditation.getLink('gettingStarted'));
        //   },
        //   icon: <Logo medium light />,
        //   noBackground: true,
        // });
        // setShowModal(true);

        // await new Promise(resolve => setTimeout(resolve, 5000))
        // resetChatSocket()
      // })
    // }, [socket])
  return (
    <ChatHandlersContext.Provider
      value={{ handleJoinPrivateChannel, handleJoinStreamChannel }}
    >
      {children}
    </ChatHandlersContext.Provider>
  );
};

export const useChatHandlersContext = () => useContext(ChatHandlersContext);
