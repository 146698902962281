import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';

const SkeletonMessages = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 328 232"
      style={{ margin: '16px', marginTop: 'auto' }}
    >
      <rect y="144" width="215" height="40" rx="8" />
      <rect x="98" y="96" width="230" height="40" rx="8" />
      <rect x="142" width="186" height="40" rx="8" />
      <rect y="48" width="154" height="40" rx="8" />
      <rect y="192" width="100" height="40" rx="8" />
    </ContentLoader>
  );
};

export const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <>
      <ContentLoader
        {...skeletonProps}
        {...colors.skeleton}
        viewBox="0 0 186 33"
        style={{ margin: '16px' }}
      >
        <rect y="9" width="14" height="14" />
        <rect x="76" y="10" width="110" height="16" />
        <circle cx="50.5" cy="16.5" r="16.5" />
      </ContentLoader>
      <SkeletonMessages />
      <ContentLoader
        {...skeletonProps}
        {...colors.skeleton}
        viewBox="0 0 328 48"
        style={{ margin: '16px' }}
      >
        <rect width="328" height="48" rx="24" />
      </ContentLoader>
    </>
  );
};

export const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <>
      <ContentLoader
        {...skeletonProps}
        {...colors.skeleton}
        viewBox="0 0 186 33"
        style={{ margin: '16px' }}
      >
        <rect y="9" width="14" height="14" />
        <rect x="76" y="10" width="110" height="16" />
        <circle cx="50.5" cy="16.5" r="16.5" />
      </ContentLoader>
      <ContentLoader
        {...skeletonProps}
        {...colors.skeleton}
        viewBox="0 0 328 90"
        style={{ margin: '16px', marginTop: 'auto' }}
      >
        <rect y="144" width="215" height="40" rx="8" />
        <rect x="98" y="96" width="230" height="40" rx="8" />
        <rect x="142" width="186" height="40" rx="8" />
        <rect y="48" width="154" height="40" rx="8" />
      </ContentLoader>
      <ContentLoader
        {...skeletonProps}
        {...colors.skeleton}
        viewBox="0 0 328 48"
      >
        <rect width="328" height="48" rx="24" />
      </ContentLoader>
    </>
  );
};

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MediaDesktop = styled(SkeletonWrapper)`
  display: none;

  ${media.greaterThan('medium')`
    display: flex;
  `}
`;

const MediaMobile = styled(SkeletonWrapper)`
  display: none;
  min-height: 240px;

  ${media.lessThan('medium')`
    display: flex;
  `}
`;

export const Skeleton = () => (
  <SkeletonWrapper>
    <MediaDesktop>
      <SkeletonDesktop />
    </MediaDesktop>
    <MediaMobile>
      <SkeletonMobile />
    </MediaMobile>
  </SkeletonWrapper>
);
