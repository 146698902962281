import { AnchorHTMLAttributes } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { ToastProps } from './Toast';

type WrapperProps = Pick<
  ToastProps,
  'show' | 'type' | 'hasChat' | 'playerHeight'
>;

type ContentWrapperOptions =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | HTMLDivElement;

const wrapperModifiers = {
  chatOpen: (theme: DefaultTheme) => css`
    right: calc(${'360px'} + 20px);
  `,
  show: () => css`
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
  `,
  default: (theme: DefaultTheme) => css`
    background: ${theme.primary};

    ${Description} {
      color: ${theme.text.details};
    }
  `,
  success: (theme: DefaultTheme) => css`
    background-color: ${theme.input.selected.outline};
    ${ButtonClose} {
      svg {
        color: ${theme.text.primary};
        height: 14px;
        width: 14px;
      }
    }
  `,
  danger: (theme: DefaultTheme) => css`
    background-color: ${'red'};
    ${ButtonClose} {
      svg {
        color: ${theme.text.primary};
        height: 14px;
        width: 14px;
      }
    }
  `,
  alert: (theme: DefaultTheme) => css`
    background-color: ${'red'};
    ${ButtonClose} {
      svg {
        color: ${theme.text.primary};
        height: 14px;
        width: 14px;
      }
    }
  `,
  conversion: (theme: DefaultTheme) => css`
    background-color: ${theme.text.details};
    align-items: flex-start;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    height: 120px;

    ${media.lessThan('large')`
+      height: 120px;
+   `}

    ${ButtonClose} {
      top: ${theme.spacing.md};
      svg {
        color: ${theme.text.primary};
        height: 14px;
        width: 14px;
      }
    }

    ${ContentWrapper} {
      padding-right: ${theme.spacing.md};
      flex-direction: column;
      height: 100%;
    }

    ${Description} {
      width: 100%;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      color: rgba(0, 0, 0, 0.8);
      /* color: ${theme.text.primary}; */
    }
  `,
  hasPlayerHeight: (theme: DefaultTheme, playerHeight: number) => css`
    /* ${media.lessThan('medium')`
      top: calc(${playerHeight}px + ${'56px'});
    `} */
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, show, type, hasChat, playerHeight }) => css`
    border-radius: ${theme.shapes.borderRadius.xs};
    font-family: ${theme.typography.family.primary};
    position: fixed;
    bottom: calc(${'80px'} + 20px);
    right: 36px;
    opacity: 0;
    padding: ${theme.spacing.sm};
    display: flex;
    justify-content: space-between;
    min-height: 80px;
    width: 416px;
    transform: translateX(100%);
    pointer-events: none;
    transition: all 0.3s ease-in-out, opacity 0.3s 0.05s linear;
    will-change: transform, opacity;
    z-index: ${'50'};

    img {
      width: 100px;
      height: 100%;
      border-radius: ${theme.shapes.borderRadius.xs};
      margin-right: ${theme.spacing.xs};
    }

    > a {
      display: flex;
      align-items: flex-start;
      width: 100%;
    }

    ${media.lessThan('medium')`
      height: max-content;
      border-radius: 0;
      top:50px;
      right:0;
      width: 100%;
      max-height: 102px;

      img {
      width: 70px;
      height: 70px;
    }
    `}

    ${show && wrapperModifiers.show()};
    ${hasChat && wrapperModifiers.chatOpen(theme)};
    ${!!type && wrapperModifiers[type](theme)};
    ${type === 'conversion' &&
    playerHeight &&
    wrapperModifiers.hasPlayerHeight(theme, playerHeight)}
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.text.primary};
    font-family: ${theme.typography.family.primary};
    font-size: ${theme.typography.sizes.base};
    font-weight: bold;
    line-height: 20px;
    margin-bottom: ${theme.spacing['2xs']};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: black;
    font-size: ${theme.typography.sizes.sm};
    font-family: ${theme.typography.family.primary};
    font-weight: 500;
    display: inline-block;
    overflow: hidden;
    width: calc(100% - 20px);

    ${media.lessThan('medium')`
      white-space: normal;
      word-wrap: normal;
      max-width: 90%;
    `}
  `}
`;

export const ContentWrapper = styled.div<ContentWrapperOptions>`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 20px);
`;

export const ActionsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    align-items: flex-end;

    > strong {
      color: rgba(0, 0, 0, 0.8);
      width: auto;
      margin-right: ${theme.spacing.sm};
      white-space: nowrap;
      padding-bottom: calc(${theme.spacing['2xs']} / 2);
    }
  `}
`;

export const ActionButton = styled.a`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.primary};
    color: ${theme.text.details};
    border-radius: ${theme.shapes.borderRadius.xs};
    padding: calc(${theme.spacing['2xs']} / 2) ${theme.spacing.sm};
    min-height: 24px;
    width: auto;
    cursor: pointer;
    white-space: nowrap;
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    border: none;
    line-height: 1;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    height: 24px;
    width: 24px;

    svg {
      color: ${theme.text.details};
      height: 14px;
      width: 14px;
    }
  `}
`;

export const IconWrapper = styled.div`
  height: 14px;
  width: 14px;
  margin-right: 8px;

  svg {
    color: black;
    height: 100%;
    width: 100%;
  }
`;
