import ContentLoader from 'react-content-loader';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

export const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 630 630"
    >
      <rect x="0" y="0" width="299" height="390" rx="4" />
      <rect x="322" y="0" width="299" height="216" rx="4" />
      <rect x="322" y="242" width="299" height="148" rx="4" />
      <rect x="0" y="417" width="299" height="148" rx="4" />
      <rect x="322" y="417" width="299" height="148" rx="4" />
    </ContentLoader>
  );
};

export const SkeletonMobile = () => {
  const { colors } = useTheme();
  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 390 1000"
    >
      <rect x="0" y="0" width="390" height="390" rx="4" />
      <rect x="0" y="412" width="390" height="216" rx="4" />
      <rect x="0" y="650" width="390" height="148" rx="4" />
      <rect x="0" y="820" width="390" height="148" rx="4" />
      <rect x="0" y="1026" width="390" height="148" rx="4" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <SkeletonMobile />
    </MediaMatch>
  </>
);
