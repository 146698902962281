import { NavLink } from 'react-router-dom';
import * as S from './FloatingLink.styles';

type FloatingLinkProps = {
  isInternal?: boolean;
  label: string;
  url: string;
  onClick?: () => void;
  disabled?: boolean;
};

const linkExternalProps = { target: '_blank', rel: 'noopener noreferrer' };

export const FloatingLink = ({
  isInternal,
  label,
  url,
  onClick,
  disabled,
}: FloatingLinkProps) => (
  <S.MenuLink
    onClick={onClick}
    to={url}
    as={url ? NavLink : 'button'}
    disabled={disabled}
    {...(isInternal ? {} : linkExternalProps)}
  >
    {label}
  </S.MenuLink>
);
