/* eslint-disable no-restricted-imports */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import {motion} from 'framer-motion'
import {
	Dispatch,
	forwardRef,
	Ref,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
//import { useLocation } from 'react-router-dom';
import { dispatchGAEvent } from 'services/google';
//import { useLikes } from 'useCases/likes';
import { useChat } from 'useCases/chat';
import { useClickOutside, useSession } from 'hooks';
import { useChatContext, useNotificationContext } from 'contexts';
import { UserChat } from 'components/contexts/interactions';
import { UserCard } from 'components/contexts/users';
import { Avatar } from 'components/contexts/users';
import { Icon } from 'components/structure';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { GA_EVENTS } from 'constants/enums';
import { InteractionsTabs } from 'constants/enums';
import { IcHeart } from 'assets/icons';
import { IcNewLike } from '../../../../../assets/icons/interactions';
import * as S from './Message.styles';

interface UseCard {
	name: string;
	userId?: string;
	avatar?: string;
	email?: string;
	country?: any;
	company?: string;
	state?: string;
}

interface deleteMessage {
	id: string;
}

export type UserCardProps = {
	handleOpenPrivateChat: (id: string) => void;
	setTab: (tab: InteractionsTabs) => void;
	isChatDisabled?: boolean;
};

export const Message = forwardRef(
	(
		{
			userIdTest,
			avatar,
			name,
			company,
			email,
			state,
			country,
			message,
			id,
			received,
			createdAt,
			isSended = true,
			isPrivate = false,
			isStaff = false,
			messageRef,
			mentions,
			handleSendReaction,
			realTimeLikes,
			userId,
			streamId,
			currentUserId,
			setUserCard,
			handleDeleteMessage,
		}: UserChat & {
			setUserCard: Dispatch<SetStateAction<UseCard>>;
			handleDeleteMessage(id: string): any;
			isLastMessage: boolean;
			messageRef: any;
			currentUserId?: string;
		} & UserCardProps,
		ref: Ref<HTMLDivElement>,
	) => {
		const data = useSession();
		const reactionIcon: string = 'like' as string;

		// const messageRef = useRef(null);
		const formattedTime =
			!!createdAt &&
			new Date(createdAt).toLocaleTimeString('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
			});

		let formattedMessage = message;

		if (
			formattedMessage?.includes('https://') ||
			formattedMessage?.includes('http://') ||
			formattedMessage?.includes('www')
		) {
			formattedMessage = formattedMessage.split(' ').map((word) => {
				if (word?.startsWith('https://') || word?.startsWith('http://')) {
					return (
						<a
							style={{
								wordBreak: 'break-all',
								textDecoration: 'underline',
								color: 'white',
							}}
							href={word}
							target={'_blank'}
							rel={'noreferrer'}
						>
							{' '}
							<i>{word}</i>
						</a>
					);
				}
				if (word?.startsWith('www')) {
					return (
						<a
							style={{
								wordBreak: 'break-all',
								textDecoration: 'underline',
								color: 'white',
							}}
							href={`https://${word}`}
							target={'_blank'}
							rel={'noreferrer'}
						>
							{' '}
							<i>{word}</i>
						</a>
					);
				}
				return <> {word}</>;
			}) as any;
		}

		function CountLike(arr: any) {
			const arr2 = arr.map((item: any) => item?.userId);
			const teste2 = Array.from(new Set(arr2)).filter((e) => e);
			return teste2.length;
		}
		// Enviada
		if (isPrivate || !received) {
			return (
				<S.BoxMensagerSend ref={ref || messageRef} >
					<S.MessageSent isPending={!isSended} isReceived={received}>
						<S.FirstBox>
							<p>{formattedMessage}</p>

							{data?.role === 'staff' && (
								<S.IconContainer onClick={() => handleDeleteMessage(id)}>
									<Icon
										icon="IcTrash"
										style={{
											cursor: 'pointer',
										}}
										width="16px"
										height="16px"
										color="red"
									/>
								</S.IconContainer>
							)}
						</S.FirstBox>
						<S.DateSent>{formattedTime}</S.DateSent>
					</S.MessageSent>

					{isPrivate ? (
						<></>
					) : (
						<div className={'likeContainerSent'}>
							{CountLike(realTimeLikes) > 0 && (
								<>
									<S.LikeButtonSend className={'liked'}>
										{/* <IcNewLike /> */}
										{reactionIcon === 'heart' ? <IcHeart style={{alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center'}}/>
									:
									<IcNewLike />
									}
									</S.LikeButtonSend>


									<span style={{ display: 'inline', color: 'gray' }}>
										{CountLike(realTimeLikes) || ''}
									</span>
								</>
							)}
						</div>
					)}
				</S.BoxMensagerSend>
			);
		}

		function emitLikeOrDislike() {
			const userAlreadyLikedComment = realTimeLikes?.find(
				(like: any) => like.userId === currentUserId,
			);
			const eventToEmit = userAlreadyLikedComment ? 'dislike' : 'new_like';

			handleSendReaction(eventToEmit, {
				reactType: eventToEmit,
				eventId: process.env.REACT_APP_EVENT_ID,
				streamId,
				messageId: id,
				userId,
				id: userAlreadyLikedComment?.id,
			});
		}

		if (mentions) {
			mentions.forEach((mention, index) => {
				message = message?.replace(
					mention?.username || mention?.name,
					`mention-${index}`,
				);
			});
		}
		const splittedMessage = message?.split(' ');

		let messageElement;
		if (mentions && mentions.length) {
			messageElement = splittedMessage?.map((word, index) => {
				const currentMention = mentions ? mentions[0] : '';
				word = word.replace(/[@]/g, '');
				if (word?.startsWith('mention-') && mentions && mentions?.length) {
					const mentionIndex: string = (word.split('-') as any).at(-1);
					const mentionUserName =
						mentions[Number(mentionIndex)]?.username ||
						mentions[Number(mentionIndex)]?.name;

					return (
						<>
							<S.Mention
								onClick={() =>
									setUserCard({
										name: mentionUserName,
										userId: mentions[Number(index)]?.id,
										country: mentions[Number(index)]?.country,
										avatar: mentions[Number(index)]?.avatar,
										email: mentions[Number(index)]?.email,
										company: mentions[Number(index)]?.company,
										state: mentions[Number(index)]?.state,
									})
								}
							>
								{' '}
								@{mentionUserName}
							</S.Mention>
						</>
					);
				}
				if (
					splittedMessage[index - 1] + ' ' + word ==
					currentMention?.username
				) {
					return <></>;
				}
				if (word.startsWith('https://')) {
					return (
						<a
							style={{
								wordBreak: 'break-all',
								textDecoration: 'underline',
								color: 'white',
							}}
							href={word}
							target={'_blank'}
							rel={'noreferrer'}
						>
							{word}
						</a>
					);
				}
				return <> {word}</>;
			});
		} else {
			messageElement = message?.split(' ').map((word) => {
				if (word.startsWith('https://') || word.startsWith('http://')) {
					return (
						<a
							style={{
								wordBreak: 'break-all',
								textDecoration: 'underline',
								color: 'white',
							}}
							href={word}
							target={'_blank'}
							rel={'noreferrer'}
						>
							{' '}
							{word}
						</a>
					);
				}
				if (word.startsWith('www')) {
					return (
						<a
							style={{
								wordBreak: 'break-all',
								textDecoration: 'underline',
								color: 'white',
							}}
							href={`https://${word}`}
							target={'_blank'}
							rel={'noreferrer'}
						>
							{' '}
							{word}
						</a>
					);
				}
				return <> {word}</>;
			});
		}
		// Recebida
		return (

			<S.MessageWrapper key={id} ref={ref || messageRef} isStaff={isStaff}
			>
				<S.MessageBox>

				<Avatar
					src={avatar}
					alt={name}
					size="small"
					onclick={() =>
						setUserCard({
							name: name,
							userId: userIdTest,
							avatar: avatar,
							country: country,
							email: email,
							company: company,
							state: state,
						})
					}
				/>

				<S.Name>
					<S.BoxName>
						{name}

					</S.BoxName>
					{data?.role === 'staff' && (
						<S.IconContainer
							onClick={() => handleDeleteMessage(id)}
						>
							<Icon
								icon="IcTrash"
								style={{
									cursor: 'pointer',
								}}
								width="16px"
								height="16px"
								color="red"
							/>
						</S.IconContainer>
					)}
				</S.Name>
				<S.Message ref={messageRef}>
					<>{messageElement}</>
					<S.LikeContainer>
					<S.Date>{formattedTime}</S.Date>
						<S.LikeButton
							className={'liked'}
							onClick={emitLikeOrDislike}
							reactionType={reactionIcon}
							liked={realTimeLikes?.find(
								(like: any) => like?.userId === currentUserId,
							)}
						>
							{reactionIcon === 'heart' ? <IcHeart /> : <IcNewLike />}


						</S.LikeButton>
						{CountLike(realTimeLikes) !== 0 && <span style={{ display: 'inline', color: 'gray', paddingLeft: '.25rem' }}>
							{CountLike(realTimeLikes) || ''}
						</span>}


					</S.LikeContainer>
				</S.Message>
				</S.MessageBox>
			</S.MessageWrapper>


		);
	},
);
