import { createContext, useContext, useState } from 'react';
import { BusinessCardProps } from 'components/contexts/users';
import { WithChildren } from 'interfaces/children';

type ContextType = {
  show: boolean;
  setShow: (isOpen: boolean) => void;
  cardProps: BusinessCardProps;
  setCardProps: (props: BusinessCardProps) => void;
};

const defaultValue: ContextType = {
  show: false,
  setShow: () => undefined,
  cardProps: {},
  setCardProps: () => undefined,
};

export const BusinessCardContext = createContext<ContextType>(defaultValue);

export const BusinessCardProvider = ({ children }: WithChildren) => {
  const [show, setShow] = useState(defaultValue.show);
  const [cardProps, setCardProps] = useState<BusinessCardProps>(
    defaultValue.cardProps,
  );

  return (
    <BusinessCardContext.Provider
      value={{
        show,
        setShow,
        cardProps,
        setCardProps,
      }}
    >
      {children}
    </BusinessCardContext.Provider>
  );
};

export const useBusinessCardContext = () => useContext(BusinessCardContext);
