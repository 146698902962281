import { useCoffee, useUsersOnlineByCountries } from 'useCases/coffee';
import { useTranslate } from 'hooks';
import { ThemeCustomProvider } from 'contexts';
import { Container, Heading } from 'components/structure';
import * as CoffeeTheme from 'styles/overrides/Coffee.theme';
import { WithChildren } from 'interfaces/children';
import { CoffeeLayout } from './Coffee.layouts';
import { Skeleton } from './Coffee.skeleton';
import * as S from './Coffee.styles';

type CoffeeWrapperProps = WithChildren;

export const CoffeeWrapper = ({ children }: CoffeeWrapperProps) => {
  const translate = useTranslate();
  return (
    <Container>
      <S.Wrapper>
        <S.Content>
          {CoffeeTheme.options.heading && (
            <Heading>{translate('coffee.title')}</Heading>
          )}
        </S.Content>
        <S.Content>{children}</S.Content>
      </S.Wrapper>
    </Container>
  );
};

export const Coffee = () => {
  const { data, isLoading } = useCoffee();
  const coffeMap = useUsersOnlineByCountries();

  if (isLoading) {
    return (
      <CoffeeWrapper>
        <Skeleton />
      </CoffeeWrapper>
    );
  }

  return (
    <ThemeCustomProvider {...CoffeeTheme}>
      <CoffeeWrapper>
        <CoffeeLayout
          layout={CoffeeTheme.options?.template ?? 'default'}
          coffeeMap={coffeMap.data}
          data={data}
        />
      </CoffeeWrapper>
    </ThemeCustomProvider>
  );
};
