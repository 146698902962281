import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { MediaMatch } from 'components/structure';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 331 1000"
    >
      <rect x="0" y="0" width="318" height="30" />
      <rect x="0" y="55" width="293" height="25" />
      <rect x="0" y="88" width="322" height="20" />

      <rect x="0" y="142" width="327" height="48" />
      <rect x="0" y="222" width="327" height="48" />

      <rect x="0" y="302" width="327" height="48" />
      <rect x="0" y="382" width="327" height="48" />
      <rect x="0" y="462" width="327" height="48" />
      <rect x="0" y="542" width="327" height="48" />
      <rect x="0" y="622" width="327" height="48" />
      <rect x="0" y="702" width="327" height="48" />

      <rect x="0" y="782" width="327" height="48" />
      <rect x="30" y="835" width="255" height="4" />
      <rect x="0" y="862" width="327" height="48" />

      <rect x="10" y="962" width="311" height="48" />
    </ContentLoader>
  );
};

const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 630 630"
    >
      <rect x="0" y="0" width="416" height="40" />
      <rect x="0" y="47" width="393" height="25" />
      <rect x="0" y="72" width="416" height="20" />

      <rect x="0" y="124" width="296" height="48" />
      <rect x="334" y="124" width="296" height="48" />

      <rect x="0" y="204" width="296" height="48" />
      <rect x="334" y="204" width="296" height="48" />

      <rect x="0" y="284" width="296" height="48" />
      <rect x="334" y="284" width="296" height="48" />

      <rect x="0" y="364" width="296" height="48" />
      <rect x="334" y="364" width="296" height="48" />

      <rect x="0" y="444" width="296" height="48" />
      <rect x="334" y="444" width="296" height="48" />

      <rect x="20" y="500" width="255" height="7" />

      <rect x="0" y="558" width="230" height="48" />
    </ContentLoader>
  );
};

export const Skeleton = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <Container>
        <SkeletonMobile />
      </Container>
    </MediaMatch>
  </>
);
