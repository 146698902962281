import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    padding: ${theme.spacing.md} ${theme.spacing['2xl']};
    ${media.lessThan('medium')`
      padding: ${theme.spacing.md};
    `}
  `}
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
`;
