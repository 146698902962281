/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useState, useEffect, useRef } from 'react';
import { isSafari } from 'react-device-detect';
import ReactPlayer from 'react-player/youtube';
import YouTubePlayer, { YouTubePlayerProps } from 'react-player/youtube';
import screenfull from 'screenfull';
import {
  getLocalStorageData,
  setLocalStorageData,
} from 'lib/contexts/localStorage';
import { useMiniPlayerContext } from 'contexts';
import { Icon } from 'components/structure';
import { YT_MUTED, YT_VOLUME } from 'constants/storage';
import * as S from './YoutubeContainer.styles';

type YoutubeContainerProps = {
  url: string;
  isMiniPlayer?: boolean;
};

type ProgressPayload = {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
};

type WrapperProps = {
  webkitRequestFullscreen: () => void;
} & HTMLDivElement;

const VOLUME_DEFAULT = 100;

const TRANSITION = {
  power: 0,
  min: 0,
  max: 200,
  timeConstant: 250,
};

const muteCached = getLocalStorageData<boolean>(YT_MUTED);
const volumeCached = getLocalStorageData<number>(YT_VOLUME);

export const YoutubeContainer = ({
  url,
  isMiniPlayer = false,
}: YoutubeContainerProps) => {
  const constraintsRef = useRef<Element>(document.documentElement);
  const playerRef = useRef<YouTubePlayer>(null);
  const wrapperRef = useRef<WrapperProps>(null);
  const [player, setPlayer] = useState<YouTubePlayer>({} as YouTubePlayer);
  const [playing, setPlaying] = useState<boolean>(false);
  const [muted, setMuted] = useState<boolean>(muteCached ?? false);
  const [volume, setVolume] = useState<number>(volumeCached ?? VOLUME_DEFAULT);
  const [progress, setProgress] = useState<number>(0);
  const [maxDuration, setMaxDuration] = useState(0);
  const [isSeeking, setIsSeeking] = useState<boolean>(false);
  const [isLiveStream, setIsLiveStream] = useState<boolean>(false);
  const { toggle: toggleMiniPlayer } = useMiniPlayerContext();

  const togglePlay = () => setPlaying(!playing);

  const toggleMuted = () => {
    setMuted(!muted);
    setLocalStorageData(YT_MUTED, !muted);
  };

  const onVolumeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = Number(event.target.value);
    const internalPlayer = player.getInternalPlayer();
    setVolume(parsedValue);
    setLocalStorageData(YT_VOLUME, parsedValue);
    setMuted(false);
    internalPlayer.setVolume(parsedValue);
  };

  const onFullscreenChange = () => {
    if (!wrapperRef.current) return;

    const fullscreenElement =
      document.fullscreenElement || (document as any).webkitFullscreenElement;

    if (!fullscreenElement) {
      if (wrapperRef.current.requestFullscreen) {
        return wrapperRef.current.requestFullscreen();
      } else if (wrapperRef.current.webkitRequestFullscreen) {
        return wrapperRef.current.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        return document.exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) {
        return (document as any).webkitExitFullscreen();
      }
    }

    if (screenfull.isEnabled && wrapperRef.current) {
      screenfull.toggle(wrapperRef.current);
    }
  };

  const handleMaxDuration = (): void => {
    const duration = player.getCurrentTime();
    setMaxDuration(duration);
  };

  const onReady = (ytPlayer: YouTubePlayerProps) => {
    const duration = ytPlayer.getDuration();
    if (!duration) setIsLiveStream(true);
    setMaxDuration(duration);
    if (!isSafari) setPlaying(true);
  };

  const onProgress = ({ playedSeconds }: ProgressPayload) => {
    if (isLiveStream && !maxDuration) handleMaxDuration();
    if (!isSeeking) setProgress(playedSeconds);
  };

  const onProgressChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isLiveStream) return;

    const internalPlayer = player.getInternalPlayer();
    const progress = Number(event.target.value);
    setProgress(progress);
    internalPlayer.seekTo(progress, 'seconds');
    if (!playing) internalPlayer.pauseVideo();
  };

  useEffect(() => {
    if (playerRef.current) {
      setPlayer(playerRef.current);
    }
  }, [playerRef]);

  const percent = maxDuration ? (progress / maxDuration) * 100 : 0;

  return (
    <S.Wrapper
      ref={wrapperRef}
      drag={isMiniPlayer}
      isMiniPlayer={isMiniPlayer}
      dragConstraints={constraintsRef}
      dragElastic={0.1}
      dragTransition={TRANSITION}
      whileTap={{ cursor: 'grabbing' }}
    >
      <ReactPlayer
        ref={playerRef}
        className="react-player"
        width="100%"
        height="100%"
        url={url}
        controls={false}
        pip={false}
        playing={playing}
        muted={muted}
        onProgress={onProgress}
        onReady={onReady}
        onEnded={() => setPlaying(false)}
      />

      {isMiniPlayer ? (
        <S.ControlsWrapper hasMiniPlayer>
          <S.Controls hide={playing}>
            <S.ControlItem>
              <S.Control onClick={togglePlay}>
                <Icon icon={playing ? 'IcPause' : 'IcPlay'} />
              </S.Control>
            </S.ControlItem>

            <S.ControlItem>
              <S.Control onClick={toggleMiniPlayer}>
                <Icon icon="IcPip" />
              </S.Control>
            </S.ControlItem>
          </S.Controls>
        </S.ControlsWrapper>
      ) : (
        <S.ControlsWrapper>
          <S.Play onClick={togglePlay} />

          <S.Controls hide={playing}>
            <S.ControlItem>
              <S.Control onClick={togglePlay}>
                <Icon icon={playing ? 'IcPause' : 'IcPlay'} />
              </S.Control>
            </S.ControlItem>

            <S.ControlItem>
              <S.ProgressBar
                type="range"
                step="any"
                onChange={onProgressChange}
                value={progress}
                min="0"
                max={maxDuration}
                onMouseDown={() => setIsSeeking(true)}
                onMouseLeave={() => setIsSeeking(false)}
                percent={percent}
              />
            </S.ControlItem>

            <S.ControlItem>
              <S.VolumeWrapper>
                <S.Control onClick={toggleMuted}>
                  <Icon icon={muted ? 'IcMute' : 'IcVolume'} />
                </S.Control>

                <S.VolumeBarWrapper>
                  <S.VolumeBar
                    type="range"
                    onChange={onVolumeChange}
                    value={muted ? 0 : volume}
                  />
                </S.VolumeBarWrapper>
              </S.VolumeWrapper>
            </S.ControlItem>

            <S.ControlItem>
              <S.Control onClick={toggleMiniPlayer}>
                <Icon icon="IcPip" />
              </S.Control>
            </S.ControlItem>

            <S.ControlItem>
              <S.Control onClick={onFullscreenChange}>
                <Icon icon="IcFullscreen" />
              </S.Control>
            </S.ControlItem>
          </S.Controls>
        </S.ControlsWrapper>
      )}
    </S.Wrapper>
  );
};
