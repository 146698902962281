import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { GalleryProps } from './Gallery';

type WrapperProps = Pick<GalleryProps, 'showModal'>;
type WrapperSliderProps = {
  isVideo?: boolean;
};

const wrapperModifiers = {
  showModal: () => css`
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s linear, visibility 0s 0s;
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, showModal }) => css`
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s linear, visibility 0s 0.2s;
    z-index: 100;
    overflow: hidden;
    padding: ${theme.spacing['2xl']} ${theme.spacing['2xl']} 0
      ${theme.spacing['2xl']};

    ${media.lessThan('medium')`
      padding: ${theme.spacing.xl} 0;
    `}

    ${showModal && wrapperModifiers.showModal()};
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.primary};
    cursor: pointer;
    position: relative;
    width: 100%;
    max-width: 1088px;
    text-align: right;
    margin-bottom: 40px;

    ${media.lessThan('medium')`
      margin-right: 10%;
      padding: 0;
      margin-bottom: 0;
    `}

    svg {
      width: 24px;
      height: 24px;
    }
  `}
`;

export const Content = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const wrapperSliderModifiers = {
  isVideo: () => css`
    .slick-slide > div > div > div {
      max-height: 512px;

      ${media.lessThan('medium')`
          min-height: 184px;
      `}
    }
  `,
};

export const WrapperSlider = styled.section<WrapperSliderProps>`
  ${({ isVideo, theme }) => css`
    height: 100%;
    display: flex;
    align-items: flex-start;

    ${media.lessThan('medium')`
      margin-top: 0;
    `}

    .slick-slider {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      box-sizing: border-box;
      height: 100%;
      width: 100%;

      ${media.lessThan('medium')`
        width: 100vw;
      `}
    }

    .slick-track {
      width: 100%;
      display: flex;
      height: 100%;
    }

    .slick-slide {
      flex: 1 0 auto;
      height: 100%;
      max-width: 1088px;
      display: flex;
      position: relative;

      ${media.lessThan('medium')`
          display: flex;
          justify-content: center;
          align-items: center;
      `}
    }

    .slick-slide > div > div {
      ${media.greaterThan('medium')`
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 84px;
      `}
    }

    .slick-slide > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding-bottom: ${theme.spacing.lg};

      ${media.lessThan('medium')`
        padding-bottom: 0;
        justify-content: center;
      `}
    }

    .slick-list {
      position: relative;
      display: flex;
      width: 1088px;
      height: 100%;
      align-items: center;
      overflow: hidden;
      margin: 0;
      padding: 0;

      ${media.lessThan('medium')`
        width: 100%;
        max-width: 90%;
      `}
    }
    .slick-list:focus {
      outline: none;
    }
    .slick-list.dragging {
      cursor: pointer;
    }

    .slick-prev,
    .slick-next {
      display: block;
      cursor: pointer;
      position: absolute;
      top: 40%;
      transform: translate(0, -50%);
      z-index: 1;
      border-radius: 50%;
      height: 64px;
      width: 64px;
      padding: ${theme.spacing.sm};

      ${media.lessThan('medium')`
        background: ${theme.secondary};
        height: 40px;
        width: 40px;
        padding: 10px;

        ${media.lessThan('medium')`
            top: 50%;
        `}

      `}
    }

    .slick-prev {
      left: -10%;

      ${media.lessThan('medium')`
        left: 10%;
      `}
    }
    .slick-next {
      right: -10%;

      ${media.lessThan('medium')`
        right: 10%;
      `}
    }

    .slick-prev.slick-disabled,
    .slick-next.slick-disabled {
      visibility: hidden;
    }

    ${isVideo && wrapperSliderModifiers.isVideo()}
  `}
`;

type SliderImageProps = {
  src: string;
};

export const SliderImage = styled.div<SliderImageProps>`
  ${({ src }) => css`
    background-image: url(${src});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 1088px;
    height: 100%;
    max-height: 512px;

    ${media.lessThan('medium')`
      height: 454px;
    `}
  `}
`;

export const DownloadButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  a {
    border: 1px solid currentColor;
  }

  ${media.lessThan('medium')`
    position: absolute;
    bottom: 0;
    left: 25%;
    align-items: flex-end;
  `}
`;
