import { Icon } from 'components/structure';
import * as S from './PlenaryIcon.styles';

export type PlenaryIconProps = {
  size?: 'small' | 'medium' | 'large';
};

export const PlenaryIcon = ({ size = 'medium' }: PlenaryIconProps) => (
  <S.Wrapper role="img" size={size}>
    <Icon icon="IcChat" />
  </S.Wrapper>
);
