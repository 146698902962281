import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslate } from 'hooks';
import { TextField } from 'components/form';
import {
  Button,
  Form,
  FormActions,
  FormGroup,
  Heading,
  Paragraph,
} from 'components/structure';

const PersonalDataForm = () => {
  const translate = useTranslate();
  const { register } = useForm();

  return (
    <Form>
      <Heading light>{translate('persona-data.title')}</Heading>
      <Paragraph>{translate('persona-data.description')}</Paragraph>

      <FormGroup>
        <TextField ref={register} name="name" label={translate('input.name')} />
        <TextField
          ref={register}
          name="businessCardName"
          label={translate('input.profileName')}
        />
      </FormGroup>

      <FormActions>
        <Button as={Link} fullWidthMobile color="secondary">
          {translate('input.back')}
        </Button>
        <Button fullWidthMobile type="submit">
          {translate('input.nextItem')}
        </Button>
      </FormActions>
    </Form>
  );
};

export default PersonalDataForm;
