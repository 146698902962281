import styled, { css, DefaultTheme } from 'styled-components';

type LanguageDisplayProps = {
  show?: boolean;
  activatedOnlyFocus?: boolean;
};

const languageDisplayModifiers = {
  open: () => css`
    svg {
      transform: rotate(-180deg);
    }
  `,

  activatedOnlyFocus: (theme: DefaultTheme, show = false) => css`
    strong {
      color: ${show ? theme.text.secondary : theme.text.secondary};
    }
    svg path {
      fill: ${show ? theme.text.secondary : theme.text.secondary};
    }
  `,
};

export const LanguageContainer = styled.div`
  ${({ theme }) => css`
    /* margin-left: ${theme.spacing.lg};
    margin-right: ${theme.spacing.xl}; */
    position: relative;
    color: #fff;

    > small {
      color: ${theme.text.secondary};

      font-weight: bold;
      white-space: nowrap;
    }
  `}
`;

export const LanguageDisplay = styled.div<LanguageDisplayProps>`
  ${({ theme, show, activatedOnlyFocus }) => css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.xs};
    color: ${theme.text.secondary};

    > strong {
      text-transform: uppercase;
      color: ${theme.text.secondary};
      font-size: ${theme.typography.sizes.sm};
      margin-right: ${theme.spacing.xs};
    }

    svg {
      height: 6px;
      transition: transform 0.2s linear;

      color: ${theme.text.secondary};
    }

    ${show && languageDisplayModifiers.open()};
    ${activatedOnlyFocus &&
    languageDisplayModifiers.activatedOnlyFocus(theme, show)};
  `}
`;
