import { handleBlurInput } from 'helpers/handleBlurInput';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  useHandleUserExists,
  useIncorrectDomain,
} from 'useCases/accreditation';
import { useEvent } from 'useCases/events';
import { useQueryString, useToast, useTranslate } from 'hooks';
import { useAccreditationContext, useIntlContext } from 'contexts';
import { GettingStartedForm } from 'components/contexts/accreditation';
import { UserExistsParams, User } from 'interfaces/user';
import { ROUTES } from 'constants/urls';
const { accreditation } = ROUTES;

export const GettingStarted = () => {
  const navigate = useNavigate();
  const { language } = useIntlContext();
  const { setGettingStarted } = useAccreditationContext();
  const translate = useTranslate();
  const { data: event } = useEvent();
  const { setToast } = useToast();
  const params = useQueryString();
	const { search } = useLocation();
  const items = search.split('&');
	const data = items.reduce((prev: { [key: string]: string }, curr: string) => {
    const [key, value] = curr.split('=');
    prev[key.replace('?', '')] = value;
    return prev;
  }, {});
	console.log({...data})
  const onUserNotExists = (uuid: string, userGettingStarted?: User) => {
    setGettingStarted({
      ...userGettingStarted,
      uuid,
      email: uuid,
      preferableLanguage: language,
      ...params,
			referrer: {
				...data,
			},
    });
    navigate(accreditation.getLink('createBusinessCard'));
  };

  const onEventIsClosedAndUserNotExists = () => {
    setToast({
      type: 'alert',
      description: translate('errors.userNotExists'),
    });
  };
  const { incorrectDomain } = useIncorrectDomain();

  const { handleUserExists, isPending } = useHandleUserExists({
    incorrectDomain,
    onUserNotExists,
    onEventIsClosedAndUserNotExists,
  });

  const onSubmit = (payload: UserExistsParams) => {
    handleBlurInput(document);
    return handleUserExists(payload);
  };

  return (
    <GettingStartedForm
      onSubmit={onSubmit}
      isPending={isPending}
      showSupportButton={event?.event.zendesk?.active}
    />
  );
};
