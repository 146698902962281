import { SubTitle } from 'components/structure';
import * as S from './SponsorItem.styles';

type SponsorType = 'small' | 'medium' | 'large';

export type SponsorsProps = {
  image: string;
  href?: string;
};

export type SponsorItemProps = {
  category: string;
  type: SponsorType;
  sponsors: SponsorsProps[];
};

export const SponsorItem = ({ category, type, sponsors }: SponsorItemProps) => (
  <S.Wrapper>
    <SubTitle>{category}</SubTitle>
    <S.Banners>
      {sponsors.map(({ href, image }) => (
        <a key={href} href={href} target="_blank" rel="noreferrer">
          <S.Banner src={image} alt="sponsor image" type={type} />
        </a>
      ))}
    </S.Banners>
  </S.Wrapper>
);
