import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Country, useCountries } from 'useCases/assets';
import { useEventById } from 'useCases/events';
import { useUpdateUser, useUserById } from 'useCases/users';
import { useEnv, useTranslate, useToast } from 'hooks';
import { isBayer } from 'lib/contexts';
import { retrieveUserSession } from 'lib/core/session';
import { Language, useBusinessCardContext, useIntlContext } from 'contexts';
import { brazilianStates } from 'components/contexts/accreditation/BusinessCardForm/Default/BusinessCardForm.mock';
import {
  TextField,
  PasswordField,
  CustomSelect,
  Option,
} from 'components/form';
import {
  Button,
  Form,
  FormGroup,
  MediaMatch,
  TrickSpace,
  FooterPoweredBy,
} from 'components/structure';
import { User } from 'interfaces/user';
import { BRAZIL_COUNTRY_CODE } from 'constants/enums';
import { Skeleton } from './ConfigurationsPersonalData.skeleton';
import * as S from './ConfigurationsPersonalData.styles';
import {
  schemaBayer,
  defaultValuesBayer,
} from './ConfigurationsPersonalData.validations';

type FormModel = {
  rePassword: string;
} & User;

export const ConfigurationsPersonalData = () => {
  const { setLanguage } = useIntlContext();
  const { data: countriesData } = useCountries();
  const { eventId } = useEnv();
  const { isLoading, data } = useEventById(eventId);
  const { isPending, updateUser } = useUpdateUser();
  const { setCardProps, setShow } = useBusinessCardContext();
  const { id: userId } = retrieveUserSession();
  const userById = useUserById(userId!);
  const { setToast } = useToast();
  const translate = useTranslate();
  const {
    errors,
    control,
    register,
    handleSubmit,
    watch,
    reset,
  } = useForm<FormModel>({
    resolver: yupResolver(schemaBayer),
    defaultValues: defaultValuesBayer,
  });

  // TODO inconsistencia na API user recebe country como string porem countries endpoint retorna como number
  const countries = useMemo(
    () =>
      countriesData?.map(
        ({ label, value }) => ({ label, value: value.toString() } as Option),
      ) || [],
    [countriesData],
  );

  const profiles: Option[] = useMemo(
    () =>
      data?.event.profiles.map(({ value, id }) => ({
        value: id,
        label: value,
      })) || [],
    [data?.event.profiles],
  );

  const languages: Option[] = useMemo(
    () =>
      data?.event.languages.map(
        (option) =>
          ({
            label: translate(`labels.${option}`),
            value: option,
          } as Option),
      ) || [],
    [data?.event.languages, translate],
  );

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const specialty: string = watch('profile.specialty');
  const email = watch('email');
  const countryForm = watch('country');
  const stateForm = watch('state');

  const country = countries?.find(({ value }) => value === countryForm);
  const state = brazilianStates.find(({ value }) => value === stateForm)?.label;
  const user = userById?.data?.user;
  const image = user?.avatar;
  const isEmailBayer = isBayer(email ?? '');
  const flagIsBayer = isEmailBayer ? 'bayerId' : 'professionalId';

  const onSubmit = async (data: User) => {
    await updateUser(data);
    if (data.preferableLanguage) {
      setLanguage(data.preferableLanguage as Language);
    }
    setToast({
      type: 'success',
      hasChat: false,
      description: translate('okay'),
    });
  };

  useEffect(() => {
    if (user) {
      reset({
        ...user,
        country: (user.country as Country).value.toString(),
      });
    }
  }, [user, reset]);

  useEffect(() => {
    const profile = profiles.find(({ value }) => value === specialty)?.label;
    setCardProps({
      image,
      name: firstName ? `${firstName} ${lastName}` : undefined,
      profile: isEmailBayer ? 'Bayer' : profile,
      email,
      country,
      state,
    });
  }, [
    setCardProps,
    isEmailBayer,
    image,
    profiles,
    firstName,
    lastName,
    specialty,
    email,
    country,
    state,
  ]);

  useEffect(() => {
    setShow(true);
    return () => setShow(false);
  }, [setShow]);

  if (isLoading || userById.isLoading) {
    return <Skeleton />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup lgMargin>
        <TextField
          name="firstName"
          ref={register}
          label={translate('labels.name')}
          placeholder={translate('labels.name')}
          error={!!errors?.firstName?.message}
          helperText={translate(errors?.firstName?.message)}
        />
        <TextField
          name="lastName"
          ref={register}
          label={translate('labels.lastName')}
          placeholder={translate('labels.lastName')}
          error={!!errors?.lastName?.message}
          helperText={translate(errors?.lastName?.message)}
        />
      </FormGroup>
      <FormGroup lgMargin>
        <CustomSelect
          control={control}
          name="country"
          label={translate('labels.country')}
          placeholder={translate('labels.country')}
          error={errors?.country?.message}
          options={countries}
        />
        {Number(countryForm) === BRAZIL_COUNTRY_CODE ? (
          <CustomSelect
            control={control}
            name="state"
            label={translate('labels.state')}
            placeholder={translate('labels.state')}
            error={errors?.state?.message}
            options={brazilianStates}
          />
        ) : (
          <TrickSpace />
        )}
      </FormGroup>
      {isEmailBayer ? (
        <>
          <FormGroup lgMargin>
            <CustomSelect
              control={control}
              name="preferableLanguage"
              label={translate('labels.language')}
              placeholder={translate('labels.language')}
              error={errors?.preferableLanguage?.message}
              options={languages}
            />
            <TextField
              name="professionalId"
              ref={register}
              label={translate(`labels.${flagIsBayer}`)}
              placeholder={translate(`labels.${flagIsBayer}`)}
              error={!!errors?.professionalId?.message}
              helperText={translate(errors?.professionalId?.message)}
            />
          </FormGroup>
          <FormGroup lgMargin>
            <TextField
              readOnly
              name="email"
              ref={register}
              label={translate('labels.email')}
              placeholder={translate('labels.email')}
              error={!!errors?.email?.message}
              helperText={translate(errors?.email?.message)}
            />
          </FormGroup>
        </>
      ) : (
        <FormGroup lgMargin>
          <CustomSelect
            control={control}
            name="profile.specialty"
            label={translate('labels.specialty')}
            placeholder={translate('labels.specialty')}
            error={errors?.profile?.specialty?.message}
            options={profiles}
          />
          <CustomSelect
            control={control}
            name="preferableLanguage"
            label={translate('labels.language')}
            placeholder={translate('labels.language')}
            error={errors?.preferableLanguage?.message}
            options={languages}
          />
          <TextField
            name="professionalId"
            ref={register}
            label={translate(`labels.${flagIsBayer}`)}
            placeholder={translate(`labels.${flagIsBayer}`)}
            error={!!errors?.professionalId?.message}
            helperText={translate(errors?.professionalId?.message)}
          />
          <TextField
            readOnly
            name="email"
            ref={register}
            label={translate('labels.email')}
            placeholder={translate('labels.email')}
            error={!!errors?.email?.message}
            helperText={translate(errors?.email?.message)}
          />
        </FormGroup>
      )}

      <FormGroup lgMargin>
        <PasswordField
          name="password"
          autoComplete="new-password"
          ref={register}
          label={translate('labels.password')}
          placeholder={translate('labels.password')}
          error={!!errors?.password?.message}
          helperText={translate(errors?.password?.message)}
          minLength={8}
          showStrength
        />
        <PasswordField
          name="rePassword"
          autoComplete="new-password"
          ref={register}
          label={translate('labels.passwordConfirmation')}
          placeholder={translate('labels.passwordConfirmation')}
          error={!!errors?.rePassword?.message}
          helperText={translate(errors?.rePassword?.message)}
          minLength={8}
        />
      </FormGroup>
      <MediaMatch lessThan="large">
        <FooterPoweredBy />
      </MediaMatch>

      <S.FormActions>
        <Button type="submit" isLoading={isPending} disabled={isPending}>
          {translate('configurations.save')}
        </Button>
      </S.FormActions>
    </Form>
  );
};
